import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client/react';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DeviceToken: string & {
    brand: 'DeviceToken';
  };
  FormattedText: string & {
    brand: 'FormattedText';
  };
  SessionToken: string & {
    brand: 'SessionToken';
  };
  UtcTimestamp: string & {
    brand: 'UtcTimestamp';
  };
};
export type AcceptFriendRequestResponse = Success | InternalError;
export type AccessDeniedError = {
  readonly __typename?: 'AccessDeniedError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ActivateMentorSystemResponse = Success | InternalError;
export enum ActivateProfileVisitorsFeatureError {
  Internal = 'INTERNAL',
}
export type ActivateProfileVisitorsFeatureResponse = {
  readonly __typename?: 'ActivateProfileVisitorsFeatureResponse';
  readonly error?: Maybe<ActivateProfileVisitorsFeatureError>;
};
export type ActivePremiumSubscriptionError = {
  readonly __typename?: 'ActivePremiumSubscriptionError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type AddPreviousSearchMutationResponse = Success | InternalError;
export type AddToWatchlistResponse = Success | WatchlistLimitReachedError | InternalError;
export type AdultChannelsShownInLastVisitedChanged = {
  readonly __typename?: 'AdultChannelsShownInLastVisitedChanged';
  readonly adultChannelsShownInLastVisited?: Maybe<Scalars['Boolean']>;
};
export type AdultChannelsVisibleChanged = {
  readonly __typename?: 'AdultChannelsVisibleChanged';
  readonly adultChannelsVisible?: Maybe<Scalars['Boolean']>;
};
export type Album = {
  readonly __typename?: 'Album';
  /** The Album ID */
  readonly id: Scalars['ID'];
  /** The Album title */
  readonly title: Scalars['String'];
  /** Returns a list of all album photos */
  readonly albumPhotos: ReadonlyArray<AlbumPhoto>;
  /** The Flag is set for the owner */
  readonly isOwner: Scalars['Boolean'];
};
export type AlbumLimitReachedError = {
  readonly __typename?: 'AlbumLimitReachedError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type AlbumPhoto = {
  readonly __typename?: 'AlbumPhoto';
  /** Photo ID */
  readonly id: Scalars['ID'];
  /** The photo description */
  readonly description?: Maybe<AlbumPhotoDescription>;
  /** The url of the photo */
  readonly photoUrl: Scalars['String'];
  /** The url of the thumbnail */
  readonly thumbnailUrl: Scalars['String'];
  /** The timestamp when the photo was uploaded */
  readonly uploadTimestamp: Scalars['UtcTimestamp'];
  /** Is only set if viewing user has administration privileges */
  readonly administrationUrl?: Maybe<Scalars['String']>;
  /** The Flag is set for the owner */
  readonly isOwner: Scalars['Boolean'];
};
export type AlbumPhotoComment = {
  readonly __typename?: 'AlbumPhotoComment';
  readonly id: Scalars['ID'];
  readonly sender: User;
  readonly timestamp: Scalars['UtcTimestamp'];
  readonly text: Scalars['FormattedText'];
};
export type AlbumPhotoDescription = {
  readonly __typename?: 'AlbumPhotoDescription';
  /** to display by the image */
  readonly formattedText: Scalars['FormattedText'];
  /** to display in the input bar when editing */
  readonly rawText: Scalars['String'];
};

/** The event that is tracked */
export enum AnalyticsEvent {
  /** The app went to the background */
  AppInBackground = 'APP_IN_BACKGROUND',
  /** The app went to the foreground */
  AppInForeground = 'APP_IN_FOREGROUND',
}
export type AnalyticsEventResponse = Success | InternalError;

/** Send tracking events to the server */
export type AnalyticsMutation = {
  readonly __typename?: 'AnalyticsMutation';
  readonly trackEvent: AnalyticsEventResponse;
};

/** Send tracking events to the server */
export type AnalyticsMutationTrackEventArgs = {
  event: AnalyticsEvent;
};
export type AndroidProductInfo = {
  readonly __typename?: 'AndroidProductInfo';
  /**
   * The SKU id for the product for the Play Store
   * @deprecated Use skuIds instead
   */
  readonly skuId: Scalars['String'];
  /**
   * A list of SKUs for the product for the Play Store. The client should use the first
   * in the list that isn't already used by the user.
   */
  readonly skuIds: ReadonlyArray<Scalars['String']>;
  readonly isSubscription: Scalars['Boolean'];
};

/** Referenz: com.knuddels.chatserver.clientprotocol.Protocol.WEBVIEW_CLOSE */
export type AppClosedEvent = AppEvent & {
  readonly __typename?: 'AppClosedEvent';
  /** Identifies a specific app window within `channelName`. */
  readonly appId: Scalars['String'];
  /** The channel the app window is associated with, if any. */
  readonly channelName?: Maybe<Scalars['String']>;
};

/** Referenz: com.knuddels.chatserver.clientprotocol.Protocol.WEBVIEW_SEND_CHAT_SERVER_EVENT */
export type AppControlPlaneEvent = AppEvent & {
  readonly __typename?: 'AppControlPlaneEvent';
  readonly appId: Scalars['String'];
  /**
   * The channel associated with the app. (TODO: Figure out if we actually need
   * this or if this information is encoded in appId as well)
   */
  readonly channelName?: Maybe<Scalars['String']>;
  /** Opaque event key supplied by the sandbox when requesting to send an event. */
  readonly eventKey: Scalars['String'];
  /** Opaque data string supplied by the sandbox when requesting to send an event. */
  readonly eventValue: Scalars['String'];
};

/** Referenz: com.knuddels.chatserver.clientprotocol.Protocol.WEBVIEW_SEND_CHAT_SERVER_EVENT */
export type AppControlPlaneEventInput = {
  readonly appId: Scalars['String'];
  /**
   * The channel associated with the app. (TODO: Figure out if we actually need
   * this or if this information is encoded in appId as well)
   */
  readonly channelName?: Maybe<Scalars['String']>;
  /** Opaque event key supplied by the sandbox when requesting to send an event. */
  readonly eventKey: Scalars['String'];
  /** Opaque data string supplied by the sandbox when requesting to send an event. */
  readonly eventValue?: Maybe<Scalars['String']>;
};

/** Referenz: com.knuddels.chatserver.clientprotocol.Protocol.WEBVIEW_SEND_EVENT */
export type AppDataEvent = AppEvent & {
  readonly __typename?: 'AppDataEvent';
  readonly appId: Scalars['String'];
  /** The channel the app is associated with, if any. */
  readonly channelName?: Maybe<Scalars['String']>;
  /** Event key to be passed into app sandbox. */
  readonly eventKey: Scalars['String'];
  /** Opaque data string to be passed into app sandbox. */
  readonly eventValue: Scalars['String'];
};

/** Referenz: com.knuddels.chatserver.clientprotocol.Protocol.WEBVIEW_SEND_EVENT */
export type AppDataEventInput = {
  readonly appId: Scalars['String'];
  /** The channel the app is associated with, if any. */
  readonly channelName?: Maybe<Scalars['String']>;
  /** Event key to be passed into app sandbox. */
  readonly eventKey: Scalars['String'];
  /** Opaque data string to be passed into app sandbox. */
  readonly eventValue?: Maybe<Scalars['String']>;
};
export type AppDimensions = {
  readonly __typename?: 'AppDimensions';
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
};
export type AppDisplay = AppDisplayHeaderbar | AppDisplayScaled | AppDisplayPopup | AppDisplayChannelBackground | AppDisplaySidebar;
export type AppDisplayChannelBackground = {
  readonly __typename?: 'AppDisplayChannelBackground';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type AppDisplayHeaderbar = {
  readonly __typename?: 'AppDisplayHeaderbar';
  readonly height: Scalars['Int'];
};
export enum AppDisplayMode {
  Overlay = 'Overlay',
  Popup = 'Popup',
}
export type AppDisplayPopup = {
  readonly __typename?: 'AppDisplayPopup';
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
};
export type AppDisplayScaled = {
  readonly __typename?: 'AppDisplayScaled';
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
};
export type AppDisplaySidebar = {
  readonly __typename?: 'AppDisplaySidebar';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
};
export type AppEvent = {
  readonly appId: Scalars['String'];
};

/** Referenz: com.knuddels.chatserver.clientprotocol.Protocol.WEBVIEW_OPEN */
export type AppOpenEvent = AppEvent & {
  readonly __typename?: 'AppOpenEvent';
  /** Identifies a specific app window within `channelName`. */
  readonly appId: Scalars['String'];
  /** The channel the app window is associated with, if any. */
  readonly channelName?: Maybe<Scalars['String']>;
  /** Describes how the app should be displayed. */
  readonly display: AppDisplay;
  /**
   * Describes how the app should be displayed.
   * @deprecated Use `display` field.
   */
  readonly displayMode: AppDisplayMode;
  /** URL of app loader. */
  readonly loaderUrl: Scalars['String'];
  /** URL of web view content to pass to the loader. */
  readonly contentUrl: Scalars['String'];
  /** @deprecated Use `display` field. */
  readonly dimensions: AppDimensions;
  /** Opaque metadata string to be passed to the loader. */
  readonly pageData: Scalars['String'];
  readonly isUserApp: Scalars['Boolean'];
};
export type AppsMutation = {
  readonly __typename?: 'AppsMutation';
  readonly sendAppEvent: SendAppEventResponse;
  readonly sendAppDataEvent: SendAppEventResponse;
  readonly sendSlashCommand: SendSlashCommandResponse;
  readonly sendSilenceCommand: SilenceCommandResponse;
  readonly sendRevertSilenceCommand: SilenceCommandResponse;
};
export type AppsMutationSendAppEventArgs = {
  event: AppControlPlaneEventInput;
};
export type AppsMutationSendAppDataEventArgs = {
  event: AppDataEventInput;
};
export type AppsMutationSendSlashCommandArgs = {
  event: SlashCommandInput;
};
export type AppsMutationSendSilenceCommandArgs = {
  channel: Scalars['String'];
  days?: Maybe<Scalars['Int']>;
};
export type AppsMutationSendRevertSilenceCommandArgs = {
  channel: Scalars['String'];
};
export enum AuthenticityClassification {
  NoData = 'NO_DATA',
  Unknown = 'UNKNOWN',
  Trusted = 'TRUSTED',
  VeryTrusted = 'VERY_TRUSTED',
}
export type AutomaticComplaintCommandExpired = ComplaintsEvent & {
  readonly __typename?: 'AutomaticComplaintCommandExpired';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user that may have offended target */
  readonly defendant: User;
};
export type AutomaticComplaintTriggered = ComplaintsEvent & {
  readonly __typename?: 'AutomaticComplaintTriggered';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user that may have offended target */
  readonly defendant: User;
  /** The command to execute to make the complaint */
  readonly slashCommand: Scalars['String'];
};
export type CancelFriendRequestResponse = Success | InternalError;
export type CancelFriendshipResponse = Success | InternalError;
export enum CannotJoinChannelReason {
  NoPermission = 'NO_PERMISSION',
  NotEnoughRights = 'NOT_ENOUGH_RIGHTS',
  Full = 'FULL',
  UrlRestriction = 'URL_RESTRICTION',
  DoesNotExist = 'DOES_NOT_EXIST',
  Other = 'OTHER',
  IsSystemChannel = 'IS_SYSTEM_CHANNEL',
  ChannelNameContainsCorruptChar = 'CHANNEL_NAME_CONTAINS_CORRUPT_CHAR',
  ChannelNameReserved = 'CHANNEL_NAME_RESERVED',
  ChannelNameMayCollideWithMyChannel = 'CHANNEL_NAME_MAY_COLLIDE_WITH_MY_CHANNEL',
  InvisibleChannelRestriction = 'INVISIBLE_CHANNEL_RESTRICTION',
  Locked = 'LOCKED',
  AlreadyInChannel = 'ALREADY_IN_CHANNEL',
  NeedToLeaveCurrentChannel = 'NEED_TO_LEAVE_CURRENT_CHANNEL',
  OnlyOneUserPerIp = 'ONLY_ONE_USER_PER_IP',
  NotEnoughRegisteredDays = 'NOT_ENOUGH_REGISTERED_DAYS',
  NotOpen = 'NOT_OPEN',
  StatusTooLow = 'STATUS_TOO_LOW',
  CmRequired = 'CM_REQUIRED',
  TooYoung = 'TOO_YOUNG',
  TooOld = 'TOO_OLD',
  AgeNotVerified = 'AGE_NOT_VERIFIED',
  PhotoRequired = 'PHOTO_REQUIRED',
  NotEnoughKnuddels = 'NOT_ENOUGH_KNUDDELS',
  VerifiedPhotoRequired = 'VERIFIED_PHOTO_REQUIRED',
  AgeRequired = 'AGE_REQUIRED',
  WrongGender = 'WRONG_GENDER',
  AgeAndGenderRequired = 'AGE_AND_GENDER_REQUIRED',
  BannedTemporarily = 'BANNED_TEMPORARILY',
  SecretReason = 'SECRET_REASON',
  IphoneRequired = 'IPHONE_REQUIRED',
  PartyRequired = 'PARTY_REQUIRED',
  AppDeveloperOnly = 'APP_DEVELOPER_ONLY',
  NotEnoughTradeableSmileys = 'NOT_ENOUGH_TRADEABLE_SMILEYS',
  SmarthphonesNotAllowed = 'SMARTHPHONES_NOT_ALLOWED',
  NotEnoughStammiMonths = 'NOT_ENOUGH_STAMMI_MONTHS',
  IncorrectPassword = 'INCORRECT_PASSWORD',
  SessionInvalid = 'SESSION_INVALID',
  NoConfirmationGiven = 'NO_CONFIRMATION_GIVEN',
}
export type CantCommentError = {
  readonly __typename?: 'CantCommentError';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly reason: CantCommentReason;
};
export enum CantCommentReason {
  Unknown = 'UNKNOWN',
  Spam = 'SPAM',
  TextFilter = 'TEXT_FILTER',
  Ignored = 'IGNORED',
  CommentsDisabled = 'COMMENTS_DISABLED',
  CommentsOnlyForFamily = 'COMMENTS_ONLY_FOR_FAMILY',
}
export type ChangeAdultChannelsVisibleInHardcoreChannelsResponse = Success | InternalError;
export type ChangeAlbumOrderResponse = Success | InternalError | NotFoundError;
export type ChangeAlbumPhotoDescriptionResponse = ChangeAlbumPhotoDescriptionSuccess | InternalError | NotFoundError;
export type ChangeAlbumPhotoDescriptionSuccess = {
  readonly __typename?: 'ChangeAlbumPhotoDescriptionSuccess';
  readonly description?: Maybe<AlbumPhotoDescription>;
};
export type ChangeAlbumTitleResponse = ChangeAlbumTitleSuccess | InternalError | NotFoundError;
export type ChangeAlbumTitleSuccess = {
  readonly __typename?: 'ChangeAlbumTitleSuccess';
  readonly title: Scalars['String'];
};
export type ChangeMentorPauseStateResponse = Success | InternalError;
export type ChangeNicknameChangeOptionsResponse = Success | InternalError;
export type ChangeNicknameHistoryCheckedResponse = Success | InternalError;
export type ChangePrivacyTypeResponse = Success | InternalError;
export type Channel = {
  readonly __typename?: 'Channel';
  /** Opaque channel ID. */
  readonly id: Scalars['ID'];
  /** Display name. */
  readonly name: Scalars['String'];
  /** Number of users currently online in this channel. */
  readonly onlineUserCount: Scalars['Int'];
  readonly users: ReadonlyArray<User>;
  readonly participants: ReadonlyArray<ChannelParticipant>;
  readonly userListVersion: Scalars['Int'];
  readonly onlineContacts: ReadonlyArray<User>;
  /** A selection of users in the channel. */
  readonly previewMembers: ChannelPreviewMembers;
  readonly groupInfo: ChannelGroupInfo;
  /**
   * Most recent messages in this channel. Is empty when...
   * - not a public channel
   * - not enough user in channel
   */
  readonly recentMessages: ReadonlyArray<ChannelMsg>;
};
export type ChannelAd = {
  readonly __typename?: 'ChannelAd';
  /** Id to use when joining the group in this ad */
  readonly adCampaignId: Scalars['Int'];
  readonly channelGroup: ChannelGroup;
};
export type ChannelBackgroundImageInfo = {
  readonly __typename?: 'ChannelBackgroundImageInfo';
  /** URL of the background image */
  readonly url: Scalars['String'];
  /** Scaling mode for the background image */
  readonly mode: ChannelBackgroundImageMode;
};
export enum ChannelBackgroundImageMode {
  Cover = 'COVER',
  Tiled = 'TILED',
  Centered = 'CENTERED',
}
export type ChannelCategory = {
  readonly __typename?: 'ChannelCategory';
  /** Opaque findCategoryById ID. */
  readonly id: Scalars['ID'];
  /** Display name. */
  readonly name: Scalars['String'];
  /** Ordered list of top-K ChannelGroups in this findCategoryById */
  readonly channelGroups: ReadonlyArray<ChannelGroup>;
};
export type ChannelCategoryChannelGroupsArgs = {
  first: Scalars['Int'];
};

/**
 * Event that represents a state change leading to the user being no longer logged into the channel. This event is routed based
 * on the subscribing users user id and session id.
 */
export type ChannelConnectionDisconnected = SystemEvent & {
  readonly __typename?: 'ChannelConnectionDisconnected';
  readonly user: User;
  readonly reason: ChannelConnectionDisconnectReason;
};
export enum ChannelConnectionDisconnectReason {
  /**
   * A not specifically handled reason for the disconnect. The client may assume that this kind of disconnect is not
   * intended by the user.
   */
  Internal = 'INTERNAL',
  /** The user closed the channel connection on his own volition. */
  UserLeave = 'USER_LEAVE',
  /** Another session has connected to the channel. */
  OtherSessionConnected = 'OTHER_SESSION_CONNECTED',
}
export type ChannelEvent = {
  readonly channel: Channel;
};

/** Collection of specific channels and their respective common attributes. */
export type ChannelGroup = {
  readonly __typename?: 'ChannelGroup';
  /** Opaque group ID. */
  readonly id: Scalars['ID'];
  /** Display name. */
  readonly name: Scalars['String'];
  /** Common base attributes of contained channels. */
  readonly info: ChannelGroupInfo;
  /** Channels that are part of this group. */
  readonly channels: ReadonlyArray<Channel>;
  /** Contacts that are online in the channel group */
  readonly onlineContacts: ReadonlyArray<User>;
  /** A selection of users in the channel. */
  readonly previewMembers: ChannelPreviewMembers;
};

/** Attributes common to all channels in a specific ChannelGroup. */
export type ChannelGroupInfo = {
  readonly __typename?: 'ChannelGroupInfo';
  /** Name of the ChannelGroup */
  readonly name: Scalars['String'];
  /** Minimum required age to enter a channel of the corresponding group. */
  readonly minAge: Scalars['Int'];
  /** Maximum allowed age to enter a channel of the corresponding group. */
  readonly maxAge: Scalars['Int'];
  /** Maximum number of users allowed in a single channel of the corresponding group. */
  readonly maxUsers: Scalars['Int'];
  /**
   * URL of the group preview image
   * @deprecated Use previewImageInfo instead
   */
  readonly previewImageUrl?: Maybe<Scalars['String']>;
  /** Info containing URLs to the group preview image and its blurred version */
  readonly previewImageInfo: ChannelPreviewImageInfo;
  readonly isSpecialPreviewImage: Scalars['Boolean'];
  /** Background color of channels in the corresponding group. */
  readonly backgroundColor?: Maybe<Color>;
  /** Highlight color of channels in the corresponding group. */
  readonly highlightColor?: Maybe<Color>;
  /** Highlight color of condensed private messages. */
  readonly highlightColorCondensedPrivateMessage?: Maybe<Color>;
  /** Information about the background image of channels in the corresponding group. */
  readonly backgroundImageInfo?: Maybe<ChannelBackgroundImageInfo>;
  /** Is this a user generated channel */
  readonly isMyChannel: Scalars['Boolean'];
  /** Is this a channel containing adult content */
  readonly isAdultChannel: Scalars['Boolean'];
  /** Optional short description of the channel */
  readonly shortDescription?: Maybe<Scalars['String']>;
  /** Names of the categories this channel group belongs to */
  readonly categoryNames: ReadonlyArray<Scalars['String']>;
  /** The main category name this channel group belongs to */
  readonly mainCategoryName?: Maybe<Scalars['String']>;
  readonly searchKeywords: ReadonlyArray<Scalars['String']>;
};

/** Attributes common to all channels in a specific ChannelGroup. */
export type ChannelGroupInfoPreviewImageInfoArgs = {
  width: Scalars['Int'];
  blur: Scalars['Int'];
  height: Scalars['Int'];
};
export type ChannelJoinMutationResponse = {
  readonly __typename?: 'ChannelJoinMutationResponse';
  readonly error?: Maybe<ChannelJoinMutationResponseError>;
  readonly channel?: Maybe<Channel>;
};
export type ChannelJoinMutationResponseError = {
  readonly __typename?: 'ChannelJoinMutationResponseError';
  readonly type: CannotJoinChannelReason;
  readonly freetext?: Maybe<Scalars['String']>;
  readonly userNick?: Maybe<Scalars['String']>;
  readonly otherChannelName?: Maybe<Scalars['String']>;
  readonly minAge?: Maybe<Scalars['Int']>;
  readonly maxUser?: Maybe<Scalars['Int']>;
  readonly startTime?: Maybe<Scalars['String']>;
  readonly endTime?: Maybe<Scalars['String']>;
  readonly minKnuddels?: Maybe<Scalars['Int']>;
  readonly minTradeableSmileys?: Maybe<Scalars['Int']>;
  readonly minRegisteredDays?: Maybe<Scalars['Int']>;
  readonly minStammiMonths?: Maybe<Scalars['Int']>;
  readonly requiredGender?: Maybe<Gender>;
  readonly requiredStatusName?: Maybe<Scalars['String']>;
};
export type ChannelListCategories = {
  readonly __typename?: 'ChannelListCategories';
  readonly categories: ReadonlyArray<ChannelListCategoriesEntry>;
};
export type ChannelListCategoriesEntry = {
  readonly __typename?: 'ChannelListCategoriesEntry';
  readonly category: ChannelListCategory;
  readonly active: Scalars['Boolean'];
};
export type ChannelListCategoriesEntryInput = {
  readonly category: ChannelListCategory;
  readonly active: Scalars['Boolean'];
};
export type ChannelListCategoriesInput = {
  readonly categories: ReadonlyArray<ChannelListCategoriesEntryInput>;
};
export enum ChannelListCategory {
  Games = 'GAMES',
  Flirt = 'FLIRT',
  Local = 'LOCAL',
  UserChannel = 'USER_CHANNEL',
  Above_20 = 'ABOVE_20',
  ThemeTalk = 'THEME_TALK',
  Music = 'MUSIC',
  Sports = 'SPORTS',
  Erotic = 'EROTIC',
  Misc = 'MISC',
}
export type ChannelListGame = {
  readonly __typename?: 'ChannelListGame';
  readonly pictureUrl: Scalars['String'];
};
export type ChannelMessage = Node & {
  readonly __typename?: 'ChannelMessage';
  readonly id: Scalars['ID'];
  readonly sender: User;
  readonly formattedText: Scalars['FormattedText'];
  readonly type: ChannelMessageType;
};
export type ChannelMessageReceived = ChannelEvent & {
  readonly __typename?: 'ChannelMessageReceived';
  readonly channel: Channel;
  /** @deprecated Use msg instead */
  readonly message: ChannelMessage;
  readonly msg: ChannelMsg;
};
export enum ChannelMessageType {
  Public = 'PUBLIC',
  Action = 'ACTION',
  System = 'SYSTEM',
}
export type ChannelMsg = ChannelMsgAction | ChannelMsgPublic | ChannelMsgSystem | ChannelMsgPrivateGroup;
export type ChannelMsgAction = {
  readonly __typename?: 'ChannelMsgAction';
  readonly id: Scalars['ID'];
  readonly sender: User;
  readonly formattedText: Scalars['FormattedText'];
  readonly iconFormattedText?: Maybe<Scalars['FormattedText']>;
};
export type ChannelMsgPrivateGroup = {
  readonly __typename?: 'ChannelMsgPrivateGroup';
  readonly id: Scalars['ID'];
  readonly sender: User;
  readonly sentFromChannel: Channel;
  readonly receiver: ReadonlyArray<User>;
  readonly formattedText: Scalars['FormattedText'];
};
export type ChannelMsgPublic = {
  readonly __typename?: 'ChannelMsgPublic';
  readonly id: Scalars['ID'];
  readonly sender: User;
  readonly formattedText: Scalars['FormattedText'];
};
export type ChannelMsgSystem = {
  readonly __typename?: 'ChannelMsgSystem';
  readonly id: Scalars['ID'];
  readonly sender: User;
  readonly formattedText: Scalars['FormattedText'];
  /** The channel from which the message was sent */
  readonly sentFromChannel: Channel;
};
export type ChannelMutation = {
  readonly __typename?: 'ChannelMutation';
  /**
   * Joins the most recent visited channel. If `force` is true, user-specified join settings are ignored
   * and the join is forced (defaults to true)
   */
  readonly initialJoin: InitialChannelJoinMutationResponse;
  readonly joinById: ChannelJoinMutationResponse;
  readonly joinByName: ChannelJoinMutationResponse;
  readonly joinByGroupId: ChannelJoinMutationResponse;
  readonly sendMessage: SendMessageMutationResponse;
  readonly leave: LeaveChannelMutationResponse;
  readonly addPreviousSearch: AddPreviousSearchMutationResponse;
};
export type ChannelMutationInitialJoinArgs = {
  force?: Maybe<Scalars['Boolean']>;
};
export type ChannelMutationJoinByIdArgs = {
  password?: Maybe<Scalars['String']>;
  mayJoinSubChannelIfFull?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  confirmed?: Maybe<Scalars['Boolean']>;
};
export type ChannelMutationJoinByNameArgs = {
  password?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  confirmed?: Maybe<Scalars['Boolean']>;
};
export type ChannelMutationJoinByGroupIdArgs = {
  password?: Maybe<Scalars['String']>;
  adCampaignId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  confirmed?: Maybe<Scalars['Boolean']>;
};
export type ChannelMutationSendMessageArgs = {
  text: Scalars['String'];
  id: Scalars['ID'];
};
export type ChannelMutationAddPreviousSearchArgs = {
  text: Scalars['String'];
};
export type ChannelParticipant = {
  readonly __typename?: 'ChannelParticipant';
  readonly user: User;
  /** Icons to add to the user. For now, process in iteration order to be consistent with other clients. */
  readonly iconsToAdd: ReadonlyArray<NicklistIcon>;
  /** The new color for the user. If null, the color did not change. */
  readonly nicklistColor?: Maybe<NicklistColor>;
  /** Users with higher priority are listed first in the nicklist */
  readonly nicklistPriorityString?: Maybe<Scalars['String']>;
  /**
   * Users with higher priority are listed first in the nicklist
   * @deprecated Use nicklistPriorityString instead
   */
  readonly nicklistPriority?: Maybe<Scalars['Int']>;
};
export type ChannelPasswordRequired = ChannelEvent & {
  readonly __typename?: 'ChannelPasswordRequired';
  readonly channel: Channel;
};
export type ChannelPreviewImageInfo = {
  readonly __typename?: 'ChannelPreviewImageInfo';
  /** URL of the channel preview image */
  readonly url: Scalars['String'];
  /** URL of the blurred channel preview image */
  readonly urlBlurred: Scalars['String'];
};
export type ChannelPreviewMembers = {
  readonly __typename?: 'ChannelPreviewMembers';
  readonly friends: ReadonlyArray<User>;
  readonly watchlist: ReadonlyArray<User>;
  readonly others: ReadonlyArray<User>;
};
export type ChannelQuery = {
  readonly __typename?: 'ChannelQuery';
  readonly categories: ReadonlyArray<ChannelCategory>;
  readonly recommendedCategories: RecommendedChannelCategories;
  readonly gameCategories: GameChannelCategories;
  readonly category: ChannelCategory;
  readonly channel: Channel;
  readonly channelForCurrentSession?: Maybe<Channel>;
  /**
   * Gets all channel groups whose name starts with the given prefix.
   * If `includeCategory` is true, channels whose category name starts with the given prefix are also returned.
   */
  readonly channelGroups: ReadonlyArray<ChannelGroup>;
  readonly channelGroupsByIds: ReadonlyArray<ChannelGroup>;
  /** Returns the channel group that exactly matches the given name (case-insensitive). */
  readonly channelGroup?: Maybe<ChannelGroup>;
  readonly initialAutoCompletionChannelGroups: ReadonlyArray<ChannelGroup>;
  readonly dashboardChannelGroups: ReadonlyArray<ChannelGroup>;
  readonly channelAds: ReadonlyArray<ChannelAd>;
  readonly previousSearches: ReadonlyArray<Scalars['String']>;
};
export type ChannelQueryCategoryArgs = {
  id: Scalars['ID'];
};
export type ChannelQueryChannelArgs = {
  id: Scalars['ID'];
};
export type ChannelQueryChannelGroupsArgs = {
  prefix: Scalars['String'];
  includeCategory?: Maybe<Scalars['Boolean']>;
};
export type ChannelQueryChannelGroupsByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};
export type ChannelQueryChannelGroupArgs = {
  name: Scalars['String'];
};
export type ChannelQueryChannelAdsArgs = {
  limit?: Maybe<Scalars['Int']>;
};
export type ChannelUserJoined = ChannelEvent & {
  readonly __typename?: 'ChannelUserJoined';
  readonly channel: Channel;
  readonly user: User;
  readonly participant: ChannelParticipant;
};
export type ChannelUserLeft = ChannelEvent & {
  readonly __typename?: 'ChannelUserLeft';
  readonly channel: Channel;
  readonly user: User;
};
export enum Children {
  NoThanks = 'NO_THANKS',
  MaybeLater = 'MAYBE_LATER',
  Existing = 'EXISTING',
  One = 'ONE',
  Two = 'TWO',
  Three = 'THREE',
  ALot = 'A_LOT',
  ExtendedFamily = 'EXTENDED_FAMILY',
  PatchworkFamily = 'PATCHWORK_FAMILY',
  ByAllMeans = 'BY_ALL_MEANS',
  InProgress = 'IN_PROGRESS',
  Planned = 'PLANNED',
  Zero = 'ZERO',
}

/**
 * Event that represents a state change leading to the user being no longer logged into the client at all. This event is routed
 * based on the subscribing users user id.
 */
export type ClientDisconnected = SystemEvent & {
  readonly __typename?: 'ClientDisconnected';
  readonly user: User;
  /** @deprecated Field no longer supported */
  readonly reason: DisconnectReason;
  readonly disconnectReason: ClientDisconnectReason;
};
export type ClientDisconnectReason = ClientDisconnectReasonUnknown | ClientDisconnectReasonUserLocked | ClientDisconnectReasonUserDeleted | ClientDisconnectReasonNicknameChanged;
export type ClientDisconnectReasonNicknameChanged = {
  readonly __typename?: 'ClientDisconnectReasonNicknameChanged';
  readonly newNick: Scalars['String'];
};
export type ClientDisconnectReasonUnknown = {
  readonly __typename?: 'ClientDisconnectReasonUnknown';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ClientDisconnectReasonUserDeleted = {
  readonly __typename?: 'ClientDisconnectReasonUserDeleted';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ClientDisconnectReasonUserLocked = {
  readonly __typename?: 'ClientDisconnectReasonUserLocked';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export enum ClientPlatform {
  Unknown = 'Unknown',
  /** Clients running on regular web platform (i.e. browser). */
  Web = 'Web',
  /** Native clients (e.g. mobile apps or native desktop app). */
  Native = 'Native',
  /** Clients embedded in other applications (e.g. messenger in legacy app). */
  Embedded = 'Embedded',
}
export type ClientSettings = {
  readonly __typename?: 'ClientSettings';
  readonly conversationListFilterType: ConversationListFilterType;
  readonly initialJoinBehavior: InitialJoinBehavior;
  readonly contactListTabs: ContactListTabs;
  readonly receivePhotoCommentsInMessenger: Scalars['Boolean'];
  readonly enabledSoundEvents: ReadonlyArray<SoundEvent>;
  readonly mentorBarExtended: Scalars['Boolean'];
  readonly privateMessageReplyBehavior: PrivateMessageReplyBehavior;
  readonly macroBoxQuickAccessEntries: ReadonlyArray<MacroBoxSettingsEntry>;
  readonly macroBoxInteractionEntries: ReadonlyArray<MacroBoxSettingsEntry>;
  /** @deprecated Use macroBoxEnabled instead */
  readonly macroBoxDisabled: Scalars['Boolean'];
  readonly macroBoxEnabled: Scalars['Boolean'];
  readonly channelListCategories: ChannelListCategories;
  readonly navIconSlot?: Maybe<Scalars['String']>;
};
export type ClientSettingsChanged = ClientSettingsEvent & {
  readonly __typename?: 'ClientSettingsChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly settings: ClientSettings;
};
export type ClientSettingsEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ClientSettingsMutation = {
  readonly __typename?: 'ClientSettingsMutation';
  readonly updateSettings: ClientUpdateSettingsResponse;
};
export type ClientSettingsMutationUpdateSettingsArgs = {
  settings: ClientUpdateSettings;
};
export type ClientSettingsQuery = {
  readonly __typename?: 'ClientSettingsQuery';
  readonly settings: ClientSettings;
};
export enum ClientState {
  /** The client is currently in foreground. */
  Active = 'Active',
  /** The client is currently in foreground and idle. */
  Inactive = 'Inactive',
  /** The client is currently in the background. */
  Background = 'Background',
}
export enum ClientType {
  Unknown = 'Unknown',
  K3GraphQl = 'K3GraphQl',
  StAppGraphQl = 'StAppGraphQl',
}
export type ClientUpdateSettings = {
  readonly conversationListFilterType?: Maybe<ConversationListFilterType>;
  readonly initialJoinBehavior?: Maybe<InitialJoinBehavior>;
  readonly contactListTabs?: Maybe<ContactListTabsInput>;
  readonly enabledSoundEvents?: Maybe<ReadonlyArray<SoundEvent>>;
  readonly mentorBarExtended?: Maybe<Scalars['Boolean']>;
  readonly privateMessageReplyBehavior?: Maybe<PrivateMessageReplyBehavior>;
  readonly macroBoxQuickAccessEntries?: Maybe<MacroBoxEntriesInput>;
  readonly macroBoxInteractionEntries?: Maybe<MacroBoxEntriesInput>;
  readonly macroBoxDisabled?: Maybe<Scalars['Boolean']>;
  readonly macroBoxEnabled?: Maybe<Scalars['Boolean']>;
  readonly channelListCategories?: Maybe<ChannelListCategoriesInput>;
  readonly navIconSlot?: Maybe<Scalars['String']>;
};
export type ClientUpdateSettingsResponse = {
  readonly __typename?: 'ClientUpdateSettingsResponse';
  readonly error?: Maybe<ClientUpdateSettingsResponseError>;
  readonly settings?: Maybe<ClientSettings>;
};
export enum ClientUpdateSettingsResponseError {
  Error = 'ERROR',
}
export type ClientVersion = {
  /** SemVer major version. */
  readonly major: Scalars['Int'];
  /** SemVer minor version. */
  readonly minor: Scalars['Int'];
  /** SemVer patch level. */
  readonly patch: Scalars['Int'];
  /** Identifiers specifying the pre-release channel (e.g. "alpha", "beta", empty for "default/release"). */
  readonly preReleaseVersion?: Maybe<Scalars['String']>;
  /** Information allowing to map a client version to a specific build/source version (e.g. git commit hash). */
  readonly buildInfo?: Maybe<Scalars['String']>;
};
export type Color = {
  readonly __typename?: 'Color';
  /** Red color component in range [0, 255] */
  readonly red: Scalars['Int'];
  /** Green color component in range [0, 255] */
  readonly green: Scalars['Int'];
  /** Blue color component in range [0, 255] */
  readonly blue: Scalars['Int'];
  /** Transparency component in range [0, 255] */
  readonly alpha: Scalars['Int'];
};
export type CommentAlbumPhotoResponse = AlbumPhotoComment | InternalError | NotFoundError | CantCommentError;
export enum CommunityStatus {
  Normal = 'NORMAL',
  Family = 'FAMILY',
  Stammi = 'STAMMI',
  Honor = 'HONOR',
  UnofficialAdmin = 'UNOFFICIAL_ADMIN',
  Admin = 'ADMIN',
  Sysadmin = 'SYSADMIN',
}
export enum ComplaintCreationError {
  LastComplaintTooRecent = 'LastComplaintTooRecent',
  CantComplainAboutSubject = 'CantComplainAboutSubject',
  CantComplainNoMessagesWithSubject = 'CantComplainNoMessagesWithSubject',
  CantComplainBecauseOfAbuse = 'CantComplainBecauseOfAbuse',
  CantComplainCaseAlreadyInProcessing = 'CantComplainCaseAlreadyInProcessing',
  CantComplainTooLate = 'CantComplainTooLate',
  InternalServerError = 'InternalServerError',
}
export type ComplaintCreationResult = {
  readonly __typename?: 'ComplaintCreationResult';
  readonly error?: Maybe<ComplaintCreationError>;
};
export enum ComplaintItemType {
  User = 'User',
  Photo = 'Photo',
  Fotomeet = 'Fotomeet',
}
export type ComplaintReason = {
  readonly __typename?: 'ComplaintReason';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** Mapping for client. Client specifies reason context it queries the reason for */
  readonly itemType: ComplaintItemType;
};
export enum ComplaintReasonContext {
  Profile = 'Profile',
  ProfileWithAlbum = 'ProfileWithAlbum',
  AlbumPhoto = 'AlbumPhoto',
  Fotomeet = 'Fotomeet',
}
export type ComplaintsEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ComplaintsMutation = {
  readonly __typename?: 'ComplaintsMutation';
  readonly reportUser: ComplaintCreationResult;
  readonly reportPhoto: ComplaintCreationResult;
  readonly reportFotomeetUser: ComplaintCreationResult;
};
export type ComplaintsMutationReportUserArgs = {
  reasonId: Scalars['ID'];
  explanation: Scalars['String'];
  userId: Scalars['ID'];
};
export type ComplaintsMutationReportPhotoArgs = {
  reasonId: Scalars['ID'];
  photoId: Scalars['ID'];
  explanation: Scalars['String'];
};
export type ComplaintsMutationReportFotomeetUserArgs = {
  fotomeetUserId: Scalars['ID'];
  reasonId: Scalars['ID'];
  explanation: Scalars['String'];
};
export type ComplaintsQuery = {
  readonly __typename?: 'ComplaintsQuery';
  readonly complaintReasons: ReadonlyArray<ComplaintReason>;
};
export type ComplaintsQueryComplaintReasonsArgs = {
  context: ComplaintReasonContext;
};
export type ContactAddedEvent = {
  readonly __typename?: 'ContactAddedEvent';
  readonly type: ContactListType;
  readonly user: User;
};
export enum ContactFilterAllowedGender {
  All = 'ALL',
  Male = 'MALE',
  Female = 'FEMALE',
}
export type ContactFilterSettings = {
  readonly __typename?: 'ContactFilterSettings';
  /** The genders that are allowed to contact the user. */
  readonly allowedGender: ContactFilterAllowedGender;
  /** The minimum age required to be able to contact the user. */
  readonly minAge: Scalars['Int'];
  /** The maximum age required to be able to contact the user. */
  readonly maxAge: Scalars['Int'];
  /** True if one needs a profile picture to be able to contact the user. */
  readonly profilePhotoRequired: Scalars['Boolean'];
  /**
   * True if Stammis are an exception to the above rules and are always
   * allowed to contact the user.
   */
  readonly alwaysAllowStammis: Scalars['Boolean'];
  /**
   * True if message smoothing of incoming messages is enabled. Note that this
   * option has no impact on outgoing message (eg. the user can't disable
   * message smoothing to be able to spam others with private messages).
   */
  readonly enableMessageSmoothing: Scalars['Boolean'];
  /** True if one needs to be a verified member to be able to contact the user. */
  readonly onlyVerifiedMembers: Scalars['Boolean'];
};
export type ContactFilterSettingsChanged = ContactFilterSettingsEvent & {
  readonly __typename?: 'ContactFilterSettingsChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly settings: ContactFilterSettings;
};
export type ContactFilterSettingsConstraints = {
  readonly __typename?: 'ContactFilterSettingsConstraints';
  /** The lowest minimum age that is allowed by the server. */
  readonly minAge: Scalars['Int'];
  /** The highest maximum age that is allowed by the server. */
  readonly maxAge: Scalars['Int'];
  /** info that the highest maximum age is smaller than usual because of a block */
  readonly contactFilterAgeRangeBlock?: Maybe<Scalars['Boolean']>;
};
export type ContactFilterSettingsEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ContactFilterSettingsInput = {
  readonly allowedGender: ContactFilterAllowedGender;
  readonly minAge: Scalars['Int'];
  readonly maxAge: Scalars['Int'];
  readonly profilePhotoRequired: Scalars['Boolean'];
  readonly alwaysAllowStammis: Scalars['Boolean'];
  readonly enableMessageSmoothing: Scalars['Boolean'];
  readonly onlyVerifiedMembers?: Maybe<Scalars['Boolean']>;
};
export type ContactFilterSettingsResponse = {
  readonly __typename?: 'ContactFilterSettingsResponse';
  readonly settings: ContactFilterSettings;
  readonly constraints: ContactFilterSettingsConstraints;
};
export type ContactList = {
  readonly __typename?: 'ContactList';
  readonly contacts: ReadonlyArray<User>;
};
export type ContactListEvent = ContactAddedEvent | ContactRemovedEvent;
export type ContactListFilter = {
  readonly type?: Maybe<ContactListType>;
};
export enum ContactListTab {
  Friends = 'FRIENDS',
  Watchlist = 'WATCHLIST',
  Fotomeet = 'FOTOMEET',
  Mentee = 'MENTEE',
  Latest = 'LATEST',
}
export type ContactListTabs = {
  readonly __typename?: 'ContactListTabs';
  readonly tabs: ReadonlyArray<ContactListTabsEntry>;
};
export type ContactListTabsEntry = {
  readonly __typename?: 'ContactListTabsEntry';
  readonly tab: ContactListTab;
  readonly active: Scalars['Boolean'];
  readonly toggleable: Scalars['Boolean'];
};
export type ContactListTabsEntryInput = {
  readonly tab: ContactListTab;
  readonly active: Scalars['Boolean'];
};
export type ContactListTabsInput = {
  readonly tabs: ReadonlyArray<ContactListTabsEntryInput>;
};
export enum ContactListType {
  Friends = 'Friends',
  Watchlist = 'Watchlist',
  Fotomeet = 'Fotomeet',
  Mentee = 'Mentee',
  Latest = 'Latest',
}
export type ContactRemovedEvent = {
  readonly __typename?: 'ContactRemovedEvent';
  readonly type: ContactListType;
  readonly user: User;
};
export type ContactsEvent = {
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ContactsMutation = {
  readonly __typename?: 'ContactsMutation';
  readonly sendFriendRequest: SendFriendRequestResponse;
  readonly cancelFriendship: CancelFriendshipResponse;
  readonly cancelFriendRequest: CancelFriendRequestResponse;
  readonly acceptFriendRequest: AcceptFriendRequestResponse;
  readonly declineFriendRequest: DeclineFriendRequestResponse;
  readonly addToWatchlist: AddToWatchlistResponse;
  readonly removeFromWatchlist: RemoveFromWatchlistResponse;
};
export type ContactsMutationSendFriendRequestArgs = {
  userId: Scalars['ID'];
};
export type ContactsMutationCancelFriendshipArgs = {
  userId: Scalars['ID'];
};
export type ContactsMutationCancelFriendRequestArgs = {
  userId: Scalars['ID'];
};
export type ContactsMutationAcceptFriendRequestArgs = {
  userId: Scalars['ID'];
};
export type ContactsMutationDeclineFriendRequestArgs = {
  userId: Scalars['ID'];
};
export type ContactsMutationAddToWatchlistArgs = {
  userId: Scalars['ID'];
};
export type ContactsMutationRemoveFromWatchlistArgs = {
  userId: Scalars['ID'];
};
export type ContactsQuery = {
  readonly __typename?: 'ContactsQuery';
  readonly sentFriendRequests: ReadonlyArray<User>;
  readonly receivedFriendRequests: ReadonlyArray<User>;
  readonly friends: FriendsResponse;
  readonly commonFriends: FriendsResponse;
};
export type ContactsQueryFriendsArgs = {
  userId: Scalars['ID'];
};
export type ContactsQueryCommonFriendsArgs = {
  userId: Scalars['ID'];
};
export type ConversationBirthdayMessageContent = {
  readonly __typename?: 'ConversationBirthdayMessageContent';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ConversationDeletedPhotoCommentMessageContent = {
  readonly __typename?: 'ConversationDeletedPhotoCommentMessageContent';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ConversationDepthNotReachedError = {
  readonly __typename?: 'ConversationDepthNotReachedError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ConversationForwardedMessageContent = {
  readonly __typename?: 'ConversationForwardedMessageContent';
  readonly starred: Scalars['Boolean'];
  readonly nestedMessage: ConversationNestedMessage;
};
export type ConversationHiddenPhotoCommentMessageContent = {
  readonly __typename?: 'ConversationHiddenPhotoCommentMessageContent';
  readonly albumPhotoId: Scalars['ID'];
  readonly photoUrl: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export type ConversationImageMessageContent = {
  readonly __typename?: 'ConversationImageMessageContent';
  readonly starred: Scalars['Boolean'];
  readonly image?: Maybe<UserSentImage>;
  readonly imageAccepted: Scalars['Boolean'];
  readonly sensitiveContentClassification: SensitiveContentClassification;
};
export type ConversationKnuddelTransferMessageContent = {
  readonly __typename?: 'ConversationKnuddelTransferMessageContent';
  readonly knuddelAmount: Scalars['Int'];
};
export enum ConversationListFilterType {
  AllMessages = 'ALL_MESSAGES',
  UnreadMessages = 'UNREAD_MESSAGES',
}
export type ConversationMentorAchievedMessageContent = {
  readonly __typename?: 'ConversationMentorAchievedMessageContent';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ConversationMessage = {
  readonly __typename?: 'ConversationMessage';
  readonly id: Scalars['ID'];
  readonly timestamp: Scalars['UtcTimestamp'];
  readonly sender: User;
  readonly content: ConversationMessageContent;
};
export type ConversationMessageContent = ConversationTextMessageContent | ConversationForwardedMessageContent | ConversationQuotedMessageContent | ConversationSnapMessageContent | ConversationImageMessageContent | ConversationVisiblePhotoCommentMessageContent | ConversationHiddenPhotoCommentMessageContent | ConversationDeletedPhotoCommentMessageContent | ConversationKnuddelTransferMessageContent | ConversationMentorAchievedMessageContent | ConversationPrivateSystemMessageContent | ConversationBirthdayMessageContent | ConversationNicknameChangeMessageContent;
export type ConversationMessages = {
  readonly __typename?: 'ConversationMessages';
  readonly messages: ReadonlyArray<ConversationMessage>;
  readonly hasMore: Scalars['Boolean'];
};
export type ConversationNestedMessage = {
  readonly __typename?: 'ConversationNestedMessage';
  readonly timestamp: Scalars['UtcTimestamp'];
  readonly sender: User;
  readonly content: ConversationNestedMessageContent;
};
export type ConversationNestedMessageContent = ConversationTextMessageContent | ConversationImageMessageContent;
export type ConversationNicknameChangeMessageContent = {
  readonly __typename?: 'ConversationNicknameChangeMessageContent';
  readonly oldNick: Scalars['String'];
  readonly newNick: Scalars['String'];
};
export type ConversationPrivateSystemMessageContent = {
  readonly __typename?: 'ConversationPrivateSystemMessageContent';
  readonly icon?: Maybe<Scalars['FormattedText']>;
  readonly formattedText: Scalars['FormattedText'];
  /**
   * If true, this message should replace an identical message that
   * appears directly before this message
   */
  readonly collapse: Scalars['Boolean'];
};
export type ConversationQuotedMessageContent = {
  readonly __typename?: 'ConversationQuotedMessageContent';
  readonly starred: Scalars['Boolean'];
  readonly formattedText: Scalars['FormattedText'];
  readonly nestedMessage: ConversationNestedMessage;
};
export type ConversationSnapMessageContent = {
  readonly __typename?: 'ConversationSnapMessageContent';
  readonly snap?: Maybe<UserSentSnap>;
  readonly imageAccepted: Scalars['Boolean'];
  readonly sensitiveContentClassification: SensitiveContentClassification;
};
export type ConversationStarterSuggestion = ConversationStarterSuggestionText | ConversationStarterSuggestionSmiley;
export type ConversationStarterSuggestionSmiley = {
  readonly __typename?: 'ConversationStarterSuggestionSmiley';
  readonly smiley: SmileyDetails;
};
export type ConversationStarterSuggestionText = {
  readonly __typename?: 'ConversationStarterSuggestionText';
  readonly text: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export type ConversationTextMessageContent = {
  readonly __typename?: 'ConversationTextMessageContent';
  readonly starred: Scalars['Boolean'];
  readonly formattedText: Scalars['FormattedText'];
};
export type ConversationVisiblePhotoCommentMessageContent = {
  readonly __typename?: 'ConversationVisiblePhotoCommentMessageContent';
  readonly albumPhotoId: Scalars['ID'];
  readonly commentId: Scalars['ID'];
  readonly photoUrl: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export enum Country {
  Germany = 'GERMANY',
  Austria = 'AUSTRIA',
  Switzerland = 'SWITZERLAND',
}
export type CreateAlbumPhotoUploadUrlResponse = CreateAlbumPhotoUploadUrlSuccess | InternalError | NotFoundError | FakeSuspectError | PhotoUploadBlockedError;
export type CreateAlbumPhotoUploadUrlSuccess = {
  readonly __typename?: 'CreateAlbumPhotoUploadUrlSuccess';
  readonly albumPhotoUploadUrl: Scalars['String'];
};
export type CreateAlbumResponse = Album | InternalError | FakeSuspectError | AlbumLimitReachedError;
export type CreateDeviceTokenResponse = {
  readonly __typename?: 'CreateDeviceTokenResponse';
  readonly result: CreateDeviceTokenResult;
  readonly token?: Maybe<Scalars['DeviceToken']>;
  readonly expiry?: Maybe<Scalars['UtcTimestamp']>;
};
export enum CreateDeviceTokenResult {
  /** The request was successful. */
  Success = 'SUCCESS',
  /** The user associated with the submitted credentials does not exist. */
  UnknownUser = 'UNKNOWN_USER',
  /** The submitted credentials do not validate. */
  InvalidCredentials = 'INVALID_CREDENTIALS',
  /** An unknown internal error occurred. (e.g. downstream service are not available) */
  InternalError = 'INTERNAL_ERROR',
  NickSwitchInProgress = 'NICK_SWITCH_IN_PROGRESS',
}
export enum CreateUploadUrlError {
  Blocked = 'BLOCKED',
  SnapsNotAllowed = 'SNAPS_NOT_ALLOWED',
  SnapServerUnavailable = 'SNAP_SERVER_UNAVAILABLE',
  Internal = 'INTERNAL',
  BlockedHagged = 'BLOCKED_HAGGED',
  BlockedPingPongDepth = 'BLOCKED_PING_PONG_DEPTH',
}
export type CreateUploadUrlResponse = {
  readonly __typename?: 'CreateUploadUrlResponse';
  readonly url?: Maybe<Scalars['String']>;
  readonly error?: Maybe<CreateUploadUrlError>;
};
export type DailyLoginUsed = HappyMomentEvent & {
  readonly __typename?: 'DailyLoginUsed';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type DeclineFriendRequestResponse = Success | InternalError;
export type DeleteAlbumPhotoCommentResponse = DeleteAlbumPhotoCommentSuccess | InternalError;
export type DeleteAlbumPhotoCommentSuccess = {
  readonly __typename?: 'DeleteAlbumPhotoCommentSuccess';
  readonly albumPhotoCommentId: Scalars['ID'];
};
export type DeleteAlbumPhotoResponse = DeleteAlbumPhotoSuccess | InternalError;
export type DeleteAlbumPhotoSuccess = {
  readonly __typename?: 'DeleteAlbumPhotoSuccess';
  readonly albumPhotoId: Scalars['ID'];
};
export type DeleteAlbumResponse = DeleteAlbumSuccess | InternalError;
export type DeleteAlbumSuccess = {
  readonly __typename?: 'DeleteAlbumSuccess';
  readonly albumId: Scalars['ID'];
};
export type DeleteProfileImageResponse = {
  readonly __typename?: 'DeleteProfileImageResponse';
  readonly user?: Maybe<User>;
  readonly error?: Maybe<DeleteProfileImageResponseError>;
};
export enum DeleteProfileImageResponseError {
  Internal = 'INTERNAL',
  Unavailable = 'UNAVAILABLE',
}
export type DeletionAlreadyRunningError = {
  readonly __typename?: 'DeletionAlreadyRunningError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type DeviceInfo = {
  /** The device's manufacturer. */
  readonly manufacturer: Scalars['String'];
  /** The device's model. */
  readonly model: Scalars['String'];
};
export type DisableNotificationResponse = Success | InternalError;
export type DisableSensitiveContentSettingsResponse = Success | InternalError;
export type DisableTanResponse = Success | InternalError;
export enum DisconnectReason {
  Unknown = 'UNKNOWN',
  /** The user has been locked by an administrative action. Many chat features are not available until the user is unlocked. */
  UserLocked = 'USER_LOCKED',
  /** The user has requested the deletion of his account. */
  UserDeletion = 'USER_DELETION',
}
export type EditContactFilterSettingsResponse = {
  readonly __typename?: 'EditContactFilterSettingsResponse';
  readonly error?: Maybe<EditContactFilterSettingsResponseError>;
};
export enum EditContactFilterSettingsResponseError {
  Internal = 'INTERNAL',
  /**
   * The request failed validation. At the time of writing, there are no inputs that permit invalid data entry
   * by the user, therefore we can simplify our error handling to a single bug-indicating bad request error instead
   * of a more complex validation error system.
   */
  BadRequest = 'BAD_REQUEST',
}
export type EditProfileEntryDateResult = {
  readonly __typename?: 'EditProfileEntryDateResult';
  readonly error?: Maybe<EditProfileEntryDateResultError>;
};
export enum EditProfileEntryDateResultError {
  Internal = 'INTERNAL',
  InvalidField = 'INVALID_FIELD',
}
export type EditProfileEntryFreeTextError = {
  readonly __typename?: 'EditProfileEntryFreeTextError';
  readonly error: EditProfileEntryFreeTextResultError;
  /** For zip code: Can be one of `CountryNeeded`, `UnsupportedCountry` or `ZipNotFound`. */
  readonly details?: Maybe<Scalars['String']>;
};
export type EditProfileEntryFreeTextResult = {
  readonly __typename?: 'EditProfileEntryFreeTextResult';
  /** @deprecated Use `errorResult` instead. */
  readonly error?: Maybe<EditProfileEntryFreeTextResultError>;
  readonly errorResult?: Maybe<EditProfileEntryFreeTextError>;
};
export enum EditProfileEntryFreeTextResultError {
  Internal = 'INTERNAL',
  InvalidField = 'INVALID_FIELD',
  InvalidValue = 'INVALID_VALUE',
}
export type EditProfileEntryMultiChoiceResult = {
  readonly __typename?: 'EditProfileEntryMultiChoiceResult';
  readonly error?: Maybe<EditProfileEntryMultiChoiceResultError>;
};
export enum EditProfileEntryMultiChoiceResultError {
  Internal = 'INTERNAL',
  InvalidValue = 'INVALID_VALUE',
}
export type EditProfileEntryStringListResult = {
  readonly __typename?: 'EditProfileEntryStringListResult';
  readonly error?: Maybe<EditProfileEntryStringListResultError>;
};
export enum EditProfileEntryStringListResultError {
  Internal = 'INTERNAL',
  InvalidField = 'INVALID_FIELD',
}
export type EnableNotificationResponse = Success | InternalError;
export type EnableSensitiveContentSettingsResponse = Success | InternalError;
export type EnableTanResponse = Success | InternalError;
export enum EngagementSystemStage {
  Onboarding = 'Onboarding',
  Feedback = 'Feedback',
}
export type ExportInitiatedRecentlyError = {
  readonly __typename?: 'ExportInitiatedRecentlyError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type FakeSuspectError = {
  readonly __typename?: 'FakeSuspectError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type FeatureFlag = {
  readonly __typename?: 'FeatureFlag';
  /** ID of the flag */
  readonly id: Scalars['Int'];
};
export type FeatureFlagsQuery = {
  readonly __typename?: 'FeatureFlagsQuery';
  /** Feature flags enabled for the currently authenticated user. */
  readonly enabledFlags: ReadonlyArray<FeatureFlag>;
};
export enum FilterReason {
  Other = 'OTHER',
  NoProfilePicture = 'NO_PROFILE_PICTURE',
  WrongAge = 'WRONG_AGE',
  WrongGender = 'WRONG_GENDER',
  NoVerification = 'NO_VERIFICATION',
}
export enum FinishAndroidPaymentError {
  Internal = 'Internal',
  UserNotInChannel = 'UserNotInChannel',
  U18AmountRestriction = 'U18AmountRestriction',
}
export type FinishAndroidPaymentResponse = {
  readonly __typename?: 'FinishAndroidPaymentResponse';
  readonly error?: Maybe<FinishAndroidPaymentError>;
};
export enum FinishIosPaymentError {
  Internal = 'Internal',
  UserNotInChannel = 'UserNotInChannel',
  U18AmountRestriction = 'U18AmountRestriction',
}
export type FinishIosPaymentResponse = {
  readonly __typename?: 'FinishIosPaymentResponse';
  readonly error?: Maybe<FinishIosPaymentError>;
};

/** deprecated. Use FotomeetMatch instead. */
export type FotomeetContact = {
  readonly __typename?: 'FotomeetContact';
  readonly user: User;
  readonly isNew: Scalars['Boolean'];
};
export type FotomeetEnabledChanged = FotomeetEvent & {
  readonly __typename?: 'FotomeetEnabledChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly enabled: Scalars['Boolean'];
};
export type FotomeetEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type FotomeetMatch = {
  readonly __typename?: 'FotomeetMatch';
  readonly isNew: Scalars['Boolean'];
  readonly matchedAt: Scalars['UtcTimestamp'];
  readonly user: User;
};
export type FotomeetMatchAdded = FotomeetEvent & {
  readonly __typename?: 'FotomeetMatchAdded';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly match: FotomeetMatch;
  /** @deprecated use `match.user` instead */
  readonly newMatch: User;
};
export type FotomeetMatchRemoved = FotomeetEvent & {
  readonly __typename?: 'FotomeetMatchRemoved';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly matchUserId: Scalars['ID'];
  /** @deprecated use `matchUserId`. we limited api as we only need the userId */
  readonly removedMatch: User;
};
export type FotomeetMutation = {
  readonly __typename?: 'FotomeetMutation';
  /** id = FotomeetUser.id */
  readonly vote: FotomeetVoteResponse;
  /** id = User.id */
  readonly removeMatchedUser: FotomeetRemoveMatchResponse;
  /** Marks all fotomeet matches of the current user as seen. */
  readonly markAllFotomeetContactsAsSeen?: Maybe<MarkAllFotomeetContactsAsSeenResponse>;
  readonly setFotomeetEnabled: SetFotomeetEnabledResponse;
};
export type FotomeetMutationVoteArgs = {
  id: Scalars['ID'];
  type: FotomeetVote;
};
export type FotomeetMutationRemoveMatchedUserArgs = {
  id: Scalars['ID'];
};
export type FotomeetMutationSetFotomeetEnabledArgs = {
  enabled: Scalars['Boolean'];
};
export type FotomeetPotentialMatchCountChanged = FotomeetEvent & {
  readonly __typename?: 'FotomeetPotentialMatchCountChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly potentialMatchCount: Scalars['Int'];
};
export type FotomeetPremiumAcquired = FotomeetEvent & {
  readonly __typename?: 'FotomeetPremiumAcquired';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type FotomeetQuery = {
  readonly __typename?: 'FotomeetQuery';
  /** only queried initially and then updated with status in `FotomeetVoteResponse` */
  readonly status: FotomeetStatus;
  /** @deprecated use `matches` query instead */
  readonly matchedUsers: ReadonlyArray<FotomeetContact>;
  readonly matches: ReadonlyArray<FotomeetMatch>;
  readonly fotomeetUser?: Maybe<FotomeetUser>;
  readonly enabled: Scalars['Boolean'];
};
export type FotomeetQueryMatchedUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
};
export type FotomeetQueryMatchesArgs = {
  limit?: Maybe<Scalars['Int']>;
};
export type FotomeetQueryFotomeetUserArgs = {
  id: Scalars['ID'];
};
export enum FotomeetRemoveMatchResponse {
  Success = 'SUCCESS',
  Error = 'ERROR',
}

/** status of fotomeet that can directly be used in client */
export type FotomeetStatus = {
  readonly __typename?: 'FotomeetStatus';
  readonly currentCandidate?: Maybe<FotomeetUser>;
  /** can be transformed in `prefetchFotomeetUsers` but currently we are specifically interested in prefetching the images */
  readonly prefetchImageUrls: ReadonlyArray<Scalars['String']>;
  /** when voting is unavailable this field will be filled with the reason */
  readonly votingUnavailableReason?: Maybe<FotomeetVotingUnavailableReason>;
  /** If user is premium, in which case currentCandidate.userInfo is always set */
  readonly isPremium: Scalars['Boolean'];
  /** The number of users that have already liked the current user */
  readonly potentialMatchCount: Scalars['Int'];
  /** Whether fotomeet is enabled for the current user */
  readonly enabled: Scalars['Boolean'];
};
export type FotomeetUser = {
  readonly __typename?: 'FotomeetUser';
  /** might want to not use userId or at least obfuscate it */
  readonly id: Scalars['ID'];
  /** part of user data */
  readonly age?: Maybe<Scalars['Int']>;
  readonly gender: Gender;
  readonly bodyHeight?: Maybe<Scalars['Int']>;
  readonly distance?: Maybe<Scalars['Int']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly authenticityClassification?: Maybe<AuthenticityClassification>;
  /** hidden imageUrl (shouldn't expose user identity). */
  readonly imageUrl: Scalars['String'];
  /** user already liked current user (if true, fullUser should also be set? => to be able to immediately show match modal) */
  readonly isPotentialMatch: Scalars['Boolean'];
  /**
   * additional user info used in Match-modal and optional in Candidate Card (if user is premium)
   * is null if user isn't a (potential) match and user is not premium
   */
  readonly userInfo?: Maybe<FotomeetUserInfo>;
  readonly isReportable: Scalars['Boolean'];
  readonly hasAlbumPhotos: Scalars['Boolean'];
};

/** might need to extend this or even use `User` */
export type FotomeetUserInfo = {
  readonly __typename?: 'FotomeetUserInfo';
  readonly id: Scalars['ID'];
  readonly nick: Scalars['String'];
};
export enum FotomeetVote {
  Yes = 'YES',
  No = 'NO',
}

/** ??? adjust what errors we see here */
export enum FotomeetVoteError {
  /** ERROR */
  UserNotFound = 'USER_NOT_FOUND',
  /** # trying to change vote for someone that is not the previously voted user? maybe don't need this and only use next error */
  CannotChangeVote = 'CANNOT_CHANGE_VOTE',
  /** # e.g. somehow trying to query a user that is not in his/her pool? */
  CannotVoteForThisUser = 'CANNOT_VOTE_FOR_THIS_USER',
}
export type FotomeetVoteResponse = {
  readonly __typename?: 'FotomeetVoteResponse';
  /** after a vote we get the next fotomeet status */
  readonly newStatus: FotomeetStatus;
  readonly error?: Maybe<FotomeetVoteError>;
};
export enum FotomeetVotingUnavailableReason {
  LimitReached = 'LIMIT_REACHED',
  NoMatches = 'NO_MATCHES',
  NoPhoto = 'NO_PHOTO',
}
export type FriendList = {
  readonly __typename?: 'FriendList';
  readonly friends: ReadonlyArray<User>;
};
export type FriendRecommendationInConversationEvent = {
  readonly __typename?: 'FriendRecommendationInConversationEvent';
  readonly conversationId: Scalars['ID'];
};
export type FriendRequestAccepted = HappyMomentEvent & {
  readonly __typename?: 'FriendRequestAccepted';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type FriendsHiddenByPrivacy = {
  readonly __typename?: 'FriendsHiddenByPrivacy';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type FriendsResponse = FriendList | FriendsHiddenByPrivacy | InternalError;
export enum FriendState {
  /** No Friends */
  None = 'NONE',
  /** Sent FriendRequest to the target user. */
  RequestInitiated = 'REQUEST_INITIATED',
  /** Got FriendRequest from the target user.. */
  RequestReceived = 'REQUEST_RECEIVED',
  /** Friends. */
  Friends = 'FRIENDS',
}
export type GameChannelCategories = {
  readonly __typename?: 'GameChannelCategories';
  readonly top: ReadonlyArray<TopGameChannel>;
  readonly all: ChannelCategory;
};
export enum Gender {
  Unknown = 'UNKNOWN',
  Male = 'MALE',
  Female = 'FEMALE',
  NonbinaryHe = 'NONBINARY_HE',
  NonbinaryShe = 'NONBINARY_SHE',
}
export type HappyHourPromotion = {
  readonly __typename?: 'HappyHourPromotion';
  readonly startTimestamp: Scalars['UtcTimestamp'];
  readonly endTimestamp: Scalars['UtcTimestamp'];
  readonly color: Color;
  readonly maxBonusPercentage: Scalars['Int'];
};
export type HappyMomentEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type IconNotificationImage = NotificationImage & {
  readonly __typename?: 'IconNotificationImage';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The url to the image */
  readonly url: Scalars['String'];
  /** An optional subtitle below the icon */
  readonly subtitle?: Maybe<NotificationIconSubtitle>;
};
export enum IgnoreState {
  None = 'NONE',
  /** Temporarily block private messages from the target user. */
  PrivateIgnore = 'PRIVATE_IGNORE',
  /** Temporarily block private and public messages from the target user. */
  Ignore = 'IGNORE',
  /** Permanently block private and public messages from the target user. */
  Block = 'BLOCK',
}
export type ImageAcceptStateChanged = MessengerEvent & {
  readonly __typename?: 'ImageAcceptStateChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
  readonly conversationMessage: ConversationMessage;
};
export type InitialChannelJoinMutationResponse = {
  readonly __typename?: 'InitialChannelJoinMutationResponse';
  /**
   * A channel join error if the user cannot join any channel (e.g. banned, account disabled). If this field is set, the
   * contents of `channels` is undefined.
   */
  readonly error?: Maybe<ChannelJoinMutationResponseError>;
  /**
   * The channels this client has been added to. If this field is set, `error` is set to `null`.
   * This might be empty, if using the `force` parameter.
   */
  readonly channels: ReadonlyArray<Channel>;
};
export enum InitialJoinBehavior {
  Enabled = 'ENABLED',
  DisabledUntilJoin = 'DISABLED_UNTIL_JOIN',
  Disabled = 'DISABLED',
}
export type InitiateMessageHistoryExportResponse = Success | ExportInitiatedRecentlyError | InternalError;
export type InternalError = {
  readonly __typename?: 'InternalError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type IosProductInfo = {
  readonly __typename?: 'IosProductInfo';
  /**
   * The SKU id for the product for the App Store
   * @deprecated Use skuIds instead
   */
  readonly skuId: Scalars['String'];
  /**
   * A list of SKUs for the product for the App Store. The client should use the first
   * in the list that isn't already used by the user.
   */
  readonly skuIds: ReadonlyArray<Scalars['String']>;
  readonly isSubscription: Scalars['Boolean'];
};
export type KeepOnlineError = {
  readonly __typename?: 'KeepOnlineError';
  readonly reason: KeepOnlineErrorReason;
};
export enum KeepOnlineErrorReason {
  /** The session of this client is invalid. The client needs to call `refreshSession` again. */
  SessionInvalid = 'SessionInvalid',
  InternalError = 'InternalError',
}
export type KeepOnlineResponse = KeepOnlineSuccess | KeepOnlineError;
export type KeepOnlineSuccess = {
  readonly __typename?: 'KeepOnlineSuccess';
  /** GraphQL doesn't allow empty types. */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type KnuddelsPlusProducts = {
  readonly __typename?: 'KnuddelsPlusProducts';
  readonly products: ReadonlyArray<PaymentProduct>;
  /** The id of the product within products that has the most savings compared to the others */
  readonly mostSavingsProductId?: Maybe<Scalars['ID']>;
  /** The id of the most popular product within products */
  readonly mostPopularProductId?: Maybe<Scalars['ID']>;
};
export type LeaveChannelMutationResponse = {
  readonly __typename?: 'LeaveChannelMutationResponse';
  readonly error?: Maybe<LeaveChannelMutationResponseError>;
};
export enum LeaveChannelMutationResponseError {
  /** An unknown internal error occurred. (e.g. downstream service are not available) */
  InternalError = 'INTERNAL_ERROR',
}
export type LockInfo = TemporaryLockInfo | PermanentLockInfo | UnlockedLockInfo;
export type LoginQuery = {
  readonly __typename?: 'LoginQuery';
  readonly refreshSession: RefreshSessionResponse;
  readonly createDeviceToken: CreateDeviceTokenResponse;
  readonly createPasswordChangeToken: CreateDeviceTokenResponse;
  readonly validateToken: ValidateTokenResponse;
  readonly revalidateSession: RevalidateSessionResponse;
};
export type LoginQueryRefreshSessionArgs = {
  sessionInfo: SessionInfo;
  token?: Maybe<Scalars['SessionToken']>;
};
export type LoginQueryCreateDeviceTokenArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};
export type LoginQueryCreatePasswordChangeTokenArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};
export type LoginQueryValidateTokenArgs = {
  token: Scalars['DeviceToken'];
};
export type LoginQueryRevalidateSessionArgs = {
  sessionInfo: SessionInfo;
};
export type LongConversationOccurred = HappyMomentEvent & {
  readonly __typename?: 'LongConversationOccurred';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
};
export type MacroBoxEntriesInput = {
  readonly entries: ReadonlyArray<MacroBoxEntryInput>;
};
export type MacroBoxEntryInput = {
  readonly id: Scalars['ID'];
  readonly active: Scalars['Boolean'];
};
export type MacroBoxSettingsEntry = {
  readonly __typename?: 'MacroBoxSettingsEntry';
  readonly id: Scalars['ID'];
  readonly active: Scalars['Boolean'];
};
export type MarkAllFotomeetContactsAsSeenResponse = Success | InternalError;
export enum MenteeStatus {
  None = 'None',
  PotentialMentee = 'PotentialMentee',
  Mentee = 'Mentee',
}
export type MentorAchievedEvent = {
  readonly __typename?: 'MentorAchievedEvent';
  readonly mentee: User;
};
export type MentorEvent = PotentialMenteeAddedEvent | PotentialMenteeRemovedEvent | MentorStatusChanged | MentorAchievedEvent;
export type MentorMutation = {
  readonly __typename?: 'MentorMutation';
  readonly activateMentorSystem: ActivateMentorSystemResponse;
  readonly changeMentorPauseState: ChangeMentorPauseStateResponse;
};
export type MentorMutationChangeMentorPauseStateArgs = {
  state: MentorPauseState;
};
export enum MentorPauseState {
  NotPaused = 'NotPaused',
  PausedForToday = 'PausedForToday',
  PausedForFourteenDays = 'PausedForFourteenDays',
}
export type MentorQuery = {
  readonly __typename?: 'MentorQuery';
  readonly mentorStatus: MentorStatus;
};
export type MentorReward = {
  readonly __typename?: 'MentorReward';
  readonly icon: Scalars['FormattedText'];
  readonly title: Scalars['String'];
};
export type MentorStatus = MentorStatusDisabled | MentorStatusAvailable | MentorStatusActive;
export type MentorStatusActive = {
  readonly __typename?: 'MentorStatusActive';
  readonly mentorPoints: Scalars['Int'];
  readonly previousMentorLevelBoundary: Scalars['Int'];
  readonly mentorLevelBoundary: Scalars['Int'];
  readonly nextMentorReward?: Maybe<MentorReward>;
  readonly conversationStartedCount: Scalars['Int'];
  readonly conversationStartedBoundary: Scalars['Int'];
  readonly pauseState: MentorPauseState;
};
export type MentorStatusAvailable = {
  readonly __typename?: 'MentorStatusAvailable';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type MentorStatusChanged = {
  readonly __typename?: 'MentorStatusChanged';
  readonly newStatus: MentorStatus;
};
export type MentorStatusDisabled = {
  readonly __typename?: 'MentorStatusDisabled';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type MessageHistoryExportReadyEvent = MessengerEvent & {
  readonly __typename?: 'MessageHistoryExportReadyEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversationId: Scalars['ID'];
  readonly downloadUrl: Scalars['String'];
};
export type MessageSuggestion = {
  readonly __typename?: 'MessageSuggestion';
  readonly text: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export type MessengerConversation = {
  readonly __typename?: 'MessengerConversation';
  readonly id: Scalars['ID'];
  readonly otherParticipants: ReadonlyArray<User>;
  /** @deprecated use `conversationMessages` instead */
  readonly messages: MessengerMessages;
  readonly conversationMessages: ConversationMessages;
  /** @deprecated use `latestConversationMessage` instead */
  readonly latestMessage?: Maybe<MessengerMessage>;
  readonly latestConversationMessage?: Maybe<ConversationMessage>;
  readonly readState: MessengerConversationReadState;
  /** @deprecated use `visibility` instead */
  readonly isArchived: Scalars['Boolean'];
  readonly visibility: MessengerConversationVisibility;
};
export type MessengerConversationMessagesArgs = {
  limit: Scalars['Int'];
  beforeMessageId?: Maybe<Scalars['ID']>;
};
export type MessengerConversationConversationMessagesArgs = {
  limit: Scalars['Int'];
  beforeMessageId?: Maybe<Scalars['ID']>;
};
export type MessengerConversationArchiveStateChanged = MessengerEvent & {
  readonly __typename?: 'MessengerConversationArchiveStateChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
};
export type MessengerConversationReadState = {
  readonly __typename?: 'MessengerConversationReadState';
  readonly markedAsUnread: Scalars['Boolean'];
  /** @deprecated use `lastReadConversationMessage` instead */
  readonly lastReadMessage?: Maybe<MessengerMessage>;
  readonly lastReadConversationMessage?: Maybe<ConversationMessage>;
  readonly unreadMessageCount?: Maybe<Scalars['Int']>;
};
export type MessengerConversationReadStateChanged = MessengerEvent & {
  readonly __typename?: 'MessengerConversationReadStateChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
};
export type MessengerConversationsResponse = {
  readonly __typename?: 'MessengerConversationsResponse';
  readonly conversations: ReadonlyArray<MessengerConversation>;
  readonly hasMore: Scalars['Boolean'];
};
export enum MessengerConversationState {
  All = 'ALL',
  Archived = 'ARCHIVED',
  NotArchived = 'NOT_ARCHIVED',
  Unread = 'UNREAD',
}
export enum MessengerConversationVisibility {
  Visible = 'VISIBLE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
}
export type MessengerConversationVisibilityChanged = MessengerEvent & {
  readonly __typename?: 'MessengerConversationVisibilityChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
};
export type MessengerDeletedPhotoComment = {
  readonly __typename?: 'MessengerDeletedPhotoComment';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type MessengerEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type MessengerForwardMessageResponse = {
  readonly __typename?: 'MessengerForwardMessageResponse';
  readonly error?: Maybe<MessengerSendMessageError>;
  readonly recipientCount: Scalars['Int'];
  readonly results: ReadonlyArray<MessengerForwardMessageResult>;
};
export type MessengerForwardMessageResult = {
  readonly __typename?: 'MessengerForwardMessageResult';
  readonly messageError?: Maybe<MessengerSendMessageError>;
  readonly messageId?: Maybe<Scalars['ID']>;
};
export type MessengerHiddenPhotoComment = {
  readonly __typename?: 'MessengerHiddenPhotoComment';
  readonly albumPhotoId: Scalars['ID'];
  readonly photoUrl: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export type MessengerMessage = {
  readonly __typename?: 'MessengerMessage';
  readonly id: Scalars['ID'];
  readonly formattedText: Scalars['FormattedText'];
  readonly timestamp: Scalars['UtcTimestamp'];
  readonly sender: User;
  readonly starred: Scalars['Boolean'];
  readonly nestedMessage?: Maybe<MessengerNestedMessage>;
  readonly image?: Maybe<UserSentImage>;
  readonly snap?: Maybe<UserSentSnap>;
  readonly photoComment?: Maybe<MessengerPhotoComment>;
};
export type MessengerMessageChanged = MessengerEvent & {
  readonly __typename?: 'MessengerMessageChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversationId: Scalars['ID'];
  /** @deprecated use `conversationMessage` instead */
  readonly message: MessengerMessage;
  readonly conversationMessage: ConversationMessage;
};
export type MessengerMessageReceived = MessengerEvent & {
  readonly __typename?: 'MessengerMessageReceived';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** @deprecated use `conversationMessage` instead */
  readonly message: MessengerMessage;
  readonly conversationMessage: ConversationMessage;
  readonly conversation: MessengerConversation;
  readonly messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMessages = {
  readonly __typename?: 'MessengerMessages';
  /** @deprecated use `conversationMessages` instead */
  readonly messages: ReadonlyArray<MessengerMessage>;
  readonly hasMore: Scalars['Boolean'];
};
export type MessengerMessageStarredStateChanged = MessengerEvent & {
  readonly __typename?: 'MessengerMessageStarredStateChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
  /** @deprecated use `conversationMessage` instead */
  readonly message: MessengerMessage;
  readonly conversationMessage: ConversationMessage;
  readonly starredMessage: StarredConversationMessage;
};
export type MessengerModifyConversationResponse = {
  readonly __typename?: 'MessengerModifyConversationResponse';
  readonly error?: Maybe<MessengerModifyConversationResponseError>;
  readonly conversation?: Maybe<MessengerConversation>;
};
export enum MessengerModifyConversationResponseError {
  InvalidConversation = 'INVALID_CONVERSATION',
}
export type MessengerMutation = {
  readonly __typename?: 'MessengerMutation';
  /** Conversation mutations */
  readonly readConversations: ReadonlyArray<MessengerModifyConversationResponse>;
  readonly markConversationUnread: MessengerModifyConversationResponse;
  readonly archiveConversation: MessengerModifyConversationResponse;
  /** @deprecated use `restoreConversation` instead. */
  readonly unarchiveConversation: MessengerModifyConversationResponse;
  readonly deleteConversation: MessengerModifyConversationResponse;
  readonly restoreConversation: MessengerModifyConversationResponse;
  readonly notifyTyping: MessengerModifyConversationResponse;
  readonly notifyStopTyping: MessengerModifyConversationResponse;
  /** Send/Mark message mutations */
  readonly sendMessage: MessengerSendMessageResponse;
  readonly quoteMessage: MessengerSendMessageResponse;
  readonly sendSmileyReaction: MessengerSendMessageResponse;
  readonly sendConversationStarterSmiley: MessengerSendMessageResponse;
  readonly sendNicknameChangeReactionSmiley: MessengerSendMessageResponse;
  readonly forwardMessage: MessengerForwardMessageResponse;
  readonly starMessage: MessengerStarMessageResponse;
  readonly unstarMessage: MessengerStarMessageResponse;
  readonly createImageUploadUrl: CreateUploadUrlResponse;
  /**
   * General messenger mutations
   * @deprecated use `clientSettings.updateSettings` instead.
   */
  readonly updateSettings: MessengerUpdateSettingsResponse;
  /** Edits the contact filter settings of the authenticated user */
  readonly editContactFilterSettings: EditContactFilterSettingsResponse;
  /** The user viewed the sensitive content of the given message from the given user. */
  readonly viewedSensitiveContent: Success;
  /** Initiate an export of the message history of the given conversation. An event will be sent when the export is ready. */
  readonly initiateMessageHistoryExport: InitiateMessageHistoryExportResponse;
};
export type MessengerMutationReadConversationsArgs = {
  ids: ReadonlyArray<Maybe<Scalars['ID']>>;
};
export type MessengerMutationMarkConversationUnreadArgs = {
  id: Scalars['ID'];
};
export type MessengerMutationArchiveConversationArgs = {
  id: Scalars['ID'];
};
export type MessengerMutationUnarchiveConversationArgs = {
  id: Scalars['ID'];
};
export type MessengerMutationDeleteConversationArgs = {
  id: Scalars['ID'];
};
export type MessengerMutationRestoreConversationArgs = {
  id: Scalars['ID'];
};
export type MessengerMutationNotifyTypingArgs = {
  conversationId: Scalars['ID'];
  usesStopEvent?: Maybe<Scalars['Boolean']>;
};
export type MessengerMutationNotifyStopTypingArgs = {
  conversationId: Scalars['ID'];
};
export type MessengerMutationSendMessageArgs = {
  conversationId: Scalars['ID'];
  text: Scalars['String'];
  messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMutationQuoteMessageArgs = {
  messageId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMutationSendSmileyReactionArgs = {
  smileyId: Scalars['ID'];
  messageId: Scalars['ID'];
  messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMutationSendConversationStarterSmileyArgs = {
  smileyId: Scalars['ID'];
  conversationId: Scalars['ID'];
  messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMutationSendNicknameChangeReactionSmileyArgs = {
  smileyId: Scalars['ID'];
  conversationId: Scalars['ID'];
  messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMutationForwardMessageArgs = {
  recipients: ReadonlyArray<Scalars['ID']>;
  messageId: Scalars['ID'];
  messageCorrelationId?: Maybe<Scalars['ID']>;
};
export type MessengerMutationStarMessageArgs = {
  messageId: Scalars['ID'];
};
export type MessengerMutationUnstarMessageArgs = {
  messageId: Scalars['ID'];
};
export type MessengerMutationCreateImageUploadUrlArgs = {
  expirationTimeInSeconds?: Maybe<Scalars['Int']>;
  conversationId: Scalars['ID'];
};
export type MessengerMutationUpdateSettingsArgs = {
  settings: MessengerUpdateSettings;
};
export type MessengerMutationEditContactFilterSettingsArgs = {
  newSettings: ContactFilterSettingsInput;
};
export type MessengerMutationViewedSensitiveContentArgs = {
  conversationMessageId: Scalars['ID'];
};
export type MessengerMutationInitiateMessageHistoryExportArgs = {
  conversationId: Scalars['ID'];
};
export type MessengerNestedMessage = {
  readonly __typename?: 'MessengerNestedMessage';
  readonly id: Scalars['ID'];
  readonly formattedText: Scalars['FormattedText'];
  readonly timestamp: Scalars['UtcTimestamp'];
  readonly sender: User;
  readonly type: MessengerNestedMessageType;
  readonly image?: Maybe<UserSentImage>;
};
export enum MessengerNestedMessageType {
  Quote = 'QUOTE',
  Forward = 'FORWARD',
}
export type MessengerPhotoComment = MessengerVisiblePhotoComment | MessengerHiddenPhotoComment | MessengerDeletedPhotoComment;
export type MessengerQuery = {
  readonly __typename?: 'MessengerQuery';
  /** @deprecated use `clientSettings.settings` instead */
  readonly settings: MessengerSettings;
  readonly conversations: MessengerConversationsResponse;
  readonly conversation?: Maybe<MessengerConversation>;
  readonly conversationWithParticipants?: Maybe<MessengerConversation>;
  /** @deprecated use `starredConversationMessages` instead */
  readonly starredMessages: ReadonlyArray<MessengerStarredMessages>;
  readonly starredConversationMessages: StarredConversationMessagesResponse;
  readonly conversationMessages: ConversationMessages;
  /** Returns the current contact filter settings of the authenticated user */
  readonly contactFilterSettings: ContactFilterSettingsResponse;
  /**
   * Returns message suggestions of the conversation identified by the id
   * @deprecated Field no longer supported
   */
  readonly messageSuggestions: ReadonlyArray<MessageSuggestion>;
  readonly conversationStarterSuggestions: ReadonlyArray<ConversationStarterSuggestion>;
  readonly nicknameChangeReactions: ReadonlyArray<NicknameChangeReaction>;
};
export type MessengerQueryConversationsArgs = {
  filterByState?: Maybe<MessengerConversationState>;
  before?: Maybe<Scalars['UtcTimestamp']>;
  limit: Scalars['Int'];
};
export type MessengerQueryConversationArgs = {
  id: Scalars['ID'];
};
export type MessengerQueryConversationWithParticipantsArgs = {
  otherParticipantIds: ReadonlyArray<Scalars['ID']>;
};
export type MessengerQueryStarredMessagesArgs = {
  limit?: Maybe<Scalars['Int']>;
};
export type MessengerQueryStarredConversationMessagesArgs = {
  before?: Maybe<Scalars['UtcTimestamp']>;
  limit: Scalars['Int'];
};
export type MessengerQueryConversationMessagesArgs = {
  beforeMessageTimestamp?: Maybe<Scalars['UtcTimestamp']>;
  conversationId: Scalars['ID'];
  limit: Scalars['Int'];
};
export type MessengerQueryMessageSuggestionsArgs = {
  conversationId: Scalars['ID'];
};
export type MessengerQueryConversationStarterSuggestionsArgs = {
  conversationId: Scalars['ID'];
};
export type MessengerQueryNicknameChangeReactionsArgs = {
  conversationId: Scalars['ID'];
};
export type MessengerSendMessageError = {
  readonly __typename?: 'MessengerSendMessageError';
  readonly type: MessengerSendMessageErrorType;
  readonly filterReason?: Maybe<FilterReason>;
};
export enum MessengerSendMessageErrorType {
  InvalidMessage = 'INVALID_MESSAGE',
  InvalidReactionSmiley = 'INVALID_REACTION_SMILEY',
  InvalidUser = 'INVALID_USER',
  IsModerator = 'IS_MODERATOR',
  PostboxIsFull = 'POSTBOX_IS_FULL',
  ContactFilter = 'CONTACT_FILTER',
  Failed = 'FAILED',
  ImagesBlocked = 'IMAGES_BLOCKED',
  ImagesSnapsNotAllowed = 'IMAGES_SNAPS_NOT_ALLOWED',
  ImagesSnapServerUnavailable = 'IMAGES_SNAP_SERVER_UNAVAILABLE',
  ImagesBlockedPingPongDepth = 'IMAGES_BLOCKED_PING_PONG_DEPTH',
  TooManyMessagesWithoutPong = 'TOO_MANY_MESSAGES_WITHOUT_PONG',
}
export type MessengerSendMessageResponse = {
  readonly __typename?: 'MessengerSendMessageResponse';
  readonly messageId?: Maybe<Scalars['ID']>;
  readonly error?: Maybe<MessengerSendMessageError>;
};
export type MessengerSettings = {
  readonly __typename?: 'MessengerSettings';
  readonly conversationListFilterType: ConversationListFilterType;
};
export type MessengerSettingsChanged = MessengerEvent & {
  readonly __typename?: 'MessengerSettingsChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** @deprecated use clientSettings. see `clientSettingsEvent`. */
  readonly settings: MessengerSettings;
};
export type MessengerStarMessageResponse = {
  readonly __typename?: 'MessengerStarMessageResponse';
  readonly error?: Maybe<MessengerStarMessageResponseError>;
};
export enum MessengerStarMessageResponseError {
  InvalidMessage = 'INVALID_MESSAGE',
}
export type MessengerStarredMessages = {
  readonly __typename?: 'MessengerStarredMessages';
  readonly conversation: MessengerConversation;
  /** @deprecated use `starredConversationMessage` instead */
  readonly starredMessages: ReadonlyArray<MessengerMessage>;
  readonly starredConversationMessages: ReadonlyArray<ConversationMessage>;
};
export type MessengerUpdateSettings = {
  readonly conversationListFilterType?: Maybe<ConversationListFilterType>;
};
export type MessengerUpdateSettingsResponse = {
  readonly __typename?: 'MessengerUpdateSettingsResponse';
  readonly error?: Maybe<MessengerUpdateSettingsResponseError>;
  readonly settings?: Maybe<MessengerSettings>;
};
export enum MessengerUpdateSettingsResponseError {
  Error = 'ERROR',
}
export type MessengerUserTypingStarted = MessengerEvent & {
  readonly __typename?: 'MessengerUserTypingStarted';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
  readonly user: User;
  readonly willReceiveStopEvent: Scalars['Boolean'];
};
export type MessengerUserTypingStopped = MessengerEvent & {
  readonly __typename?: 'MessengerUserTypingStopped';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly conversation: MessengerConversation;
  readonly user: User;
};
export type MessengerVisiblePhotoComment = {
  readonly __typename?: 'MessengerVisiblePhotoComment';
  readonly albumPhotoId: Scalars['ID'];
  readonly commentId: Scalars['ID'];
  readonly photoUrl: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export type MultipleUserChangeSubscriptionConfiguration = {
  /** IDs of the users for which to listen for changes */
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  /** Fields for which to listen on changes */
  readonly fields: ReadonlyArray<SubscribableUserField>;
};
export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly user: UserMutation;
  readonly channel: ChannelMutation;
  readonly messenger: MessengerMutation;
  readonly fotomeet: FotomeetMutation;
  readonly payment: PaymentMutation;
  readonly complaints: ComplaintsMutation;
  readonly pushnotification: PushNotificationMutation;
  readonly clientSettings: ClientSettingsMutation;
  readonly apps: AppsMutation;
  readonly contacts: ContactsMutation;
  readonly mentor: MentorMutation;
  readonly tan: TanMutation;
  readonly notificationSettings: NotificationSettingsMutation;
  readonly analytics: AnalyticsMutation;
};
export type NativeProductInfo = {
  readonly __typename?: 'NativeProductInfo';
  readonly isSubscription: Scalars['Boolean'];
};
export type NewProfileVisitEvent = ProfileVisitEvent & {
  readonly __typename?: 'NewProfileVisitEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly profileVisit?: Maybe<ProfileVisit>;
};
export enum NicklistColor {
  Gold = 'Gold',
  Red = 'Red',
  Green = 'Green',
  Blue = 'Blue',
  Default = 'Default',
}
export type NicklistIcon = {
  readonly __typename?: 'NicklistIcon';
  /** Non-unique identifier to be able to remove all icons with the same name as needed by NicklistIconRemoved. */
  readonly iconName: Scalars['String'];
  /** The icon to display. */
  readonly iconFormattedText: Scalars['FormattedText'];
  /** Whether the icon should be added before or after the nickname. */
  readonly listType: NicklistIconType;
  /**
   * Optional index that indicates at which position the icon should be added. If
   * not set, the icon should be added at the end of the list.
   */
  readonly index?: Maybe<Scalars['Int']>;
};

/** Indicates that all icons identified by iconName should be removed from the prefix and suffix list. */
export type NicklistIconRemoved = ChannelEvent & {
  readonly __typename?: 'NicklistIconRemoved';
  readonly channel: Channel;
  readonly user: User;
  readonly iconName: Scalars['String'];
};
export type NicklistIconsAdded = ChannelEvent & {
  readonly __typename?: 'NicklistIconsAdded';
  readonly channel: Channel;
  readonly participants: ReadonlyArray<ChannelParticipant>;
};
export enum NicklistIconType {
  Prefix = 'PREFIX',
  Suffix = 'SUFFIX',
}
export type NicklistPriorityChanged = ChannelEvent & {
  readonly __typename?: 'NicklistPriorityChanged';
  readonly channel: Channel;
  readonly user: User;
  /** @deprecated Use nicklistPriorityString instead */
  readonly nicklistPriority: Scalars['Int'];
  readonly nicklistPriorityString: Scalars['String'];
};
export type NicknameChangeOptions = {
  readonly __typename?: 'NicknameChangeOptions';
  readonly showOnTopOfNickList: NicknameChangeSetting;
  readonly showNickListIcon: NicknameChangeSetting;
  readonly showNickListEffect: NicknameChangeSetting;
  readonly specialGreeting: NicknameChangeSetting;
};
export type NicknameChangeOptionsInput = {
  readonly showOnTopOfNickList?: Maybe<Scalars['Boolean']>;
  readonly showNickListIcon?: Maybe<Scalars['Boolean']>;
  readonly showNickListEffect?: Maybe<Scalars['Boolean']>;
  readonly specialGreeting?: Maybe<Scalars['Boolean']>;
};
export type NicknameChangeReaction = NicknameChangeReactionText | NicknameChangeReactionSmiley;
export type NicknameChangeReactionSmiley = {
  readonly __typename?: 'NicknameChangeReactionSmiley';
  readonly smiley: SmileyDetails;
};
export type NicknameChangeReactionText = {
  readonly __typename?: 'NicknameChangeReactionText';
  readonly text: Scalars['String'];
  readonly formattedText: Scalars['FormattedText'];
};
export type NicknameChangeSetting = {
  readonly __typename?: 'NicknameChangeSetting';
  readonly checked: Scalars['Boolean'];
  readonly toggleable: Scalars['Boolean'];
  readonly availableUntil?: Maybe<Scalars['UtcTimestamp']>;
};
export type NicknameHistorySettings = {
  readonly __typename?: 'NicknameHistorySettings';
  readonly checked: Scalars['Boolean'];
  readonly toggleable: Scalars['Boolean'];
  readonly disabledUntil?: Maybe<Scalars['UtcTimestamp']>;
};
export type Node = {
  readonly id: Scalars['ID'];
};
export type NotAllowedByReceiverError = {
  readonly __typename?: 'NotAllowedByReceiverError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type NotFoundError = {
  readonly __typename?: 'NotFoundError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type Notification = {
  readonly __typename?: 'Notification';
  /** The title of the notification */
  readonly title: Scalars['String'];
  /** The description of the notification */
  readonly description: Scalars['FormattedText'];
  /**
   * An optional icon that should be displayed as part of the notification
   * @deprecated Field no longer supported
   */
  readonly icon?: Maybe<NotificationIcon>;
  /** An optional image that should be displayed as part of the notification */
  readonly image?: Maybe<NotificationImage>;
  /** An optional call to action that should be displayed as part of the notification */
  readonly callToAction?: Maybe<NotificationCallToAction>;
  /**
   * An optional key that can be used for arbitrary client-side logic, f. ex.
   * showing notifications with this key only in specific contexts
   */
  readonly key?: Maybe<Scalars['String']>;
};
export type NotificationCallToAction = {
  readonly __typename?: 'NotificationCallToAction';
  /** The text to be displayed and the end of the notification describing the action */
  readonly text: Scalars['String'];
  /** A slash command the client should execute in response to the action */
  readonly slashCommand: Scalars['String'];
};
export type NotificationDisabled = {
  readonly __typename?: 'NotificationDisabled';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type NotificationEnabled = {
  readonly __typename?: 'NotificationEnabled';
  readonly notifyBirthday: Scalars['Boolean'];
  readonly notifyTeamJoin: Scalars['Boolean'];
  readonly notifyProfilePictureChange: Scalars['Boolean'];
  readonly notifyCommunityStatusChange: Scalars['Boolean'];
  readonly notifyProfileChange: Scalars['Boolean'];
  readonly notifyNicknameChange?: Maybe<Scalars['Boolean']>;
};
export type NotificationEnabledInput = {
  readonly notifyBirthday?: Maybe<Scalars['Boolean']>;
  readonly notifyTeamJoin?: Maybe<Scalars['Boolean']>;
  readonly notifyProfilePictureChange?: Maybe<Scalars['Boolean']>;
  readonly notifyCommunityStatusChange?: Maybe<Scalars['Boolean']>;
  readonly notifyProfileChange?: Maybe<Scalars['Boolean']>;
  readonly notifyNicknameChange?: Maybe<Scalars['Boolean']>;
};
export type NotificationIcon = {
  readonly __typename?: 'NotificationIcon';
  /** The url to the icon */
  readonly url: Scalars['String'];
  /** An optional subtitle below the icon */
  readonly subtitle?: Maybe<NotificationIconSubtitle>;
};
export type NotificationIconSubtitle = {
  readonly __typename?: 'NotificationIconSubtitle';
  /** The text that should be displayed below the icon */
  readonly text: Scalars['String'];
  /** The color of the subtitle text */
  readonly color: NotificationIconSubtitleColor;
};
export type NotificationIconSubtitleColor = {
  readonly __typename?: 'NotificationIconSubtitleColor';
  /** The color that should be used in the light theme */
  readonly lightThemeColor: Color;
  /** The color that should be used in the dark theme */
  readonly darkThemeColor: Color;
};
export type NotificationImage = {
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type NotificationSettingsChanged = {
  readonly __typename?: 'NotificationSettingsChanged';
  readonly friendStatus: NotificationStatus;
  readonly watchlistStatus: NotificationStatus;
  readonly sensitiveContentSettings: SensitiveContentSettings;
  readonly profileVisitorSettings: ProfileVisitorNotificationSettings;
};
export type NotificationSettingsEvent = NotificationSettingsChanged;
export type NotificationSettingsMutation = {
  readonly __typename?: 'NotificationSettingsMutation';
  readonly enableFriendNotification: EnableNotificationResponse;
  readonly disableFriendNotification: DisableNotificationResponse;
  readonly updateFriendNotificationEnabledStatus: UpdateNotificationEnabledStatusResponse;
  readonly enableWatchlistNotification: EnableNotificationResponse;
  readonly disableWatchlistNotification: DisableNotificationResponse;
  readonly updateWatchlistNotificationEnabledStatus: UpdateNotificationEnabledStatusResponse;
  readonly enableSensitiveContentWarning: EnableSensitiveContentSettingsResponse;
  readonly disableSensitiveContentWarning: DisableSensitiveContentSettingsResponse;
  readonly enableNewProfileVisitorNotification: EnableNotificationResponse;
  readonly disableNewProfileVisitorNotification: DisableNotificationResponse;
  readonly enableFreeProfileVisitorUnlockAvailableNotification: EnableNotificationResponse;
  readonly disableFreeProfileVisitorUnlockAvailableNotification: DisableNotificationResponse;
};
export type NotificationSettingsMutationUpdateFriendNotificationEnabledStatusArgs = {
  status: NotificationEnabledInput;
};
export type NotificationSettingsMutationUpdateWatchlistNotificationEnabledStatusArgs = {
  status: NotificationEnabledInput;
};
export type NotificationSettingsQuery = {
  readonly __typename?: 'NotificationSettingsQuery';
  readonly friendStatus: NotificationStatus;
  readonly watchlistStatus: NotificationStatus;
  readonly sensitiveContentSettings: SensitiveContentSettings;
  readonly profileVisitorSettings: ProfileVisitorNotificationSettings;
};
export type NotificationStatus = NotificationDisabled | NotificationEnabled;
export type NsfwSearchEntriesVisibleChanged = {
  readonly __typename?: 'NsfwSearchEntriesVisibleChanged';
  readonly nsfwSearchEntriesVisible?: Maybe<Scalars['Boolean']>;
};
export type OpenAccountDeletionAppResponse = Success | DeletionAlreadyRunningError | SpecialStatusOrRightsError | ActivePremiumSubscriptionError | InternalError;
export type OpenUrl = SystemEvent & {
  readonly __typename?: 'OpenUrl';
  readonly user: User;
  readonly url: Scalars['String'];
  readonly target: Scalars['String'];
};
export type OsInfo = {
  /** Type of OS the client is running on. */
  readonly type: OsType;
  /** Vendor-specific version of the OS the client is running on. */
  readonly version: Scalars['String'];
};
export enum OsType {
  Unknown = 'Unknown',
  Windows = 'Windows',
  MacOs = 'MacOs',
  Linux = 'Linux',
  Android = 'Android',
  Ios = 'Ios',
}
export type PaymentEvent = {
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type PaymentFailed = PaymentEvent & {
  readonly __typename?: 'PaymentFailed';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly productId: Scalars['ID'];
};
export type PaymentMutation = {
  readonly __typename?: 'PaymentMutation';
  /** Starts the web payment for the given product. */
  readonly startWebPayment: WebPaymentResponse;
  readonly startAndroidPayment: StartAndroidPaymentResponse;
  readonly finishAndroidPayment: FinishAndroidPaymentResponse;
  readonly startIosPayment: StartIosPaymentResponse;
  readonly finishIosPayment: FinishIosPaymentResponse;
};
export type PaymentMutationStartWebPaymentArgs = {
  product: Scalars['ID'];
};
export type PaymentMutationStartAndroidPaymentArgs = {
  product: Scalars['ID'];
};
export type PaymentMutationFinishAndroidPaymentArgs = {
  payload: Scalars['String'];
};
export type PaymentMutationStartIosPaymentArgs = {
  product: Scalars['ID'];
};
export type PaymentMutationFinishIosPaymentArgs = {
  receipt: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};
export type PaymentProduct = {
  readonly __typename?: 'PaymentProduct';
  readonly id: Scalars['ID'];
  /** Price in euro cents */
  readonly priceInCents: Scalars['Int'];
  /** Information about the subscription (if the product is a subscription) */
  readonly subscriptionInfo?: Maybe<PaymentSubscriptionInfo>;
};
export type PaymentQuery = {
  readonly __typename?: 'PaymentQuery';
  readonly knuddelsPlusProducts: KnuddelsPlusProducts;
  readonly unusedSkuIdIos?: Maybe<Scalars['String']>;
  readonly nickSwitchKnuddelCost: Scalars['Int'];
  readonly nativeProductInfo: NativeProductInfo;
};
export type PaymentQueryUnusedSkuIdIosArgs = {
  receipt: Scalars['String'];
  skuIds: ReadonlyArray<Scalars['String']>;
};
export type PaymentQueryNativeProductInfoArgs = {
  skuId: Scalars['String'];
};
export type PaymentSubscriptionInfo = {
  readonly __typename?: 'PaymentSubscriptionInfo';
  readonly duration: Scalars['Int'];
  readonly durationUnit: PaymentSubscriptionPeriodUnit;
};
export enum PaymentSubscriptionPeriodUnit {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}
export type PaymentSucceeded = PaymentEvent & {
  readonly __typename?: 'PaymentSucceeded';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly productId: Scalars['ID'];
};
export type PermanentLockInfo = {
  readonly __typename?: 'PermanentLockInfo';
  /** The reason the user is locked. */
  readonly lockReason: Scalars['FormattedText'];
  /** The user that issues the lock. May be null if the issuing user has been deleted by now. */
  readonly lockedBy?: Maybe<User>;
};
export type PhotoAuthenticatedAsJamesUrl = {
  readonly __typename?: 'PhotoAuthenticatedAsJamesUrl';
  readonly url: Scalars['String'];
};
export type PhotoAuthenticatedAsJamesUrlResponse = PhotoAuthenticatedAsJamesUrl | AccessDeniedError | InternalError;
export type PhotoUploadBlockedError = {
  readonly __typename?: 'PhotoUploadBlockedError';
  /** Is null if the user is permanently blocked */
  readonly blockedUntil?: Maybe<Scalars['UtcTimestamp']>;
};
export type PhotoUrl = {
  readonly __typename?: 'PhotoUrl';
  readonly url: Scalars['String'];
};
export type PhotoUrlResponse = PhotoUrl | InternalError;
export type PhotoVerifyUrl = {
  readonly __typename?: 'PhotoVerifyUrl';
  readonly url: Scalars['String'];
};
export type PhotoVerifyUrlResponse = PhotoVerifyUrl | InternalError;
export type PotentialMenteeAddedEvent = {
  readonly __typename?: 'PotentialMenteeAddedEvent';
  readonly user: User;
};
export type PotentialMenteeRemovedEvent = {
  readonly __typename?: 'PotentialMenteeRemovedEvent';
  readonly user: User;
};
export type PrivacySettings = {
  readonly __typename?: 'PrivacySettings';
  readonly friendsPrivacyType: PrivacyType;
  readonly knuddelPrivacyType: PrivacyType;
  readonly nicknameHistory: NicknameHistorySettings;
  readonly nicknameChangeOptions: NicknameChangeOptions;
  readonly adultChannelsVisibleInHardcoreChannels?: Maybe<Scalars['Boolean']>;
};
export enum PrivacyType {
  Public = 'Public',
  FriendsOnly = 'FriendsOnly',
  BestFriendsOnly = 'BestFriendsOnly',
  Hidden = 'Hidden',
}
export enum PrivateMessageReplyBehavior {
  Messenger = 'MESSENGER',
  SlashCommand = 'SLASH_COMMAND',
}
export type ProfileEditEntry = {
  readonly field: ProfileEditField;
};
export type ProfileEditEntryChildren = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntryChildren';
  readonly field: ProfileEditField;
  readonly childrenValue?: Maybe<Children>;
};
export type ProfileEditEntryCountry = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntryCountry';
  readonly field: ProfileEditField;
  readonly countryValue?: Maybe<Country>;
};
export type ProfileEditEntryDate = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntryDate';
  readonly field: ProfileEditField;
  readonly dateValue?: Maybe<Scalars['UtcTimestamp']>;
  readonly dateMinValue?: Maybe<Scalars['UtcTimestamp']>;
  readonly dateMaxValue?: Maybe<Scalars['UtcTimestamp']>;
};
export type ProfileEditEntryFreeText = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntryFreeText';
  readonly field: ProfileEditField;
  readonly freeTextValue?: Maybe<Scalars['String']>;
  /** A regex to check the validity of the value for the field */
  readonly freeTextPattern?: Maybe<Scalars['String']>;
  /** The minimum length that is required for the value to be considered valid */
  readonly freeTextMinLength?: Maybe<Scalars['Int']>;
  /** The maximum length that is requried for the value to be considered valid */
  readonly freeTextMaxLength?: Maybe<Scalars['Int']>;
  /** Whether this entry is allowed to contain multiple lines */
  readonly freeTextMultiLineAllowed: Scalars['Boolean'];
};
export type ProfileEditEntryRelationshipStatus = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntryRelationshipStatus';
  readonly field: ProfileEditField;
  readonly relationshipStatusValue?: Maybe<RelationshipStatus>;
};
export type ProfileEditEntrySexualOrientation = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntrySexualOrientation';
  readonly field: ProfileEditField;
  readonly sexualOrientationValue?: Maybe<SexualOrientation>;
};
export type ProfileEditEntrySmoker = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntrySmoker';
  readonly field: ProfileEditField;
  readonly smokerValue?: Maybe<Smoker>;
};
export type ProfileEditEntryStringList = ProfileEditEntry & {
  readonly __typename?: 'ProfileEditEntryStringList';
  readonly field: ProfileEditField;
  readonly stringListValue: ReadonlyArray<Scalars['String']>;
};
export enum ProfileEditField {
  Readme = 'README',
  Name = 'NAME',
  SexualOrientation = 'SEXUAL_ORIENTATION',
  DateOfBirth = 'DATE_OF_BIRTH',
  RelationshipStatus = 'RELATIONSHIP_STATUS',
  Email = 'EMAIL',
  Country = 'COUNTRY',
  Children = 'CHILDREN',
  Smoker = 'SMOKER',
  Music = 'MUSIC',
  Hobbies = 'HOBBIES',
  Movies = 'MOVIES',
  Series = 'SERIES',
  Books = 'BOOKS',
  Languages = 'LANGUAGES',
  ZipCode = 'ZIP_CODE',
  CountryEnum = 'COUNTRY_ENUM',
}
export type ProfilePicture = {
  readonly __typename?: 'ProfilePicture';
  /** The users very large profile picture url. Will be a fallback image if exists == false. */
  readonly urlVeryLarge: Scalars['String'];
  /** The users large square profile picture url. Will be a fallback image if exists == false. */
  readonly urlLargeSquare: Scalars['String'];
  /** The users custom size square profile url. Will be a fallback image if exists == false. */
  readonly urlCustomSizeSquare: Scalars['String'];
  /** True if the user currently has an uploaded profile picture. */
  readonly exists: Scalars['Boolean'];
};
export type ProfilePictureUrlCustomSizeSquareArgs = {
  pixelDensity: Scalars['Float'];
  size: Scalars['Int'];
};
export type ProfilePictureChanged = ChannelEvent & {
  readonly __typename?: 'ProfilePictureChanged';
  readonly channel: Channel;
  readonly user: User;
};
export type ProfilePictureNotificationImage = NotificationImage & {
  readonly __typename?: 'ProfilePictureNotificationImage';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user of the profile picture that is shown as the notification image */
  readonly user: User;
  /** If the profile picture should be shown as blurred or not */
  readonly isBlurred: Scalars['Boolean'];
};
export type ProfilePictureOverlays = {
  readonly __typename?: 'ProfilePictureOverlays';
  readonly urlsLarge: ReadonlyArray<Scalars['String']>;
  readonly urlsSmall: ReadonlyArray<Scalars['String']>;
};
export type ProfilePictureOverlaysChanged = ChannelEvent & {
  readonly __typename?: 'ProfilePictureOverlaysChanged';
  readonly channel: Channel;
  readonly user: User;
};
export type ProfileTag = {
  readonly __typename?: 'ProfileTag';
  readonly displayName: Scalars['String'];
  readonly isMatching: Scalars['Boolean'];
  /** Optional category that the tag belongs to. There is only one tag per category. */
  readonly category?: Maybe<ProfileTagCategory>;
  /** Optional slash command to execute when clicking on the tag. */
  readonly slashCommand?: Maybe<Scalars['String']>;
};
export enum ProfileTagCategory {
  RelationshipStatus = 'RelationshipStatus',
  CommunityStatus = 'CommunityStatus',
  SexualOrientation = 'SexualOrientation',
}
export type ProfileVisit = {
  readonly __typename?: 'ProfileVisit';
  readonly visitor: User;
  readonly isUnlocked: Scalars['Boolean'];
};
export type ProfileVisitChangedEvent = ProfileVisitEvent & {
  readonly __typename?: 'ProfileVisitChangedEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly profileVisit?: Maybe<ProfileVisit>;
};
export type ProfileVisitEvent = {
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type ProfileVisitorAdded = ProfileVisitorsEvent & {
  readonly __typename?: 'ProfileVisitorAdded';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly visitor: User;
};
export type ProfileVisitorNotificationSettings = {
  readonly __typename?: 'ProfileVisitorNotificationSettings';
  readonly newVisitorNotificationEnabled: Scalars['Boolean'];
  readonly freeUnlockAvailableNotificationEnabled: Scalars['Boolean'];
};
export type ProfileVisitors = {
  readonly __typename?: 'ProfileVisitors';
  readonly visitors: ReadonlyArray<User>;
  readonly visibilityStatus: ProfileVisitorsVisibilityStatus;
};
export type ProfileVisitorsEvent = {
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export enum ProfileVisitorsVisibilityStatus {
  Visible = 'VISIBLE',
  NotVisible = 'NOT_VISIBLE',
  /** The user has manually disabled the feature */
  Disabled = 'DISABLED',
}
export type ProfileVisitorsVisibilityStatusChanged = ProfileVisitorsEvent & {
  readonly __typename?: 'ProfileVisitorsVisibilityStatusChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly visibilityStatus: ProfileVisitorsVisibilityStatus;
};
export type ProfileVisitorSystemEnabledChanged = ProfileVisitEvent & {
  readonly __typename?: 'ProfileVisitorSystemEnabledChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly enabled: Scalars['Boolean'];
};
export type Promotion = HappyHourPromotion;
export type PromotionEndedEvent = {
  readonly __typename?: 'PromotionEndedEvent';
  readonly promotion: Promotion;
};
export type PromotionEvent = PromotionStartedEvent | PromotionEndedEvent;
export type PromotionQuery = {
  readonly __typename?: 'PromotionQuery';
  readonly activePromotions: ReadonlyArray<Promotion>;
};
export type PromotionStartedEvent = {
  readonly __typename?: 'PromotionStartedEvent';
  readonly newPromotion: Promotion;
};
export type PushNotificationMutation = {
  readonly __typename?: 'PushNotificationMutation';
  readonly registerFirebaseInstanceId: RegisterFirebaseInstanceIdResponse;
  readonly removeFirebaseInstanceId: RemoveFirebaseInstanceIdResponse;
};
export type PushNotificationMutationRegisterFirebaseInstanceIdArgs = {
  instanceId: Scalars['String'];
};
export type PushNotificationMutationRemoveFirebaseInstanceIdArgs = {
  instanceId: Scalars['String'];
};
export type Query = {
  readonly __typename?: 'Query';
  readonly login: LoginQuery;
  readonly user: UserQuery;
  readonly channel: ChannelQuery;
  readonly messenger: MessengerQuery;
  readonly fotomeet: FotomeetQuery;
  readonly payment: PaymentQuery;
  readonly complaints: ComplaintsQuery;
  readonly currentTime: Scalars['UtcTimestamp'];
  readonly smileybox: SmileyboxQuery;
  readonly clientSettings: ClientSettingsQuery;
  readonly featureFlags: FeatureFlagsQuery;
  readonly promotions: PromotionQuery;
  readonly contacts: ContactsQuery;
  readonly mentor: MentorQuery;
  readonly tan: TanQuery;
  readonly notificationSettings: NotificationSettingsQuery;
};
export type ReceivedFriendRequestAddedEvent = ContactsEvent & {
  readonly __typename?: 'ReceivedFriendRequestAddedEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user that has sent a friend request to the current user */
  readonly user: User;
};
export type ReceivedFriendRequestRemovedEvent = ContactsEvent & {
  readonly __typename?: 'ReceivedFriendRequestRemovedEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user that has sent a friend request to the current user */
  readonly user: User;
};
export type ReceiverFriendLimitReachedError = {
  readonly __typename?: 'ReceiverFriendLimitReachedError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type RecommendedChannelCategories = {
  readonly __typename?: 'RecommendedChannelCategories';
  readonly lastVisited: ChannelCategory;
  readonly popular: ChannelCategory;
};
export type RefreshSessionError = {
  readonly __typename?: 'RefreshSessionError';
  /** The current user. Authentication information is extracted from the device token used to authorized the session refresh. */
  readonly user: User;
  /** Localised, human-readable error message. */
  readonly errorMessage: Scalars['String'];
  readonly formattedErrorMessage: Scalars['FormattedText'];
};
export type RefreshSessionResponse = RefreshSessionSuccess | RefreshSessionError;
export type RefreshSessionSuccess = {
  readonly __typename?: 'RefreshSessionSuccess';
  readonly token: Scalars['SessionToken'];
  readonly expiry: Scalars['UtcTimestamp'];
};
export type RegisterFirebaseInstanceIdResponse = {
  readonly __typename?: 'RegisterFirebaseInstanceIdResponse';
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export enum RegistrationInterest {
  Friends = 'Friends',
  Chat = 'Chat',
  Flirt = 'Flirt',
  Games = 'Games',
  Adventure = 'Adventure',
  Themetalk = 'Themetalk',
  LocalChannels = 'LocalChannels',
}
export enum RelationshipStatus {
  Unknown = 'UNKNOWN',
  Solo = 'SOLO',
  HappilySolo = 'HAPPILY_SOLO',
  InLove = 'IN_LOVE',
  UnhappilyInLove = 'UNHAPPILY_IN_LOVE',
  Taken = 'TAKEN',
  HappilyTaken = 'HAPPILY_TAKEN',
  Engaged = 'ENGAGED',
  Married = 'MARRIED',
  HappilyMarried = 'HAPPILY_MARRIED',
  Divorced = 'DIVORCED',
  HappilyDivorced = 'HAPPILY_DIVORCED',
  RelationallyDisturbed = 'RELATIONALLY_DISTURBED',
  IGoToTheMonastery = 'I_GO_TO_THE_MONASTERY',
  OpenRelationship = 'OPEN_RELATIONSHIP',
  HappilyInLove = 'HAPPILY_IN_LOVE',
  InSearchOf = 'IN_SEARCH_OF',
  InHogHeaven = 'IN_HOG_HEAVEN',
  JustSeparated = 'JUST_SEPARATED',
  LivingSeparated = 'LIVING_SEPARATED',
  SingleParent = 'SINGLE_PARENT',
  TotallyOpenMinded = 'TOTALLY_OPEN_MINDED',
  ForeverAlone = 'FOREVER_ALONE',
}
export type RemoveFirebaseInstanceIdResponse = {
  readonly __typename?: 'RemoveFirebaseInstanceIdResponse';
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type RemoveFromWatchlistResponse = Success | InternalError;
export type RevalidateSessionResponse = Success | InternalError;
export type SendAppEventResponse = {
  readonly __typename?: 'SendAppEventResponse';
  /** The GraphQL spec does not allow empty types, see https://github.com/graphql/graphql-spec/issues/568 */
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type SenderFriendLimitReachedError = {
  readonly __typename?: 'SenderFriendLimitReachedError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type SendFriendRequestResponse = Success | InternalError | SenderFriendLimitReachedError | ReceiverFriendLimitReachedError | NotAllowedByReceiverError | TooManyRequestsError | ConversationDepthNotReachedError;
export enum SendMessageMutationError {
  /** An unknown internal error occurred. (e.g. downstream service are not available) */
  InternalError = 'INTERNAL_ERROR',
  /** The channel to which the message should be send was not found */
  ChannelNotFound = 'CHANNEL_NOT_FOUND',
}
export type SendMessageMutationResponse = {
  readonly __typename?: 'SendMessageMutationResponse';
  readonly error?: Maybe<SendMessageMutationError>;
};

/** There was a internal error that prevented execution of the send slash command */
export type SendSlashCommandInternalError = {
  readonly __typename?: 'SendSlashCommandInternalError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};

/** The send slash command was not found / does not exist */
export type SendSlashCommandNotFoundError = {
  readonly __typename?: 'SendSlashCommandNotFoundError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};

/** The send slash command is currently not enabled / supported */
export type SendSlashCommandNotSupportedError = {
  readonly __typename?: 'SendSlashCommandNotSupportedError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type SendSlashCommandResponse = SendSlashCommandSuccess | SendSlashCommandInternalError | SendSlashCommandNotFoundError | SendSlashCommandNotSupportedError;

/** The send slash command was successfully executed */
export type SendSlashCommandSuccess = {
  readonly __typename?: 'SendSlashCommandSuccess';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export enum SensitiveContentClassification {
  None = 'none',
  Adult = 'adult',
  Medical = 'medical',
  Violence = 'violence',
  Racy = 'racy',
}
export type SensitiveContentSettings = {
  readonly __typename?: 'SensitiveContentSettings';
  readonly warnOnSensitiveContent: Scalars['Boolean'];
};
export type SentFriendRequestAddedEvent = ContactsEvent & {
  readonly __typename?: 'SentFriendRequestAddedEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user that has received a friend request from the current user */
  readonly user: User;
};
export type SentFriendRequestRemovedEvent = ContactsEvent & {
  readonly __typename?: 'SentFriendRequestRemovedEvent';
  readonly unused?: Maybe<Scalars['Boolean']>;
  /** The user that has received a friend request from the current user */
  readonly user: User;
};
export type SessionInfo = {
  readonly type: ClientType;
  readonly platform: ClientPlatform;
  readonly clientVersion: ClientVersion;
  readonly osInfo: OsInfo;
  readonly deviceInfo?: Maybe<DeviceInfo>;
  /** Arbitrary strings to uniquely identify the user's device. */
  readonly deviceIdentifiers: ReadonlyArray<Scalars['String']>;
  readonly clientState?: Maybe<ClientState>;
};
export type SetFotomeetEnabledResponse = Success | InternalError;
export type SetIgnoreStateMutationResponse = {
  readonly __typename?: 'SetIgnoreStateMutationResponse';
  readonly error?: Maybe<SetIgnoreStateMutationResponseError>;
};
export enum SetIgnoreStateMutationResponseError {
  Internal = 'INTERNAL',
  /** The user can't be ignored at the moment. */
  NotIgnorable = 'NOT_IGNORABLE',
}
export type SetImagePermissionResponse = {
  readonly __typename?: 'SetImagePermissionResponse';
  readonly error?: Maybe<SetImagePermissionResponseError>;
};
export enum SetImagePermissionResponseError {
  Internal = 'INTERNAL',
}
export enum SexualOrientation {
  Unknown = 'UNKNOWN',
  Heterosexual = 'HETEROSEXUAL',
  Homosexual = 'HOMOSEXUAL',
  Bi = 'BI',
  Undecided = 'UNDECIDED',
  Transgender = 'TRANSGENDER',
  Other = 'OTHER',
}
export type SilenceCommandChannelNotFoundError = {
  readonly __typename?: 'SilenceCommandChannelNotFoundError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type SilenceCommandResponse = Success | SilenceCommandChannelNotFoundError | InternalError;
export type SlashCommandInput = {
  /** The full command that should be executed with a backslash e.g. "/garden2 Holgi" or just "/garden2" */
  readonly command: Scalars['String'];
};
export type SmileyBoxEvent = {
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type SmileyBoxListChanged = SmileyBoxEvent & {
  readonly __typename?: 'SmileyBoxListChanged';
  readonly unused?: Maybe<Scalars['Boolean']>;
  readonly listType: SmileyListType;
  readonly smileys: ReadonlyArray<SmileyDetails>;
};
export type SmileyboxQuery = {
  readonly __typename?: 'SmileyboxQuery';
  readonly smileyList: ReadonlyArray<SmileyDetails>;
  readonly smileyDetails: SmileyDetails;
  readonly reactionSmileys: ReadonlyArray<SmileyDetails>;
};
export type SmileyboxQuerySmileyListArgs = {
  limit?: Maybe<Scalars['Int']>;
  type: SmileyListType;
};
export type SmileyboxQuerySmileyDetailsArgs = {
  id: Scalars['ID'];
};
export type SmileyDetails = {
  readonly __typename?: 'SmileyDetails';
  readonly id: Scalars['ID'];
  readonly textRepresentation: Scalars['String'];
  readonly image: Scalars['FormattedText'];
};
export enum SmileyListType {
  RecentlyUsed = 'RecentlyUsed',
  AllUsable = 'AllUsable',
}
export enum Smoker {
  Yes = 'YES',
  No = 'NO',
  Quit = 'QUIT',
  Occasional = 'OCCASIONAL',
}
export enum SoundEvent {
  NewMessageReceived = 'NewMessageReceived',
  FriendRequestAccepted = 'FriendRequestAccepted',
  EngagementSystemApp = 'EngagementSystemApp',
  VipApp = 'VipApp',
  Global = 'Global',
  LoyaltyApp = 'LoyaltyApp',
}
export type SpecialStatusOrRightsError = {
  readonly __typename?: 'SpecialStatusOrRightsError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type StarredConversationMessage = {
  readonly __typename?: 'StarredConversationMessage';
  readonly conversation: MessengerConversation;
  readonly message: ConversationMessage;
};
export type StarredConversationMessagesResponse = {
  readonly __typename?: 'StarredConversationMessagesResponse';
  readonly messages: ReadonlyArray<StarredConversationMessage>;
  readonly hasMore: Scalars['Boolean'];
};
export type StartAndroidPaymentResponse = {
  readonly __typename?: 'StartAndroidPaymentResponse';
  readonly productInfo?: Maybe<AndroidProductInfo>;
  readonly error?: Maybe<StartAndroidPaymentResponseError>;
};
export enum StartAndroidPaymentResponseError {
  Internal = 'Internal',
  UserNotInChannel = 'UserNotInChannel',
  U18AmountRestriction = 'U18AmountRestriction',
}
export type StartIosPaymentResponse = {
  readonly __typename?: 'StartIosPaymentResponse';
  readonly productInfo?: Maybe<IosProductInfo>;
  readonly error?: Maybe<StartIosPaymentResponseError>;
};
export enum StartIosPaymentResponseError {
  Internal = 'Internal',
  UserNotInChannel = 'UserNotInChannel',
  U18AmountRestriction = 'U18AmountRestriction',
}
export enum SubscribableUserField {
  IsOnline = 'isOnline',
  Channel = 'Channel',
  CurrentOnlineChannelName = 'CurrentOnlineChannelName',
  KnuddelAmount = 'KnuddelAmount',
  FriendState = 'FriendState',
  Email = 'Email',
  VerifiedPhoneNumber = 'VerifiedPhoneNumber',
  EvergreenData = 'EvergreenData',
  ProfilePicture = 'ProfilePicture',
  ProfilePictureOverlays = 'ProfilePictureOverlays',
}
export type Subscription = {
  readonly __typename?: 'Subscription';
  /**
   * Generic notification system used primarily by (possibly closed) system apps to
   * render notifications in the host application
   */
  readonly notificationReceived: Notification;
  readonly userChanged: User;
  readonly multipleUserChanged: User;
  readonly ignoreStateChanged: User;
  readonly isIgnoringChanged: User;
  readonly canSendImagesChanged: User;
  readonly isAdFreeChanged: User;
  readonly hasKnuddelsPlusChanged: User;
  readonly friendStateChanged: User;
  readonly tanStatusChanged: TanStatus;
  readonly adultChannelsVisibleChanged?: Maybe<AdultChannelsVisibleChanged>;
  readonly nsfwSearchEntriesVisibleChanged?: Maybe<NsfwSearchEntriesVisibleChanged>;
  readonly adultChannelsShownInLastVisitedChanged?: Maybe<AdultChannelsShownInLastVisitedChanged>;
  readonly channelEvent: ChannelEvent;
  readonly systemEvent: SystemEvent;
  readonly messengerEvent: MessengerEvent;
  readonly fotomeetEvent: FotomeetEvent;
  readonly paymentEvent: PaymentEvent;
  readonly smileyBoxEvent: SmileyBoxEvent;
  /** @deprecated Use `profileVisitEvent` instead. */
  readonly profileVisitorsEvent: ProfileVisitorsEvent;
  readonly profileVisitEvent: ProfileVisitEvent;
  readonly clientSettingsEvent: ClientSettingsEvent;
  readonly happyMomentEvent: HappyMomentEvent;
  readonly contactFilterSettingsEvent: ContactFilterSettingsEvent;
  readonly appEvent: AppEvent;
  readonly complaintsEvent: ComplaintsEvent;
  readonly promotionEvent: PromotionEvent;
  readonly contactsEvent: ContactsEvent;
  /** only implemented for friends and watchlist */
  readonly contactListEvent: ContactListEvent;
  readonly friendRecommendationInConversationEvent: FriendRecommendationInConversationEvent;
  readonly privacySettingsChanged: PrivacySettings;
  readonly mentorEvent: MentorEvent;
  readonly notificationSettingsEvent: NotificationSettingsEvent;
};
export type SubscriptionUserChangedArgs = {
  config: UserChangeSubscriptionConfiguration;
};
export type SubscriptionMultipleUserChangedArgs = {
  config: MultipleUserChangeSubscriptionConfiguration;
};
export type SubscriptionContactListEventArgs = {
  filter: ContactListFilter;
};
export type Success = {
  readonly __typename?: 'Success';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type SystemEvent = {
  readonly user: User;
};
export type TanDisabled = {
  readonly __typename?: 'TanDisabled';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type TanEnabled = {
  readonly __typename?: 'TanEnabled';
  readonly confirmMobileNumberChange: Scalars['Boolean'];
  readonly confirmEmailChange: Scalars['Boolean'];
  readonly confirmPasswordChange: Scalars['Boolean'];
  readonly confirmSmileyTrade: Scalars['Boolean'];
  readonly confirmSmileyCodeUse: Scalars['Boolean'];
  readonly confirmKnuddelTransfer: Scalars['Boolean'];
  readonly confirmKnuddelTransferAmount: Scalars['Int'];
  readonly confirmNickSwitch: Scalars['Boolean'];
};
export type TanEnabledInput = {
  readonly confirmMobileNumberChange?: Maybe<Scalars['Boolean']>;
  readonly confirmEmailChange?: Maybe<Scalars['Boolean']>;
  readonly confirmPasswordChange?: Maybe<Scalars['Boolean']>;
  readonly confirmSmileyTrade?: Maybe<Scalars['Boolean']>;
  readonly confirmSmileyCodeUse?: Maybe<Scalars['Boolean']>;
  readonly confirmKnuddelTransfer?: Maybe<Scalars['Boolean']>;
  readonly confirmKnuddelTransferAmount?: Maybe<Scalars['Int']>;
  readonly confirmNickSwitch?: Maybe<Scalars['Boolean']>;
};
export type TanMutation = {
  readonly __typename?: 'TanMutation';
  readonly enableTan: EnableTanResponse;
  readonly disableTan: DisableTanResponse;
  readonly updateTanEnabledStatus: UpdateTanEnabledStatusResponse;
};
export type TanMutationUpdateTanEnabledStatusArgs = {
  status?: Maybe<TanEnabledInput>;
};
export type TanQuery = {
  readonly __typename?: 'TanQuery';
  readonly status: TanStatus;
};
export type TanStatus = TanDisabled | TanEnabled;
export type TemporaryLockInfo = {
  readonly __typename?: 'TemporaryLockInfo';
  /** The reason the user is locked. */
  readonly lockReason: Scalars['FormattedText'];
  /** Date until the user is locked (inclusive). */
  readonly lockedUntilDate: Scalars['UtcTimestamp'];
  /** The user that issues the lock. May be null if the issuing user has been deleted by now. */
  readonly lockedBy?: Maybe<User>;
};
export type TooManyRequestsError = {
  readonly __typename?: 'TooManyRequestsError';
  readonly unused?: Maybe<Scalars['Boolean']>;
};
export type TopGameChannel = {
  readonly __typename?: 'TopGameChannel';
  readonly group: ChannelGroup;
  readonly longDescription: Scalars['String'];
  readonly headerImage: Scalars['String'];
  /** Optional overlay image to be displayed on top of the header image */
  readonly glowOverlay?: Maybe<Scalars['String']>;
  /** Number of games in the channel. Can be larger than the number of games returned in the `games` field. */
  readonly gameCount: Scalars['Int'];
  readonly games: ReadonlyArray<ChannelListGame>;
};
export type UnlockedLockInfo = {
  readonly __typename?: 'UnlockedLockInfo';
  /** GraphQL doesn't allow empty types. */
  readonly dummy?: Maybe<Scalars['Boolean']>;
};
export type UpdateAdultChannelsShownInLastVisitedResponse = Success | InternalError;
export type UpdateAdultChannelsVisibleResponse = Success | InternalError;
export type UpdateNotificationEnabledStatusResponse = Success | InternalError;
export type UpdateNsfwSearchEntriesVisibleResponse = Success | InternalError;
export type UpdateTanEnabledStatusResponse = Success | InternalError;
export type User = Node & {
  readonly __typename?: 'User';
  /** Id of this user. */
  readonly id: Scalars['ID'];
  /** The users display name. */
  readonly nick: Scalars['String'];
  /** The users age in years. */
  readonly age?: Maybe<Scalars['Int']>;
  /** Whether the user is currently online in at least one client */
  readonly isOnline?: Maybe<Scalars['Boolean']>;
  /** The users home city. */
  readonly city?: Maybe<Scalars['String']>;
  /** The users current zip code. Can only be requested for the currently authenticated user. */
  readonly zipCode?: Maybe<Scalars['String']>;
  /**
   * The users county. Derived from zip code but visible to other users (depends on
   * privacy settings). Returns a translation key.
   * See ZipCodeDE, ZipCodeAT, and ZipCodeCH for the available keys and take a look at w2_translations.trans for the current
   * translations.
   */
  readonly county?: Maybe<Scalars['String']>;
  /** The users current country. Necessary to set the zipCode. */
  readonly countryEnum?: Maybe<Country>;
  /** The distance in km to the currently authenticated user. */
  readonly distance?: Maybe<Scalars['Int']>;
  /** The users gender. */
  readonly gender: Gender;
  /** The users sexual orientation. */
  readonly sexualOrientation: SexualOrientation;
  /** The users relationship status. */
  readonly relationshipStatus: RelationshipStatus;
  /** Is the user private ignoring, ignoring or blocking the currently authenticated user. */
  readonly isIgnoring: Scalars['Boolean'];
  /**
   * Can the currently authenticated user send messages to the user. Takes ignore states into account.
   * Does not take contact filter into account.
   */
  readonly canReceiveMessages: Scalars['Boolean'];
  /**
   * Can the currently authenticated user add comments on the users photos. Takes ignore states into account.
   * Does not take contact filter into account.
   */
  readonly canReceivePhotoComments: Scalars['Boolean'];
  /** Does this users contact filter allow the currently authenticated user to send messages. */
  readonly isAllowedByContactFilter: Scalars['Boolean'];
  /**
   * Endpoint that provides URLS of all album photos of the user.
   * @deprecated Use 'albums' instead
   */
  readonly albumPhotosUrl?: Maybe<Scalars['String']>;
  /**
   * The users very large profile.
   * @deprecated Use `profilePicture` instead.
   */
  readonly profilePictureUrlVeryLarge: Scalars['String'];
  /**
   * The users large square profile picture url.
   * @deprecated Use `profilePicture` instead.
   */
  readonly profilePictureUrlLargeSquare: Scalars['String'];
  /** Information about the user's profile picture. */
  readonly profilePicture: ProfilePicture;
  /**
   * Overlay images that are displayed on top of the profile picture.
   * @deprecated Use `profilePictureOverlayUrls` instead.
   */
  readonly profilePictureOverlays: ReadonlyArray<Scalars['String']>;
  /** Overlay images that are displayed on top of the profile picture. */
  readonly profilePictureOverlayUrls: ProfilePictureOverlays;
  /** Should ads be shown to the user. */
  readonly isAdFree?: Maybe<Scalars['Boolean']>;
  /** The type of ignore the currently authenticated user has applied to the user */
  readonly ignoreState: IgnoreState;
  /** The users readMe text */
  readonly readMe?: Maybe<Scalars['FormattedText']>;
  /**
   * The channel name where the user is currently online.
   * Is null if the user is not online or not online in a channel.
   */
  readonly currentOnlineChannelName?: Maybe<Scalars['String']>;
  /** The name of the latest channel in which the user was active */
  readonly latestOnlineChannelName?: Maybe<Scalars['String']>;
  /** The number of minutes the user has been online */
  readonly onlineMinutes: Scalars['Int'];
  /** Whether the currently authenticated user wants to receive images from the user */
  readonly canSendImages: Scalars['Boolean'];
  /** The users real name */
  readonly name?: Maybe<Scalars['String']>;
  /** The users real name formatted */
  readonly nameFormatted?: Maybe<Scalars['FormattedText']>;
  /** The day at which the user was born */
  readonly dateOfBirth?: Maybe<Scalars['UtcTimestamp']>;
  /** The users real name. Can only be requested for the currently authenticated user. */
  readonly email?: Maybe<Scalars['String']>;
  /**
   * The users home country
   * @deprecated Use `countryEnum` instead.
   */
  readonly country?: Maybe<Scalars['String']>;
  /** The users take on children */
  readonly children?: Maybe<Children>;
  /** The users smoke status */
  readonly smoker?: Maybe<Smoker>;
  /** A list of the users hobbies */
  readonly hobbies: ReadonlyArray<Scalars['String']>;
  /** A list of the users music */
  readonly music: ReadonlyArray<Scalars['String']>;
  /** A list of the users favourite movies */
  readonly movies: ReadonlyArray<Scalars['String']>;
  /** A list of the users favourite series */
  readonly series: ReadonlyArray<Scalars['String']>;
  /** A list of the users favourite books */
  readonly books: ReadonlyArray<Scalars['String']>;
  /** A list of the languages that the user speaks */
  readonly languages: ReadonlyArray<Scalars['String']>;
  /** The time at which the user was last online */
  readonly lastOnlineTime?: Maybe<Scalars['UtcTimestamp']>;
  /** The date at which the user created his account */
  readonly dateOfRegistration?: Maybe<Scalars['UtcTimestamp']>;
  /** The users status */
  readonly status?: Maybe<CommunityStatus>;
  /** Is the user supporting the knuddels philosophy */
  readonly supportsKnuddelsPhilosophy: Scalars['Boolean'];
  /** The teams that the user is part of */
  readonly teams: ReadonlyArray<Scalars['String']>;
  /** The number of months that this user has been a 'stammi' */
  readonly stammiMonths: Scalars['Int'];
  /** The number of months that this user has been a channel moderator */
  readonly channelModeratorMonths: Scalars['Int'];
  /** The number of months that this user has been a my channel moderator */
  readonly myChannelModeratorMonths: Scalars['Int'];
  /** The number of times that this user has been up for election as a channel lead */
  readonly channelLeadElectionCount: Scalars['Int'];
  /** The number of periods that this user has been an admin */
  readonly adminPeriods: Scalars['Int'];
  /** The name of the my channel of the user. */
  readonly myChannelName?: Maybe<Scalars['String']>;
  /** The name of the favourite channel of the user. */
  readonly favouriteChannelName?: Maybe<Scalars['String']>;
  /** The name of the favourite my channel of the user. */
  readonly favouriteMyChannelName?: Maybe<Scalars['String']>;
  /** The name of the channel that the user is a moderator of. */
  readonly moderatedChannelName?: Maybe<Scalars['String']>;
  /** A list of the channel names that the user is a moderator of. */
  readonly moderatedMyChannelNames: ReadonlyArray<Scalars['String']>;
  /** A list of the channel names that the user is a lead of. */
  readonly channelLeadChannelNames: ReadonlyArray<Scalars['String']>;
  /** A list of the hard core channel names that the user if part of the inner circle of. */
  readonly hardCoreChannelNames: ReadonlyArray<Scalars['String']>;
  /** The number of hickey that the user has received */
  readonly hickeys: Scalars['Int'];
  /** The number of flowers that the user has received */
  readonly flowers: Scalars['Int'];
  /** The number of roses that the user has received */
  readonly roses: Scalars['Int'];
  /** The number of chat meetups that the user has visited */
  readonly chatMeetups: Scalars['Int'];
  /** A list of nicks that have given their heart to the user */
  readonly receivedHearts: ReadonlyArray<Scalars['String']>;
  /** The nick of the user that this user has given his heart to */
  readonly givenHeart?: Maybe<Scalars['String']>;
  /** The number of mentor points that the user has received */
  readonly mentorPoints: Scalars['Int'];
  /** Classification in the Authenticity Service */
  readonly authenticityClassification?: Maybe<AuthenticityClassification>;
  readonly isReportable: Scalars['Boolean'];
  /** The id of the conversation between the currently authenticated user and this user. */
  readonly conversationId: Scalars['ID'];
  /** Metadata of a user lock. */
  readonly lockInfo: LockInfo;
  /** The amount of Knuddel of the user */
  readonly knuddelAmount?: Maybe<Scalars['Int']>;
  readonly isAppBot: Scalars['Boolean'];
  readonly isLockedByAutomaticComplaint: Scalars['Boolean'];
  readonly automaticComplaintCommand?: Maybe<Scalars['String']>;
  /** Returns as many ohotos as specified in limit */
  readonly albumPhotos: ReadonlyArray<AlbumPhoto>;
  /** The sorted list of all photoalbums */
  readonly albums: ReadonlyArray<Album>;
  readonly albumProfilePhoto?: Maybe<AlbumPhoto>;
  /** The type of friendship the currently authenticated user has applied to the user */
  readonly friendState?: Maybe<FriendState>;
  /** Is this user on the current user's watchlist */
  readonly isOnWatchlist: Scalars['Boolean'];
  /** Marks whether the currently authenticated user has already visited the profile of a new friend */
  readonly hasSeenProfileAfterFriendshipStarted: Scalars['Boolean'];
  /** The mentee status of the user in relation to the authenticated user */
  readonly menteeStatus: MenteeStatus;
  /**
   * The client with which the user was online most recent.
   * Only available if menteeStatus is not NONE.
   */
  readonly latestClient?: Maybe<Scalars['String']>;
  /**
   * The interest that has been specified during registration.
   * Only available if menteeStatus is not NONE.
   */
  readonly interest?: Maybe<Scalars['String']>;
  /**
   * The interest that has been specified during registration.
   * Only viewable by self.
   */
  readonly registrationInterest?: Maybe<RegistrationInterest>;
  /** The verified phone number, used e.g. for the TANs. Only for the authenticated user */
  readonly verifiedPhoneNumber?: Maybe<Scalars['String']>;
  /** The stage of the engagement system. Only for the authenticated user and if the engagement system is enabled for the user. */
  readonly engagementSystemStage?: Maybe<EngagementSystemStage>;
  readonly engagementSystemLevel: Scalars['Int'];
  readonly hasNicknameChangedIcon?: Maybe<Scalars['Boolean']>;
  readonly hasNicknameHistoryVisible?: Maybe<Scalars['Boolean']>;
  readonly canSeeAdminInfo?: Maybe<Scalars['Boolean']>;
  /** Only viewable by self. Intended for easier feature flagging. */
  readonly isKnuddelsTeamMember: Scalars['Boolean'];
  /** Only viewable by self. */
  readonly hasKnuddelsPlus?: Maybe<Scalars['Boolean']>;
  /** Only viewable by self. Timestamp until the subscription is active disregarding bonus days. */
  readonly knuddelsPlusActiveUntil?: Maybe<Scalars['UtcTimestamp']>;
  /** Only viewable by self. Timestamp until the subscription is active including bonus days. */
  readonly knuddelsPlusBonusDaysActiveUntil?: Maybe<Scalars['UtcTimestamp']>;
  /** Only visible by self. */
  readonly aliasNicks: ReadonlyArray<Scalars['String']>;
  /** Only visible by self. */
  readonly evergreenData: Scalars['String'];
  readonly profileTags: ReadonlyArray<ProfileTag>;
};
export type UserAlbumPhotosArgs = {
  limit: Scalars['Int'];
};
export type UserChangeSubscriptionConfiguration = {
  /** ID of the user for which to listen for changes */
  readonly userId: Scalars['ID'];
  /** Fields for which to listen on changes */
  readonly fields: ReadonlyArray<SubscribableUserField>;
};
export type UserMutation = {
  readonly __typename?: 'UserMutation';
  /**
   * Updates the last seen timestamp for the (user, client) currently authenticated. Using a mutation instead of a subscription
   * or websocket frame to update the last seen timestamp has several advantages:
   * 1. The subscription approach was not feasible because it was not possible to reliably close the health ping subscription
   *    leading to users that were always online and a minor memory leak in the api-gateway.
   * 2. Using a websocket frame couples us to the websocket as form of event transporting, requiring a rewrite when switching
   *    to another implementation, for example HTTP/2 streams.
   * 3. Using a websocket frame would also lead to code duplication for the token authentication. For graphql queries and
   *    mutation this is already handled by the SpringGraphQLContext.
   * 4. Using a mutation allows us to load balance updateLastSeenTimestamp requests across api-gateway instances.
   * @deprecated Use `keepOnline` instead.
   */
  readonly updateLastSeenTimestamp?: Maybe<Scalars['Boolean']>;
  /**
   * Keeps the currently authenticated user online.
   * @deprecated Use `keepOnlineV2` with `clientState` instead.
   */
  readonly keepOnline: KeepOnlineResponse;
  /** Keeps the currently authenticated user online. */
  readonly keepOnlineV2: KeepOnlineResponse;
  /** Sets the online status for the currently authenticated (user, client) tuple to offline. */
  readonly setOffline?: Maybe<Scalars['Boolean']>;
  /** Mark the provided users profile as being visited by the currently authenticated user. */
  readonly notifyProfileVisited?: Maybe<Scalars['Boolean']>;
  /** Mark the provided users profile picture as being viewed by the currently authenticated user. */
  readonly notifyProfilePictureViewed?: Maybe<Scalars['Boolean']>;
  /** Mark the provided users album photo as being viewed by the currently authenticated user. */
  readonly notifyAlbumPhotoViewed?: Maybe<Scalars['Boolean']>;
  /** Make the currently authenticated user private ignore the user with the given id. */
  readonly privateIgnore: SetIgnoreStateMutationResponse;
  /** Make the currently authenticated user  ignore the user with the given {@code id}. */
  readonly ignore: SetIgnoreStateMutationResponse;
  /** Make the currently authenticated user block the user with the given {@code id}. */
  readonly block: SetIgnoreStateMutationResponse;
  /** Make the currently authenticated user not private ignore the user with the given {@code id}. */
  readonly unPrivateIgnore: SetIgnoreStateMutationResponse;
  /** Make the currently authenticated user unignore the user with the given {@code id}. */
  readonly unignore: SetIgnoreStateMutationResponse;
  /** Make the currently authenticated user unblock the user with the given {@code id}. */
  readonly unblock: SetIgnoreStateMutationResponse;
  /** Do not show images from the given user. */
  readonly blockImages: SetImagePermissionResponse;
  /** Show images from the given user. */
  readonly allowImages: SetImagePermissionResponse;
  readonly editProfileEntryFreeText: EditProfileEntryFreeTextResult;
  readonly editProfileEntrySexualOrientation: EditProfileEntryMultiChoiceResult;
  readonly editProfileEntryRelationshipStatus: EditProfileEntryMultiChoiceResult;
  readonly editProfileEntryChildren: EditProfileEntryMultiChoiceResult;
  readonly editProfileEntrySmoker: EditProfileEntryMultiChoiceResult;
  readonly editProfileEntryCountry: EditProfileEntryMultiChoiceResult;
  readonly editProfileEntryStringList: EditProfileEntryStringListResult;
  readonly editProfileEntryDate: EditProfileEntryDateResult;
  readonly deleteProfileImage: DeleteProfileImageResponse;
  /**
   * If the user has disabled the profile visitors feature on another platform,
   * this mutation will re-enable it.
   */
  readonly activateProfileVisitorsFeature: ActivateProfileVisitorsFeatureResponse;
  readonly createAlbum: CreateAlbumResponse;
  readonly deleteAlbum: DeleteAlbumResponse;
  readonly createAlbumPhotoUploadUrl: CreateAlbumPhotoUploadUrlResponse;
  readonly deleteAlbumPhoto: DeleteAlbumPhotoResponse;
  readonly commentAlbumPhoto: CommentAlbumPhotoResponse;
  readonly deleteAlbumPhotoComment: DeleteAlbumPhotoCommentResponse;
  readonly changeAlbumOrder: ChangeAlbumOrderResponse;
  readonly changeAlbumTitle: ChangeAlbumTitleResponse;
  readonly changeAlbumPhotoDescription: ChangeAlbumPhotoDescriptionResponse;
  /** Mark the profile of the given new friend as visited by the currently authenticated user. Return type is to be ignored. */
  readonly markProfileSeenAfterFriendshipStarted?: Maybe<Scalars['Boolean']>;
  readonly changeFriendsPrivacyType: ChangePrivacyTypeResponse;
  readonly changeKnuddelPrivacyType: ChangePrivacyTypeResponse;
  readonly changeNicknameHistoryChecked: ChangeNicknameHistoryCheckedResponse;
  readonly changeNicknameChangeOptions: ChangeNicknameChangeOptionsResponse;
  readonly changeAdultChannelsVisibleInHardcoreChannels: ChangeAdultChannelsVisibleInHardcoreChannelsResponse;
  readonly openAccountDeletionApp: OpenAccountDeletionAppResponse;
  readonly updateAdultChannelsVisible: UpdateAdultChannelsVisibleResponse;
  readonly updateNsfwSearchEntriesVisible: UpdateNsfwSearchEntriesVisibleResponse;
  readonly updateAdultChannelsShownInLastVisited: UpdateAdultChannelsShownInLastVisitedResponse;
  readonly markProfileVisitorSeen?: Maybe<Scalars['Boolean']>;
};
export type UserMutationUpdateLastSeenTimestampArgs = {
  isPresent?: Maybe<Scalars['Boolean']>;
};
export type UserMutationKeepOnlineArgs = {
  isPresent: Scalars['Boolean'];
};
export type UserMutationKeepOnlineV2Args = {
  clientState: ClientState;
};
export type UserMutationNotifyProfileVisitedArgs = {
  userId: Scalars['ID'];
};
export type UserMutationNotifyProfilePictureViewedArgs = {
  userId: Scalars['ID'];
};
export type UserMutationNotifyAlbumPhotoViewedArgs = {
  userId: Scalars['ID'];
};
export type UserMutationPrivateIgnoreArgs = {
  userId: Scalars['ID'];
};
export type UserMutationIgnoreArgs = {
  userId: Scalars['ID'];
};
export type UserMutationBlockArgs = {
  userId: Scalars['ID'];
};
export type UserMutationUnPrivateIgnoreArgs = {
  userId: Scalars['ID'];
};
export type UserMutationUnignoreArgs = {
  userId: Scalars['ID'];
};
export type UserMutationUnblockArgs = {
  userId: Scalars['ID'];
};
export type UserMutationBlockImagesArgs = {
  userId: Scalars['ID'];
};
export type UserMutationAllowImagesArgs = {
  userId: Scalars['ID'];
};
export type UserMutationEditProfileEntryFreeTextArgs = {
  field: ProfileEditField;
  value: Scalars['String'];
};
export type UserMutationEditProfileEntrySexualOrientationArgs = {
  field: ProfileEditField;
  value?: Maybe<SexualOrientation>;
};
export type UserMutationEditProfileEntryRelationshipStatusArgs = {
  field: ProfileEditField;
  value?: Maybe<RelationshipStatus>;
};
export type UserMutationEditProfileEntryChildrenArgs = {
  field: ProfileEditField;
  value?: Maybe<Children>;
};
export type UserMutationEditProfileEntrySmokerArgs = {
  field: ProfileEditField;
  value?: Maybe<Smoker>;
};
export type UserMutationEditProfileEntryCountryArgs = {
  field: ProfileEditField;
  value: Country;
};
export type UserMutationEditProfileEntryStringListArgs = {
  field: ProfileEditField;
  value: ReadonlyArray<Scalars['String']>;
};
export type UserMutationEditProfileEntryDateArgs = {
  field: ProfileEditField;
  value: Scalars['UtcTimestamp'];
};
export type UserMutationCreateAlbumArgs = {
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};
export type UserMutationDeleteAlbumArgs = {
  albumId: Scalars['ID'];
};
export type UserMutationCreateAlbumPhotoUploadUrlArgs = {
  albumId: Scalars['ID'];
};
export type UserMutationDeleteAlbumPhotoArgs = {
  albumPhotoId: Scalars['ID'];
};
export type UserMutationCommentAlbumPhotoArgs = {
  albumPhotoId: Scalars['ID'];
  text: Scalars['String'];
};
export type UserMutationDeleteAlbumPhotoCommentArgs = {
  albumPhotoCommentId: Scalars['ID'];
  albumPhotoId: Scalars['ID'];
};
export type UserMutationChangeAlbumOrderArgs = {
  newAlbumOrder: ReadonlyArray<Scalars['ID']>;
};
export type UserMutationChangeAlbumTitleArgs = {
  newTitle: Scalars['String'];
  albumId: Scalars['ID'];
};
export type UserMutationChangeAlbumPhotoDescriptionArgs = {
  albumPhotoId: Scalars['ID'];
  description: Scalars['String'];
};
export type UserMutationMarkProfileSeenAfterFriendshipStartedArgs = {
  userId: Scalars['ID'];
};
export type UserMutationChangeFriendsPrivacyTypeArgs = {
  privacyType: PrivacyType;
};
export type UserMutationChangeKnuddelPrivacyTypeArgs = {
  privacyType: PrivacyType;
};
export type UserMutationChangeNicknameHistoryCheckedArgs = {
  checked: Scalars['Boolean'];
};
export type UserMutationChangeNicknameChangeOptionsArgs = {
  change: NicknameChangeOptionsInput;
};
export type UserMutationChangeAdultChannelsVisibleInHardcoreChannelsArgs = {
  value: Scalars['Boolean'];
};
export type UserMutationUpdateAdultChannelsVisibleArgs = {
  adultChannelsVisible: Scalars['Boolean'];
};
export type UserMutationUpdateNsfwSearchEntriesVisibleArgs = {
  nsfwSearchEntriesVisible: Scalars['Boolean'];
};
export type UserMutationUpdateAdultChannelsShownInLastVisitedArgs = {
  adultChannelsShownInLastVisited: Scalars['Boolean'];
};
export type UserMutationMarkProfileVisitorSeenArgs = {
  userId: Scalars['ID'];
};
export type UserQuery = {
  readonly __typename?: 'UserQuery';
  /** Returns user data for the user currently authenticated. */
  readonly currentUser: User;
  /** Returns user data for the given id. */
  readonly user?: Maybe<User>;
  /** Returns user data for the given nick. */
  readonly userFromNick?: Maybe<User>;
  /** Gets a list of users that count as contacts for the currently authenticated user. */
  readonly contactList: ContactList;
  readonly profileEditEntries: ReadonlyArray<ProfileEditEntry>;
  /** @deprecated Use `profileVisits` instead. */
  readonly profileVisitors: ProfileVisitors;
  readonly profileVisits: ReadonlyArray<ProfileVisit>;
  readonly profileVisitorsEnabled: Scalars['Boolean'];
  readonly ipDetectionUrl?: Maybe<Scalars['String']>;
  readonly photoVerifyUrl: PhotoVerifyUrlResponse;
  readonly photoUrl: PhotoUrlResponse;
  readonly photoAuthenticatedAsJamesUrl: PhotoAuthenticatedAsJamesUrlResponse;
  readonly albumPhotoComments: ReadonlyArray<AlbumPhotoComment>;
  readonly privacySettings: PrivacySettings;
  /** Gets a list fo users that have given their heart to the user with the given id. */
  readonly receivedHearts: ReadonlyArray<User>;
  /** If null, this setting is not available for the authenticated user */
  readonly adultChannelsVisible?: Maybe<Scalars['Boolean']>;
  /** If null, this setting is not available for the authenticated user */
  readonly nsfwSearchEntriesVisible?: Maybe<Scalars['Boolean']>;
  /** If null, this setting is not available for the authenticated user */
  readonly adultChannelsShownInLastVisited?: Maybe<Scalars['Boolean']>;
};
export type UserQueryUserArgs = {
  id: Scalars['ID'];
};
export type UserQueryUserFromNickArgs = {
  nick: Scalars['String'];
  accountForNickSwitch?: Maybe<Scalars['Boolean']>;
};
export type UserQueryContactListArgs = {
  filter?: Maybe<ContactListFilter>;
};
export type UserQueryPhotoUrlArgs = {
  photoId: Scalars['ID'];
};
export type UserQueryAlbumPhotoCommentsArgs = {
  albumPhotoId: Scalars['ID'];
};
export type UserQueryReceivedHeartsArgs = {
  id: Scalars['ID'];
};
export type UserSentImage = {
  readonly __typename?: 'UserSentImage';
  readonly url: Scalars['String'];
};
export type UserSentSnap = {
  readonly __typename?: 'UserSentSnap';
  readonly url: Scalars['String'];
  readonly photoId: Scalars['ID'];
  readonly decryptionKey: Scalars['String'];
  readonly duration: Scalars['Int'];
};
export type ValidateTokenResponse = {
  readonly __typename?: 'ValidateTokenResponse';
  readonly result: ValidateTokenResult;
  readonly user?: Maybe<User>;
};
export enum ValidateTokenResult {
  /** Token is valid. */
  Valid = 'VALID',
  /** Token has expired. */
  Expired = 'EXPIRED',
  /** The request was invalid. */
  InvalidRequest = 'INVALID_REQUEST',
  /** An unknown internal error occurred. */
  InternalError = 'INTERNAL_ERROR',
}
export type WatchlistLimitReachedError = {
  readonly __typename?: 'WatchlistLimitReachedError';
  readonly limit: Scalars['Int'];
};
export type WebPaymentResponse = {
  readonly __typename?: 'WebPaymentResponse';
  /** The payment provider (e.g. paypal) url for the client to open. */
  readonly paymentUrl?: Maybe<Scalars['String']>;
  readonly error?: Maybe<WebPaymentResponseError>;
};
export enum WebPaymentResponseError {
  Internal = 'Internal',
  UserNotInChannel = 'UserNotInChannel',
  U18AmountRestriction = 'U18AmountRestriction',
}
export type SendSlashCommandMutationVariables = {
  event: SlashCommandInput;
};
export type SendSlashCommandMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly apps: {
    readonly __typename?: 'AppsMutation';
  } & {
    readonly sendSlashCommand: ({
      readonly __typename?: 'SendSlashCommandSuccess';
    } & Pick<SendSlashCommandSuccess, 'unused'>) | ({
      readonly __typename?: 'SendSlashCommandInternalError';
    } & Pick<SendSlashCommandInternalError, 'unused'>) | ({
      readonly __typename?: 'SendSlashCommandNotFoundError';
    } & Pick<SendSlashCommandNotFoundError, 'unused'>) | ({
      readonly __typename?: 'SendSlashCommandNotSupportedError';
    } & Pick<SendSlashCommandNotSupportedError, 'unused'>);
  };
};
export type AuthAsJamesUrlQueryVariables = {};
export type AuthAsJamesUrlQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly photoAuthenticatedAsJamesUrl: ({
      readonly __typename?: 'PhotoAuthenticatedAsJamesUrl';
    } & Pick<PhotoAuthenticatedAsJamesUrl, 'url'>) | {
      readonly __typename: 'AccessDeniedError';
    } | {
      readonly __typename: 'InternalError';
    };
  };
};
export type PhotoVerifyUrlQueryVariables = {};
export type PhotoVerifyUrlQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly photoVerifyUrl: ({
      readonly __typename?: 'PhotoVerifyUrl';
    } & Pick<PhotoVerifyUrl, 'url'>) | {
      readonly __typename: 'InternalError';
    };
  };
};
export type RefreshSessionTokenQueryVariables = {
  sessionInfo: SessionInfo;
  oldSessionToken?: Maybe<Scalars['SessionToken']>;
};
export type RefreshSessionTokenQuery = {
  readonly __typename?: 'Query';
} & {
  readonly login: {
    readonly __typename?: 'LoginQuery';
  } & {
    readonly refreshSession: ({
      readonly __typename?: 'RefreshSessionSuccess';
    } & Pick<RefreshSessionSuccess, 'expiry' | 'token'>) | ({
      readonly __typename?: 'RefreshSessionError';
    } & RefreshSessionErrorFragment);
  };
};
export type RefreshSessionErrorFragment = {
  readonly __typename?: 'RefreshSessionError';
} & Pick<RefreshSessionError, 'formattedErrorMessage'> & {
  readonly user: {
    readonly __typename?: 'User';
  } & UserWithLockInfoFragment;
};
export type UserWithLockInfoFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick'> & {
  readonly lockInfo: ({
    readonly __typename?: 'TemporaryLockInfo';
  } & Pick<TemporaryLockInfo, 'lockReason' | 'lockedUntilDate'> & {
    readonly lockedBy?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'nick'>>;
  }) | ({
    readonly __typename?: 'PermanentLockInfo';
  } & Pick<PermanentLockInfo, 'lockReason'> & {
    readonly lockedBy?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'nick'>>;
  }) | {
    readonly __typename: 'UnlockedLockInfo';
  };
};
export type CurrentServerTimeQueryVariables = {};
export type CurrentServerTimeQuery = {
  readonly __typename?: 'Query';
} & Pick<Query, 'currentTime'>;
export type CreateDeviceTokenQueryVariables = {
  username: Scalars['String'];
  password: Scalars['String'];
};
export type CreateDeviceTokenQuery = {
  readonly __typename?: 'Query';
} & {
  readonly login: {
    readonly __typename?: 'LoginQuery';
  } & {
    readonly createDeviceToken: {
      readonly __typename?: 'CreateDeviceTokenResponse';
    } & Pick<CreateDeviceTokenResponse, 'result' | 'token'>;
  };
};
export type EvergreenDataChangedSubscriptionVariables = {
  userId: Scalars['ID'];
};
export type EvergreenDataChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'evergreenData'>;
};
export type UserIsAdFreeQueryVariables = {};
export type UserIsAdFreeQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly currentUser: {
      readonly __typename?: 'User';
    } & UserWithAdFreeFragment;
  };
};
export type AdFreeAcquiredSubscriptionVariables = {};
export type AdFreeAcquiredSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly isAdFreeChanged: {
    readonly __typename?: 'User';
  } & UserWithAdFreeFragment;
};
export type UserWithAdFreeFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'isAdFree'>;
export type AppEventsSubscriptionVariables = {};
export type AppEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly appEvent: ({
    readonly __typename?: 'AppClosedEvent';
  } & Pick<AppClosedEvent, 'appId' | 'channelName'>) | ({
    readonly __typename?: 'AppControlPlaneEvent';
  } & Pick<AppControlPlaneEvent, 'appId' | 'channelName' | 'eventKey' | 'eventValue'>) | ({
    readonly __typename?: 'AppDataEvent';
  } & Pick<AppDataEvent, 'appId' | 'channelName' | 'eventKey' | 'eventValue'>) | ({
    readonly __typename?: 'AppOpenEvent';
  } & Pick<AppOpenEvent, 'appId' | 'channelName' | 'contentUrl' | 'loaderUrl' | 'pageData' | 'isUserApp'> & {
    readonly display: ({
      readonly __typename?: 'AppDisplayHeaderbar';
    } & Pick<AppDisplayHeaderbar, 'height'>) | ({
      readonly __typename?: 'AppDisplayScaled';
    } & Pick<AppDisplayScaled, 'height' | 'width'>) | ({
      readonly __typename?: 'AppDisplayPopup';
    } & Pick<AppDisplayPopup, 'height' | 'width'>) | ({
      readonly __typename?: 'AppDisplayChannelBackground';
    } & Pick<AppDisplayChannelBackground, 'unused'>) | ({
      readonly __typename?: 'AppDisplaySidebar';
    } & Pick<AppDisplaySidebar, 'height' | 'width'>);
  });
};
export type SendAppDataEventMutationVariables = {
  event: AppDataEventInput;
};
export type SendAppDataEventMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly apps: {
    readonly __typename?: 'AppsMutation';
  } & {
    readonly sendAppDataEvent: {
      readonly __typename?: 'SendAppEventResponse';
    } & Pick<SendAppEventResponse, 'unused'>;
  };
};
export type SendAppControlPlaneEventMutationVariables = {
  event: AppControlPlaneEventInput;
};
export type SendAppControlPlaneEventMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly apps: {
    readonly __typename?: 'AppsMutation';
  } & {
    readonly sendAppEvent: {
      readonly __typename?: 'SendAppEventResponse';
    } & Pick<SendAppEventResponse, 'unused'>;
  };
};
export type SilenceAppMutationVariables = {
  channel: Scalars['String'];
  days?: Maybe<Scalars['Int']>;
};
export type SilenceAppMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly apps: {
    readonly __typename?: 'AppsMutation';
  } & {
    readonly sendSilenceCommand: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'SilenceCommandChannelNotFoundError';
    } & Pick<SilenceCommandChannelNotFoundError, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type RevertSilenceAppMutationVariables = {
  channel: Scalars['String'];
};
export type RevertSilenceAppMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly apps: {
    readonly __typename?: 'AppsMutation';
  } & {
    readonly sendRevertSilenceCommand: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'SilenceCommandChannelNotFoundError';
    } & Pick<SilenceCommandChannelNotFoundError, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChannelGroupsByPrefixQueryVariables = {
  prefix: Scalars['String'];
};
export type ChannelGroupsByPrefixQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channelGroups: ReadonlyArray<{
      readonly __typename?: 'ChannelGroup';
    } & AutocompleteChannelGroupFragment>;
  };
};
export type InitialChannelAutocompleteQueryVariables = {};
export type InitialChannelAutocompleteQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly initialAutoCompletionChannelGroups: ReadonlyArray<{
      readonly __typename?: 'ChannelGroup';
    } & AutocompleteChannelGroupFragment>;
  };
};
export type AutocompleteChannelGroupFragment = {
  readonly __typename?: 'ChannelGroup';
} & Pick<ChannelGroup, 'id' | 'name'> & {
  readonly info: {
    readonly __typename?: 'ChannelGroupInfo';
  } & Pick<ChannelGroupInfo, 'previewImageUrl'> & {
    readonly backgroundColor?: Maybe<{
      readonly __typename?: 'Color';
    } & Pick<Color, 'red' | 'green' | 'blue' | 'alpha'>>;
  };
  readonly channels: ReadonlyArray<{
    readonly __typename?: 'Channel';
  } & Pick<Channel, 'id' | 'name' | 'onlineUserCount'>>;
};
export type SendMessageMutationVariables = {
  channelId: Scalars['ID'];
  text: Scalars['String'];
};
export type SendMessageMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly sendMessage: {
      readonly __typename?: 'SendMessageMutationResponse';
    } & Pick<SendMessageMutationResponse, 'error'>;
  };
};
export type GetUserForMacroBoxQueryVariables = {
  userId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type GetUserForMacroBoxQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & MacroBoxUserFragment>;
  };
};
export type MacroBoxUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'age' | 'gender' | 'city' | 'ignoreState' | 'isReportable' | 'isAppBot' | 'menteeStatus' | 'authenticityClassification' | 'canReceiveMessages' | 'conversationId' | 'isAllowedByContactFilter'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type GetCachedUserDataQueryVariables = {
  userId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type GetCachedUserDataQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'nick' | 'gender' | 'age'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
    }>;
  };
};
type ChannelMessage_ChannelMsgAction_Fragment = {
  readonly __typename?: 'ChannelMsgAction';
} & Pick<ChannelMsgAction, 'id' | 'formattedText' | 'iconFormattedText'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & ChannelMsgUserFragment;
};
type ChannelMessage_ChannelMsgPublic_Fragment = {
  readonly __typename?: 'ChannelMsgPublic';
} & Pick<ChannelMsgPublic, 'id' | 'formattedText'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & ChannelMsgUserFragment;
};
type ChannelMessage_ChannelMsgSystem_Fragment = {
  readonly __typename?: 'ChannelMsgSystem';
} & Pick<ChannelMsgSystem, 'id' | 'formattedText'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & ChannelMsgUserFragment;
  readonly sentFromChannel: {
    readonly __typename?: 'Channel';
  } & Pick<Channel, 'id'>;
};
type ChannelMessage_ChannelMsgPrivateGroup_Fragment = {
  readonly __typename?: 'ChannelMsgPrivateGroup';
} & Pick<ChannelMsgPrivateGroup, 'id' | 'formattedText'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & ChannelMsgUserFragment;
  readonly receiver: ReadonlyArray<{
    readonly __typename?: 'User';
  } & ChannelMsgUserFragment>;
};
export type ChannelMessageFragment = ChannelMessage_ChannelMsgAction_Fragment | ChannelMessage_ChannelMsgPublic_Fragment | ChannelMessage_ChannelMsgSystem_Fragment | ChannelMessage_ChannelMsgPrivateGroup_Fragment;
export type ChannelMsgUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick'>;
export type ChannelUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'age' | 'gender' | 'friendState' | 'menteeStatus'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type ActiveChannelFragment = {
  readonly __typename?: 'Channel';
} & Pick<Channel, 'id' | 'name'> & {
  readonly participants: ReadonlyArray<{
    readonly __typename?: 'ChannelParticipant';
  } & Pick<ChannelParticipant, 'nicklistPriority'> & {
    readonly user: {
      readonly __typename?: 'User';
    } & ChannelUserFragment;
  }>;
  readonly groupInfo: {
    readonly __typename?: 'ChannelGroupInfo';
  } & Pick<ChannelGroupInfo, 'isMyChannel' | 'isAdultChannel'> & {
    readonly backgroundColor?: Maybe<{
      readonly __typename?: 'Color';
    } & ColorFragment>;
    readonly backgroundImageInfo?: Maybe<{
      readonly __typename?: 'ChannelBackgroundImageInfo';
    } & Pick<ChannelBackgroundImageInfo, 'mode' | 'url'>>;
    readonly highlightColor?: Maybe<{
      readonly __typename?: 'Color';
    } & ColorFragment>;
    readonly highlightColorCondensedPrivateMessage?: Maybe<{
      readonly __typename?: 'Color';
    } & ColorFragment>;
  };
  readonly recentMessages: ReadonlyArray<({
    readonly __typename?: 'ChannelMsgAction';
  } & ChannelMessage_ChannelMsgAction_Fragment) | ({
    readonly __typename?: 'ChannelMsgPublic';
  } & ChannelMessage_ChannelMsgPublic_Fragment) | ({
    readonly __typename?: 'ChannelMsgSystem';
  } & ChannelMessage_ChannelMsgSystem_Fragment) | ({
    readonly __typename?: 'ChannelMsgPrivateGroup';
  } & ChannelMessage_ChannelMsgPrivateGroup_Fragment)>;
};
export type NicklistIconFragment = {
  readonly __typename?: 'NicklistIcon';
} & Pick<NicklistIcon, 'iconName' | 'iconFormattedText' | 'listType' | 'index'>;
export type ChannelEventsSubscriptionVariables = {
  pixelDensity: Scalars['Float'];
};
export type ChannelEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly channelEvent: ({
    readonly __typename?: 'ChannelMessageReceived';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly msg: ({
      readonly __typename?: 'ChannelMsgAction';
    } & ChannelMessage_ChannelMsgAction_Fragment) | ({
      readonly __typename?: 'ChannelMsgPublic';
    } & ChannelMessage_ChannelMsgPublic_Fragment) | ({
      readonly __typename?: 'ChannelMsgSystem';
    } & ChannelMessage_ChannelMsgSystem_Fragment) | ({
      readonly __typename?: 'ChannelMsgPrivateGroup';
    } & ChannelMessage_ChannelMsgPrivateGroup_Fragment);
  }) | ({
    readonly __typename?: 'ChannelPasswordRequired';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
  }) | ({
    readonly __typename?: 'ChannelUserJoined';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly participant: {
      readonly __typename?: 'ChannelParticipant';
    } & Pick<ChannelParticipant, 'nicklistPriority'> & {
      readonly user: {
        readonly __typename?: 'User';
      } & ChannelUserFragment;
    };
  }) | ({
    readonly __typename?: 'ChannelUserLeft';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'>;
  }) | ({
    readonly __typename?: 'NicklistIconRemoved';
  } & Pick<NicklistIconRemoved, 'iconName'> & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'>;
  }) | ({
    readonly __typename?: 'NicklistIconsAdded';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'ChannelParticipant';
    } & Pick<ChannelParticipant, 'nicklistColor'> & {
      readonly user: {
        readonly __typename?: 'User';
      } & Pick<User, 'id'>;
      readonly iconsToAdd: ReadonlyArray<{
        readonly __typename?: 'NicklistIcon';
      } & NicklistIconFragment>;
    }>;
  }) | ({
    readonly __typename?: 'NicklistPriorityChanged';
  } & Pick<NicklistPriorityChanged, 'nicklistPriority'> & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'>;
  }) | ({
    readonly __typename?: 'ProfilePictureChanged';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly user: {
      readonly __typename?: 'User';
    } & ProfilePictureUserFragment;
  }) | ({
    readonly __typename?: 'ProfilePictureOverlaysChanged';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id'>;
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePictureOverlayUrls: {
        readonly __typename?: 'ProfilePictureOverlays';
      } & ProfilePictureOverlaysFragment;
    };
  });
};
export type GetChannelNameQueryVariables = {
  channelId: Scalars['ID'];
};
export type GetChannelNameQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channel: {
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id' | 'name'>;
  };
};
export type GetChannelForCurrentSessionQueryVariables = {
  pixelDensity: Scalars['Float'];
};
export type GetChannelForCurrentSessionQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channelForCurrentSession?: Maybe<{
      readonly __typename?: 'Channel';
    } & ActiveChannelFragment>;
  };
};
export type GetPreviousSearchesQueryVariables = {};
export type GetPreviousSearchesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & Pick<ChannelQuery, 'previousSearches'>;
};
export type JoinChannelByIdMutationVariables = {
  channelId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  pixelDensity: Scalars['Float'];
  confirmed?: Maybe<Scalars['Boolean']>;
  mayJoinSubChannelIfFull?: Maybe<Scalars['Boolean']>;
};
export type JoinChannelByIdMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly joinById: {
      readonly __typename?: 'ChannelJoinMutationResponse';
    } & {
      readonly channel?: Maybe<{
        readonly __typename?: 'Channel';
      } & ActiveChannelFragment>;
      readonly error?: Maybe<{
        readonly __typename?: 'ChannelJoinMutationResponseError';
      } & ChannelJoinErrorFragment>;
    };
  };
};
export type InitialJoinMutationVariables = {
  force?: Maybe<Scalars['Boolean']>;
  pixelDensity: Scalars['Float'];
};
export type InitialJoinMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly initialJoin: {
      readonly __typename?: 'InitialChannelJoinMutationResponse';
    } & {
      readonly channels: ReadonlyArray<{
        readonly __typename?: 'Channel';
      } & ActiveChannelFragment>;
      readonly error?: Maybe<{
        readonly __typename?: 'ChannelJoinMutationResponseError';
      } & ChannelJoinErrorFragment>;
    };
  };
};
export type JoinChannelByGroupIdMutationVariables = {
  groupId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  adCampaignId?: Maybe<Scalars['Int']>;
  pixelDensity: Scalars['Float'];
  confirmed?: Maybe<Scalars['Boolean']>;
};
export type JoinChannelByGroupIdMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly joinByGroupId: {
      readonly __typename?: 'ChannelJoinMutationResponse';
    } & {
      readonly channel?: Maybe<{
        readonly __typename?: 'Channel';
      } & ActiveChannelFragment>;
      readonly error?: Maybe<{
        readonly __typename?: 'ChannelJoinMutationResponseError';
      } & ChannelJoinErrorFragment>;
    };
  };
};
export type JoinChannelByNameMutationVariables = {
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  pixelDensity: Scalars['Float'];
  confirmed?: Maybe<Scalars['Boolean']>;
};
export type JoinChannelByNameMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly joinByName: {
      readonly __typename?: 'ChannelJoinMutationResponse';
    } & {
      readonly channel?: Maybe<{
        readonly __typename?: 'Channel';
      } & ActiveChannelFragment>;
      readonly error?: Maybe<{
        readonly __typename?: 'ChannelJoinMutationResponseError';
      } & ChannelJoinErrorFragment>;
    };
  };
};
export type AddPreviousSearchMutationVariables = {
  text: Scalars['String'];
};
export type AddPreviousSearchMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly addPreviousSearch: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChannelJoinErrorFragment = {
  readonly __typename?: 'ChannelJoinMutationResponseError';
} & Pick<ChannelJoinMutationResponseError, 'type' | 'freetext' | 'userNick' | 'otherChannelName' | 'minAge' | 'maxUser' | 'startTime' | 'endTime' | 'minKnuddels' | 'minTradeableSmileys' | 'minRegisteredDays' | 'minStammiMonths' | 'requiredGender' | 'requiredStatusName'>;
export type ProfilePictureUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
};
export type LeaveChannelMutationVariables = {};
export type LeaveChannelMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelMutation';
  } & {
    readonly leave: {
      readonly __typename?: 'LeaveChannelMutationResponse';
    } & Pick<LeaveChannelMutationResponse, 'error'>;
  };
};
export type GetProfilePictureByUserIdQueryVariables = {
  userId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
  size?: Maybe<Scalars['Int']>;
};
export type GetProfilePictureByUserIdQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
    }>;
  };
};
export type GetUserIsAppBotQueryVariables = {
  userId: Scalars['ID'];
};
export type GetUserIsAppBotQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'isAppBot'>>;
  };
};
export type SystemEventsSubscriptionVariables = {};
export type SystemEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly systemEvent: ({
    readonly __typename?: 'ChannelConnectionDisconnected';
  } & {
    newReason: ChannelConnectionDisconnected['reason'];
  }) | ({
    readonly __typename?: 'ClientDisconnected';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'>;
    readonly disconnectReason: ({
      readonly __typename?: 'ClientDisconnectReasonUnknown';
    } & Pick<ClientDisconnectReasonUnknown, 'unused'>) | ({
      readonly __typename?: 'ClientDisconnectReasonUserLocked';
    } & Pick<ClientDisconnectReasonUserLocked, 'unused'>) | ({
      readonly __typename?: 'ClientDisconnectReasonUserDeleted';
    } & Pick<ClientDisconnectReasonUserDeleted, 'unused'>) | ({
      readonly __typename?: 'ClientDisconnectReasonNicknameChanged';
    } & Pick<ClientDisconnectReasonNicknameChanged, 'newNick'>);
  }) | ({
    readonly __typename?: 'OpenUrl';
  } & Pick<OpenUrl, 'target' | 'url'>);
};
export type ExactMatchChannelSearchQueryVariables = {
  searchText: Scalars['String'];
  pixelDensity: Scalars['Float'];
};
export type ExactMatchChannelSearchQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channelGroup?: Maybe<{
      readonly __typename?: 'ChannelGroup';
    } & SearchChannelGroupFragment>;
  };
};
export type FilteredChannelGroupsByPrefixAndCategoryQueryVariables = {
  prefix: Scalars['String'];
  pixelDensity: Scalars['Float'];
};
export type FilteredChannelGroupsByPrefixAndCategoryQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channelGroups: ReadonlyArray<{
      readonly __typename?: 'ChannelGroup';
    } & SearchChannelGroupFragment>;
  };
};
export type SearchChannelGroupFragment = {
  readonly __typename?: 'ChannelGroup';
} & Pick<ChannelGroup, 'id' | 'name'> & {
  readonly info: {
    readonly __typename?: 'ChannelGroupInfo';
  } & SearchChannelGroupInfoFragment;
  readonly channels: ReadonlyArray<{
    readonly __typename?: 'Channel';
  } & Pick<Channel, 'id' | 'name' | 'onlineUserCount'>>;
  readonly previewMembers: {
    readonly __typename?: 'ChannelPreviewMembers';
  } & ChannelPreviewMembersFragment;
};
export type SearchChannelGroupInfoFragment = {
  readonly __typename?: 'ChannelGroupInfo';
} & Pick<ChannelGroupInfo, 'categoryNames' | 'searchKeywords'> & ChannelGroupInfoFragment;
export type GetChannelListOverviewQueryVariables = {
  groupAmount: Scalars['Int'];
  pixelDensity: Scalars['Float'];
};
export type GetChannelListOverviewQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channelAds: ReadonlyArray<{
      readonly __typename?: 'ChannelAd';
    } & ChannelAdFragment>;
    readonly categories: ReadonlyArray<{
      readonly __typename?: 'ChannelCategory';
    } & ChannelCategoryFragment>;
  };
};
export type GetRecommendedChannelsQueryVariables = {
  groupAmount: Scalars['Int'];
  pixelDensity: Scalars['Float'];
};
export type GetRecommendedChannelsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly recommendedCategories: {
      readonly __typename?: 'RecommendedChannelCategories';
    } & {
      readonly lastVisited: {
        readonly __typename?: 'ChannelCategory';
      } & ChannelCategoryFragment;
      readonly popular: {
        readonly __typename?: 'ChannelCategory';
      } & ChannelCategoryFragment;
    };
  };
};
export type GetChannelCategoriesQueryVariables = {
  groupAmount: Scalars['Int'];
  pixelDensity: Scalars['Float'];
};
export type GetChannelCategoriesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly categories: ReadonlyArray<{
      readonly __typename?: 'ChannelCategory';
    } & ChannelCategoryFragment>;
  };
};
export type GetGameChannelsQueryVariables = {
  groupAmount: Scalars['Int'];
  pixelDensity: Scalars['Float'];
};
export type GetGameChannelsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly gameCategories: {
      readonly __typename?: 'GameChannelCategories';
    } & {
      readonly top: ReadonlyArray<{
        readonly __typename?: 'TopGameChannel';
      } & TopChannelCategoryFragment>;
      readonly all: {
        readonly __typename?: 'ChannelCategory';
      } & ChannelCategoryFragment;
    };
  };
};
export type GetChannelListCategoryHeaderQueryVariables = {
  categoryId: Scalars['ID'];
};
export type GetChannelListCategoryHeaderQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly category: {
      readonly __typename?: 'ChannelCategory';
    } & Pick<ChannelCategory, 'id' | 'name'>;
  };
};
export type GetChannelListCategoryQueryVariables = {
  categoryId: Scalars['ID'];
  groupAmount: Scalars['Int'];
  pixelDensity: Scalars['Float'];
};
export type GetChannelListCategoryQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly category: {
      readonly __typename?: 'ChannelCategory';
    } & ChannelCategoryFragment;
  };
};
export type GetChannelGroupsQueryVariables = {
  ids: ReadonlyArray<Scalars['ID']>;
  pixelDensity: Scalars['Float'];
};
export type GetChannelGroupsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly channelGroupsByIds: ReadonlyArray<{
      readonly __typename?: 'ChannelGroup';
    } & ChannelGroupFragment>;
  };
};
export type GetDashboardChannelGroupsQueryVariables = {
  pixelDensity: Scalars['Float'];
};
export type GetDashboardChannelGroupsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly channel: {
    readonly __typename?: 'ChannelQuery';
  } & {
    readonly dashboardChannelGroups: ReadonlyArray<{
      readonly __typename?: 'ChannelGroup';
    } & ChannelGroupFragment>;
  };
};
export type ChannelAdFragment = {
  readonly __typename?: 'ChannelAd';
} & Pick<ChannelAd, 'adCampaignId'> & {
  readonly channelGroup: {
    readonly __typename?: 'ChannelGroup';
  } & Pick<ChannelGroup, 'id' | 'name'> & {
    readonly info: {
      readonly __typename?: 'ChannelGroupInfo';
    } & ChannelGroupInfoFragment;
    readonly channels: ReadonlyArray<{
      readonly __typename?: 'Channel';
    } & Pick<Channel, 'id' | 'onlineUserCount'>>;
  };
};
export type ChannelCategoryFragment = {
  readonly __typename?: 'ChannelCategory';
} & Pick<ChannelCategory, 'id' | 'name'> & {
  readonly channelGroups: ReadonlyArray<{
    readonly __typename?: 'ChannelGroup';
  } & ChannelGroupFragment>;
};
export type ChannelGroupFragment = {
  readonly __typename?: 'ChannelGroup';
} & Pick<ChannelGroup, 'id' | 'name'> & {
  readonly info: {
    readonly __typename?: 'ChannelGroupInfo';
  } & ChannelGroupInfoFragment;
  readonly channels: ReadonlyArray<{
    readonly __typename?: 'Channel';
  } & ChannelFragment>;
  readonly onlineContacts: ReadonlyArray<{
    readonly __typename?: 'User';
  } & ChannelListContactFragment>;
  readonly previewMembers: {
    readonly __typename?: 'ChannelPreviewMembers';
  } & ChannelPreviewMembersFragment;
};
export type ChannelPreviewMembersFragment = {
  readonly __typename?: 'ChannelPreviewMembers';
} & {
  readonly friends: ReadonlyArray<{
    readonly __typename?: 'User';
  } & ChannelListContactFragment>;
  readonly watchlist: ReadonlyArray<{
    readonly __typename?: 'User';
  } & ChannelListContactFragment>;
  readonly others: ReadonlyArray<{
    readonly __typename?: 'User';
  } & ChannelListContactFragment>;
};
export type TopChannelCategoryFragment = {
  readonly __typename?: 'TopGameChannel';
} & Pick<TopGameChannel, 'longDescription' | 'headerImage' | 'glowOverlay' | 'gameCount'> & {
  readonly group: {
    readonly __typename?: 'ChannelGroup';
  } & Pick<ChannelGroup, 'id' | 'name'> & {
    readonly info: {
      readonly __typename?: 'ChannelGroupInfo';
    } & ChannelGroupInfoFragment;
    readonly channels: ReadonlyArray<{
      readonly __typename?: 'Channel';
    } & ChannelFragment>;
    readonly onlineContacts: ReadonlyArray<{
      readonly __typename?: 'User';
    } & ChannelListContactFragment>;
  };
  readonly games: ReadonlyArray<{
    readonly __typename?: 'ChannelListGame';
  } & Pick<ChannelListGame, 'pictureUrl'>>;
};
export type ChannelFragment = {
  readonly __typename?: 'Channel';
} & Pick<Channel, 'id' | 'name' | 'onlineUserCount'> & {
  readonly onlineContacts: ReadonlyArray<{
    readonly __typename?: 'User';
  } & ChannelListContactFragment>;
  readonly previewMembers: {
    readonly __typename?: 'ChannelPreviewMembers';
  } & ChannelPreviewMembersFragment;
};
export type ChannelListContactFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
};
export type ChannelGroupInfoFragment = {
  readonly __typename?: 'ChannelGroupInfo';
} & Pick<ChannelGroupInfo, 'previewImageUrl' | 'isSpecialPreviewImage' | 'shortDescription' | 'mainCategoryName' | 'isMyChannel'> & {
  readonly previewImageInfo: {
    readonly __typename?: 'ChannelPreviewImageInfo';
  } & Pick<ChannelPreviewImageInfo, 'url' | 'urlBlurred'>;
  readonly backgroundColor?: Maybe<{
    readonly __typename?: 'Color';
  } & ColorFragment>;
};
export type ColorFragment = {
  readonly __typename?: 'Color';
} & Pick<Color, 'alpha' | 'blue' | 'green' | 'red'>;
export type ContactsUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'isOnline' | 'currentOnlineChannelName' | 'lastOnlineTime' | 'latestOnlineChannelName' | 'readMe' | 'canReceiveMessages' | 'menteeStatus' | 'isAppBot' | 'authenticityClassification' | 'isOnWatchlist' | 'friendState'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type ContactsUserByNickQueryVariables = {
  nick: Scalars['String'];
  pixelDensity: Scalars['Float'];
  accountForNickSwitch?: Maybe<Scalars['Boolean']>;
};
export type ContactsUserByNickQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly userFromNick?: Maybe<{
      readonly __typename?: 'User';
    } & ContactsUserFragment>;
  };
};
export type GetContactsQueryVariables = {
  filter?: Maybe<ContactListFilter>;
  pixelDensity: Scalars['Float'];
};
export type GetContactsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly contactList: {
      readonly __typename?: 'ContactList';
    } & {
      readonly contacts: ReadonlyArray<{
        readonly __typename?: 'User';
      } & ContactsUserFragment>;
    };
  };
};
export type FriendRequestUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'age' | 'gender'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type FriendRequestsQueryVariables = {
  pixelDensity: Scalars['Float'];
};
export type FriendRequestsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsQuery';
  } & {
    readonly sentFriendRequests: ReadonlyArray<{
      readonly __typename?: 'User';
    } & FriendRequestUserFragment>;
    readonly receivedFriendRequests: ReadonlyArray<{
      readonly __typename?: 'User';
    } & FriendRequestUserFragment>;
  };
};
export type FriendRequestEventsSubscriptionVariables = {
  pixelDensity: Scalars['Float'];
};
export type FriendRequestEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly contactsEvent: ({
    readonly __typename?: 'ReceivedFriendRequestAddedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & FriendRequestUserFragment;
  }) | ({
    readonly __typename?: 'ReceivedFriendRequestRemovedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & FriendRequestUserFragment;
  }) | ({
    readonly __typename?: 'SentFriendRequestAddedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & FriendRequestUserFragment;
  }) | ({
    readonly __typename?: 'SentFriendRequestRemovedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & FriendRequestUserFragment;
  });
};
export type FriendStateChangedEventSubscriptionVariables = {};
export type FriendStateChangedEventSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly friendStateChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'friendState'>;
};
export type CancelFriendRequestMutationVariables = {
  userId: Scalars['ID'];
};
export type CancelFriendRequestMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly cancelFriendRequest: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type CancelFriendshipMutationVariables = {
  userId: Scalars['ID'];
};
export type CancelFriendshipMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly cancelFriendship: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type AcceptFriendRequestMutationVariables = {
  userId: Scalars['ID'];
};
export type AcceptFriendRequestMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly acceptFriendRequest: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DeclineFriendRequestMutationVariables = {
  userId: Scalars['ID'];
};
export type DeclineFriendRequestMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly declineFriendRequest: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type AddToWatchlistMutationVariables = {
  userId: Scalars['ID'];
};
export type AddToWatchlistMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly addToWatchlist: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'WatchlistLimitReachedError';
    } & Pick<WatchlistLimitReachedError, 'limit'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type RemoveFromWatchlistMutationVariables = {
  userId: Scalars['ID'];
};
export type RemoveFromWatchlistMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly removeFromWatchlist: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type WatchlistUserQueryVariables = {
  userId: Scalars['ID'];
};
export type WatchlistUserQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'isOnWatchlist'>>;
  };
};
export type ContactListChangedSubscriptionVariables = {
  filter: ContactListFilter;
  pixelDensity: Scalars['Float'];
};
export type ContactListChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly contactListEvent: ({
    readonly __typename?: 'ContactAddedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & ContactsUserFragment;
  }) | ({
    readonly __typename?: 'ContactRemovedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'isOnWatchlist'>;
  });
};
export type GetFeatureFlagsQueryVariables = {};
export type GetFeatureFlagsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly featureFlags: {
    readonly __typename?: 'FeatureFlagsQuery';
  } & {
    readonly enabledFlags: ReadonlyArray<{
      readonly __typename?: 'FeatureFlag';
    } & Pick<FeatureFlag, 'id'>>;
  };
};
export type RemoveMatchMutationVariables = {
  id: Scalars['ID'];
};
export type RemoveMatchMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly fotomeet: {
    readonly __typename?: 'FotomeetMutation';
  } & Pick<FotomeetMutation, 'removeMatchedUser'>;
};
export type HappyMomentEventsSubscriptionVariables = {};
export type HappyMomentEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly happyMomentEvent: ({
    readonly __typename?: 'DailyLoginUsed';
  } & DailyLoginUsedFragment) | ({
    readonly __typename?: 'FriendRequestAccepted';
  } & FriendRequestAcceptedFragment) | ({
    readonly __typename?: 'LongConversationOccurred';
  } & LongConversationOccurredFragment);
};
export type LongConversationOccurredFragment = {
  readonly __typename?: 'LongConversationOccurred';
} & {
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id'>;
};
export type DailyLoginUsedFragment = {
  readonly __typename?: 'DailyLoginUsed';
} & Pick<DailyLoginUsed, 'unused'>;
export type FriendRequestAcceptedFragment = {
  readonly __typename?: 'FriendRequestAccepted';
} & Pick<FriendRequestAccepted, 'unused'>;
export type IpDetectionUrlQueryVariables = {};
export type IpDetectionUrlQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & Pick<UserQuery, 'ipDetectionUrl'>;
};
export type MentorEventsSubscriptionVariables = {};
export type MentorEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly mentorEvent: ({
    readonly __typename?: 'PotentialMenteeAddedEvent';
  } & PotentialMenteeAddedEventFragment) | ({
    readonly __typename?: 'PotentialMenteeRemovedEvent';
  } & {
    readonly user: {
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'menteeStatus'>;
  }) | ({
    readonly __typename?: 'MentorStatusChanged';
  } & {
    readonly newStatus: ({
      readonly __typename?: 'MentorStatusDisabled';
    } & MentorStatus_MentorStatusDisabled_Fragment) | ({
      readonly __typename?: 'MentorStatusAvailable';
    } & MentorStatus_MentorStatusAvailable_Fragment) | ({
      readonly __typename?: 'MentorStatusActive';
    } & MentorStatus_MentorStatusActive_Fragment);
  }) | ({
    readonly __typename?: 'MentorAchievedEvent';
  } & {
    readonly mentee: {
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'menteeStatus' | 'interest' | 'latestClient'>;
  });
};
export type PotentialMenteeAddedEventFragment = {
  readonly __typename?: 'PotentialMenteeAddedEvent';
} & {
  readonly user: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'nick' | 'age' | 'gender' | 'currentOnlineChannelName' | 'isOnline' | 'conversationId' | 'menteeStatus' | 'interest' | 'latestClient'>;
};
export type GetMentorStatusQueryVariables = {};
export type GetMentorStatusQuery = {
  readonly __typename?: 'Query';
} & {
  readonly mentor: {
    readonly __typename?: 'MentorQuery';
  } & {
    readonly mentorStatus: ({
      readonly __typename?: 'MentorStatusDisabled';
    } & MentorStatus_MentorStatusDisabled_Fragment) | ({
      readonly __typename?: 'MentorStatusAvailable';
    } & MentorStatus_MentorStatusAvailable_Fragment) | ({
      readonly __typename?: 'MentorStatusActive';
    } & MentorStatus_MentorStatusActive_Fragment);
  };
};
type MentorStatus_MentorStatusDisabled_Fragment = {
  readonly __typename?: 'MentorStatusDisabled';
} & Pick<MentorStatusDisabled, 'unused'>;
type MentorStatus_MentorStatusAvailable_Fragment = {
  readonly __typename?: 'MentorStatusAvailable';
} & Pick<MentorStatusAvailable, 'unused'>;
type MentorStatus_MentorStatusActive_Fragment = {
  readonly __typename?: 'MentorStatusActive';
} & MentorStatusActiveFragment;
export type MentorStatusFragment = MentorStatus_MentorStatusDisabled_Fragment | MentorStatus_MentorStatusAvailable_Fragment | MentorStatus_MentorStatusActive_Fragment;
export type MentorStatusActiveFragment = {
  readonly __typename?: 'MentorStatusActive';
} & Pick<MentorStatusActive, 'pauseState' | 'mentorPoints' | 'previousMentorLevelBoundary' | 'mentorLevelBoundary' | 'conversationStartedCount' | 'conversationStartedBoundary'> & {
  readonly nextMentorReward?: Maybe<{
    readonly __typename?: 'MentorReward';
  } & Pick<MentorReward, 'icon' | 'title'>>;
};
export type ActivateMentorSystemMutationVariables = {};
export type ActivateMentorSystemMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly mentor: {
    readonly __typename?: 'MentorMutation';
  } & {
    readonly activateMentorSystem: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type UpdateMentorPauseStateMutationVariables = {
  state: MentorPauseState;
};
export type UpdateMentorPauseStateMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly mentor: {
    readonly __typename?: 'MentorMutation';
  } & {
    readonly changeMentorPauseState: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type CommonFriendsQueryVariables = {
  userId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type CommonFriendsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsQuery';
  } & {
    readonly commonFriends: ({
      readonly __typename?: 'FriendList';
    } & {
      readonly friends: ReadonlyArray<{
        readonly __typename?: 'User';
      } & CommonFriendFragment>;
    }) | ({
      readonly __typename?: 'FriendsHiddenByPrivacy';
    } & Pick<FriendsHiddenByPrivacy, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type CommonFriendFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type StarredMessagesQueryVariables = {
  limit: Scalars['Int'];
  before?: Maybe<Scalars['UtcTimestamp']>;
  pixelDensity: Scalars['Float'];
};
export type StarredMessagesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly starredConversationMessages: {
      readonly __typename?: 'StarredConversationMessagesResponse';
    } & Pick<StarredConversationMessagesResponse, 'hasMore'> & {
      readonly messages: ReadonlyArray<{
        readonly __typename?: 'StarredConversationMessage';
      } & StarredConversationMessageFragment>;
    };
  };
};
export type StarredConversationMessageFragment = {
  readonly __typename?: 'StarredConversationMessage';
} & {
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id'> & {
    readonly otherParticipants: ReadonlyArray<{
      readonly __typename?: 'User';
    } & StarredMessagesUserFragment>;
  };
  readonly message: {
    readonly __typename?: 'ConversationMessage';
  } & StarredMessagesConversationMessageFragment;
};
export type StarredMessagesConversationMessageFragment = {
  readonly __typename?: 'ConversationMessage';
} & {
  readonly sender: {
    readonly __typename?: 'User';
  } & StarredMessagesUserFragment;
} & ConversationMessageFragment;
export type StarredMessagesUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
};
export type MessengerOverviewQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['UtcTimestamp']>;
  pixelDensity: Scalars['Float'];
  filterByState?: Maybe<MessengerConversationState>;
};
export type MessengerOverviewQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversations: {
      readonly __typename?: 'MessengerConversationsResponse';
    } & Pick<MessengerConversationsResponse, 'hasMore'> & {
      readonly conversations: ReadonlyArray<{
        readonly __typename?: 'MessengerConversation';
      } & FullConversationWithoutMessagesFragment>;
    };
  };
};
export type GetConversationQueryVariables = {
  id: Scalars['ID'];
  messageCount?: Maybe<Scalars['Int']>;
  beforeMessageId?: Maybe<Scalars['ID']>;
  pixelDensity: Scalars['Float'];
};
export type GetConversationQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversation?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & FullConversationFragment>;
  };
};
export type LoadMoreMessagesQueryVariables = {
  conversationId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  beforeMessageTimestamp?: Maybe<Scalars['UtcTimestamp']>;
};
export type LoadMoreMessagesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversationMessages: {
      readonly __typename?: 'ConversationMessages';
    } & Pick<ConversationMessages, 'hasMore'> & {
      readonly messages: ReadonlyArray<{
        readonly __typename?: 'ConversationMessage';
      } & ConversationMessageFragment>;
    };
  };
};
export type GetConversationWithoutMessagesQueryVariables = {
  id: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type GetConversationWithoutMessagesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversation?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & FullConversationWithoutMessagesFragment>;
  };
};
export type FullConversationFragment = {
  readonly __typename?: 'MessengerConversation';
} & Pick<MessengerConversation, 'id' | 'visibility'> & {
  readonly otherParticipants: ReadonlyArray<{
    readonly __typename?: 'User';
  } & MessengerFullUserFragment>;
  readonly readState: {
    readonly __typename?: 'MessengerConversationReadState';
  } & Pick<MessengerConversationReadState, 'markedAsUnread' | 'unreadMessageCount'> & {
    readonly lastReadConversationMessage?: Maybe<{
      readonly __typename?: 'ConversationMessage';
    } & Pick<ConversationMessage, 'id'>>;
  };
  readonly latestConversationMessage?: Maybe<{
    readonly __typename?: 'ConversationMessage';
  } & ConversationMessageFragment>;
  readonly conversationMessages: {
    readonly __typename?: 'ConversationMessages';
  } & Pick<ConversationMessages, 'hasMore'> & {
    readonly messages: ReadonlyArray<{
      readonly __typename?: 'ConversationMessage';
    } & ConversationMessageFragment>;
  };
};
export type ConversationMessageFragment = {
  readonly __typename?: 'ConversationMessage';
} & Pick<ConversationMessage, 'id' | 'timestamp'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & MessengerBasicUserFragment;
  readonly content: ({
    readonly __typename?: 'ConversationTextMessageContent';
  } & ConversationMessageContent_ConversationTextMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationForwardedMessageContent';
  } & ConversationMessageContent_ConversationForwardedMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationQuotedMessageContent';
  } & ConversationMessageContent_ConversationQuotedMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationSnapMessageContent';
  } & ConversationMessageContent_ConversationSnapMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationImageMessageContent';
  } & ConversationMessageContent_ConversationImageMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationVisiblePhotoCommentMessageContent';
  } & ConversationMessageContent_ConversationVisiblePhotoCommentMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationHiddenPhotoCommentMessageContent';
  } & ConversationMessageContent_ConversationHiddenPhotoCommentMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationDeletedPhotoCommentMessageContent';
  } & ConversationMessageContent_ConversationDeletedPhotoCommentMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationKnuddelTransferMessageContent';
  } & ConversationMessageContent_ConversationKnuddelTransferMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationMentorAchievedMessageContent';
  } & ConversationMessageContent_ConversationMentorAchievedMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationPrivateSystemMessageContent';
  } & ConversationMessageContent_ConversationPrivateSystemMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationBirthdayMessageContent';
  } & ConversationMessageContent_ConversationBirthdayMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationNicknameChangeMessageContent';
  } & ConversationMessageContent_ConversationNicknameChangeMessageContent_Fragment);
};
type ConversationMessageContent_ConversationTextMessageContent_Fragment = {
  readonly __typename?: 'ConversationTextMessageContent';
} & ConversationTextMessageContentFragment;
type ConversationMessageContent_ConversationForwardedMessageContent_Fragment = {
  readonly __typename?: 'ConversationForwardedMessageContent';
} & ConversationForwardedMessageContentFragment;
type ConversationMessageContent_ConversationQuotedMessageContent_Fragment = {
  readonly __typename?: 'ConversationQuotedMessageContent';
} & ConversationQuotedMessageContentFragment;
type ConversationMessageContent_ConversationSnapMessageContent_Fragment = {
  readonly __typename?: 'ConversationSnapMessageContent';
} & ConversationSnapMessageContentFragment;
type ConversationMessageContent_ConversationImageMessageContent_Fragment = {
  readonly __typename?: 'ConversationImageMessageContent';
} & ConversationImageMessageContentFragment;
type ConversationMessageContent_ConversationVisiblePhotoCommentMessageContent_Fragment = {
  readonly __typename?: 'ConversationVisiblePhotoCommentMessageContent';
} & ConversationVisiblePhotoCommentMessageContentFragment;
type ConversationMessageContent_ConversationHiddenPhotoCommentMessageContent_Fragment = {
  readonly __typename?: 'ConversationHiddenPhotoCommentMessageContent';
} & ConversationHiddenPhotoCommentMessageContentFragment;
type ConversationMessageContent_ConversationDeletedPhotoCommentMessageContent_Fragment = {
  readonly __typename?: 'ConversationDeletedPhotoCommentMessageContent';
} & ConversationDeletedPhotoCommentMessageContentFragment;
type ConversationMessageContent_ConversationKnuddelTransferMessageContent_Fragment = {
  readonly __typename?: 'ConversationKnuddelTransferMessageContent';
} & ConversationKnuddelTransferMessageContentFragment;
type ConversationMessageContent_ConversationMentorAchievedMessageContent_Fragment = {
  readonly __typename?: 'ConversationMentorAchievedMessageContent';
} & ConversationMentorAchievedMessageContentFragment;
type ConversationMessageContent_ConversationPrivateSystemMessageContent_Fragment = {
  readonly __typename?: 'ConversationPrivateSystemMessageContent';
} & ConversationPrivateSystemMessageContentFragment;
type ConversationMessageContent_ConversationBirthdayMessageContent_Fragment = {
  readonly __typename?: 'ConversationBirthdayMessageContent';
} & ConversationBirthdayMessageContentFragment;
type ConversationMessageContent_ConversationNicknameChangeMessageContent_Fragment = {
  readonly __typename?: 'ConversationNicknameChangeMessageContent';
} & ConversationNicknameChangeMessageContentFragment;
export type ConversationMessageContentFragment = ConversationMessageContent_ConversationTextMessageContent_Fragment | ConversationMessageContent_ConversationForwardedMessageContent_Fragment | ConversationMessageContent_ConversationQuotedMessageContent_Fragment | ConversationMessageContent_ConversationSnapMessageContent_Fragment | ConversationMessageContent_ConversationImageMessageContent_Fragment | ConversationMessageContent_ConversationVisiblePhotoCommentMessageContent_Fragment | ConversationMessageContent_ConversationHiddenPhotoCommentMessageContent_Fragment | ConversationMessageContent_ConversationDeletedPhotoCommentMessageContent_Fragment | ConversationMessageContent_ConversationKnuddelTransferMessageContent_Fragment | ConversationMessageContent_ConversationMentorAchievedMessageContent_Fragment | ConversationMessageContent_ConversationPrivateSystemMessageContent_Fragment | ConversationMessageContent_ConversationBirthdayMessageContent_Fragment | ConversationMessageContent_ConversationNicknameChangeMessageContent_Fragment;
export type ConversationTextMessageContentFragment = {
  readonly __typename?: 'ConversationTextMessageContent';
} & Pick<ConversationTextMessageContent, 'formattedText' | 'starred'>;
export type ConversationVisiblePhotoCommentMessageContentFragment = {
  readonly __typename?: 'ConversationVisiblePhotoCommentMessageContent';
} & Pick<ConversationVisiblePhotoCommentMessageContent, 'albumPhotoId' | 'commentId' | 'photoUrl' | 'formattedText'>;
export type ConversationHiddenPhotoCommentMessageContentFragment = {
  readonly __typename?: 'ConversationHiddenPhotoCommentMessageContent';
} & Pick<ConversationHiddenPhotoCommentMessageContent, 'albumPhotoId' | 'photoUrl' | 'formattedText'>;
export type ConversationDeletedPhotoCommentMessageContentFragment = {
  readonly __typename?: 'ConversationDeletedPhotoCommentMessageContent';
} & Pick<ConversationDeletedPhotoCommentMessageContent, 'unused'>;
export type ConversationSnapMessageContentFragment = {
  readonly __typename?: 'ConversationSnapMessageContent';
} & Pick<ConversationSnapMessageContent, 'imageAccepted' | 'sensitiveContentClassification'> & {
  readonly snap?: Maybe<{
    readonly __typename?: 'UserSentSnap';
  } & Pick<UserSentSnap, 'url' | 'photoId' | 'duration' | 'decryptionKey'>>;
};
export type ConversationImageMessageContentFragment = {
  readonly __typename?: 'ConversationImageMessageContent';
} & Pick<ConversationImageMessageContent, 'starred' | 'imageAccepted' | 'sensitiveContentClassification'> & {
  readonly image?: Maybe<{
    readonly __typename?: 'UserSentImage';
  } & Pick<UserSentImage, 'url'>>;
};
export type ConversationQuotedMessageContentFragment = {
  readonly __typename?: 'ConversationQuotedMessageContent';
} & Pick<ConversationQuotedMessageContent, 'starred' | 'formattedText'> & {
  readonly nestedMessage: {
    readonly __typename?: 'ConversationNestedMessage';
  } & ConversationNestedMessageFragment;
};
export type ConversationForwardedMessageContentFragment = {
  readonly __typename?: 'ConversationForwardedMessageContent';
} & Pick<ConversationForwardedMessageContent, 'starred'> & {
  readonly nestedMessage: {
    readonly __typename?: 'ConversationNestedMessage';
  } & ConversationNestedMessageFragment;
};
export type ConversationBirthdayMessageContentFragment = {
  readonly __typename?: 'ConversationBirthdayMessageContent';
} & Pick<ConversationBirthdayMessageContent, 'unused'>;
export type ConversationNicknameChangeMessageContentFragment = {
  readonly __typename?: 'ConversationNicknameChangeMessageContent';
} & Pick<ConversationNicknameChangeMessageContent, 'oldNick' | 'newNick'>;
export type ConversationNestedMessageFragment = {
  readonly __typename?: 'ConversationNestedMessage';
} & Pick<ConversationNestedMessage, 'timestamp'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & MessengerBasicUserFragment;
  readonly content: ({
    readonly __typename?: 'ConversationTextMessageContent';
  } & ConversationNestedMessageContent_ConversationTextMessageContent_Fragment) | ({
    readonly __typename?: 'ConversationImageMessageContent';
  } & ConversationNestedMessageContent_ConversationImageMessageContent_Fragment);
};
type ConversationNestedMessageContent_ConversationTextMessageContent_Fragment = {
  readonly __typename?: 'ConversationTextMessageContent';
} & Pick<ConversationTextMessageContent, 'starred' | 'formattedText'>;
type ConversationNestedMessageContent_ConversationImageMessageContent_Fragment = {
  readonly __typename?: 'ConversationImageMessageContent';
} & Pick<ConversationImageMessageContent, 'starred' | 'imageAccepted' | 'sensitiveContentClassification'> & {
  readonly image?: Maybe<{
    readonly __typename?: 'UserSentImage';
  } & Pick<UserSentImage, 'url'>>;
};
export type ConversationNestedMessageContentFragment = ConversationNestedMessageContent_ConversationTextMessageContent_Fragment | ConversationNestedMessageContent_ConversationImageMessageContent_Fragment;
export type ConversationKnuddelTransferMessageContentFragment = {
  readonly __typename?: 'ConversationKnuddelTransferMessageContent';
} & Pick<ConversationKnuddelTransferMessageContent, 'knuddelAmount'>;
export type ConversationMentorAchievedMessageContentFragment = {
  readonly __typename?: 'ConversationMentorAchievedMessageContent';
} & Pick<ConversationMentorAchievedMessageContent, 'unused'>;
export type ConversationPrivateSystemMessageContentFragment = {
  readonly __typename?: 'ConversationPrivateSystemMessageContent';
} & Pick<ConversationPrivateSystemMessageContent, 'icon' | 'formattedText' | 'collapse'>;
export type FullConversationWithoutMessagesFragment = {
  readonly __typename?: 'MessengerConversation';
} & Pick<MessengerConversation, 'id' | 'visibility'> & {
  readonly otherParticipants: ReadonlyArray<{
    readonly __typename?: 'User';
  } & MessengerOverviewUserFragment>;
  readonly readState: {
    readonly __typename?: 'MessengerConversationReadState';
  } & Pick<MessengerConversationReadState, 'markedAsUnread' | 'unreadMessageCount'> & {
    readonly lastReadConversationMessage?: Maybe<{
      readonly __typename?: 'ConversationMessage';
    } & Pick<ConversationMessage, 'id'>>;
  };
  readonly latestConversationMessage?: Maybe<{
    readonly __typename?: 'ConversationMessage';
  } & ConversationMessageFragment>;
};
export type MessengerBasicUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'isOnline' | 'canSendImages' | 'menteeStatus'>;
export type MessengerOverviewUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'age' | 'albumPhotosUrl' | 'canReceiveMessages' | 'city' | 'distance' | 'gender' | 'id' | 'isOnline' | 'currentOnlineChannelName' | 'latestOnlineChannelName' | 'lastOnlineTime' | 'nick' | 'readMe' | 'relationshipStatus' | 'sexualOrientation' | 'onlineMinutes' | 'isAppBot' | 'isLockedByAutomaticComplaint' | 'automaticComplaintCommand' | 'isReportable' | 'interest' | 'latestClient' | 'authenticityClassification' | 'ignoreState'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
  readonly profileTags: ReadonlyArray<{
    readonly __typename?: 'ProfileTag';
  } & ProfileTagFragment>;
} & MessengerBasicUserFragment;
export type MessengerFullUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'isIgnoring' | 'isAllowedByContactFilter'> & MessengerOverviewUserFragment;
export type ArchiveConversationMutationVariables = {
  id: Scalars['ID'];
};
export type ArchiveConversationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly archiveConversation: {
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'> & {
      readonly conversation?: Maybe<{
        readonly __typename?: 'MessengerConversation';
      } & Pick<MessengerConversation, 'id' | 'visibility'>>;
    };
  };
};
export type DeleteConversationMutationVariables = {
  id: Scalars['ID'];
};
export type DeleteConversationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly deleteConversation: {
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'> & {
      readonly conversation?: Maybe<{
        readonly __typename?: 'MessengerConversation';
      } & Pick<MessengerConversation, 'id' | 'visibility'>>;
    };
  };
};
export type RestoreConversationMutationVariables = {
  id: Scalars['ID'];
};
export type RestoreConversationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly restoreConversation: {
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'> & {
      readonly conversation?: Maybe<{
        readonly __typename?: 'MessengerConversation';
      } & Pick<MessengerConversation, 'id' | 'visibility'>>;
    };
  };
};
export type MessengerSendMessageMutationVariables = {
  id: Scalars['ID'];
  text: Scalars['String'];
  messageCorrelationId: Scalars['ID'];
};
export type MessengerSendMessageMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly sendMessage: {
      readonly __typename?: 'MessengerSendMessageResponse';
    } & {
      readonly error?: Maybe<{
        readonly __typename?: 'MessengerSendMessageError';
      } & Pick<MessengerSendMessageError, 'type' | 'filterReason'>>;
    };
  };
};
export type ForwardMessageMutationVariables = {
  messageId: Scalars['ID'];
  recipients: ReadonlyArray<Scalars['ID']>;
  messageCorrelationId: Scalars['ID'];
};
export type ForwardMessageMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly forwardMessage: {
      readonly __typename?: 'MessengerForwardMessageResponse';
    } & Pick<MessengerForwardMessageResponse, 'recipientCount'> & {
      readonly error?: Maybe<{
        readonly __typename?: 'MessengerSendMessageError';
      } & Pick<MessengerSendMessageError, 'filterReason' | 'type'>>;
      readonly results: ReadonlyArray<{
        readonly __typename?: 'MessengerForwardMessageResult';
      } & {
        readonly messageError?: Maybe<{
          readonly __typename?: 'MessengerSendMessageError';
        } & Pick<MessengerSendMessageError, 'type' | 'filterReason'>>;
      }>;
    };
  };
};
export type StarMessengerMessageMutationVariables = {
  id: Scalars['ID'];
};
export type StarMessengerMessageMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly starMessage: {
      readonly __typename?: 'MessengerStarMessageResponse';
    } & Pick<MessengerStarMessageResponse, 'error'>;
  };
};
export type UnStarMessengerMessageMutationVariables = {
  id: Scalars['ID'];
};
export type UnStarMessengerMessageMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly unstarMessage: {
      readonly __typename?: 'MessengerStarMessageResponse';
    } & Pick<MessengerStarMessageResponse, 'error'>;
  };
};
export type SendTypingMutationVariables = {
  id: Scalars['ID'];
};
export type SendTypingMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly notifyTyping: {
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'>;
  };
};
export type SendStopTypingMutationVariables = {
  id: Scalars['ID'];
};
export type SendStopTypingMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly notifyStopTyping: {
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'>;
  };
};
export type ConversationWithParticipantsQueryVariables = {
  ids: ReadonlyArray<Scalars['ID']>;
};
export type ConversationWithParticipantsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversationWithParticipants?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & Pick<MessengerConversation, 'id'>>;
  };
};
export type QuoteMessageMutationVariables = {
  messageId: Scalars['ID'];
  text: Scalars['String'];
  messageCorrelationId: Scalars['ID'];
};
export type QuoteMessageMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly quoteMessage: {
      readonly __typename?: 'MessengerSendMessageResponse';
    } & {
      readonly error?: Maybe<{
        readonly __typename?: 'MessengerSendMessageError';
      } & Pick<MessengerSendMessageError, 'filterReason' | 'type'>>;
    };
  };
};
export type CreateImageUploadUrlMutationVariables = {
  conversationId: Scalars['ID'];
  expirationTimeInSeconds?: Maybe<Scalars['Int']>;
};
export type CreateImageUploadUrlMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly createImageUploadUrl: {
      readonly __typename?: 'CreateUploadUrlResponse';
    } & Pick<CreateUploadUrlResponse, 'url' | 'error'>;
  };
};
export type ShowSensitiveContentMutationVariables = {
  messageId: Scalars['ID'];
};
export type ShowSensitiveContentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly viewedSensitiveContent: {
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>;
  };
};
export type GetPhotoUrlQueryVariables = {
  photoId: Scalars['ID'];
};
export type GetPhotoUrlQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly photoUrl: ({
      readonly __typename: 'PhotoUrl';
    } & Pick<PhotoUrl, 'url'>) | ({
      readonly __typename: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type GetMessengerConversationVisibilityQueryVariables = {
  id: Scalars['ID'];
};
export type GetMessengerConversationVisibilityQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversation?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & Pick<MessengerConversation, 'id' | 'visibility'>>;
  };
};
export type InitiateMessageHistoryExportMutationVariables = {
  id: Scalars['ID'];
};
export type InitiateMessageHistoryExportMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly initiateMessageHistoryExport: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'ExportInitiatedRecentlyError';
    } & Pick<ExportInitiatedRecentlyError, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ConversationStarterSuggestionsQueryVariables = {
  conversationId: Scalars['ID'];
};
export type ConversationStarterSuggestionsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversationStarterSuggestions: ReadonlyArray<({
      readonly __typename?: 'ConversationStarterSuggestionText';
    } & ConversationStarterSuggestionTextFragment) | ({
      readonly __typename?: 'ConversationStarterSuggestionSmiley';
    } & ConversationStarterSuggestionSmileyFragment)>;
  };
};
export type ConversationStarterSuggestionTextFragment = {
  readonly __typename?: 'ConversationStarterSuggestionText';
} & Pick<ConversationStarterSuggestionText, 'formattedText' | 'text'>;
export type ConversationStarterSuggestionSmileyFragment = {
  readonly __typename?: 'ConversationStarterSuggestionSmiley';
} & {
  readonly smiley: {
    readonly __typename?: 'SmileyDetails';
  } & SmileyFragment;
};
export type SendConversationStarterSmileyMutationVariables = {
  conversationId: Scalars['ID'];
  smileyId: Scalars['ID'];
  messageCorrelationId: Scalars['ID'];
};
export type SendConversationStarterSmileyMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly sendConversationStarterSmiley: {
      readonly __typename?: 'MessengerSendMessageResponse';
    } & {
      readonly error?: Maybe<{
        readonly __typename?: 'MessengerSendMessageError';
      } & Pick<MessengerSendMessageError, 'filterReason' | 'type'>>;
    };
  };
};
export type FriendRecommendationSubscriptionSubscriptionVariables = {};
export type FriendRecommendationSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly friendRecommendationInConversationEvent: {
    readonly __typename?: 'FriendRecommendationInConversationEvent';
  } & Pick<FriendRecommendationInConversationEvent, 'conversationId'>;
};
export type NicknameChangeReactionSmileysQueryVariables = {
  conversationId: Scalars['ID'];
};
export type NicknameChangeReactionSmileysQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly nicknameChangeReactions: ReadonlyArray<({
      readonly __typename?: 'NicknameChangeReactionText';
    } & Pick<NicknameChangeReactionText, 'text' | 'formattedText'>) | ({
      readonly __typename?: 'NicknameChangeReactionSmiley';
    } & {
      readonly smiley: {
        readonly __typename?: 'SmileyDetails';
      } & Pick<SmileyDetails, 'id' | 'image' | 'textRepresentation'>;
    })>;
  };
};
export type SendNicknameChangeSmileyReactionMutationVariables = {
  conversationId: Scalars['ID'];
  smileyId: Scalars['ID'];
  messageCorrelationId: Scalars['ID'];
};
export type SendNicknameChangeSmileyReactionMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly sendNicknameChangeReactionSmiley: {
      readonly __typename?: 'MessengerSendMessageResponse';
    } & {
      readonly error?: Maybe<{
        readonly __typename?: 'MessengerSendMessageError';
      } & Pick<MessengerSendMessageError, 'filterReason' | 'type'>>;
    };
  };
};
export type ReactionSmileysQueryVariables = {};
export type ReactionSmileysQuery = {
  readonly __typename?: 'Query';
} & {
  readonly smileybox: {
    readonly __typename?: 'SmileyboxQuery';
  } & {
    readonly reactionSmileys: ReadonlyArray<{
      readonly __typename?: 'SmileyDetails';
    } & Pick<SmileyDetails, 'id' | 'image' | 'textRepresentation'>>;
  };
};
export type SendSmileyReactionMutationVariables = {
  messageId: Scalars['ID'];
  smileyId: Scalars['ID'];
  messageCorrelationId: Scalars['ID'];
};
export type SendSmileyReactionMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly sendSmileyReaction: {
      readonly __typename?: 'MessengerSendMessageResponse';
    } & {
      readonly error?: Maybe<{
        readonly __typename?: 'MessengerSendMessageError';
      } & Pick<MessengerSendMessageError, 'filterReason' | 'type'>>;
    };
  };
};
export type MessengerSubscriptionSubscriptionVariables = {
  pixelDensity: Scalars['Float'];
};
export type MessengerSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly messengerEvent: {
    readonly __typename?: 'ImageAcceptStateChanged';
  } | ({
    readonly __typename?: 'MessageHistoryExportReadyEvent';
  } & MessageHistoryExportReadyFragment) | {
    readonly __typename?: 'MessengerConversationArchiveStateChanged';
  } | ({
    readonly __typename?: 'MessengerConversationReadStateChanged';
  } & MessengerConversationReadStateChangedFragment) | ({
    readonly __typename?: 'MessengerConversationVisibilityChanged';
  } & MessengerConversationVisibilityChangedFragment) | ({
    readonly __typename?: 'MessengerMessageChanged';
  } & MessengerMessageChangedFragment) | ({
    readonly __typename?: 'MessengerMessageReceived';
  } & MessengerMessageReceivedFragment) | ({
    readonly __typename?: 'MessengerMessageStarredStateChanged';
  } & MessengerMessageStarredStateChangedFragment) | ({
    readonly __typename?: 'MessengerSettingsChanged';
  } & MessengerSettingsChangedFragment) | ({
    readonly __typename?: 'MessengerUserTypingStarted';
  } & MessengerUserTypingStartedFragment) | ({
    readonly __typename?: 'MessengerUserTypingStopped';
  } & MessengerUserTypingStoppedFragment);
};
export type MessengerSettingsChangedFragment = {
  readonly __typename?: 'MessengerSettingsChanged';
} & {
  readonly settings: {
    readonly __typename?: 'MessengerSettings';
  } & Pick<MessengerSettings, 'conversationListFilterType'>;
};
export type MessengerConversationVisibilityChangedFragment = {
  readonly __typename?: 'MessengerConversationVisibilityChanged';
} & {
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id' | 'visibility'>;
};
export type MessengerMessageReceivedFragment = {
  readonly __typename?: 'MessengerMessageReceived';
} & Pick<MessengerMessageReceived, 'messageCorrelationId'> & {
  readonly conversationMessage: {
    readonly __typename?: 'ConversationMessage';
  } & ReceivedConversationMessageFragment;
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id' | 'visibility'> & {
    readonly latestConversationMessage?: Maybe<{
      readonly __typename?: 'ConversationMessage';
    } & ReceivedConversationMessageFragment>;
    readonly otherParticipants: ReadonlyArray<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'nick'>>;
  };
};
export type ReceivedConversationMessageFragment = {
  readonly __typename?: 'ConversationMessage';
} & {
  readonly sender: {
    readonly __typename?: 'User';
  } & Pick<User, 'currentOnlineChannelName'> & MessengerBasicUserFragment;
} & ConversationMessageFragment;
export type MessengerMessageStarredStateChangedFragment = {
  readonly __typename?: 'MessengerMessageStarredStateChanged';
} & {
  readonly starredMessage: {
    readonly __typename?: 'StarredConversationMessage';
  } & StarredConversationMessageFragment;
};
export type MessengerUserTypingStartedFragment = {
  readonly __typename?: 'MessengerUserTypingStarted';
} & Pick<MessengerUserTypingStarted, 'willReceiveStopEvent'> & {
  readonly user: {
    readonly __typename?: 'User';
  } & Pick<User, 'id'>;
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id'>;
};
export type MessengerUserTypingStoppedFragment = {
  readonly __typename?: 'MessengerUserTypingStopped';
} & {
  readonly user: {
    readonly __typename?: 'User';
  } & Pick<User, 'id'>;
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id'>;
};
export type MessengerConversationReadStateChangedFragment = {
  readonly __typename?: 'MessengerConversationReadStateChanged';
} & {
  readonly conversation: {
    readonly __typename?: 'MessengerConversation';
  } & Pick<MessengerConversation, 'id'> & {
    readonly readState: {
      readonly __typename?: 'MessengerConversationReadState';
    } & Pick<MessengerConversationReadState, 'markedAsUnread' | 'unreadMessageCount'> & {
      readonly lastReadConversationMessage?: Maybe<{
        readonly __typename?: 'ConversationMessage';
      } & Pick<ConversationMessage, 'id'>>;
    };
  };
};
export type MessengerMessageChangedFragment = {
  readonly __typename?: 'MessengerMessageChanged';
} & Pick<MessengerMessageChanged, 'conversationId'> & {
  readonly conversationMessage: {
    readonly __typename?: 'ConversationMessage';
  } & Pick<ConversationMessage, 'id'> & {
    readonly content: {
      readonly __typename?: 'ConversationTextMessageContent';
    } | {
      readonly __typename?: 'ConversationForwardedMessageContent';
    } | {
      readonly __typename?: 'ConversationQuotedMessageContent';
    } | {
      readonly __typename?: 'ConversationSnapMessageContent';
    } | {
      readonly __typename?: 'ConversationImageMessageContent';
    } | ({
      readonly __typename?: 'ConversationVisiblePhotoCommentMessageContent';
    } & Pick<ConversationVisiblePhotoCommentMessageContent, 'albumPhotoId' | 'commentId' | 'formattedText' | 'photoUrl'>) | ({
      readonly __typename?: 'ConversationHiddenPhotoCommentMessageContent';
    } & Pick<ConversationHiddenPhotoCommentMessageContent, 'albumPhotoId' | 'formattedText' | 'photoUrl'>) | ({
      readonly __typename?: 'ConversationDeletedPhotoCommentMessageContent';
    } & Pick<ConversationDeletedPhotoCommentMessageContent, 'unused'>) | {
      readonly __typename?: 'ConversationKnuddelTransferMessageContent';
    } | {
      readonly __typename?: 'ConversationMentorAchievedMessageContent';
    } | {
      readonly __typename?: 'ConversationPrivateSystemMessageContent';
    } | {
      readonly __typename?: 'ConversationBirthdayMessageContent';
    } | {
      readonly __typename?: 'ConversationNicknameChangeMessageContent';
    };
  };
};
export type MessageHistoryExportReadyFragment = {
  readonly __typename?: 'MessageHistoryExportReadyEvent';
} & Pick<MessageHistoryExportReadyEvent, 'conversationId' | 'downloadUrl'>;
export type CanSendImagesChangedSubscriptionVariables = {};
export type CanSendImagesChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly canSendImagesChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'canSendImages'>;
};
export type MessengerMarkConversationsAsReadMutationVariables = {
  ids: ReadonlyArray<Scalars['ID']>;
};
export type MessengerMarkConversationsAsReadMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly readConversations: ReadonlyArray<{
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'> & {
      readonly conversation?: Maybe<{
        readonly __typename?: 'MessengerConversation';
      } & Pick<MessengerConversation, 'id'> & {
        readonly readState: {
          readonly __typename?: 'MessengerConversationReadState';
        } & Pick<MessengerConversationReadState, 'markedAsUnread' | 'unreadMessageCount'> & {
          readonly lastReadConversationMessage?: Maybe<{
            readonly __typename?: 'ConversationMessage';
          } & Pick<ConversationMessage, 'id'>>;
        };
      }>;
    }>;
  };
};
export type MessengerMarkConversationAsUnreadMutationVariables = {
  id: Scalars['ID'];
};
export type MessengerMarkConversationAsUnreadMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerMutation';
  } & {
    readonly markConversationUnread: {
      readonly __typename?: 'MessengerModifyConversationResponse';
    } & Pick<MessengerModifyConversationResponse, 'error'> & {
      readonly conversation?: Maybe<{
        readonly __typename?: 'MessengerConversation';
      } & Pick<MessengerConversation, 'id'> & {
        readonly readState: {
          readonly __typename?: 'MessengerConversationReadState';
        } & Pick<MessengerConversationReadState, 'markedAsUnread' | 'unreadMessageCount'> & {
          readonly lastReadConversationMessage?: Maybe<{
            readonly __typename?: 'ConversationMessage';
          } & Pick<ConversationMessage, 'id'>>;
        };
      }>;
    };
  };
};
export type MessengerConversationReadStateQueryVariables = {
  id: Scalars['ID'];
};
export type MessengerConversationReadStateQuery = {
  readonly __typename?: 'Query';
} & {
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversation?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & Pick<MessengerConversation, 'id'> & {
      readonly readState: {
        readonly __typename?: 'MessengerConversationReadState';
      } & Pick<MessengerConversationReadState, 'markedAsUnread' | 'unreadMessageCount'> & {
        readonly lastReadConversationMessage?: Maybe<{
          readonly __typename?: 'ConversationMessage';
        } & Pick<ConversationMessage, 'id'>>;
      };
    }>;
  };
};
export type RegisterFirebaseIdMutationVariables = {
  instanceId: Scalars['String'];
};
export type RegisterFirebaseIdMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly pushnotification: {
    readonly __typename?: 'PushNotificationMutation';
  } & {
    readonly registerFirebaseInstanceId: {
      readonly __typename: 'RegisterFirebaseInstanceIdResponse';
    };
  };
};
export type RemoveFirebaseIdMutationVariables = {
  instanceId: Scalars['String'];
};
export type RemoveFirebaseIdMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly pushnotification: {
    readonly __typename?: 'PushNotificationMutation';
  } & {
    readonly removeFirebaseInstanceId: {
      readonly __typename: 'RemoveFirebaseInstanceIdResponse';
    };
  };
};
export type NotificationsSubscriptionVariables = {
  pixelDensity: Scalars['Float'];
};
export type NotificationsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly notificationReceived: {
    readonly __typename?: 'Notification';
  } & NotificationFragment;
};
export type NotificationFragment = {
  readonly __typename?: 'Notification';
} & Pick<Notification, 'key' | 'title' | 'description'> & {
  readonly icon?: Maybe<{
    readonly __typename?: 'NotificationIcon';
  } & Pick<NotificationIcon, 'url'> & {
    readonly subtitle?: Maybe<{
      readonly __typename?: 'NotificationIconSubtitle';
    } & Pick<NotificationIconSubtitle, 'text'> & {
      readonly color: {
        readonly __typename?: 'NotificationIconSubtitleColor';
      } & {
        readonly lightThemeColor: {
          readonly __typename?: 'Color';
        } & ColorFragment;
        readonly darkThemeColor: {
          readonly __typename?: 'Color';
        } & ColorFragment;
      };
    }>;
  }>;
  readonly image?: Maybe<({
    readonly __typename?: 'IconNotificationImage';
  } & Pick<IconNotificationImage, 'url'> & {
    readonly subtitle?: Maybe<{
      readonly __typename?: 'NotificationIconSubtitle';
    } & Pick<NotificationIconSubtitle, 'text'> & {
      readonly color: {
        readonly __typename?: 'NotificationIconSubtitleColor';
      } & {
        readonly lightThemeColor: {
          readonly __typename?: 'Color';
        } & ColorFragment;
        readonly darkThemeColor: {
          readonly __typename?: 'Color';
        } & ColorFragment;
      };
    }>;
  }) | ({
    readonly __typename?: 'ProfilePictureNotificationImage';
  } & Pick<ProfilePictureNotificationImage, 'isBlurred'> & {
    readonly user: {
      readonly __typename?: 'User';
    } & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
      readonly profilePictureOverlayUrls: {
        readonly __typename?: 'ProfilePictureOverlays';
      } & Pick<ProfilePictureOverlays, 'urlsSmall'>;
    };
  })>;
  readonly callToAction?: Maybe<{
    readonly __typename?: 'NotificationCallToAction';
  } & Pick<NotificationCallToAction, 'text' | 'slashCommand'>>;
};
export type StartWebPaymentMutationVariables = {
  productId: Scalars['ID'];
};
export type StartWebPaymentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentMutation';
  } & {
    readonly startWebPayment: {
      readonly __typename?: 'WebPaymentResponse';
    } & Pick<WebPaymentResponse, 'error' | 'paymentUrl'>;
  };
};
export type StartAndroidPaymentMutationVariables = {
  productId: Scalars['ID'];
};
export type StartAndroidPaymentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentMutation';
  } & {
    readonly startAndroidPayment: {
      readonly __typename?: 'StartAndroidPaymentResponse';
    } & Pick<StartAndroidPaymentResponse, 'error'> & {
      readonly productInfo?: Maybe<{
        readonly __typename?: 'AndroidProductInfo';
      } & Pick<AndroidProductInfo, 'skuIds' | 'isSubscription'>>;
    };
  };
};
export type FinishAndroidPaymentMutationVariables = {
  payload: Scalars['String'];
};
export type FinishAndroidPaymentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentMutation';
  } & {
    readonly finishAndroidPayment: {
      readonly __typename?: 'FinishAndroidPaymentResponse';
    } & Pick<FinishAndroidPaymentResponse, 'error'>;
  };
};
export type StartIosPaymentMutationVariables = {
  productId: Scalars['ID'];
};
export type StartIosPaymentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentMutation';
  } & {
    readonly startIosPayment: {
      readonly __typename?: 'StartIosPaymentResponse';
    } & Pick<StartIosPaymentResponse, 'error'> & {
      readonly productInfo?: Maybe<{
        readonly __typename?: 'IosProductInfo';
      } & Pick<IosProductInfo, 'skuIds' | 'isSubscription'>>;
    };
  };
};
export type FinishIosPaymentMutationVariables = {
  payload: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};
export type FinishIosPaymentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentMutation';
  } & {
    readonly finishIosPayment: {
      readonly __typename?: 'FinishIosPaymentResponse';
    } & Pick<FinishIosPaymentResponse, 'error'>;
  };
};
export type PaymentSubscriptionSubscriptionVariables = {};
export type PaymentSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly paymentEvent: ({
    readonly __typename?: 'PaymentFailed';
  } & Pick<PaymentFailed, 'productId'>) | ({
    readonly __typename?: 'PaymentSucceeded';
  } & Pick<PaymentSucceeded, 'productId'>);
};
export type GetUnusedSkuIdQueryVariables = {
  skuIds: ReadonlyArray<Scalars['String']>;
  receipt: Scalars['String'];
};
export type GetUnusedSkuIdQuery = {
  readonly __typename?: 'Query';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentQuery';
  } & Pick<PaymentQuery, 'unusedSkuIdIos'>;
};
export type GetNativeProductInfoQueryVariables = {
  skuId: Scalars['String'];
};
export type GetNativeProductInfoQuery = {
  readonly __typename?: 'Query';
} & {
  readonly payment: {
    readonly __typename?: 'PaymentQuery';
  } & {
    readonly nativeProductInfo: {
      readonly __typename?: 'NativeProductInfo';
    } & Pick<NativeProductInfo, 'isSubscription'>;
  };
};
export type GetUserForProfileQueryVariables = {
  userId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type GetUserForProfileQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & UserForProfileFragment>;
  };
  readonly contacts: {
    readonly __typename?: 'ContactsQuery';
  } & {
    readonly friends: ({
      readonly __typename?: 'FriendList';
    } & {
      readonly friends: ReadonlyArray<{
        readonly __typename?: 'User';
      } & ProfileFriendUserFragment>;
    }) | ({
      readonly __typename?: 'FriendsHiddenByPrivacy';
    } & Pick<FriendsHiddenByPrivacy, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
    readonly commonFriends: ({
      readonly __typename?: 'FriendList';
    } & {
      readonly friends: ReadonlyArray<{
        readonly __typename?: 'User';
      } & ProfileCommonFriendUserFragment>;
    }) | ({
      readonly __typename?: 'FriendsHiddenByPrivacy';
    } & Pick<FriendsHiddenByPrivacy, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversationWithParticipants?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & Pick<MessengerConversation, 'id'>>;
  };
};
export type GetAlbumInfoForProfileQueryVariables = {
  userId: Scalars['ID'];
};
export type GetAlbumInfoForProfileQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & {
      readonly albumPhotos: ReadonlyArray<{
        readonly __typename?: 'AlbumPhoto';
      } & Pick<AlbumPhoto, 'id' | 'thumbnailUrl'>>;
      readonly albums: ReadonlyArray<{
        readonly __typename?: 'Album';
      } & AlbumFragment>;
      readonly albumProfilePhoto?: Maybe<{
        readonly __typename?: 'AlbumPhoto';
      } & AlbumDetailPhotoFragment>;
    }>;
  };
};
export type ProfileFriendUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'isOnline' | 'currentOnlineChannelName' | 'readMe'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type ProfileCommonFriendUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type GetUserForQuickWhoisQueryVariables = {
  userId: Scalars['ID'];
};
export type GetUserForQuickWhoisQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & UserForQuickWhoisFragment>;
    readonly currentUser: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'>;
  };
  readonly messenger: {
    readonly __typename?: 'MessengerQuery';
  } & {
    readonly conversationWithParticipants?: Maybe<{
      readonly __typename?: 'MessengerConversation';
    } & Pick<MessengerConversation, 'id'>>;
  };
};
export type UserForQuickWhoisFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'age' | 'gender' | 'ignoreState' | 'sexualOrientation' | 'relationshipStatus' | 'city' | 'distance' | 'canReceiveMessages' | 'albumPhotosUrl' | 'isOnWatchlist' | 'friendState' | 'hasSeenProfileAfterFriendshipStarted' | 'isAppBot' | 'authenticityClassification' | 'isAllowedByContactFilter'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlLargeSquare' | 'urlVeryLarge'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
  readonly profileTags: ReadonlyArray<{
    readonly __typename?: 'ProfileTag';
  } & ProfileTagFragment>;
};
export type ProfileTagFragment = {
  readonly __typename?: 'ProfileTag';
} & Pick<ProfileTag, 'displayName' | 'isMatching' | 'category' | 'slashCommand'>;
export type UserForProfileFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'age' | 'gender' | 'sexualOrientation' | 'relationshipStatus' | 'city' | 'countryEnum' | 'distance' | 'ignoreState' | 'canReceiveMessages' | 'canReceivePhotoComments' | 'albumPhotosUrl' | 'readMe' | 'nameFormatted' | 'dateOfBirth' | 'children' | 'smoker' | 'hobbies' | 'music' | 'movies' | 'series' | 'books' | 'languages' | 'isOnline' | 'lastOnlineTime' | 'dateOfRegistration' | 'status' | 'supportsKnuddelsPhilosophy' | 'teams' | 'stammiMonths' | 'channelModeratorMonths' | 'myChannelModeratorMonths' | 'channelLeadElectionCount' | 'adminPeriods' | 'latestOnlineChannelName' | 'myChannelName' | 'favouriteChannelName' | 'favouriteMyChannelName' | 'moderatedChannelName' | 'moderatedMyChannelNames' | 'channelLeadChannelNames' | 'hardCoreChannelNames' | 'hickeys' | 'flowers' | 'roses' | 'chatMeetups' | 'receivedHearts' | 'givenHeart' | 'mentorPoints' | 'engagementSystemLevel' | 'canSeeAdminInfo' | 'onlineMinutes' | 'isReportable' | 'isAppBot' | 'knuddelAmount' | 'friendState' | 'isOnWatchlist' | 'hasSeenProfileAfterFriendshipStarted' | 'menteeStatus' | 'authenticityClassification' | 'hasNicknameHistoryVisible' | 'isAllowedByContactFilter'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlLargeSquare' | 'urlVeryLarge' | 'exists'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type AlbumFragment = {
  readonly __typename?: 'Album';
} & Pick<Album, 'id' | 'title' | 'isOwner'> & {
  readonly albumPhotos: ReadonlyArray<{
    readonly __typename?: 'AlbumPhoto';
  } & AlbumDetailPhotoFragment>;
};
export type AlbumDetailPhotoFragment = {
  readonly __typename?: 'AlbumPhoto';
} & Pick<AlbumPhoto, 'id' | 'thumbnailUrl' | 'photoUrl' | 'administrationUrl' | 'isOwner'> & {
  readonly description?: Maybe<{
    readonly __typename?: 'AlbumPhotoDescription';
  } & Pick<AlbumPhotoDescription, 'formattedText' | 'rawText'>>;
};
export type NotifyProfileVisitedMutationVariables = {
  userId: Scalars['ID'];
};
export type NotifyProfileVisitedMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & Pick<UserMutation, 'notifyProfileVisited'>;
};
export type NotifyProfilePicturedViewedMutationVariables = {
  userId: Scalars['ID'];
};
export type NotifyProfilePicturedViewedMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & Pick<UserMutation, 'notifyProfilePictureViewed'>;
};
export type NotifyAlbumPhotosViewedMutationVariables = {
  userId: Scalars['ID'];
};
export type NotifyAlbumPhotosViewedMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & Pick<UserMutation, 'notifyAlbumPhotoViewed'>;
};
export type EditProfileEntriesQueryVariables = {};
export type EditProfileEntriesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly profileEditEntries: ReadonlyArray<({
      readonly __typename?: 'ProfileEditEntryChildren';
    } & EditProfileEntry_ProfileEditEntryChildren_Fragment) | ({
      readonly __typename?: 'ProfileEditEntryCountry';
    } & EditProfileEntry_ProfileEditEntryCountry_Fragment) | ({
      readonly __typename?: 'ProfileEditEntryDate';
    } & EditProfileEntry_ProfileEditEntryDate_Fragment) | ({
      readonly __typename?: 'ProfileEditEntryFreeText';
    } & EditProfileEntry_ProfileEditEntryFreeText_Fragment) | ({
      readonly __typename?: 'ProfileEditEntryRelationshipStatus';
    } & EditProfileEntry_ProfileEditEntryRelationshipStatus_Fragment) | ({
      readonly __typename?: 'ProfileEditEntrySexualOrientation';
    } & EditProfileEntry_ProfileEditEntrySexualOrientation_Fragment) | ({
      readonly __typename?: 'ProfileEditEntrySmoker';
    } & EditProfileEntry_ProfileEditEntrySmoker_Fragment) | ({
      readonly __typename?: 'ProfileEditEntryStringList';
    } & EditProfileEntry_ProfileEditEntryStringList_Fragment)>;
  };
  readonly payment: {
    readonly __typename?: 'PaymentQuery';
  } & Pick<PaymentQuery, 'nickSwitchKnuddelCost'>;
};
type EditProfileEntry_ProfileEditEntryChildren_Fragment = {
  readonly __typename?: 'ProfileEditEntryChildren';
} & Pick<ProfileEditEntryChildren, 'childrenValue' | 'field'>;
type EditProfileEntry_ProfileEditEntryCountry_Fragment = {
  readonly __typename?: 'ProfileEditEntryCountry';
} & Pick<ProfileEditEntryCountry, 'countryValue' | 'field'>;
type EditProfileEntry_ProfileEditEntryDate_Fragment = {
  readonly __typename?: 'ProfileEditEntryDate';
} & Pick<ProfileEditEntryDate, 'dateValue' | 'dateMinValue' | 'dateMaxValue' | 'field'>;
type EditProfileEntry_ProfileEditEntryFreeText_Fragment = {
  readonly __typename?: 'ProfileEditEntryFreeText';
} & Pick<ProfileEditEntryFreeText, 'freeTextValue' | 'freeTextPattern' | 'freeTextMinLength' | 'freeTextMaxLength' | 'freeTextMultiLineAllowed' | 'field'>;
type EditProfileEntry_ProfileEditEntryRelationshipStatus_Fragment = {
  readonly __typename?: 'ProfileEditEntryRelationshipStatus';
} & Pick<ProfileEditEntryRelationshipStatus, 'relationshipStatusValue' | 'field'>;
type EditProfileEntry_ProfileEditEntrySexualOrientation_Fragment = {
  readonly __typename?: 'ProfileEditEntrySexualOrientation';
} & Pick<ProfileEditEntrySexualOrientation, 'sexualOrientationValue' | 'field'>;
type EditProfileEntry_ProfileEditEntrySmoker_Fragment = {
  readonly __typename?: 'ProfileEditEntrySmoker';
} & Pick<ProfileEditEntrySmoker, 'smokerValue' | 'field'>;
type EditProfileEntry_ProfileEditEntryStringList_Fragment = {
  readonly __typename?: 'ProfileEditEntryStringList';
} & Pick<ProfileEditEntryStringList, 'stringListValue' | 'field'>;
export type EditProfileEntryFragment = EditProfileEntry_ProfileEditEntryChildren_Fragment | EditProfileEntry_ProfileEditEntryCountry_Fragment | EditProfileEntry_ProfileEditEntryDate_Fragment | EditProfileEntry_ProfileEditEntryFreeText_Fragment | EditProfileEntry_ProfileEditEntryRelationshipStatus_Fragment | EditProfileEntry_ProfileEditEntrySexualOrientation_Fragment | EditProfileEntry_ProfileEditEntrySmoker_Fragment | EditProfileEntry_ProfileEditEntryStringList_Fragment;
export type EditFreeTextEntryMutationVariables = {
  field: ProfileEditField;
  value: Scalars['String'];
};
export type EditFreeTextEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntryFreeText: {
      readonly __typename?: 'EditProfileEntryFreeTextResult';
    } & {
      readonly errorResult?: Maybe<{
        readonly __typename?: 'EditProfileEntryFreeTextError';
      } & Pick<EditProfileEntryFreeTextError, 'details' | 'error'>>;
    };
  };
};
export type EditStringListEntryMutationVariables = {
  field: ProfileEditField;
  value: ReadonlyArray<Scalars['String']>;
};
export type EditStringListEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntryStringList: {
      readonly __typename?: 'EditProfileEntryStringListResult';
    } & Pick<EditProfileEntryStringListResult, 'error'>;
  };
};
export type EditDateEntryMutationVariables = {
  field: ProfileEditField;
  value: Scalars['UtcTimestamp'];
};
export type EditDateEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntryDate: {
      readonly __typename?: 'EditProfileEntryDateResult';
    } & Pick<EditProfileEntryDateResult, 'error'>;
  };
};
export type EditChildrenEntryMutationVariables = {
  field: ProfileEditField;
  value?: Maybe<Children>;
};
export type EditChildrenEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntryChildren: {
      readonly __typename?: 'EditProfileEntryMultiChoiceResult';
    } & Pick<EditProfileEntryMultiChoiceResult, 'error'>;
  };
};
export type EditSmokerEntryMutationVariables = {
  field: ProfileEditField;
  value?: Maybe<Smoker>;
};
export type EditSmokerEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntrySmoker: {
      readonly __typename?: 'EditProfileEntryMultiChoiceResult';
    } & Pick<EditProfileEntryMultiChoiceResult, 'error'>;
  };
};
export type EditCountryEntryMutationVariables = {
  field: ProfileEditField;
  value: Country;
};
export type EditCountryEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntryCountry: {
      readonly __typename?: 'EditProfileEntryMultiChoiceResult';
    } & Pick<EditProfileEntryMultiChoiceResult, 'error'>;
  };
};
export type EditRelationshipStatusEntryMutationVariables = {
  field: ProfileEditField;
  value?: Maybe<RelationshipStatus>;
};
export type EditRelationshipStatusEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntryRelationshipStatus: {
      readonly __typename?: 'EditProfileEntryMultiChoiceResult';
    } & Pick<EditProfileEntryMultiChoiceResult, 'error'>;
  };
};
export type EditSexualOrientationEntryMutationVariables = {
  field: ProfileEditField;
  value?: Maybe<SexualOrientation>;
};
export type EditSexualOrientationEntryMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly editProfileEntrySexualOrientation: {
      readonly __typename?: 'EditProfileEntryMultiChoiceResult';
    } & Pick<EditProfileEntryMultiChoiceResult, 'error'>;
  };
};
export type EditAlbumTitleMutationVariables = {
  id: Scalars['ID'];
  title: Scalars['String'];
};
export type EditAlbumTitleMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeAlbumTitle: ({
      readonly __typename?: 'ChangeAlbumTitleSuccess';
    } & Pick<ChangeAlbumTitleSuccess, 'title'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'NotFoundError';
    } & Pick<NotFoundError, 'unused'>);
  };
};
export type CreateNewAlbumMutationVariables = {
  position: Scalars['Int'];
  title: Scalars['String'];
};
export type CreateNewAlbumMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly createAlbum: ({
      readonly __typename?: 'Album';
    } & AlbumFragment) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'FakeSuspectError';
    } & Pick<FakeSuspectError, 'unused'>) | ({
      readonly __typename?: 'AlbumLimitReachedError';
    } & Pick<AlbumLimitReachedError, 'unused'>);
  };
};
export type DeletePhotoMutationVariables = {
  photoId: Scalars['ID'];
};
export type DeletePhotoMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly deleteAlbumPhoto: ({
      readonly __typename?: 'DeleteAlbumPhotoSuccess';
    } & Pick<DeleteAlbumPhotoSuccess, 'albumPhotoId'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type CreateAlbumPhotoUploadUrlMutationVariables = {
  albumId: Scalars['ID'];
};
export type CreateAlbumPhotoUploadUrlMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly createAlbumPhotoUploadUrl: ({
      readonly __typename?: 'CreateAlbumPhotoUploadUrlSuccess';
    } & Pick<CreateAlbumPhotoUploadUrlSuccess, 'albumPhotoUploadUrl'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'NotFoundError';
    } & Pick<NotFoundError, 'unused'>) | ({
      readonly __typename?: 'FakeSuspectError';
    } & Pick<FakeSuspectError, 'unused'>) | ({
      readonly __typename?: 'PhotoUploadBlockedError';
    } & Pick<PhotoUploadBlockedError, 'blockedUntil'>);
  };
};
export type EditAlbumPhotoDescriptionMutationVariables = {
  photoId: Scalars['ID'];
  newDescription: Scalars['String'];
};
export type EditAlbumPhotoDescriptionMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeAlbumPhotoDescription: ({
      readonly __typename?: 'ChangeAlbumPhotoDescriptionSuccess';
    } & {
      readonly description?: Maybe<{
        readonly __typename?: 'AlbumPhotoDescription';
      } & Pick<AlbumPhotoDescription, 'formattedText' | 'rawText'>>;
    }) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'NotFoundError';
    } & Pick<NotFoundError, 'unused'>);
  };
};
export type DeletePhotoAlbumMutationVariables = {
  albumId: Scalars['ID'];
};
export type DeletePhotoAlbumMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly deleteAlbum: ({
      readonly __typename?: 'DeleteAlbumSuccess';
    } & Pick<DeleteAlbumSuccess, 'albumId'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChangeAlbumOrderMutationVariables = {
  newAlbumOrderIds: ReadonlyArray<Scalars['ID']>;
};
export type ChangeAlbumOrderMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeAlbumOrder: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'NotFoundError';
    } & Pick<NotFoundError, 'unused'>);
  };
};
export type UserFriendStateChangedSubscriptionVariables = {
  userId: Scalars['ID'];
};
export type UserFriendStateChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'friendState'>;
};
export type UserEmailChangedSubscriptionVariables = {
  userId: Scalars['ID'];
};
export type UserEmailChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'email'>;
};
export type MarkProfileSeenAfterFriendshipStartedMutationVariables = {
  userId: Scalars['ID'];
};
export type MarkProfileSeenAfterFriendshipStartedMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & Pick<UserMutation, 'markProfileSeenAfterFriendshipStarted'>;
};
export type MarkProfileVisitorSeenMutationVariables = {
  userId: Scalars['ID'];
};
export type MarkProfileVisitorSeenMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & Pick<UserMutation, 'markProfileVisitorSeen'>;
};
export type DeleteProfilePictureMutationVariables = {};
export type DeleteProfilePictureMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly deleteProfileImage: {
      readonly __typename?: 'DeleteProfileImageResponse';
    } & Pick<DeleteProfileImageResponse, 'error'> & {
      readonly user?: Maybe<{
        readonly __typename?: 'User';
      } & Pick<User, 'id'> & {
        readonly profilePicture: {
          readonly __typename?: 'ProfilePicture';
        } & Pick<ProfilePicture, 'urlLargeSquare' | 'urlVeryLarge' | 'exists'>;
      }>;
    };
  };
};
export type GetProfilePictureUrlsQueryVariables = {
  userId: Scalars['ID'];
};
export type GetProfilePictureUrlsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlLargeSquare' | 'urlVeryLarge' | 'exists'>;
      readonly profilePictureOverlayUrls: {
        readonly __typename?: 'ProfilePictureOverlays';
      } & ProfilePictureOverlaysFragment;
    }>;
  };
};
export type GetCurrentUserProfilePictureUrlsQueryVariables = {};
export type GetCurrentUserProfilePictureUrlsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly currentUser: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlLargeSquare' | 'urlVeryLarge' | 'exists'>;
    };
  };
};
export type GetCurrentUserProfilePictureCustomSizeUrlQueryVariables = {
  pixelDensity: Scalars['Float'];
  size: Scalars['Int'];
};
export type GetCurrentUserProfilePictureCustomSizeUrlQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly currentUser: {
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
    };
  };
};
export type AlbumPhotoCommentsQueryVariables = {
  albumPhotoId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type AlbumPhotoCommentsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly albumPhotoComments: ReadonlyArray<{
      readonly __typename?: 'AlbumPhotoComment';
    } & AlbumPhotoCommentFragment>;
  };
};
export type AlbumPhotoCommentFragment = {
  readonly __typename?: 'AlbumPhotoComment';
} & Pick<AlbumPhotoComment, 'id' | 'text' | 'timestamp'> & {
  readonly sender: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'nick'> & {
    readonly profilePicture: {
      readonly __typename?: 'ProfilePicture';
    } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
    readonly profilePictureOverlayUrls: {
      readonly __typename?: 'ProfilePictureOverlays';
    } & ProfilePictureOverlaysFragment;
  };
};
export type SendAlbumPhotoCommentMutationVariables = {
  albumPhotoId: Scalars['ID'];
  text: Scalars['String'];
  pixelDensity: Scalars['Float'];
};
export type SendAlbumPhotoCommentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly commentAlbumPhoto: ({
      readonly __typename?: 'AlbumPhotoComment';
    } & AlbumPhotoCommentFragment) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'NotFoundError';
    } & Pick<NotFoundError, 'unused'>) | ({
      readonly __typename?: 'CantCommentError';
    } & Pick<CantCommentError, 'reason'>);
  };
};
export type DeleteAlbumPhotoCommentMutationVariables = {
  albumPhotoId: Scalars['ID'];
  albumPhotoCommentId: Scalars['ID'];
};
export type DeleteAlbumPhotoCommentMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly deleteAlbumPhotoComment: ({
      readonly __typename?: 'DeleteAlbumPhotoCommentSuccess';
    } & Pick<DeleteAlbumPhotoCommentSuccess, 'albumPhotoCommentId'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type SendFriendRequestMutationVariables = {
  userId: Scalars['ID'];
};
export type SendFriendRequestMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly contacts: {
    readonly __typename?: 'ContactsMutation';
  } & {
    readonly sendFriendRequest: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>) | ({
      readonly __typename?: 'SenderFriendLimitReachedError';
    } & Pick<SenderFriendLimitReachedError, 'unused'>) | ({
      readonly __typename?: 'ReceiverFriendLimitReachedError';
    } & Pick<ReceiverFriendLimitReachedError, 'unused'>) | ({
      readonly __typename?: 'NotAllowedByReceiverError';
    } & Pick<NotAllowedByReceiverError, 'unused'>) | ({
      readonly __typename?: 'TooManyRequestsError';
    } & Pick<TooManyRequestsError, 'unused'>) | ({
      readonly __typename?: 'ConversationDepthNotReachedError';
    } & Pick<ConversationDepthNotReachedError, 'unused'>);
  };
};
export type ProfileVisitEventsSubscriptionVariables = {
  pixelDensity: Scalars['Float'];
};
export type ProfileVisitEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly profileVisitEvent: ({
    readonly __typename?: 'NewProfileVisitEvent';
  } & {
    readonly profileVisit?: Maybe<{
      readonly __typename?: 'ProfileVisit';
    } & Pick<ProfileVisit, 'isUnlocked'> & {
      readonly visitor: {
        readonly __typename?: 'User';
      } & ProfileVisitorsUserFragment;
    }>;
  }) | ({
    readonly __typename?: 'ProfileVisitChangedEvent';
  } & {
    readonly profileVisit?: Maybe<{
      readonly __typename?: 'ProfileVisit';
    } & Pick<ProfileVisit, 'isUnlocked'> & {
      readonly visitor: {
        readonly __typename?: 'User';
      } & ProfileVisitorsUserFragment;
    }>;
  }) | ({
    readonly __typename?: 'ProfileVisitorSystemEnabledChanged';
  } & Pick<ProfileVisitorSystemEnabledChanged, 'enabled'>);
};
export type ProfileVisitsQueryVariables = {
  pixelDensity: Scalars['Float'];
};
export type ProfileVisitsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & Pick<UserQuery, 'profileVisitorsEnabled'> & {
    readonly profileVisits: ReadonlyArray<{
      readonly __typename?: 'ProfileVisit';
    } & Pick<ProfileVisit, 'isUnlocked'> & {
      readonly visitor: {
        readonly __typename?: 'User';
      } & ProfileVisitorsUserFragment;
    }>;
  };
};
export type ActivateProfileVisitorsFeatureMutationVariables = {};
export type ActivateProfileVisitorsFeatureMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly activateProfileVisitorsFeature: {
      readonly __typename?: 'ActivateProfileVisitorsFeatureResponse';
    } & Pick<ActivateProfileVisitorsFeatureResponse, 'error'>;
  };
};
export type ProfileVisitorsUserFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'nick' | 'age' | 'gender' | 'city' | 'isOnline' | 'isAppBot' | 'authenticityClassification'> & {
  readonly profilePicture: {
    readonly __typename?: 'ProfilePicture';
  } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
  readonly profilePictureOverlayUrls: {
    readonly __typename?: 'ProfilePictureOverlays';
  } & ProfilePictureOverlaysFragment;
};
export type PromotionEventsSubscriptionVariables = {};
export type PromotionEventsSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly promotionEvent: ({
    readonly __typename?: 'PromotionStartedEvent';
  } & {
    readonly newPromotion: {
      readonly __typename?: 'HappyHourPromotion';
    } & HappyHourFragment;
  }) | ({
    readonly __typename?: 'PromotionEndedEvent';
  } & {
    readonly promotion: {
      readonly __typename?: 'HappyHourPromotion';
    } & HappyHourFragment;
  });
};
export type ActivePromotionsQueryVariables = {};
export type ActivePromotionsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly promotions: {
    readonly __typename?: 'PromotionQuery';
  } & {
    readonly activePromotions: ReadonlyArray<{
      readonly __typename?: 'HappyHourPromotion';
    } & HappyHourFragment>;
  };
};
export type HappyHourFragment = {
  readonly __typename?: 'HappyHourPromotion';
} & Pick<HappyHourPromotion, 'endTimestamp' | 'maxBonusPercentage'> & {
  readonly color: {
    readonly __typename?: 'Color';
  } & Pick<Color, 'alpha' | 'red' | 'blue' | 'green'>;
};
export type OpenAccountDeletionAppMutationVariables = {};
export type OpenAccountDeletionAppMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly openAccountDeletionApp: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'DeletionAlreadyRunningError';
    } & Pick<DeletionAlreadyRunningError, 'unused'>) | ({
      readonly __typename?: 'SpecialStatusOrRightsError';
    } & Pick<SpecialStatusOrRightsError, 'unused'>) | ({
      readonly __typename?: 'ActivePremiumSubscriptionError';
    } & Pick<ActivePremiumSubscriptionError, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type GetFotomeetEnabledQueryVariables = {};
export type GetFotomeetEnabledQuery = {
  readonly __typename?: 'Query';
} & {
  readonly fotomeet: {
    readonly __typename?: 'FotomeetQuery';
  } & Pick<FotomeetQuery, 'enabled'>;
};
export type SetFotomeetEnabledMutationVariables = {
  enabled: Scalars['Boolean'];
};
export type SetFotomeetEnabledMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly fotomeet: {
    readonly __typename?: 'FotomeetMutation';
  } & {
    readonly setFotomeetEnabled: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type PrivacySettingsQueryQueryVariables = {};
export type PrivacySettingsQueryQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly privacySettings: {
      readonly __typename?: 'PrivacySettings';
    } & Pick<PrivacySettings, 'friendsPrivacyType' | 'knuddelPrivacyType' | 'adultChannelsVisibleInHardcoreChannels'> & {
      readonly nicknameChangeOptions: {
        readonly __typename?: 'NicknameChangeOptions';
      } & NicknameChangeOptionsFragment;
      readonly nicknameHistory: {
        readonly __typename?: 'NicknameHistorySettings';
      } & NicknameHistorySettingsFragment;
    };
  };
};
export type PrivacySettingsSubscriptionSubscriptionVariables = {};
export type PrivacySettingsSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly privacySettingsChanged: {
    readonly __typename?: 'PrivacySettings';
  } & Pick<PrivacySettings, 'friendsPrivacyType' | 'knuddelPrivacyType' | 'adultChannelsVisibleInHardcoreChannels'> & {
    readonly nicknameChangeOptions: {
      readonly __typename?: 'NicknameChangeOptions';
    } & NicknameChangeOptionsFragment;
    readonly nicknameHistory: {
      readonly __typename?: 'NicknameHistorySettings';
    } & NicknameHistorySettingsFragment;
  };
};
export type ChangeFriendsPrivacySettingsMutationVariables = {
  privacyType: PrivacyType;
};
export type ChangeFriendsPrivacySettingsMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeFriendsPrivacyType: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChangeKnuddelPrivacySettingsMutationVariables = {
  privacyType: PrivacyType;
};
export type ChangeKnuddelPrivacySettingsMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeKnuddelPrivacyType: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables = {
  visible: Scalars['Boolean'];
};
export type ChangeAdultChannelsVisibleInHardcoreChannelsMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeAdultChannelsVisibleInHardcoreChannels: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChangeNicknameHistoryCheckedMutationVariables = {
  checked: Scalars['Boolean'];
};
export type ChangeNicknameHistoryCheckedMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeNicknameHistoryChecked: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type ChangeNicknameChangeOptionsMutationVariables = {
  change: NicknameChangeOptionsInput;
};
export type ChangeNicknameChangeOptionsMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly changeNicknameChangeOptions: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type NicknameChangeOptionsFragment = {
  readonly __typename?: 'NicknameChangeOptions';
} & {
  readonly showOnTopOfNickList: {
    readonly __typename?: 'NicknameChangeSetting';
  } & NicknameChangeOptionFragment;
  readonly showNickListIcon: {
    readonly __typename?: 'NicknameChangeSetting';
  } & NicknameChangeOptionFragment;
  readonly specialGreeting: {
    readonly __typename?: 'NicknameChangeSetting';
  } & NicknameChangeOptionFragment;
  readonly showNickListEffect: {
    readonly __typename?: 'NicknameChangeSetting';
  } & NicknameChangeOptionFragment;
};
export type NicknameChangeOptionFragment = {
  readonly __typename?: 'NicknameChangeSetting';
} & Pick<NicknameChangeSetting, 'checked' | 'toggleable' | 'availableUntil'>;
export type NicknameHistorySettingsFragment = {
  readonly __typename?: 'NicknameHistorySettings';
} & Pick<NicknameHistorySettings, 'checked' | 'toggleable' | 'disabledUntil'>;
export type GetAdultChannelsVisibleQueryVariables = {};
export type GetAdultChannelsVisibleQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & Pick<UserQuery, 'adultChannelsVisible'>;
};
export type GetNsfwSearchEntriesVisibleQueryVariables = {};
export type GetNsfwSearchEntriesVisibleQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & Pick<UserQuery, 'nsfwSearchEntriesVisible'>;
};
export type GetAdultChannelsShownInLastVisitedQueryVariables = {};
export type GetAdultChannelsShownInLastVisitedQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & Pick<UserQuery, 'adultChannelsShownInLastVisited'>;
};
export type UpdateAdultChannelsVisibleMutationVariables = {
  visible: Scalars['Boolean'];
};
export type UpdateAdultChannelsVisibleMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly updateAdultChannelsVisible: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type UpdateNsfwSearchEntriesVisibleMutationVariables = {
  visible: Scalars['Boolean'];
};
export type UpdateNsfwSearchEntriesVisibleMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly updateNsfwSearchEntriesVisible: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type UpdateAdultChannelsShownInLastVisitedMutationVariables = {
  shown: Scalars['Boolean'];
};
export type UpdateAdultChannelsShownInLastVisitedMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly updateAdultChannelsShownInLastVisited: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type AdultChannelsVisibleSubscriptionSubscriptionVariables = {};
export type AdultChannelsVisibleSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly adultChannelsVisibleChanged?: Maybe<{
    readonly __typename?: 'AdultChannelsVisibleChanged';
  } & Pick<AdultChannelsVisibleChanged, 'adultChannelsVisible'>>;
};
export type AllClientSettingsFragment = {
  readonly __typename?: 'ClientSettings';
} & Pick<ClientSettings, 'conversationListFilterType' | 'initialJoinBehavior' | 'enabledSoundEvents' | 'mentorBarExtended' | 'privateMessageReplyBehavior' | 'macroBoxEnabled' | 'navIconSlot'> & {
  readonly contactListTabs: {
    readonly __typename?: 'ContactListTabs';
  } & {
    readonly tabs: ReadonlyArray<{
      readonly __typename?: 'ContactListTabsEntry';
    } & Pick<ContactListTabsEntry, 'tab' | 'active' | 'toggleable'>>;
  };
  readonly macroBoxQuickAccessEntries: ReadonlyArray<{
    readonly __typename?: 'MacroBoxSettingsEntry';
  } & MacroBoxSettingsEntryFragment>;
  readonly macroBoxInteractionEntries: ReadonlyArray<{
    readonly __typename?: 'MacroBoxSettingsEntry';
  } & MacroBoxSettingsEntryFragment>;
  readonly channelListCategories: {
    readonly __typename?: 'ChannelListCategories';
  } & {
    readonly categories: ReadonlyArray<{
      readonly __typename?: 'ChannelListCategoriesEntry';
    } & Pick<ChannelListCategoriesEntry, 'category' | 'active'>>;
  };
};
export type MacroBoxSettingsEntryFragment = {
  readonly __typename?: 'MacroBoxSettingsEntry';
} & Pick<MacroBoxSettingsEntry, 'id' | 'active'>;
export type GetClientSettingsQueryVariables = {};
export type GetClientSettingsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly clientSettings: {
    readonly __typename?: 'ClientSettingsQuery';
  } & {
    readonly settings: {
      readonly __typename?: 'ClientSettings';
    } & AllClientSettingsFragment;
  };
};
export type UpdateClientSettingsMutationVariables = {
  settings: ClientUpdateSettings;
};
export type UpdateClientSettingsMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly clientSettings: {
    readonly __typename?: 'ClientSettingsMutation';
  } & {
    readonly updateSettings: {
      readonly __typename?: 'ClientUpdateSettingsResponse';
    } & Pick<ClientUpdateSettingsResponse, 'error'> & {
      readonly settings?: Maybe<{
        readonly __typename?: 'ClientSettings';
      } & AllClientSettingsFragment>;
    };
  };
};
export type ClientSettingsSubscriptionSubscriptionVariables = {};
export type ClientSettingsSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly clientSettingsEvent: {
    readonly __typename?: 'ClientSettingsChanged';
  } & ClientSettingsChangedFragment;
};
export type ClientSettingsChangedFragment = {
  readonly __typename?: 'ClientSettingsChanged';
} & {
  readonly settings: {
    readonly __typename?: 'ClientSettings';
  } & AllClientSettingsFragment;
};
export type GetNotificationSettingsQueryVariables = {};
export type GetNotificationSettingsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsQuery';
  } & {
    readonly friendStatus: ({
      readonly __typename?: 'NotificationDisabled';
    } & NotificationStatus_NotificationDisabled_Fragment) | ({
      readonly __typename?: 'NotificationEnabled';
    } & NotificationStatus_NotificationEnabled_Fragment);
    readonly watchlistStatus: ({
      readonly __typename?: 'NotificationDisabled';
    } & NotificationStatus_NotificationDisabled_Fragment) | ({
      readonly __typename?: 'NotificationEnabled';
    } & NotificationStatus_NotificationEnabled_Fragment);
    readonly sensitiveContentSettings: {
      readonly __typename?: 'SensitiveContentSettings';
    } & Pick<SensitiveContentSettings, 'warnOnSensitiveContent'>;
  };
};
export type GetVisitorNotificationSettingsQueryVariables = {};
export type GetVisitorNotificationSettingsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsQuery';
  } & {
    readonly profileVisitorSettings: {
      readonly __typename?: 'ProfileVisitorNotificationSettings';
    } & Pick<ProfileVisitorNotificationSettings, 'newVisitorNotificationEnabled' | 'freeUnlockAvailableNotificationEnabled'>;
  };
};
type NotificationStatus_NotificationDisabled_Fragment = {
  readonly __typename?: 'NotificationDisabled';
} & Pick<NotificationDisabled, 'unused'>;
type NotificationStatus_NotificationEnabled_Fragment = {
  readonly __typename?: 'NotificationEnabled';
} & NotificationEnabledFragment;
export type NotificationStatusFragment = NotificationStatus_NotificationDisabled_Fragment | NotificationStatus_NotificationEnabled_Fragment;
export type NotificationEnabledFragment = {
  readonly __typename?: 'NotificationEnabled';
} & Pick<NotificationEnabled, 'notifyBirthday' | 'notifyCommunityStatusChange' | 'notifyProfileChange' | 'notifyProfilePictureChange' | 'notifyTeamJoin' | 'notifyNicknameChange'>;
export type NotificationSettingsSubscriptionSubscriptionVariables = {};
export type NotificationSettingsSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly notificationSettingsEvent: {
    readonly __typename?: 'NotificationSettingsChanged';
  } & NotificationSettingsChangedFragment;
};
export type NotificationSettingsChangedFragment = {
  readonly __typename?: 'NotificationSettingsChanged';
} & {
  readonly friendStatus: ({
    readonly __typename?: 'NotificationDisabled';
  } & NotificationStatus_NotificationDisabled_Fragment) | ({
    readonly __typename?: 'NotificationEnabled';
  } & NotificationStatus_NotificationEnabled_Fragment);
  readonly watchlistStatus: ({
    readonly __typename?: 'NotificationDisabled';
  } & NotificationStatus_NotificationDisabled_Fragment) | ({
    readonly __typename?: 'NotificationEnabled';
  } & NotificationStatus_NotificationEnabled_Fragment);
  readonly sensitiveContentSettings: {
    readonly __typename?: 'SensitiveContentSettings';
  } & Pick<SensitiveContentSettings, 'warnOnSensitiveContent'>;
};
export type VisitorNotificationSettingsSubscriptionSubscriptionVariables = {};
export type VisitorNotificationSettingsSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly notificationSettingsEvent: {
    readonly __typename?: 'NotificationSettingsChanged';
  } & VisitorNotificationSettingsChangedFragment;
};
export type VisitorNotificationSettingsChangedFragment = {
  readonly __typename?: 'NotificationSettingsChanged';
} & {
  readonly profileVisitorSettings: {
    readonly __typename?: 'ProfileVisitorNotificationSettings';
  } & Pick<ProfileVisitorNotificationSettings, 'newVisitorNotificationEnabled' | 'freeUnlockAvailableNotificationEnabled'>;
};
export type EnableFriendNotificationMutationVariables = {};
export type EnableFriendNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly enableFriendNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DisableFriendNotificationMutationVariables = {};
export type DisableFriendNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly disableFriendNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type UpdateFriendNotificationEnabledStatusMutationVariables = {
  status: NotificationEnabledInput;
};
export type UpdateFriendNotificationEnabledStatusMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly updateFriendNotificationEnabledStatus: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type EnableWatchlistNotificationMutationVariables = {};
export type EnableWatchlistNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly enableWatchlistNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DisableWatchlistNotificationMutationVariables = {};
export type DisableWatchlistNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly disableWatchlistNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type UpdateWatchlistNotificationEnabledStatusMutationVariables = {
  status: NotificationEnabledInput;
};
export type UpdateWatchlistNotificationEnabledStatusMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly updateWatchlistNotificationEnabledStatus: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type EnableSensitiveContentWarningMutationVariables = {};
export type EnableSensitiveContentWarningMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly enableSensitiveContentWarning: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DisableSensitiveContentWarningMutationVariables = {};
export type DisableSensitiveContentWarningMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly disableSensitiveContentWarning: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type EnableNewProfileVisitorNotificationMutationVariables = {};
export type EnableNewProfileVisitorNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly enableNewProfileVisitorNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DisableNewProfileVisitorNotificationMutationVariables = {};
export type DisableNewProfileVisitorNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly disableNewProfileVisitorNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables = {};
export type EnableFreeProfileVisitorUnlockAvailableNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly enableFreeProfileVisitorUnlockAvailableNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables = {};
export type DisableFreeProfileVisitorUnlockAvailableNotificationMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly notificationSettings: {
    readonly __typename?: 'NotificationSettingsMutation';
  } & {
    readonly disableFreeProfileVisitorUnlockAvailableNotification: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type GetTanSystemStatusQueryVariables = {};
export type GetTanSystemStatusQuery = {
  readonly __typename?: 'Query';
} & {
  readonly tan: {
    readonly __typename?: 'TanQuery';
  } & {
    readonly status: ({
      readonly __typename?: 'TanDisabled';
    } & TanSystemStatus_TanDisabled_Fragment) | ({
      readonly __typename?: 'TanEnabled';
    } & TanSystemStatus_TanEnabled_Fragment);
  };
};
export type TanSystemSubscriptionSubscriptionVariables = {};
export type TanSystemSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly tanStatusChanged: ({
    readonly __typename?: 'TanDisabled';
  } & TanSystemStatus_TanDisabled_Fragment) | ({
    readonly __typename?: 'TanEnabled';
  } & TanSystemStatus_TanEnabled_Fragment);
};
export type VerifiedDataSubscriptionSubscriptionVariables = {
  id: Scalars['ID'];
};
export type VerifiedDataSubscriptionSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'email' | 'verifiedPhoneNumber'>;
};
export type EnableTanSystemMutationVariables = {};
export type EnableTanSystemMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly tan: {
    readonly __typename?: 'TanMutation';
  } & {
    readonly enableTan: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type DisableTanSystemMutationVariables = {};
export type DisableTanSystemMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly tan: {
    readonly __typename?: 'TanMutation';
  } & {
    readonly disableTan: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
export type UpdateTanSystemStatusMutationVariables = {
  status: TanEnabledInput;
};
export type UpdateTanSystemStatusMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly tan: {
    readonly __typename?: 'TanMutation';
  } & {
    readonly updateTanEnabledStatus: ({
      readonly __typename?: 'Success';
    } & Pick<Success, 'unused'>) | ({
      readonly __typename?: 'InternalError';
    } & Pick<InternalError, 'unused'>);
  };
};
type TanSystemStatus_TanDisabled_Fragment = {
  readonly __typename?: 'TanDisabled';
} & Pick<TanDisabled, 'unused'>;
type TanSystemStatus_TanEnabled_Fragment = {
  readonly __typename?: 'TanEnabled';
} & Pick<TanEnabled, 'confirmMobileNumberChange' | 'confirmEmailChange' | 'confirmPasswordChange' | 'confirmSmileyTrade' | 'confirmSmileyCodeUse' | 'confirmKnuddelTransfer' | 'confirmKnuddelTransferAmount' | 'confirmNickSwitch'>;
export type TanSystemStatusFragment = TanSystemStatus_TanDisabled_Fragment | TanSystemStatus_TanEnabled_Fragment;
export type SmileyboxChangedSubscriptionVariables = {};
export type SmileyboxChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly smileyBoxEvent: {
    readonly __typename?: 'SmileyBoxListChanged';
  } & Pick<SmileyBoxListChanged, 'listType'> & {
    readonly smileys: ReadonlyArray<{
      readonly __typename?: 'SmileyDetails';
    } & Pick<SmileyDetails, 'id'>>;
  };
};
export type RecentSmileysQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
};
export type RecentSmileysQuery = {
  readonly __typename?: 'Query';
} & {
  readonly smileybox: {
    readonly __typename?: 'SmileyboxQuery';
  } & {
    readonly smileyList: ReadonlyArray<{
      readonly __typename?: 'SmileyDetails';
    } & SmileyFragment>;
  };
};
export type AllSmileyIdsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
};
export type AllSmileyIdsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly smileybox: {
    readonly __typename?: 'SmileyboxQuery';
  } & {
    readonly smileyList: ReadonlyArray<{
      readonly __typename?: 'SmileyDetails';
    } & Pick<SmileyDetails, 'id'>>;
  };
};
export type PreloadAllSmileysQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
};
export type PreloadAllSmileysQuery = {
  readonly __typename?: 'Query';
} & {
  readonly smileybox: {
    readonly __typename?: 'SmileyboxQuery';
  } & {
    readonly smileyList: ReadonlyArray<{
      readonly __typename?: 'SmileyDetails';
    } & SmileyFragment>;
  };
};
export type SmileyDetailsQueryVariables = {
  id: Scalars['ID'];
};
export type SmileyDetailsQuery = {
  readonly __typename?: 'Query';
} & {
  readonly smileybox: {
    readonly __typename?: 'SmileyboxQuery';
  } & {
    readonly smileyDetails: {
      readonly __typename?: 'SmileyDetails';
    } & SmileyFragment;
  };
};
export type SmileyFragment = {
  readonly __typename?: 'SmileyDetails';
} & Pick<SmileyDetails, 'id' | 'image' | 'textRepresentation'>;
export type GetCurrentUserNickQueryVariables = {};
export type GetCurrentUserNickQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly currentUser: {
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'nick' | 'gender' | 'evergreenData' | 'email' | 'engagementSystemStage' | 'verifiedPhoneNumber' | 'isKnuddelsTeamMember' | 'hasKnuddelsPlus' | 'knuddelsPlusActiveUntil' | 'knuddelsPlusBonusDaysActiveUntil' | 'knuddelAmount' | 'age' | 'dateOfRegistration' | 'status' | 'aliasNicks' | 'registrationInterest' | 'onlineMinutes'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'exists' | 'urlLargeSquare' | 'urlVeryLarge'>;
      readonly profilePictureOverlayUrls: {
        readonly __typename?: 'ProfilePictureOverlays';
      } & ProfilePictureOverlaysFragment;
    };
  };
};
export type GetCurrentUserGenderQueryVariables = {};
export type GetCurrentUserGenderQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly currentUser: {
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'gender'>;
  };
};
export type GetProfilePictureLargeSquareQueryVariables = {
  userId: Scalars['ID'];
};
export type GetProfilePictureLargeSquareQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlLargeSquare'>;
    }>;
  };
};
export type GetUserIdFromNickQueryVariables = {
  nick: Scalars['String'];
  accountForNickSwitch?: Maybe<Scalars['Boolean']>;
};
export type GetUserIdFromNickQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly userFromNick?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'nick'>>;
  };
};
export type AllowImagesMutationVariables = {
  userId: Scalars['ID'];
};
export type AllowImagesMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly allowImages: {
      readonly __typename?: 'SetImagePermissionResponse';
    } & Pick<SetImagePermissionResponse, 'error'>;
  };
};
export type BlockImagesMutationVariables = {
  userId: Scalars['ID'];
};
export type BlockImagesMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly blockImages: {
      readonly __typename?: 'SetImagePermissionResponse';
    } & Pick<SetImagePermissionResponse, 'error'>;
  };
};
export type CanSendImagesQueryVariables = {
  userId: Scalars['ID'];
};
export type CanSendImagesQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'canSendImages'>>;
  };
};
export type PrivateIgnoreMutationVariables = {
  id: Scalars['ID'];
};
export type PrivateIgnoreMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly privateIgnore: {
      readonly __typename?: 'SetIgnoreStateMutationResponse';
    } & Pick<SetIgnoreStateMutationResponse, 'error'>;
  };
};
export type IgnoreMutationVariables = {
  id: Scalars['ID'];
};
export type IgnoreMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly ignore: {
      readonly __typename?: 'SetIgnoreStateMutationResponse';
    } & Pick<SetIgnoreStateMutationResponse, 'error'>;
  };
};
export type BlockMutationVariables = {
  id: Scalars['ID'];
};
export type BlockMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly block: {
      readonly __typename?: 'SetIgnoreStateMutationResponse';
    } & Pick<SetIgnoreStateMutationResponse, 'error'>;
  };
};
export type UnPrivateIgnoreMutationVariables = {
  id: Scalars['ID'];
};
export type UnPrivateIgnoreMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly unPrivateIgnore: {
      readonly __typename?: 'SetIgnoreStateMutationResponse';
    } & Pick<SetIgnoreStateMutationResponse, 'error'>;
  };
};
export type UnIgnoreMutationVariables = {
  id: Scalars['ID'];
};
export type UnIgnoreMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly unignore: {
      readonly __typename?: 'SetIgnoreStateMutationResponse';
    } & Pick<SetIgnoreStateMutationResponse, 'error'>;
  };
};
export type UnBlockMutationVariables = {
  id: Scalars['ID'];
};
export type UnBlockMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly unblock: {
      readonly __typename?: 'SetIgnoreStateMutationResponse';
    } & Pick<SetIgnoreStateMutationResponse, 'error'>;
  };
};
export type IgnoreStateChangedSubscriptionVariables = {};
export type IgnoreStateChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly ignoreStateChanged: {
    readonly __typename?: 'User';
  } & IgnoreStateChangedFragment;
};
export type IgnoreStateChangedFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'ignoreState' | 'canReceiveMessages' | 'canReceivePhotoComments'>;
export type IsIgnoringChangedSubscriptionVariables = {};
export type IsIgnoringChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly isIgnoringChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'isIgnoring' | 'canReceiveMessages' | 'canReceivePhotoComments'>;
};
export type HasKnuddelsPlusChangedSubscriptionVariables = {};
export type HasKnuddelsPlusChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly hasKnuddelsPlusChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id' | 'hasKnuddelsPlus' | 'knuddelsPlusActiveUntil' | 'knuddelsPlusBonusDaysActiveUntil'>;
};
export type OwnProfilePictureChangedSubscriptionVariables = {
  id: Scalars['ID'];
  pixelDensity: Scalars['Float'];
};
export type OwnProfilePictureChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & {
    readonly profilePicture: {
      readonly __typename?: 'ProfilePicture';
    } & Pick<ProfilePicture, 'urlVeryLarge' | 'urlLargeSquare'>;
  } & ProfilePictureUserFragment;
};
export type OwnProfilePictureOverlaysChangedSubscriptionVariables = {
  id: Scalars['ID'];
};
export type OwnProfilePictureOverlaysChangedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'id'> & {
    readonly profilePictureOverlayUrls: {
      readonly __typename?: 'ProfilePictureOverlays';
    } & ProfilePictureOverlaysFragment;
  };
};
export type ProfilePictureOverlaysFragment = {
  readonly __typename?: 'ProfilePictureOverlays';
} & Pick<ProfilePictureOverlays, 'urlsLarge' | 'urlsSmall'>;
export type UserIsOnlineInChannelQueryVariables = {
  id: Scalars['ID'];
};
export type UserIsOnlineInChannelQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & UserWithOnlineAndChannelFragment>;
  };
};
export type UserOnlineOrChannelUpdatedSubscriptionVariables = {
  id: Scalars['ID'];
};
export type UserOnlineOrChannelUpdatedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'latestOnlineChannelName' | 'lastOnlineTime'> & UserWithOnlineAndChannelFragment;
};
export type MultipleUserOnlineOrChannelUpdatedSubscriptionVariables = {
  ids: ReadonlyArray<Scalars['ID']>;
};
export type MultipleUserOnlineOrChannelUpdatedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly multipleUserChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'latestOnlineChannelName' | 'lastOnlineTime'> & UserWithOnlineAndChannelFragment;
};
export type UserWithOnlineAndChannelFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'isOnline' | 'currentOnlineChannelName'>;
export type KeepOnlineMutationVariables = {
  isPresent: Scalars['Boolean'];
};
export type KeepOnlineMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly keepOnline: {
      readonly __typename: 'KeepOnlineSuccess';
    } | ({
      readonly __typename: 'KeepOnlineError';
    } & Pick<KeepOnlineError, 'reason'>);
  };
};
export type KeepOnlineV2MutationVariables = {
  clientState: ClientState;
};
export type KeepOnlineV2Mutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & {
    readonly keepOnlineV2: {
      readonly __typename: 'KeepOnlineSuccess';
    } | ({
      readonly __typename: 'KeepOnlineError';
    } & Pick<KeepOnlineError, 'reason'>);
  };
};
export type UserIsOnlineQueryVariables = {
  id: Scalars['ID'];
};
export type UserIsOnlineQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & UserWithOnlineFragment>;
  };
};
export type UserOnlineUpdatedSubscriptionVariables = {
  id: Scalars['ID'];
};
export type UserOnlineUpdatedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'latestOnlineChannelName' | 'lastOnlineTime'> & UserWithOnlineFragment;
};
export type UserWithOnlineFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'isOnline'>;
export type SetOfflineMutationVariables = {};
export type SetOfflineMutation = {
  readonly __typename?: 'Mutation';
} & {
  readonly user: {
    readonly __typename?: 'UserMutation';
  } & Pick<UserMutation, 'setOffline'>;
};
export type MultipleUserOnlineUpdatedSubscriptionVariables = {
  ids: ReadonlyArray<Scalars['ID']>;
};
export type MultipleUserOnlineUpdatedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly multipleUserChanged: {
    readonly __typename?: 'User';
  } & Pick<User, 'latestOnlineChannelName' | 'lastOnlineTime'> & UserWithOnlineFragment;
};
export type UserKnuddelQueryVariables = {
  id: Scalars['ID'];
};
export type UserKnuddelQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & UserKnuddelFragment>;
  };
};
export type UserKnuddelUpdatedSubscriptionVariables = {
  id: Scalars['ID'];
};
export type UserKnuddelUpdatedSubscription = {
  readonly __typename?: 'Subscription';
} & {
  readonly userChanged: {
    readonly __typename?: 'User';
  } & UserKnuddelFragment;
};
export type UserKnuddelFragment = {
  readonly __typename?: 'User';
} & Pick<User, 'id' | 'knuddelAmount'>;
export type UserFriendStateQueryVariables = {
  userId: Scalars['ID'];
};
export type UserFriendStateQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id' | 'friendState'>>;
  };
};
export type GetProfilePictureCustomSizeQueryVariables = {
  userId: Scalars['ID'];
  pixelDensity: Scalars['Float'];
  size: Scalars['Int'];
};
export type GetProfilePictureCustomSizeQuery = {
  readonly __typename?: 'Query';
} & {
  readonly user: {
    readonly __typename?: 'UserQuery';
  } & {
    readonly user?: Maybe<{
      readonly __typename?: 'User';
    } & Pick<User, 'id'> & {
      readonly profilePicture: {
        readonly __typename?: 'ProfilePicture';
      } & Pick<ProfilePicture, 'urlCustomSizeSquare'>;
      readonly profilePictureOverlayUrls: {
        readonly __typename?: 'ProfilePictureOverlays';
      } & ProfilePictureOverlaysFragment;
    }>;
  };
};
export const UserWithLockInfoFragmentDoc = gql`
	fragment UserWithLockInfo on User {
		id
		nick
		lockInfo {
			... on UnlockedLockInfo {
				__typename
			}
			... on TemporaryLockInfo {
				lockReason
				lockedBy {
					nick
				}
				lockedUntilDate
			}
			... on PermanentLockInfo {
				lockReason
				lockedBy {
					nick
				}
			}
		}
	}
`;
export const RefreshSessionErrorFragmentDoc = gql`
	fragment RefreshSessionError on RefreshSessionError {
		formattedErrorMessage
		user {
			...UserWithLockInfo
		}
	}
	${UserWithLockInfoFragmentDoc}
`;
export const UserWithAdFreeFragmentDoc = gql`
	fragment UserWithAdFree on User {
		id
		isAdFree
	}
`;
export const AutocompleteChannelGroupFragmentDoc = gql`
	fragment AutocompleteChannelGroup on ChannelGroup {
		id
		name
		info {
			backgroundColor {
				red
				green
				blue
				alpha
			}
			previewImageUrl
		}
		channels {
			id
			name
			onlineUserCount
		}
	}
`;
export const ProfilePictureOverlaysFragmentDoc = gql`
	fragment ProfilePictureOverlays on ProfilePictureOverlays {
		urlsLarge
		urlsSmall
	}
`;
export const MacroBoxUserFragmentDoc = gql`
	fragment MacroBoxUser on User {
		id
		nick
		age
		gender
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		city
		ignoreState
		isReportable
		isAppBot
		menteeStatus
		authenticityClassification
		canReceiveMessages
		conversationId
		isAllowedByContactFilter
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const ChannelUserFragmentDoc = gql`
	fragment ChannelUser on User {
		id
		nick
		age
		gender
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 40)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		friendState
		menteeStatus
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const ColorFragmentDoc = gql`
	fragment Color on Color {
		alpha
		blue
		green
		red
	}
`;
export const ChannelMsgUserFragmentDoc = gql`
	fragment ChannelMsgUser on User {
		id
		nick
	}
`;
export const ChannelMessageFragmentDoc = gql`
	fragment ChannelMessage on ChannelMsg {
		... on ChannelMsgAction {
			id
			sender {
				...ChannelMsgUser
			}
			formattedText
			iconFormattedText
		}
		... on ChannelMsgPublic {
			id
			sender {
				...ChannelMsgUser
			}
			formattedText
		}
		... on ChannelMsgSystem {
			id
			sender {
				...ChannelMsgUser
			}
			formattedText
			sentFromChannel {
				id
			}
		}
		... on ChannelMsgPrivateGroup {
			id
			sender {
				...ChannelMsgUser
			}
			formattedText
			receiver {
				...ChannelMsgUser
			}
		}
	}
	${ChannelMsgUserFragmentDoc}
`;
export const ActiveChannelFragmentDoc = gql`
	fragment ActiveChannel on Channel {
		id
		name
		participants {
			user {
				...ChannelUser
			}
			nicklistPriority
		}
		groupInfo {
			backgroundColor {
				...Color
			}
			backgroundImageInfo {
				mode
				url
			}
			highlightColor {
				...Color
			}
			highlightColorCondensedPrivateMessage {
				...Color
			}
			isMyChannel
			isAdultChannel
		}
		recentMessages {
			...ChannelMessage
		}
	}
	${ChannelUserFragmentDoc}
	${ColorFragmentDoc}
	${ChannelMessageFragmentDoc}
`;
export const NicklistIconFragmentDoc = gql`
	fragment NicklistIcon on NicklistIcon {
		iconName
		iconFormattedText
		listType
		index
	}
`;
export const ChannelJoinErrorFragmentDoc = gql`
	fragment ChannelJoinError on ChannelJoinMutationResponseError {
		type
		freetext
		userNick
		otherChannelName
		minAge
		maxUser
		startTime
		endTime
		minKnuddels
		minTradeableSmileys
		minRegisteredDays
		minStammiMonths
		requiredGender
		requiredStatusName
	}
`;
export const ProfilePictureUserFragmentDoc = gql`
	fragment ProfilePictureUser on User {
		id
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 40)
		}
	}
`;
export const ChannelGroupInfoFragmentDoc = gql`
	fragment ChannelGroupInfo on ChannelGroupInfo {
		previewImageUrl
		previewImageInfo(width: 306, height: 306, blur: 40) {
			url
			urlBlurred
		}
		isSpecialPreviewImage
		backgroundColor {
			...Color
		}
		shortDescription
		mainCategoryName
		isMyChannel
	}
	${ColorFragmentDoc}
`;
export const SearchChannelGroupInfoFragmentDoc = gql`
	fragment SearchChannelGroupInfo on ChannelGroupInfo {
		...ChannelGroupInfo
		categoryNames
		searchKeywords
	}
	${ChannelGroupInfoFragmentDoc}
`;
export const ChannelListContactFragmentDoc = gql`
	fragment ChannelListContact on User {
		id
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 40)
		}
	}
`;
export const ChannelPreviewMembersFragmentDoc = gql`
	fragment ChannelPreviewMembers on ChannelPreviewMembers {
		friends {
			...ChannelListContact
		}
		watchlist {
			...ChannelListContact
		}
		others {
			...ChannelListContact
		}
	}
	${ChannelListContactFragmentDoc}
`;
export const SearchChannelGroupFragmentDoc = gql`
	fragment SearchChannelGroup on ChannelGroup {
		id
		name
		info {
			...SearchChannelGroupInfo
		}
		channels {
			id
			name
			onlineUserCount
		}
		previewMembers {
			...ChannelPreviewMembers
		}
	}
	${SearchChannelGroupInfoFragmentDoc}
	${ChannelPreviewMembersFragmentDoc}
`;
export const ChannelAdFragmentDoc = gql`
	fragment ChannelAd on ChannelAd {
		adCampaignId
		channelGroup {
			id
			name
			info {
				...ChannelGroupInfo
			}
			channels {
				id
				onlineUserCount
			}
		}
	}
	${ChannelGroupInfoFragmentDoc}
`;
export const ChannelFragmentDoc = gql`
	fragment Channel on Channel {
		id
		name
		onlineUserCount
		onlineContacts {
			...ChannelListContact
		}
		previewMembers {
			...ChannelPreviewMembers
		}
	}
	${ChannelListContactFragmentDoc}
	${ChannelPreviewMembersFragmentDoc}
`;
export const ChannelGroupFragmentDoc = gql`
	fragment ChannelGroup on ChannelGroup {
		id
		name
		info {
			...ChannelGroupInfo
		}
		channels {
			...Channel
		}
		onlineContacts {
			...ChannelListContact
		}
		previewMembers {
			...ChannelPreviewMembers
		}
	}
	${ChannelGroupInfoFragmentDoc}
	${ChannelFragmentDoc}
	${ChannelListContactFragmentDoc}
	${ChannelPreviewMembersFragmentDoc}
`;
export const ChannelCategoryFragmentDoc = gql`
	fragment ChannelCategory on ChannelCategory {
		id
		name
		channelGroups(first: $groupAmount) {
			...ChannelGroup
		}
	}
	${ChannelGroupFragmentDoc}
`;
export const TopChannelCategoryFragmentDoc = gql`
	fragment TopChannelCategory on TopGameChannel {
		group {
			id
			name
			info {
				...ChannelGroupInfo
			}
			channels {
				...Channel
			}
			onlineContacts {
				...ChannelListContact
			}
		}
		longDescription
		headerImage
		glowOverlay
		games {
			pictureUrl
		}
		gameCount
	}
	${ChannelGroupInfoFragmentDoc}
	${ChannelFragmentDoc}
	${ChannelListContactFragmentDoc}
`;
export const ContactsUserFragmentDoc = gql`
	fragment ContactsUser on User {
		id
		nick
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		isOnline
		currentOnlineChannelName
		lastOnlineTime
		latestOnlineChannelName
		readMe
		canReceiveMessages
		menteeStatus
		isAppBot
		authenticityClassification
		isOnWatchlist
		friendState
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const FriendRequestUserFragmentDoc = gql`
	fragment FriendRequestUser on User {
		id
		nick
		age
		gender
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const LongConversationOccurredFragmentDoc = gql`
	fragment LongConversationOccurred on LongConversationOccurred {
		conversation {
			id
		}
	}
`;
export const DailyLoginUsedFragmentDoc = gql`
	fragment DailyLoginUsed on DailyLoginUsed {
		unused
	}
`;
export const FriendRequestAcceptedFragmentDoc = gql`
	fragment FriendRequestAccepted on FriendRequestAccepted {
		unused
	}
`;
export const PotentialMenteeAddedEventFragmentDoc = gql`
	fragment PotentialMenteeAddedEvent on PotentialMenteeAddedEvent {
		user {
			id
			nick
			age
			gender
			currentOnlineChannelName
			isOnline
			conversationId
			menteeStatus
			interest
			latestClient
		}
	}
`;
export const MentorStatusActiveFragmentDoc = gql`
	fragment MentorStatusActive on MentorStatusActive {
		pauseState
		mentorPoints
		previousMentorLevelBoundary
		mentorLevelBoundary
		nextMentorReward {
			icon
			title
		}
		conversationStartedCount
		conversationStartedBoundary
	}
`;
export const MentorStatusFragmentDoc = gql`
	fragment MentorStatus on MentorStatus {
		... on MentorStatusActive {
			...MentorStatusActive
		}
		... on MentorStatusAvailable {
			unused
		}
		... on MentorStatusDisabled {
			unused
		}
	}
	${MentorStatusActiveFragmentDoc}
`;
export const CommonFriendFragmentDoc = gql`
	fragment CommonFriend on User {
		id
		nick
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 40)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const MessengerBasicUserFragmentDoc = gql`
	fragment MessengerBasicUser on User {
		id
		nick
		isOnline
		canSendImages
		menteeStatus
	}
`;
export const ProfileTagFragmentDoc = gql`
	fragment ProfileTag on ProfileTag {
		displayName
		isMatching
		category
		slashCommand
	}
`;
export const MessengerOverviewUserFragmentDoc = gql`
	fragment MessengerOverviewUser on User {
		...MessengerBasicUser
		age
		albumPhotosUrl
		canReceiveMessages
		city
		distance
		gender
		id
		isOnline
		currentOnlineChannelName
		latestOnlineChannelName
		lastOnlineTime
		nick
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		readMe
		relationshipStatus
		sexualOrientation
		onlineMinutes
		isAppBot
		isLockedByAutomaticComplaint
		automaticComplaintCommand
		isReportable
		interest
		latestClient
		authenticityClassification
		ignoreState
		profileTags {
			...ProfileTag
		}
	}
	${MessengerBasicUserFragmentDoc}
	${ProfilePictureOverlaysFragmentDoc}
	${ProfileTagFragmentDoc}
`;
export const MessengerFullUserFragmentDoc = gql`
	fragment MessengerFullUser on User {
		...MessengerOverviewUser
		isIgnoring
		isAllowedByContactFilter
	}
	${MessengerOverviewUserFragmentDoc}
`;
export const ConversationTextMessageContentFragmentDoc = gql`
	fragment ConversationTextMessageContent on ConversationTextMessageContent {
		formattedText
		starred
	}
`;
export const ConversationNestedMessageContentFragmentDoc = gql`
	fragment ConversationNestedMessageContent on ConversationNestedMessageContent {
		... on ConversationTextMessageContent {
			starred
			formattedText
		}
		... on ConversationImageMessageContent {
			starred
			image {
				url
			}
			imageAccepted
			sensitiveContentClassification
		}
	}
`;
export const ConversationNestedMessageFragmentDoc = gql`
	fragment ConversationNestedMessage on ConversationNestedMessage {
		sender {
			...MessengerBasicUser
		}
		timestamp
		content {
			...ConversationNestedMessageContent
		}
	}
	${MessengerBasicUserFragmentDoc}
	${ConversationNestedMessageContentFragmentDoc}
`;
export const ConversationQuotedMessageContentFragmentDoc = gql`
	fragment ConversationQuotedMessageContent on ConversationQuotedMessageContent {
		starred
		formattedText
		nestedMessage {
			...ConversationNestedMessage
		}
	}
	${ConversationNestedMessageFragmentDoc}
`;
export const ConversationForwardedMessageContentFragmentDoc = gql`
	fragment ConversationForwardedMessageContent on ConversationForwardedMessageContent {
		starred
		nestedMessage {
			...ConversationNestedMessage
		}
	}
	${ConversationNestedMessageFragmentDoc}
`;
export const ConversationImageMessageContentFragmentDoc = gql`
	fragment ConversationImageMessageContent on ConversationImageMessageContent {
		starred
		image {
			url
		}
		imageAccepted
		sensitiveContentClassification
	}
`;
export const ConversationSnapMessageContentFragmentDoc = gql`
	fragment ConversationSnapMessageContent on ConversationSnapMessageContent {
		snap {
			url
			photoId
			duration
			decryptionKey
		}
		imageAccepted
		sensitiveContentClassification
	}
`;
export const ConversationVisiblePhotoCommentMessageContentFragmentDoc = gql`
	fragment ConversationVisiblePhotoCommentMessageContent on ConversationVisiblePhotoCommentMessageContent {
		albumPhotoId
		commentId
		photoUrl
		formattedText
	}
`;
export const ConversationHiddenPhotoCommentMessageContentFragmentDoc = gql`
	fragment ConversationHiddenPhotoCommentMessageContent on ConversationHiddenPhotoCommentMessageContent {
		albumPhotoId
		photoUrl
		formattedText
	}
`;
export const ConversationDeletedPhotoCommentMessageContentFragmentDoc = gql`
	fragment ConversationDeletedPhotoCommentMessageContent on ConversationDeletedPhotoCommentMessageContent {
		unused
	}
`;
export const ConversationKnuddelTransferMessageContentFragmentDoc = gql`
	fragment ConversationKnuddelTransferMessageContent on ConversationKnuddelTransferMessageContent {
		knuddelAmount
	}
`;
export const ConversationMentorAchievedMessageContentFragmentDoc = gql`
	fragment ConversationMentorAchievedMessageContent on ConversationMentorAchievedMessageContent {
		unused
	}
`;
export const ConversationPrivateSystemMessageContentFragmentDoc = gql`
	fragment ConversationPrivateSystemMessageContent on ConversationPrivateSystemMessageContent {
		icon
		formattedText
		collapse
	}
`;
export const ConversationBirthdayMessageContentFragmentDoc = gql`
	fragment ConversationBirthdayMessageContent on ConversationBirthdayMessageContent {
		unused
	}
`;
export const ConversationNicknameChangeMessageContentFragmentDoc = gql`
	fragment ConversationNicknameChangeMessageContent on ConversationNicknameChangeMessageContent {
		oldNick
		newNick
	}
`;
export const ConversationMessageContentFragmentDoc = gql`
	fragment ConversationMessageContent on ConversationMessageContent {
		... on ConversationTextMessageContent {
			...ConversationTextMessageContent
		}
		... on ConversationQuotedMessageContent {
			...ConversationQuotedMessageContent
		}
		... on ConversationForwardedMessageContent {
			...ConversationForwardedMessageContent
		}
		... on ConversationImageMessageContent {
			...ConversationImageMessageContent
		}
		... on ConversationSnapMessageContent {
			...ConversationSnapMessageContent
		}
		... on ConversationVisiblePhotoCommentMessageContent {
			...ConversationVisiblePhotoCommentMessageContent
		}
		... on ConversationHiddenPhotoCommentMessageContent {
			...ConversationHiddenPhotoCommentMessageContent
		}
		... on ConversationDeletedPhotoCommentMessageContent {
			...ConversationDeletedPhotoCommentMessageContent
		}
		... on ConversationKnuddelTransferMessageContent {
			...ConversationKnuddelTransferMessageContent
		}
		... on ConversationMentorAchievedMessageContent {
			...ConversationMentorAchievedMessageContent
		}
		... on ConversationPrivateSystemMessageContent {
			...ConversationPrivateSystemMessageContent
		}
		... on ConversationBirthdayMessageContent {
			...ConversationBirthdayMessageContent
		}
		... on ConversationNicknameChangeMessageContent {
			...ConversationNicknameChangeMessageContent
		}
	}
	${ConversationTextMessageContentFragmentDoc}
	${ConversationQuotedMessageContentFragmentDoc}
	${ConversationForwardedMessageContentFragmentDoc}
	${ConversationImageMessageContentFragmentDoc}
	${ConversationSnapMessageContentFragmentDoc}
	${ConversationVisiblePhotoCommentMessageContentFragmentDoc}
	${ConversationHiddenPhotoCommentMessageContentFragmentDoc}
	${ConversationDeletedPhotoCommentMessageContentFragmentDoc}
	${ConversationKnuddelTransferMessageContentFragmentDoc}
	${ConversationMentorAchievedMessageContentFragmentDoc}
	${ConversationPrivateSystemMessageContentFragmentDoc}
	${ConversationBirthdayMessageContentFragmentDoc}
	${ConversationNicknameChangeMessageContentFragmentDoc}
`;
export const ConversationMessageFragmentDoc = gql`
	fragment ConversationMessage on ConversationMessage {
		id
		timestamp
		sender {
			...MessengerBasicUser
		}
		content {
			...ConversationMessageContent
		}
	}
	${MessengerBasicUserFragmentDoc}
	${ConversationMessageContentFragmentDoc}
`;
export const FullConversationFragmentDoc = gql`
	fragment FullConversation on MessengerConversation {
		id
		visibility
		otherParticipants {
			...MessengerFullUser
		}
		readState {
			markedAsUnread
			unreadMessageCount
			lastReadConversationMessage {
				id
			}
		}
		latestConversationMessage {
			...ConversationMessage
		}
		conversationMessages(
			limit: $messageCount
			beforeMessageId: $beforeMessageId
		) @connection(key: "conversationMessages") {
			messages {
				...ConversationMessage
			}
			hasMore
		}
	}
	${MessengerFullUserFragmentDoc}
	${ConversationMessageFragmentDoc}
`;
export const FullConversationWithoutMessagesFragmentDoc = gql`
	fragment FullConversationWithoutMessages on MessengerConversation {
		id
		visibility
		otherParticipants {
			...MessengerOverviewUser
		}
		readState {
			markedAsUnread
			unreadMessageCount
			lastReadConversationMessage {
				id
			}
		}
		latestConversationMessage {
			...ConversationMessage
		}
	}
	${MessengerOverviewUserFragmentDoc}
	${ConversationMessageFragmentDoc}
`;
export const ConversationStarterSuggestionTextFragmentDoc = gql`
	fragment ConversationStarterSuggestionText on ConversationStarterSuggestionText {
		formattedText
		text
	}
`;
export const SmileyFragmentDoc = gql`
	fragment Smiley on SmileyDetails {
		id
		image
		textRepresentation
	}
`;
export const ConversationStarterSuggestionSmileyFragmentDoc = gql`
	fragment ConversationStarterSuggestionSmiley on ConversationStarterSuggestionSmiley {
		smiley {
			...Smiley
		}
	}
	${SmileyFragmentDoc}
`;
export const MessengerSettingsChangedFragmentDoc = gql`
	fragment MessengerSettingsChanged on MessengerSettingsChanged {
		settings {
			conversationListFilterType
		}
	}
`;
export const MessengerConversationVisibilityChangedFragmentDoc = gql`
	fragment MessengerConversationVisibilityChanged on MessengerConversationVisibilityChanged {
		conversation {
			id
			visibility
		}
	}
`;
export const ReceivedConversationMessageFragmentDoc = gql`
	fragment ReceivedConversationMessage on ConversationMessage {
		...ConversationMessage
		sender {
			...MessengerBasicUser
			currentOnlineChannelName
		}
	}
	${ConversationMessageFragmentDoc}
	${MessengerBasicUserFragmentDoc}
`;
export const MessengerMessageReceivedFragmentDoc = gql`
	fragment MessengerMessageReceived on MessengerMessageReceived {
		messageCorrelationId
		conversationMessage {
			...ReceivedConversationMessage
		}
		conversation {
			latestConversationMessage {
				...ReceivedConversationMessage
			}
			id
			otherParticipants {
				id
				nick
			}
			visibility
		}
	}
	${ReceivedConversationMessageFragmentDoc}
`;
export const StarredMessagesUserFragmentDoc = gql`
	fragment StarredMessagesUser on User {
		id
		nick
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
	}
`;
export const StarredMessagesConversationMessageFragmentDoc = gql`
	fragment StarredMessagesConversationMessage on ConversationMessage {
		...ConversationMessage
		sender {
			...StarredMessagesUser
		}
	}
	${ConversationMessageFragmentDoc}
	${StarredMessagesUserFragmentDoc}
`;
export const StarredConversationMessageFragmentDoc = gql`
	fragment StarredConversationMessage on StarredConversationMessage {
		conversation {
			id
			otherParticipants {
				...StarredMessagesUser
			}
		}
		message {
			...StarredMessagesConversationMessage
		}
	}
	${StarredMessagesUserFragmentDoc}
	${StarredMessagesConversationMessageFragmentDoc}
`;
export const MessengerMessageStarredStateChangedFragmentDoc = gql`
	fragment MessengerMessageStarredStateChanged on MessengerMessageStarredStateChanged {
		starredMessage {
			...StarredConversationMessage
		}
	}
	${StarredConversationMessageFragmentDoc}
`;
export const MessengerUserTypingStartedFragmentDoc = gql`
	fragment MessengerUserTypingStarted on MessengerUserTypingStarted {
		user {
			id
		}
		conversation {
			id
		}
		willReceiveStopEvent
	}
`;
export const MessengerUserTypingStoppedFragmentDoc = gql`
	fragment MessengerUserTypingStopped on MessengerUserTypingStopped {
		user {
			id
		}
		conversation {
			id
		}
	}
`;
export const MessengerConversationReadStateChangedFragmentDoc = gql`
	fragment MessengerConversationReadStateChanged on MessengerConversationReadStateChanged {
		conversation {
			id
			readState {
				lastReadConversationMessage {
					id
				}
				markedAsUnread
				unreadMessageCount
			}
		}
	}
`;
export const MessengerMessageChangedFragmentDoc = gql`
	fragment MessengerMessageChanged on MessengerMessageChanged {
		conversationId
		conversationMessage {
			id
			content {
				... on ConversationVisiblePhotoCommentMessageContent {
					albumPhotoId
					commentId
					formattedText
					photoUrl
				}
				... on ConversationHiddenPhotoCommentMessageContent {
					albumPhotoId
					formattedText
					photoUrl
				}
				... on ConversationDeletedPhotoCommentMessageContent {
					unused
				}
			}
		}
	}
`;
export const MessageHistoryExportReadyFragmentDoc = gql`
	fragment MessageHistoryExportReady on MessageHistoryExportReadyEvent {
		conversationId
		downloadUrl
	}
`;
export const NotificationFragmentDoc = gql`
	fragment Notification on Notification {
		key
		title
		description
		icon {
			subtitle {
				text
				color {
					lightThemeColor {
						...Color
					}
					darkThemeColor {
						...Color
					}
				}
			}
			url
		}
		image {
			... on IconNotificationImage {
				subtitle {
					text
					color {
						lightThemeColor {
							...Color
						}
						darkThemeColor {
							...Color
						}
					}
				}
				url
			}
			... on ProfilePictureNotificationImage {
				isBlurred
				user {
					profilePicture {
						urlCustomSizeSquare(
							pixelDensity: $pixelDensity
							size: 40
						)
					}
					profilePictureOverlayUrls {
						urlsSmall
					}
				}
			}
		}
		callToAction {
			text
			slashCommand
		}
	}
	${ColorFragmentDoc}
`;
export const ProfileFriendUserFragmentDoc = gql`
	fragment ProfileFriendUser on User {
		id
		nick
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		isOnline
		currentOnlineChannelName
		readMe
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const ProfileCommonFriendUserFragmentDoc = gql`
	fragment ProfileCommonFriendUser on User {
		id
		nick
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const UserForQuickWhoisFragmentDoc = gql`
	fragment UserForQuickWhois on User {
		id
		nick
		age
		gender
		ignoreState
		sexualOrientation
		relationshipStatus
		city
		distance
		canReceiveMessages
		profilePicture {
			urlLargeSquare
			urlVeryLarge
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		albumPhotosUrl
		isOnWatchlist
		friendState
		hasSeenProfileAfterFriendshipStarted
		isAppBot
		authenticityClassification
		isAllowedByContactFilter
		profileTags {
			...ProfileTag
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
	${ProfileTagFragmentDoc}
`;
export const UserForProfileFragmentDoc = gql`
	fragment UserForProfile on User {
		id
		nick
		age
		gender
		sexualOrientation
		relationshipStatus
		city
		countryEnum
		distance
		ignoreState
		canReceiveMessages
		canReceivePhotoComments
		profilePicture {
			urlLargeSquare
			urlVeryLarge
			exists
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		albumPhotosUrl
		readMe
		nameFormatted
		dateOfBirth
		children
		smoker
		hobbies
		music
		movies
		series
		books
		languages
		isOnline
		lastOnlineTime
		dateOfRegistration
		status
		supportsKnuddelsPhilosophy
		teams
		stammiMonths
		channelModeratorMonths
		myChannelModeratorMonths
		channelLeadElectionCount
		adminPeriods
		latestOnlineChannelName
		myChannelName
		favouriteChannelName
		favouriteMyChannelName
		moderatedChannelName
		moderatedMyChannelNames
		channelLeadChannelNames
		hardCoreChannelNames
		hickeys
		flowers
		roses
		chatMeetups
		receivedHearts
		givenHeart
		mentorPoints
		engagementSystemLevel
		canSeeAdminInfo
		onlineMinutes
		isReportable
		isAppBot
		knuddelAmount
		friendState
		isOnWatchlist
		hasSeenProfileAfterFriendshipStarted
		menteeStatus
		authenticityClassification
		hasNicknameHistoryVisible
		isAllowedByContactFilter
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const AlbumDetailPhotoFragmentDoc = gql`
	fragment AlbumDetailPhoto on AlbumPhoto {
		id
		thumbnailUrl
		photoUrl
		administrationUrl
		description {
			formattedText
			rawText
		}
		isOwner
	}
`;
export const AlbumFragmentDoc = gql`
	fragment Album on Album {
		id
		title
		isOwner
		albumPhotos {
			...AlbumDetailPhoto
		}
	}
	${AlbumDetailPhotoFragmentDoc}
`;
export const EditProfileEntryFragmentDoc = gql`
	fragment EditProfileEntry on ProfileEditEntry {
		field
		... on ProfileEditEntryFreeText {
			freeTextValue
			freeTextPattern
			freeTextMinLength
			freeTextMaxLength
			freeTextMultiLineAllowed
		}
		... on ProfileEditEntryChildren {
			childrenValue
		}
		... on ProfileEditEntryDate {
			dateValue
			dateMinValue
			dateMaxValue
		}
		... on ProfileEditEntryRelationshipStatus {
			relationshipStatusValue
		}
		... on ProfileEditEntrySexualOrientation {
			sexualOrientationValue
		}
		... on ProfileEditEntrySmoker {
			smokerValue
		}
		... on ProfileEditEntryStringList {
			stringListValue
		}
		... on ProfileEditEntryCountry {
			countryValue
		}
	}
`;
export const AlbumPhotoCommentFragmentDoc = gql`
	fragment AlbumPhotoComment on AlbumPhotoComment {
		id
		text
		timestamp
		sender {
			id
			nick
			profilePicture {
				urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 40)
			}
			profilePictureOverlayUrls {
				...ProfilePictureOverlays
			}
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const ProfileVisitorsUserFragmentDoc = gql`
	fragment ProfileVisitorsUser on User {
		id
		nick
		age
		gender
		city
		isOnline
		profilePicture {
			urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 60)
		}
		profilePictureOverlayUrls {
			...ProfilePictureOverlays
		}
		isAppBot
		authenticityClassification
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export const HappyHourFragmentDoc = gql`
	fragment HappyHour on HappyHourPromotion {
		color {
			alpha
			red
			blue
			green
		}
		endTimestamp
		maxBonusPercentage
	}
`;
export const NicknameChangeOptionFragmentDoc = gql`
	fragment NicknameChangeOption on NicknameChangeSetting {
		checked
		toggleable
		availableUntil
	}
`;
export const NicknameChangeOptionsFragmentDoc = gql`
	fragment NicknameChangeOptions on NicknameChangeOptions {
		showOnTopOfNickList {
			...NicknameChangeOption
		}
		showNickListIcon {
			...NicknameChangeOption
		}
		specialGreeting {
			...NicknameChangeOption
		}
		showNickListEffect {
			...NicknameChangeOption
		}
	}
	${NicknameChangeOptionFragmentDoc}
`;
export const NicknameHistorySettingsFragmentDoc = gql`
	fragment NicknameHistorySettings on NicknameHistorySettings {
		checked
		toggleable
		disabledUntil
	}
`;
export const MacroBoxSettingsEntryFragmentDoc = gql`
	fragment MacroBoxSettingsEntry on MacroBoxSettingsEntry {
		id
		active
	}
`;
export const AllClientSettingsFragmentDoc = gql`
	fragment AllClientSettings on ClientSettings {
		conversationListFilterType
		initialJoinBehavior
		enabledSoundEvents
		mentorBarExtended
		contactListTabs {
			tabs {
				tab
				active
				toggleable
			}
		}
		privateMessageReplyBehavior
		macroBoxQuickAccessEntries {
			...MacroBoxSettingsEntry
		}
		macroBoxInteractionEntries {
			...MacroBoxSettingsEntry
		}
		macroBoxEnabled
		channelListCategories {
			categories {
				category
				active
			}
		}
		navIconSlot
	}
	${MacroBoxSettingsEntryFragmentDoc}
`;
export const ClientSettingsChangedFragmentDoc = gql`
	fragment ClientSettingsChanged on ClientSettingsChanged {
		settings {
			...AllClientSettings
		}
	}
	${AllClientSettingsFragmentDoc}
`;
export const NotificationEnabledFragmentDoc = gql`
	fragment NotificationEnabled on NotificationEnabled {
		notifyBirthday
		notifyCommunityStatusChange
		notifyProfileChange
		notifyProfilePictureChange
		notifyTeamJoin
		notifyNicknameChange
	}
`;
export const NotificationStatusFragmentDoc = gql`
	fragment NotificationStatus on NotificationStatus {
		... on NotificationDisabled {
			unused
		}
		... on NotificationEnabled {
			...NotificationEnabled
		}
	}
	${NotificationEnabledFragmentDoc}
`;
export const NotificationSettingsChangedFragmentDoc = gql`
	fragment NotificationSettingsChanged on NotificationSettingsChanged {
		friendStatus {
			...NotificationStatus
		}
		watchlistStatus {
			...NotificationStatus
		}
		sensitiveContentSettings {
			warnOnSensitiveContent
		}
	}
	${NotificationStatusFragmentDoc}
`;
export const VisitorNotificationSettingsChangedFragmentDoc = gql`
	fragment VisitorNotificationSettingsChanged on NotificationSettingsChanged {
		profileVisitorSettings {
			newVisitorNotificationEnabled
			freeUnlockAvailableNotificationEnabled
		}
	}
`;
export const TanSystemStatusFragmentDoc = gql`
	fragment TanSystemStatus on TanStatus {
		... on TanDisabled {
			unused
		}
		... on TanEnabled {
			confirmMobileNumberChange
			confirmEmailChange
			confirmPasswordChange
			confirmSmileyTrade
			confirmSmileyCodeUse
			confirmKnuddelTransfer
			confirmKnuddelTransferAmount
			confirmNickSwitch
		}
	}
`;
export const IgnoreStateChangedFragmentDoc = gql`
	fragment IgnoreStateChanged on User {
		id
		ignoreState
		canReceiveMessages
		canReceivePhotoComments
	}
`;
export const UserWithOnlineAndChannelFragmentDoc = gql`
	fragment UserWithOnlineAndChannel on User {
		id
		isOnline
		currentOnlineChannelName
	}
`;
export const UserWithOnlineFragmentDoc = gql`
	fragment UserWithOnline on User {
		id
		isOnline
	}
`;
export const UserKnuddelFragmentDoc = gql`
	fragment UserKnuddel on User {
		id
		knuddelAmount
	}
`;
export const SendSlashCommandDocument = gql`
	mutation SendSlashCommand($event: SlashCommandInput!) {
		apps {
			sendSlashCommand(event: $event) {
				... on SendSlashCommandSuccess {
					unused
				}
				... on SendSlashCommandInternalError {
					unused
				}
				... on SendSlashCommandNotFoundError {
					unused
				}
				... on SendSlashCommandNotSupportedError {
					unused
				}
			}
		}
	}
`;
export type SendSlashCommandMutationFn = ApolloReactCommon.MutationFunction<SendSlashCommandMutation, SendSlashCommandMutationVariables>;
export type SendSlashCommandComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendSlashCommandMutation, SendSlashCommandMutationVariables>, 'mutation'>;
export const SendSlashCommandComponent = (props: SendSlashCommandComponentProps) => <ApolloReactComponents.Mutation<SendSlashCommandMutation, SendSlashCommandMutationVariables> mutation={SendSlashCommandDocument} {...props} />;
export type SendSlashCommandProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendSlashCommandMutation, SendSlashCommandMutationVariables> } & TChildProps;
export function withSendSlashCommand<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendSlashCommandMutation, SendSlashCommandMutationVariables, SendSlashCommandProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendSlashCommandMutation, SendSlashCommandMutationVariables, SendSlashCommandProps<TChildProps, TDataName>>(SendSlashCommandDocument, {
    alias: 'sendSlashCommand',
    ...operationOptions
  });
}
export type SendSlashCommandMutationResult = ApolloReactCommon.MutationResult<SendSlashCommandMutation>;
export type SendSlashCommandMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSlashCommandMutation, SendSlashCommandMutationVariables>;
export const SendSlashCommand = (({
  operationKind: 'Mutation',
  document: SendSlashCommandDocument,
  primaryResultPath: ['apps', 'sendSlashCommand']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendSlashCommandMutationVariables;
  TResult: SendSlashCommandMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendSlashCommandMutation['apps']['sendSlashCommand'];
});
export const AuthAsJamesUrlDocument = gql`
	query AuthAsJamesUrl {
		user {
			photoAuthenticatedAsJamesUrl {
				... on PhotoAuthenticatedAsJamesUrl {
					url
				}
				... on AccessDeniedError {
					__typename
				}
				... on InternalError {
					__typename
				}
			}
		}
	}
`;
export type AuthAsJamesUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthAsJamesUrlQuery, AuthAsJamesUrlQueryVariables>, 'query'>;
export const AuthAsJamesUrlComponent = (props: AuthAsJamesUrlComponentProps) => <ApolloReactComponents.Query<AuthAsJamesUrlQuery, AuthAsJamesUrlQueryVariables> query={AuthAsJamesUrlDocument} {...props} />;
export type AuthAsJamesUrlProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<AuthAsJamesUrlQuery, AuthAsJamesUrlQueryVariables> } & TChildProps;
export function withAuthAsJamesUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AuthAsJamesUrlQuery, AuthAsJamesUrlQueryVariables, AuthAsJamesUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, AuthAsJamesUrlQuery, AuthAsJamesUrlQueryVariables, AuthAsJamesUrlProps<TChildProps, TDataName>>(AuthAsJamesUrlDocument, {
    alias: 'authAsJamesUrl',
    ...operationOptions
  });
}
export type AuthAsJamesUrlQueryResult = ApolloReactCommon.QueryResult<AuthAsJamesUrlQuery, AuthAsJamesUrlQueryVariables>;
export const AuthAsJamesUrl = (({
  operationKind: 'Query',
  document: AuthAsJamesUrlDocument,
  primaryResultPath: ['user', 'photoAuthenticatedAsJamesUrl']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: AuthAsJamesUrlQueryVariables;
  TResult: AuthAsJamesUrlQuery;
  primaryResultPath: string[];
  TPrimaryResult: AuthAsJamesUrlQuery['user']['photoAuthenticatedAsJamesUrl'];
});
export const PhotoVerifyUrlDocument = gql`
	query PhotoVerifyUrl {
		user {
			photoVerifyUrl {
				... on PhotoVerifyUrl {
					url
				}
				... on InternalError {
					__typename
				}
			}
		}
	}
`;
export type PhotoVerifyUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PhotoVerifyUrlQuery, PhotoVerifyUrlQueryVariables>, 'query'>;
export const PhotoVerifyUrlComponent = (props: PhotoVerifyUrlComponentProps) => <ApolloReactComponents.Query<PhotoVerifyUrlQuery, PhotoVerifyUrlQueryVariables> query={PhotoVerifyUrlDocument} {...props} />;
export type PhotoVerifyUrlProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<PhotoVerifyUrlQuery, PhotoVerifyUrlQueryVariables> } & TChildProps;
export function withPhotoVerifyUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PhotoVerifyUrlQuery, PhotoVerifyUrlQueryVariables, PhotoVerifyUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, PhotoVerifyUrlQuery, PhotoVerifyUrlQueryVariables, PhotoVerifyUrlProps<TChildProps, TDataName>>(PhotoVerifyUrlDocument, {
    alias: 'photoVerifyUrl',
    ...operationOptions
  });
}
export type PhotoVerifyUrlQueryResult = ApolloReactCommon.QueryResult<PhotoVerifyUrlQuery, PhotoVerifyUrlQueryVariables>;
export const PhotoVerifyUrl = (({
  operationKind: 'Query',
  document: PhotoVerifyUrlDocument,
  primaryResultPath: ['user', 'photoVerifyUrl']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: PhotoVerifyUrlQueryVariables;
  TResult: PhotoVerifyUrlQuery;
  primaryResultPath: string[];
  TPrimaryResult: PhotoVerifyUrlQuery['user']['photoVerifyUrl'];
});
export const RefreshSessionTokenDocument = gql`
	query RefreshSessionToken(
		$sessionInfo: SessionInfo!
		$oldSessionToken: SessionToken
	) {
		login {
			refreshSession(sessionInfo: $sessionInfo, token: $oldSessionToken) {
				... on RefreshSessionSuccess {
					expiry
					token
				}
				...RefreshSessionError
			}
		}
	}
	${RefreshSessionErrorFragmentDoc}
`;
export type RefreshSessionTokenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RefreshSessionTokenQuery, RefreshSessionTokenQueryVariables>, 'query'> & ({
  variables: RefreshSessionTokenQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const RefreshSessionTokenComponent = (props: RefreshSessionTokenComponentProps) => <ApolloReactComponents.Query<RefreshSessionTokenQuery, RefreshSessionTokenQueryVariables> query={RefreshSessionTokenDocument} {...props} />;
export type RefreshSessionTokenProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<RefreshSessionTokenQuery, RefreshSessionTokenQueryVariables> } & TChildProps;
export function withRefreshSessionToken<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RefreshSessionTokenQuery, RefreshSessionTokenQueryVariables, RefreshSessionTokenProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, RefreshSessionTokenQuery, RefreshSessionTokenQueryVariables, RefreshSessionTokenProps<TChildProps, TDataName>>(RefreshSessionTokenDocument, {
    alias: 'refreshSessionToken',
    ...operationOptions
  });
}
export type RefreshSessionTokenQueryResult = ApolloReactCommon.QueryResult<RefreshSessionTokenQuery, RefreshSessionTokenQueryVariables>;
export const RefreshSessionToken = (({
  operationKind: 'Query',
  document: RefreshSessionTokenDocument,
  primaryResultPath: ['login', 'refreshSession']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: RefreshSessionTokenQueryVariables;
  TResult: RefreshSessionTokenQuery;
  primaryResultPath: string[];
  TPrimaryResult: RefreshSessionTokenQuery['login']['refreshSession'];
});
export const CurrentServerTimeDocument = gql`
	query CurrentServerTime {
		currentTime
	}
`;
export type CurrentServerTimeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentServerTimeQuery, CurrentServerTimeQueryVariables>, 'query'>;
export const CurrentServerTimeComponent = (props: CurrentServerTimeComponentProps) => <ApolloReactComponents.Query<CurrentServerTimeQuery, CurrentServerTimeQueryVariables> query={CurrentServerTimeDocument} {...props} />;
export type CurrentServerTimeProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<CurrentServerTimeQuery, CurrentServerTimeQueryVariables> } & TChildProps;
export function withCurrentServerTime<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CurrentServerTimeQuery, CurrentServerTimeQueryVariables, CurrentServerTimeProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, CurrentServerTimeQuery, CurrentServerTimeQueryVariables, CurrentServerTimeProps<TChildProps, TDataName>>(CurrentServerTimeDocument, {
    alias: 'currentServerTime',
    ...operationOptions
  });
}
export type CurrentServerTimeQueryResult = ApolloReactCommon.QueryResult<CurrentServerTimeQuery, CurrentServerTimeQueryVariables>;
export const CurrentServerTime = (({
  operationKind: 'Query',
  document: CurrentServerTimeDocument,
  primaryResultPath: ['currentTime']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: CurrentServerTimeQueryVariables;
  TResult: CurrentServerTimeQuery;
  primaryResultPath: string[];
  TPrimaryResult: CurrentServerTimeQuery['currentTime'];
});
export const CreateDeviceTokenDocument = gql`
	query CreateDeviceToken($username: String!, $password: String!) {
		login {
			createDeviceToken(username: $username, password: $password) {
				result
				token
			}
		}
	}
`;
export type CreateDeviceTokenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CreateDeviceTokenQuery, CreateDeviceTokenQueryVariables>, 'query'> & ({
  variables: CreateDeviceTokenQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const CreateDeviceTokenComponent = (props: CreateDeviceTokenComponentProps) => <ApolloReactComponents.Query<CreateDeviceTokenQuery, CreateDeviceTokenQueryVariables> query={CreateDeviceTokenDocument} {...props} />;
export type CreateDeviceTokenProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<CreateDeviceTokenQuery, CreateDeviceTokenQueryVariables> } & TChildProps;
export function withCreateDeviceToken<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CreateDeviceTokenQuery, CreateDeviceTokenQueryVariables, CreateDeviceTokenProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, CreateDeviceTokenQuery, CreateDeviceTokenQueryVariables, CreateDeviceTokenProps<TChildProps, TDataName>>(CreateDeviceTokenDocument, {
    alias: 'createDeviceToken',
    ...operationOptions
  });
}
export type CreateDeviceTokenQueryResult = ApolloReactCommon.QueryResult<CreateDeviceTokenQuery, CreateDeviceTokenQueryVariables>;
export const CreateDeviceToken = (({
  operationKind: 'Query',
  document: CreateDeviceTokenDocument,
  primaryResultPath: ['login', 'createDeviceToken']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: CreateDeviceTokenQueryVariables;
  TResult: CreateDeviceTokenQuery;
  primaryResultPath: string[];
  TPrimaryResult: CreateDeviceTokenQuery['login']['createDeviceToken'];
});
export const EvergreenDataChangedDocument = gql`
	subscription EvergreenDataChanged($userId: ID!) {
		userChanged(config: { fields: [EvergreenData], userId: $userId }) {
			id
			evergreenData
		}
	}
`;
export type EvergreenDataChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<EvergreenDataChangedSubscription, EvergreenDataChangedSubscriptionVariables>, 'subscription'>;
export const EvergreenDataChangedComponent = (props: EvergreenDataChangedComponentProps) => <ApolloReactComponents.Subscription<EvergreenDataChangedSubscription, EvergreenDataChangedSubscriptionVariables> subscription={EvergreenDataChangedDocument} {...props} />;
export type EvergreenDataChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<EvergreenDataChangedSubscription, EvergreenDataChangedSubscriptionVariables> } & TChildProps;
export function withEvergreenDataChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EvergreenDataChangedSubscription, EvergreenDataChangedSubscriptionVariables, EvergreenDataChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, EvergreenDataChangedSubscription, EvergreenDataChangedSubscriptionVariables, EvergreenDataChangedProps<TChildProps, TDataName>>(EvergreenDataChangedDocument, {
    alias: 'evergreenDataChanged',
    ...operationOptions
  });
}
export type EvergreenDataChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<EvergreenDataChangedSubscription>;
export const EvergreenDataChanged = (({
  operationKind: 'Subscription',
  document: EvergreenDataChangedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: EvergreenDataChangedSubscriptionVariables;
  TResult: EvergreenDataChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: EvergreenDataChangedSubscription['userChanged'];
});
export const UserIsAdFreeDocument = gql`
	query UserIsAdFree {
		user {
			currentUser {
				...UserWithAdFree
			}
		}
	}
	${UserWithAdFreeFragmentDoc}
`;
export type UserIsAdFreeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserIsAdFreeQuery, UserIsAdFreeQueryVariables>, 'query'>;
export const UserIsAdFreeComponent = (props: UserIsAdFreeComponentProps) => <ApolloReactComponents.Query<UserIsAdFreeQuery, UserIsAdFreeQueryVariables> query={UserIsAdFreeDocument} {...props} />;
export type UserIsAdFreeProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserIsAdFreeQuery, UserIsAdFreeQueryVariables> } & TChildProps;
export function withUserIsAdFree<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserIsAdFreeQuery, UserIsAdFreeQueryVariables, UserIsAdFreeProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, UserIsAdFreeQuery, UserIsAdFreeQueryVariables, UserIsAdFreeProps<TChildProps, TDataName>>(UserIsAdFreeDocument, {
    alias: 'userIsAdFree',
    ...operationOptions
  });
}
export type UserIsAdFreeQueryResult = ApolloReactCommon.QueryResult<UserIsAdFreeQuery, UserIsAdFreeQueryVariables>;
export const UserIsAdFree = (({
  operationKind: 'Query',
  document: UserIsAdFreeDocument,
  primaryResultPath: ['user', 'currentUser']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: UserIsAdFreeQueryVariables;
  TResult: UserIsAdFreeQuery;
  primaryResultPath: string[];
  TPrimaryResult: UserIsAdFreeQuery['user']['currentUser'];
});
export const AdFreeAcquiredDocument = gql`
	subscription AdFreeAcquired {
		isAdFreeChanged {
			...UserWithAdFree
		}
	}
	${UserWithAdFreeFragmentDoc}
`;
export type AdFreeAcquiredComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<AdFreeAcquiredSubscription, AdFreeAcquiredSubscriptionVariables>, 'subscription'>;
export const AdFreeAcquiredComponent = (props: AdFreeAcquiredComponentProps) => <ApolloReactComponents.Subscription<AdFreeAcquiredSubscription, AdFreeAcquiredSubscriptionVariables> subscription={AdFreeAcquiredDocument} {...props} />;
export type AdFreeAcquiredProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<AdFreeAcquiredSubscription, AdFreeAcquiredSubscriptionVariables> } & TChildProps;
export function withAdFreeAcquired<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AdFreeAcquiredSubscription, AdFreeAcquiredSubscriptionVariables, AdFreeAcquiredProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, AdFreeAcquiredSubscription, AdFreeAcquiredSubscriptionVariables, AdFreeAcquiredProps<TChildProps, TDataName>>(AdFreeAcquiredDocument, {
    alias: 'adFreeAcquired',
    ...operationOptions
  });
}
export type AdFreeAcquiredSubscriptionResult = ApolloReactCommon.SubscriptionResult<AdFreeAcquiredSubscription>;
export const AdFreeAcquired = (({
  operationKind: 'Subscription',
  document: AdFreeAcquiredDocument,
  primaryResultPath: ['isAdFreeChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: AdFreeAcquiredSubscriptionVariables;
  TResult: AdFreeAcquiredSubscription;
  primaryResultPath: string[];
  TPrimaryResult: AdFreeAcquiredSubscription['isAdFreeChanged'];
});
export const AppEventsDocument = gql`
	subscription AppEvents {
		appEvent {
			... on AppOpenEvent {
				appId
				channelName
				contentUrl
				display {
					... on AppDisplayHeaderbar {
						height
					}
					... on AppDisplayPopup {
						height
						width
					}
					... on AppDisplayScaled {
						height
						width
					}
					... on AppDisplayChannelBackground {
						unused
					}
					... on AppDisplaySidebar {
						height
						width
					}
				}
				loaderUrl
				pageData
				isUserApp
			}
			... on AppClosedEvent {
				appId
				channelName
			}
			... on AppDataEvent {
				appId
				channelName
				eventKey
				eventValue
			}
			... on AppControlPlaneEvent {
				appId
				channelName
				eventKey
				eventValue
			}
		}
	}
`;
export type AppEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<AppEventsSubscription, AppEventsSubscriptionVariables>, 'subscription'>;
export const AppEventsComponent = (props: AppEventsComponentProps) => <ApolloReactComponents.Subscription<AppEventsSubscription, AppEventsSubscriptionVariables> subscription={AppEventsDocument} {...props} />;
export type AppEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<AppEventsSubscription, AppEventsSubscriptionVariables> } & TChildProps;
export function withAppEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AppEventsSubscription, AppEventsSubscriptionVariables, AppEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, AppEventsSubscription, AppEventsSubscriptionVariables, AppEventsProps<TChildProps, TDataName>>(AppEventsDocument, {
    alias: 'appEvents',
    ...operationOptions
  });
}
export type AppEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<AppEventsSubscription>;
export const AppEvents = (({
  operationKind: 'Subscription',
  document: AppEventsDocument,
  primaryResultPath: ['appEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: AppEventsSubscriptionVariables;
  TResult: AppEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: AppEventsSubscription['appEvent'];
});
export const SendAppDataEventDocument = gql`
	mutation SendAppDataEvent($event: AppDataEventInput!) {
		apps {
			sendAppDataEvent(event: $event) {
				unused
			}
		}
	}
`;
export type SendAppDataEventMutationFn = ApolloReactCommon.MutationFunction<SendAppDataEventMutation, SendAppDataEventMutationVariables>;
export type SendAppDataEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendAppDataEventMutation, SendAppDataEventMutationVariables>, 'mutation'>;
export const SendAppDataEventComponent = (props: SendAppDataEventComponentProps) => <ApolloReactComponents.Mutation<SendAppDataEventMutation, SendAppDataEventMutationVariables> mutation={SendAppDataEventDocument} {...props} />;
export type SendAppDataEventProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendAppDataEventMutation, SendAppDataEventMutationVariables> } & TChildProps;
export function withSendAppDataEvent<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendAppDataEventMutation, SendAppDataEventMutationVariables, SendAppDataEventProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendAppDataEventMutation, SendAppDataEventMutationVariables, SendAppDataEventProps<TChildProps, TDataName>>(SendAppDataEventDocument, {
    alias: 'sendAppDataEvent',
    ...operationOptions
  });
}
export type SendAppDataEventMutationResult = ApolloReactCommon.MutationResult<SendAppDataEventMutation>;
export type SendAppDataEventMutationOptions = ApolloReactCommon.BaseMutationOptions<SendAppDataEventMutation, SendAppDataEventMutationVariables>;
export const SendAppDataEvent = (({
  operationKind: 'Mutation',
  document: SendAppDataEventDocument,
  primaryResultPath: ['apps', 'sendAppDataEvent', 'unused']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendAppDataEventMutationVariables;
  TResult: SendAppDataEventMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendAppDataEventMutation['apps']['sendAppDataEvent']['unused'];
});
export const SendAppControlPlaneEventDocument = gql`
	mutation SendAppControlPlaneEvent($event: AppControlPlaneEventInput!) {
		apps {
			sendAppEvent(event: $event) {
				unused
			}
		}
	}
`;
export type SendAppControlPlaneEventMutationFn = ApolloReactCommon.MutationFunction<SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables>;
export type SendAppControlPlaneEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables>, 'mutation'>;
export const SendAppControlPlaneEventComponent = (props: SendAppControlPlaneEventComponentProps) => <ApolloReactComponents.Mutation<SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables> mutation={SendAppControlPlaneEventDocument} {...props} />;
export type SendAppControlPlaneEventProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables> } & TChildProps;
export function withSendAppControlPlaneEvent<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables, SendAppControlPlaneEventProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables, SendAppControlPlaneEventProps<TChildProps, TDataName>>(SendAppControlPlaneEventDocument, {
    alias: 'sendAppControlPlaneEvent',
    ...operationOptions
  });
}
export type SendAppControlPlaneEventMutationResult = ApolloReactCommon.MutationResult<SendAppControlPlaneEventMutation>;
export type SendAppControlPlaneEventMutationOptions = ApolloReactCommon.BaseMutationOptions<SendAppControlPlaneEventMutation, SendAppControlPlaneEventMutationVariables>;
export const SendAppControlPlaneEvent = (({
  operationKind: 'Mutation',
  document: SendAppControlPlaneEventDocument,
  primaryResultPath: ['apps', 'sendAppEvent', 'unused']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendAppControlPlaneEventMutationVariables;
  TResult: SendAppControlPlaneEventMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendAppControlPlaneEventMutation['apps']['sendAppEvent']['unused'];
});
export const SilenceAppDocument = gql`
	mutation SilenceApp($channel: String!, $days: Int) {
		apps {
			sendSilenceCommand(channel: $channel, days: $days) {
				... on SilenceCommandChannelNotFoundError {
					unused
				}
				... on InternalError {
					unused
				}
				... on Success {
					unused
				}
			}
		}
	}
`;
export type SilenceAppMutationFn = ApolloReactCommon.MutationFunction<SilenceAppMutation, SilenceAppMutationVariables>;
export type SilenceAppComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SilenceAppMutation, SilenceAppMutationVariables>, 'mutation'>;
export const SilenceAppComponent = (props: SilenceAppComponentProps) => <ApolloReactComponents.Mutation<SilenceAppMutation, SilenceAppMutationVariables> mutation={SilenceAppDocument} {...props} />;
export type SilenceAppProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SilenceAppMutation, SilenceAppMutationVariables> } & TChildProps;
export function withSilenceApp<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SilenceAppMutation, SilenceAppMutationVariables, SilenceAppProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SilenceAppMutation, SilenceAppMutationVariables, SilenceAppProps<TChildProps, TDataName>>(SilenceAppDocument, {
    alias: 'silenceApp',
    ...operationOptions
  });
}
export type SilenceAppMutationResult = ApolloReactCommon.MutationResult<SilenceAppMutation>;
export type SilenceAppMutationOptions = ApolloReactCommon.BaseMutationOptions<SilenceAppMutation, SilenceAppMutationVariables>;
export const SilenceApp = (({
  operationKind: 'Mutation',
  document: SilenceAppDocument,
  primaryResultPath: ['apps', 'sendSilenceCommand']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SilenceAppMutationVariables;
  TResult: SilenceAppMutation;
  primaryResultPath: string[];
  TPrimaryResult: SilenceAppMutation['apps']['sendSilenceCommand'];
});
export const RevertSilenceAppDocument = gql`
	mutation RevertSilenceApp($channel: String!) {
		apps {
			sendRevertSilenceCommand(channel: $channel) {
				... on SilenceCommandChannelNotFoundError {
					unused
				}
				... on InternalError {
					unused
				}
				... on Success {
					unused
				}
			}
		}
	}
`;
export type RevertSilenceAppMutationFn = ApolloReactCommon.MutationFunction<RevertSilenceAppMutation, RevertSilenceAppMutationVariables>;
export type RevertSilenceAppComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevertSilenceAppMutation, RevertSilenceAppMutationVariables>, 'mutation'>;
export const RevertSilenceAppComponent = (props: RevertSilenceAppComponentProps) => <ApolloReactComponents.Mutation<RevertSilenceAppMutation, RevertSilenceAppMutationVariables> mutation={RevertSilenceAppDocument} {...props} />;
export type RevertSilenceAppProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<RevertSilenceAppMutation, RevertSilenceAppMutationVariables> } & TChildProps;
export function withRevertSilenceApp<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RevertSilenceAppMutation, RevertSilenceAppMutationVariables, RevertSilenceAppProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, RevertSilenceAppMutation, RevertSilenceAppMutationVariables, RevertSilenceAppProps<TChildProps, TDataName>>(RevertSilenceAppDocument, {
    alias: 'revertSilenceApp',
    ...operationOptions
  });
}
export type RevertSilenceAppMutationResult = ApolloReactCommon.MutationResult<RevertSilenceAppMutation>;
export type RevertSilenceAppMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertSilenceAppMutation, RevertSilenceAppMutationVariables>;
export const RevertSilenceApp = (({
  operationKind: 'Mutation',
  document: RevertSilenceAppDocument,
  primaryResultPath: ['apps', 'sendRevertSilenceCommand']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: RevertSilenceAppMutationVariables;
  TResult: RevertSilenceAppMutation;
  primaryResultPath: string[];
  TPrimaryResult: RevertSilenceAppMutation['apps']['sendRevertSilenceCommand'];
});
export const ChannelGroupsByPrefixDocument = gql`
	query ChannelGroupsByPrefix($prefix: String!) {
		channel {
			channelGroups(prefix: $prefix) {
				...AutocompleteChannelGroup
			}
		}
	}
	${AutocompleteChannelGroupFragmentDoc}
`;
export type ChannelGroupsByPrefixComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ChannelGroupsByPrefixQuery, ChannelGroupsByPrefixQueryVariables>, 'query'> & ({
  variables: ChannelGroupsByPrefixQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const ChannelGroupsByPrefixComponent = (props: ChannelGroupsByPrefixComponentProps) => <ApolloReactComponents.Query<ChannelGroupsByPrefixQuery, ChannelGroupsByPrefixQueryVariables> query={ChannelGroupsByPrefixDocument} {...props} />;
export type ChannelGroupsByPrefixProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ChannelGroupsByPrefixQuery, ChannelGroupsByPrefixQueryVariables> } & TChildProps;
export function withChannelGroupsByPrefix<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChannelGroupsByPrefixQuery, ChannelGroupsByPrefixQueryVariables, ChannelGroupsByPrefixProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ChannelGroupsByPrefixQuery, ChannelGroupsByPrefixQueryVariables, ChannelGroupsByPrefixProps<TChildProps, TDataName>>(ChannelGroupsByPrefixDocument, {
    alias: 'channelGroupsByPrefix',
    ...operationOptions
  });
}
export type ChannelGroupsByPrefixQueryResult = ApolloReactCommon.QueryResult<ChannelGroupsByPrefixQuery, ChannelGroupsByPrefixQueryVariables>;
export const ChannelGroupsByPrefix = (({
  operationKind: 'Query',
  document: ChannelGroupsByPrefixDocument,
  primaryResultPath: ['channel', 'channelGroups']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ChannelGroupsByPrefixQueryVariables;
  TResult: ChannelGroupsByPrefixQuery;
  primaryResultPath: string[];
  TPrimaryResult: ChannelGroupsByPrefixQuery['channel']['channelGroups'];
});
export const InitialChannelAutocompleteDocument = gql`
	query InitialChannelAutocomplete {
		channel {
			initialAutoCompletionChannelGroups {
				...AutocompleteChannelGroup
			}
		}
	}
	${AutocompleteChannelGroupFragmentDoc}
`;
export type InitialChannelAutocompleteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InitialChannelAutocompleteQuery, InitialChannelAutocompleteQueryVariables>, 'query'>;
export const InitialChannelAutocompleteComponent = (props: InitialChannelAutocompleteComponentProps) => <ApolloReactComponents.Query<InitialChannelAutocompleteQuery, InitialChannelAutocompleteQueryVariables> query={InitialChannelAutocompleteDocument} {...props} />;
export type InitialChannelAutocompleteProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<InitialChannelAutocompleteQuery, InitialChannelAutocompleteQueryVariables> } & TChildProps;
export function withInitialChannelAutocomplete<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, InitialChannelAutocompleteQuery, InitialChannelAutocompleteQueryVariables, InitialChannelAutocompleteProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, InitialChannelAutocompleteQuery, InitialChannelAutocompleteQueryVariables, InitialChannelAutocompleteProps<TChildProps, TDataName>>(InitialChannelAutocompleteDocument, {
    alias: 'initialChannelAutocomplete',
    ...operationOptions
  });
}
export type InitialChannelAutocompleteQueryResult = ApolloReactCommon.QueryResult<InitialChannelAutocompleteQuery, InitialChannelAutocompleteQueryVariables>;
export const InitialChannelAutocomplete = (({
  operationKind: 'Query',
  document: InitialChannelAutocompleteDocument,
  primaryResultPath: ['channel', 'initialAutoCompletionChannelGroups']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: InitialChannelAutocompleteQueryVariables;
  TResult: InitialChannelAutocompleteQuery;
  primaryResultPath: string[];
  TPrimaryResult: InitialChannelAutocompleteQuery['channel']['initialAutoCompletionChannelGroups'];
});
export const SendMessageDocument = gql`
	mutation SendMessage($channelId: ID!, $text: String!) {
		channel {
			sendMessage(id: $channelId, text: $text) {
				error
			}
		}
	}
`;
export type SendMessageMutationFn = ApolloReactCommon.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;
export type SendMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendMessageMutation, SendMessageMutationVariables>, 'mutation'>;
export const SendMessageComponent = (props: SendMessageComponentProps) => <ApolloReactComponents.Mutation<SendMessageMutation, SendMessageMutationVariables> mutation={SendMessageDocument} {...props} />;
export type SendMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendMessageMutation, SendMessageMutationVariables> } & TChildProps;
export function withSendMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendMessageMutation, SendMessageMutationVariables, SendMessageProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendMessageMutation, SendMessageMutationVariables, SendMessageProps<TChildProps, TDataName>>(SendMessageDocument, {
    alias: 'sendMessage',
    ...operationOptions
  });
}
export type SendMessageMutationResult = ApolloReactCommon.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const SendMessage = (({
  operationKind: 'Mutation',
  document: SendMessageDocument,
  primaryResultPath: ['channel', 'sendMessage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendMessageMutationVariables;
  TResult: SendMessageMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendMessageMutation['channel']['sendMessage'];
});
export const GetUserForMacroBoxDocument = gql`
	query GetUserForMacroBox($userId: ID!, $pixelDensity: Float!) {
		user {
			user(id: $userId) {
				...MacroBoxUser
			}
		}
	}
	${MacroBoxUserFragmentDoc}
`;
export type GetUserForMacroBoxComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserForMacroBoxQuery, GetUserForMacroBoxQueryVariables>, 'query'> & ({
  variables: GetUserForMacroBoxQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetUserForMacroBoxComponent = (props: GetUserForMacroBoxComponentProps) => <ApolloReactComponents.Query<GetUserForMacroBoxQuery, GetUserForMacroBoxQueryVariables> query={GetUserForMacroBoxDocument} {...props} />;
export type GetUserForMacroBoxProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetUserForMacroBoxQuery, GetUserForMacroBoxQueryVariables> } & TChildProps;
export function withGetUserForMacroBox<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetUserForMacroBoxQuery, GetUserForMacroBoxQueryVariables, GetUserForMacroBoxProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetUserForMacroBoxQuery, GetUserForMacroBoxQueryVariables, GetUserForMacroBoxProps<TChildProps, TDataName>>(GetUserForMacroBoxDocument, {
    alias: 'getUserForMacroBox',
    ...operationOptions
  });
}
export type GetUserForMacroBoxQueryResult = ApolloReactCommon.QueryResult<GetUserForMacroBoxQuery, GetUserForMacroBoxQueryVariables>;
export const GetUserForMacroBox = (({
  operationKind: 'Query',
  document: GetUserForMacroBoxDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetUserForMacroBoxQueryVariables;
  TResult: GetUserForMacroBoxQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetUserForMacroBoxQuery['user']['user'];
});
export const GetCachedUserDataDocument = gql`
	query GetCachedUserData($userId: ID!, $pixelDensity: Float!) {
		user {
			user(id: $userId) {
				id
				nick
				gender
				age
				profilePicture {
					urlCustomSizeSquare(pixelDensity: $pixelDensity, size: 40)
				}
			}
		}
	}
`;
export type GetCachedUserDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCachedUserDataQuery, GetCachedUserDataQueryVariables>, 'query'> & ({
  variables: GetCachedUserDataQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetCachedUserDataComponent = (props: GetCachedUserDataComponentProps) => <ApolloReactComponents.Query<GetCachedUserDataQuery, GetCachedUserDataQueryVariables> query={GetCachedUserDataDocument} {...props} />;
export type GetCachedUserDataProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetCachedUserDataQuery, GetCachedUserDataQueryVariables> } & TChildProps;
export function withGetCachedUserData<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetCachedUserDataQuery, GetCachedUserDataQueryVariables, GetCachedUserDataProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetCachedUserDataQuery, GetCachedUserDataQueryVariables, GetCachedUserDataProps<TChildProps, TDataName>>(GetCachedUserDataDocument, {
    alias: 'getCachedUserData',
    ...operationOptions
  });
}
export type GetCachedUserDataQueryResult = ApolloReactCommon.QueryResult<GetCachedUserDataQuery, GetCachedUserDataQueryVariables>;
export const GetCachedUserData = (({
  operationKind: 'Query',
  document: GetCachedUserDataDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetCachedUserDataQueryVariables;
  TResult: GetCachedUserDataQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetCachedUserDataQuery['user']['user'];
});
export const ChannelEventsDocument = gql`
	subscription ChannelEvents($pixelDensity: Float!) {
		channelEvent {
			... on ChannelMessageReceived {
				channel {
					id
				}
				msg {
					...ChannelMessage
				}
			}
			... on ChannelUserJoined {
				channel {
					id
				}
				participant {
					user {
						...ChannelUser
					}
					nicklistPriority
				}
			}
			... on ChannelUserLeft {
				channel {
					id
				}
				user {
					id
				}
			}
			... on NicklistIconsAdded {
				channel {
					id
				}
				participants {
					user {
						id
					}
					iconsToAdd {
						...NicklistIcon
					}
					nicklistColor
				}
			}
			... on NicklistIconRemoved {
				channel {
					id
				}
				user {
					id
				}
				iconName
			}
			... on ProfilePictureChanged {
				channel {
					id
				}
				user {
					...ProfilePictureUser
				}
			}
			... on ProfilePictureOverlaysChanged {
				channel {
					id
				}
				user {
					id
					profilePictureOverlayUrls {
						...ProfilePictureOverlays
					}
				}
			}
			... on ChannelPasswordRequired {
				channel {
					id
				}
			}
			... on NicklistPriorityChanged {
				channel {
					id
				}
				user {
					id
				}
				nicklistPriority
			}
		}
	}
	${ChannelMessageFragmentDoc}
	${ChannelUserFragmentDoc}
	${NicklistIconFragmentDoc}
	${ProfilePictureUserFragmentDoc}
	${ProfilePictureOverlaysFragmentDoc}
`;
export type ChannelEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ChannelEventsSubscription, ChannelEventsSubscriptionVariables>, 'subscription'>;
export const ChannelEventsComponent = (props: ChannelEventsComponentProps) => <ApolloReactComponents.Subscription<ChannelEventsSubscription, ChannelEventsSubscriptionVariables> subscription={ChannelEventsDocument} {...props} />;
export type ChannelEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ChannelEventsSubscription, ChannelEventsSubscriptionVariables> } & TChildProps;
export function withChannelEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChannelEventsSubscription, ChannelEventsSubscriptionVariables, ChannelEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, ChannelEventsSubscription, ChannelEventsSubscriptionVariables, ChannelEventsProps<TChildProps, TDataName>>(ChannelEventsDocument, {
    alias: 'channelEvents',
    ...operationOptions
  });
}
export type ChannelEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<ChannelEventsSubscription>;
export const ChannelEvents = (({
  operationKind: 'Subscription',
  document: ChannelEventsDocument,
  primaryResultPath: ['channelEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: ChannelEventsSubscriptionVariables;
  TResult: ChannelEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: ChannelEventsSubscription['channelEvent'];
});
export const GetChannelNameDocument = gql`
	query GetChannelName($channelId: ID!) {
		channel {
			channel(id: $channelId) {
				id
				name
			}
		}
	}
`;
export type GetChannelNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelNameQuery, GetChannelNameQueryVariables>, 'query'> & ({
  variables: GetChannelNameQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelNameComponent = (props: GetChannelNameComponentProps) => <ApolloReactComponents.Query<GetChannelNameQuery, GetChannelNameQueryVariables> query={GetChannelNameDocument} {...props} />;
export type GetChannelNameProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelNameQuery, GetChannelNameQueryVariables> } & TChildProps;
export function withGetChannelName<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelNameQuery, GetChannelNameQueryVariables, GetChannelNameProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelNameQuery, GetChannelNameQueryVariables, GetChannelNameProps<TChildProps, TDataName>>(GetChannelNameDocument, {
    alias: 'getChannelName',
    ...operationOptions
  });
}
export type GetChannelNameQueryResult = ApolloReactCommon.QueryResult<GetChannelNameQuery, GetChannelNameQueryVariables>;
export const GetChannelName = (({
  operationKind: 'Query',
  document: GetChannelNameDocument,
  primaryResultPath: ['channel', 'channel']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelNameQueryVariables;
  TResult: GetChannelNameQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelNameQuery['channel']['channel'];
});
export const GetChannelForCurrentSessionDocument = gql`
	query GetChannelForCurrentSession($pixelDensity: Float!) {
		channel {
			channelForCurrentSession {
				...ActiveChannel
			}
		}
	}
	${ActiveChannelFragmentDoc}
`;
export type GetChannelForCurrentSessionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelForCurrentSessionQuery, GetChannelForCurrentSessionQueryVariables>, 'query'> & ({
  variables: GetChannelForCurrentSessionQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelForCurrentSessionComponent = (props: GetChannelForCurrentSessionComponentProps) => <ApolloReactComponents.Query<GetChannelForCurrentSessionQuery, GetChannelForCurrentSessionQueryVariables> query={GetChannelForCurrentSessionDocument} {...props} />;
export type GetChannelForCurrentSessionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelForCurrentSessionQuery, GetChannelForCurrentSessionQueryVariables> } & TChildProps;
export function withGetChannelForCurrentSession<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelForCurrentSessionQuery, GetChannelForCurrentSessionQueryVariables, GetChannelForCurrentSessionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelForCurrentSessionQuery, GetChannelForCurrentSessionQueryVariables, GetChannelForCurrentSessionProps<TChildProps, TDataName>>(GetChannelForCurrentSessionDocument, {
    alias: 'getChannelForCurrentSession',
    ...operationOptions
  });
}
export type GetChannelForCurrentSessionQueryResult = ApolloReactCommon.QueryResult<GetChannelForCurrentSessionQuery, GetChannelForCurrentSessionQueryVariables>;
export const GetChannelForCurrentSession = (({
  operationKind: 'Query',
  document: GetChannelForCurrentSessionDocument,
  primaryResultPath: ['channel', 'channelForCurrentSession']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelForCurrentSessionQueryVariables;
  TResult: GetChannelForCurrentSessionQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelForCurrentSessionQuery['channel']['channelForCurrentSession'];
});
export const GetPreviousSearchesDocument = gql`
	query GetPreviousSearches {
		channel {
			previousSearches
		}
	}
`;
export type GetPreviousSearchesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPreviousSearchesQuery, GetPreviousSearchesQueryVariables>, 'query'>;
export const GetPreviousSearchesComponent = (props: GetPreviousSearchesComponentProps) => <ApolloReactComponents.Query<GetPreviousSearchesQuery, GetPreviousSearchesQueryVariables> query={GetPreviousSearchesDocument} {...props} />;
export type GetPreviousSearchesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetPreviousSearchesQuery, GetPreviousSearchesQueryVariables> } & TChildProps;
export function withGetPreviousSearches<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetPreviousSearchesQuery, GetPreviousSearchesQueryVariables, GetPreviousSearchesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetPreviousSearchesQuery, GetPreviousSearchesQueryVariables, GetPreviousSearchesProps<TChildProps, TDataName>>(GetPreviousSearchesDocument, {
    alias: 'getPreviousSearches',
    ...operationOptions
  });
}
export type GetPreviousSearchesQueryResult = ApolloReactCommon.QueryResult<GetPreviousSearchesQuery, GetPreviousSearchesQueryVariables>;
export const GetPreviousSearches = (({
  operationKind: 'Query',
  document: GetPreviousSearchesDocument,
  primaryResultPath: ['channel', 'previousSearches']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetPreviousSearchesQueryVariables;
  TResult: GetPreviousSearchesQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetPreviousSearchesQuery['channel']['previousSearches'];
});
export const JoinChannelByIdDocument = gql`
	mutation JoinChannelById(
		$channelId: ID!
		$password: String
		$pixelDensity: Float!
		$confirmed: Boolean
		$mayJoinSubChannelIfFull: Boolean
	) {
		channel {
			joinById(
				id: $channelId
				password: $password
				confirmed: $confirmed
				mayJoinSubChannelIfFull: $mayJoinSubChannelIfFull
			) {
				channel {
					...ActiveChannel
				}
				error {
					...ChannelJoinError
				}
			}
		}
	}
	${ActiveChannelFragmentDoc}
	${ChannelJoinErrorFragmentDoc}
`;
export type JoinChannelByIdMutationFn = ApolloReactCommon.MutationFunction<JoinChannelByIdMutation, JoinChannelByIdMutationVariables>;
export type JoinChannelByIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<JoinChannelByIdMutation, JoinChannelByIdMutationVariables>, 'mutation'>;
export const JoinChannelByIdComponent = (props: JoinChannelByIdComponentProps) => <ApolloReactComponents.Mutation<JoinChannelByIdMutation, JoinChannelByIdMutationVariables> mutation={JoinChannelByIdDocument} {...props} />;
export type JoinChannelByIdProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<JoinChannelByIdMutation, JoinChannelByIdMutationVariables> } & TChildProps;
export function withJoinChannelById<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, JoinChannelByIdMutation, JoinChannelByIdMutationVariables, JoinChannelByIdProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, JoinChannelByIdMutation, JoinChannelByIdMutationVariables, JoinChannelByIdProps<TChildProps, TDataName>>(JoinChannelByIdDocument, {
    alias: 'joinChannelById',
    ...operationOptions
  });
}
export type JoinChannelByIdMutationResult = ApolloReactCommon.MutationResult<JoinChannelByIdMutation>;
export type JoinChannelByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinChannelByIdMutation, JoinChannelByIdMutationVariables>;
export const JoinChannelById = (({
  operationKind: 'Mutation',
  document: JoinChannelByIdDocument,
  primaryResultPath: ['channel', 'joinById']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: JoinChannelByIdMutationVariables;
  TResult: JoinChannelByIdMutation;
  primaryResultPath: string[];
  TPrimaryResult: JoinChannelByIdMutation['channel']['joinById'];
});
export const InitialJoinDocument = gql`
	mutation InitialJoin($force: Boolean, $pixelDensity: Float!) {
		channel {
			initialJoin(force: $force) {
				channels {
					...ActiveChannel
				}
				error {
					...ChannelJoinError
				}
			}
		}
	}
	${ActiveChannelFragmentDoc}
	${ChannelJoinErrorFragmentDoc}
`;
export type InitialJoinMutationFn = ApolloReactCommon.MutationFunction<InitialJoinMutation, InitialJoinMutationVariables>;
export type InitialJoinComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InitialJoinMutation, InitialJoinMutationVariables>, 'mutation'>;
export const InitialJoinComponent = (props: InitialJoinComponentProps) => <ApolloReactComponents.Mutation<InitialJoinMutation, InitialJoinMutationVariables> mutation={InitialJoinDocument} {...props} />;
export type InitialJoinProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<InitialJoinMutation, InitialJoinMutationVariables> } & TChildProps;
export function withInitialJoin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, InitialJoinMutation, InitialJoinMutationVariables, InitialJoinProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, InitialJoinMutation, InitialJoinMutationVariables, InitialJoinProps<TChildProps, TDataName>>(InitialJoinDocument, {
    alias: 'initialJoin',
    ...operationOptions
  });
}
export type InitialJoinMutationResult = ApolloReactCommon.MutationResult<InitialJoinMutation>;
export type InitialJoinMutationOptions = ApolloReactCommon.BaseMutationOptions<InitialJoinMutation, InitialJoinMutationVariables>;
export const InitialJoin = (({
  operationKind: 'Mutation',
  document: InitialJoinDocument,
  primaryResultPath: ['channel', 'initialJoin']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: InitialJoinMutationVariables;
  TResult: InitialJoinMutation;
  primaryResultPath: string[];
  TPrimaryResult: InitialJoinMutation['channel']['initialJoin'];
});
export const JoinChannelByGroupIdDocument = gql`
	mutation JoinChannelByGroupId(
		$groupId: ID!
		$password: String
		$adCampaignId: Int
		$pixelDensity: Float!
		$confirmed: Boolean
	) {
		channel {
			joinByGroupId(
				id: $groupId
				password: $password
				adCampaignId: $adCampaignId
				confirmed: $confirmed
			) {
				channel {
					...ActiveChannel
				}
				error {
					...ChannelJoinError
				}
			}
		}
	}
	${ActiveChannelFragmentDoc}
	${ChannelJoinErrorFragmentDoc}
`;
export type JoinChannelByGroupIdMutationFn = ApolloReactCommon.MutationFunction<JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables>;
export type JoinChannelByGroupIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables>, 'mutation'>;
export const JoinChannelByGroupIdComponent = (props: JoinChannelByGroupIdComponentProps) => <ApolloReactComponents.Mutation<JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables> mutation={JoinChannelByGroupIdDocument} {...props} />;
export type JoinChannelByGroupIdProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables> } & TChildProps;
export function withJoinChannelByGroupId<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables, JoinChannelByGroupIdProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables, JoinChannelByGroupIdProps<TChildProps, TDataName>>(JoinChannelByGroupIdDocument, {
    alias: 'joinChannelByGroupId',
    ...operationOptions
  });
}
export type JoinChannelByGroupIdMutationResult = ApolloReactCommon.MutationResult<JoinChannelByGroupIdMutation>;
export type JoinChannelByGroupIdMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinChannelByGroupIdMutation, JoinChannelByGroupIdMutationVariables>;
export const JoinChannelByGroupId = (({
  operationKind: 'Mutation',
  document: JoinChannelByGroupIdDocument,
  primaryResultPath: ['channel', 'joinByGroupId']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: JoinChannelByGroupIdMutationVariables;
  TResult: JoinChannelByGroupIdMutation;
  primaryResultPath: string[];
  TPrimaryResult: JoinChannelByGroupIdMutation['channel']['joinByGroupId'];
});
export const JoinChannelByNameDocument = gql`
	mutation JoinChannelByName(
		$name: String!
		$password: String
		$pixelDensity: Float!
		$confirmed: Boolean
	) {
		channel {
			joinByName(
				name: $name
				password: $password
				confirmed: $confirmed
			) {
				channel {
					...ActiveChannel
				}
				error {
					...ChannelJoinError
				}
			}
		}
	}
	${ActiveChannelFragmentDoc}
	${ChannelJoinErrorFragmentDoc}
`;
export type JoinChannelByNameMutationFn = ApolloReactCommon.MutationFunction<JoinChannelByNameMutation, JoinChannelByNameMutationVariables>;
export type JoinChannelByNameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<JoinChannelByNameMutation, JoinChannelByNameMutationVariables>, 'mutation'>;
export const JoinChannelByNameComponent = (props: JoinChannelByNameComponentProps) => <ApolloReactComponents.Mutation<JoinChannelByNameMutation, JoinChannelByNameMutationVariables> mutation={JoinChannelByNameDocument} {...props} />;
export type JoinChannelByNameProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<JoinChannelByNameMutation, JoinChannelByNameMutationVariables> } & TChildProps;
export function withJoinChannelByName<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, JoinChannelByNameMutation, JoinChannelByNameMutationVariables, JoinChannelByNameProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, JoinChannelByNameMutation, JoinChannelByNameMutationVariables, JoinChannelByNameProps<TChildProps, TDataName>>(JoinChannelByNameDocument, {
    alias: 'joinChannelByName',
    ...operationOptions
  });
}
export type JoinChannelByNameMutationResult = ApolloReactCommon.MutationResult<JoinChannelByNameMutation>;
export type JoinChannelByNameMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinChannelByNameMutation, JoinChannelByNameMutationVariables>;
export const JoinChannelByName = (({
  operationKind: 'Mutation',
  document: JoinChannelByNameDocument,
  primaryResultPath: ['channel', 'joinByName']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: JoinChannelByNameMutationVariables;
  TResult: JoinChannelByNameMutation;
  primaryResultPath: string[];
  TPrimaryResult: JoinChannelByNameMutation['channel']['joinByName'];
});
export const AddPreviousSearchDocument = gql`
	mutation AddPreviousSearch($text: String!) {
		channel {
			addPreviousSearch(text: $text) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type AddPreviousSearchMutationFn = ApolloReactCommon.MutationFunction<AddPreviousSearchMutation, AddPreviousSearchMutationVariables>;
export type AddPreviousSearchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddPreviousSearchMutation, AddPreviousSearchMutationVariables>, 'mutation'>;
export const AddPreviousSearchComponent = (props: AddPreviousSearchComponentProps) => <ApolloReactComponents.Mutation<AddPreviousSearchMutation, AddPreviousSearchMutationVariables> mutation={AddPreviousSearchDocument} {...props} />;
export type AddPreviousSearchProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<AddPreviousSearchMutation, AddPreviousSearchMutationVariables> } & TChildProps;
export function withAddPreviousSearch<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AddPreviousSearchMutation, AddPreviousSearchMutationVariables, AddPreviousSearchProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, AddPreviousSearchMutation, AddPreviousSearchMutationVariables, AddPreviousSearchProps<TChildProps, TDataName>>(AddPreviousSearchDocument, {
    alias: 'addPreviousSearch',
    ...operationOptions
  });
}
export type AddPreviousSearchMutationResult = ApolloReactCommon.MutationResult<AddPreviousSearchMutation>;
export type AddPreviousSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPreviousSearchMutation, AddPreviousSearchMutationVariables>;
export const AddPreviousSearch = (({
  operationKind: 'Mutation',
  document: AddPreviousSearchDocument,
  primaryResultPath: ['channel', 'addPreviousSearch']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: AddPreviousSearchMutationVariables;
  TResult: AddPreviousSearchMutation;
  primaryResultPath: string[];
  TPrimaryResult: AddPreviousSearchMutation['channel']['addPreviousSearch'];
});
export const LeaveChannelDocument = gql`
	mutation LeaveChannel {
		channel {
			leave {
				error
			}
		}
	}
`;
export type LeaveChannelMutationFn = ApolloReactCommon.MutationFunction<LeaveChannelMutation, LeaveChannelMutationVariables>;
export type LeaveChannelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LeaveChannelMutation, LeaveChannelMutationVariables>, 'mutation'>;
export const LeaveChannelComponent = (props: LeaveChannelComponentProps) => <ApolloReactComponents.Mutation<LeaveChannelMutation, LeaveChannelMutationVariables> mutation={LeaveChannelDocument} {...props} />;
export type LeaveChannelProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<LeaveChannelMutation, LeaveChannelMutationVariables> } & TChildProps;
export function withLeaveChannel<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, LeaveChannelMutation, LeaveChannelMutationVariables, LeaveChannelProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, LeaveChannelMutation, LeaveChannelMutationVariables, LeaveChannelProps<TChildProps, TDataName>>(LeaveChannelDocument, {
    alias: 'leaveChannel',
    ...operationOptions
  });
}
export type LeaveChannelMutationResult = ApolloReactCommon.MutationResult<LeaveChannelMutation>;
export type LeaveChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveChannelMutation, LeaveChannelMutationVariables>;
export const LeaveChannel = (({
  operationKind: 'Mutation',
  document: LeaveChannelDocument,
  primaryResultPath: ['channel', 'leave']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: LeaveChannelMutationVariables;
  TResult: LeaveChannelMutation;
  primaryResultPath: string[];
  TPrimaryResult: LeaveChannelMutation['channel']['leave'];
});
export const GetProfilePictureByUserIdDocument = gql`
	query GetProfilePictureByUserId(
		$userId: ID!
		$pixelDensity: Float!
		$size: Int = 40
	) {
		user {
			user(id: $userId) {
				id
				profilePicture {
					urlCustomSizeSquare(
						pixelDensity: $pixelDensity
						size: $size
					)
				}
			}
		}
	}
`;
export type GetProfilePictureByUserIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProfilePictureByUserIdQuery, GetProfilePictureByUserIdQueryVariables>, 'query'> & ({
  variables: GetProfilePictureByUserIdQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetProfilePictureByUserIdComponent = (props: GetProfilePictureByUserIdComponentProps) => <ApolloReactComponents.Query<GetProfilePictureByUserIdQuery, GetProfilePictureByUserIdQueryVariables> query={GetProfilePictureByUserIdDocument} {...props} />;
export type GetProfilePictureByUserIdProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetProfilePictureByUserIdQuery, GetProfilePictureByUserIdQueryVariables> } & TChildProps;
export function withGetProfilePictureByUserId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetProfilePictureByUserIdQuery, GetProfilePictureByUserIdQueryVariables, GetProfilePictureByUserIdProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetProfilePictureByUserIdQuery, GetProfilePictureByUserIdQueryVariables, GetProfilePictureByUserIdProps<TChildProps, TDataName>>(GetProfilePictureByUserIdDocument, {
    alias: 'getProfilePictureByUserId',
    ...operationOptions
  });
}
export type GetProfilePictureByUserIdQueryResult = ApolloReactCommon.QueryResult<GetProfilePictureByUserIdQuery, GetProfilePictureByUserIdQueryVariables>;
export const GetProfilePictureByUserId = (({
  operationKind: 'Query',
  document: GetProfilePictureByUserIdDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetProfilePictureByUserIdQueryVariables;
  TResult: GetProfilePictureByUserIdQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetProfilePictureByUserIdQuery['user']['user'];
});
export const GetUserIsAppBotDocument = gql`
	query GetUserIsAppBot($userId: ID!) {
		user {
			user(id: $userId) {
				id
				isAppBot
			}
		}
	}
`;
export type GetUserIsAppBotComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserIsAppBotQuery, GetUserIsAppBotQueryVariables>, 'query'> & ({
  variables: GetUserIsAppBotQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetUserIsAppBotComponent = (props: GetUserIsAppBotComponentProps) => <ApolloReactComponents.Query<GetUserIsAppBotQuery, GetUserIsAppBotQueryVariables> query={GetUserIsAppBotDocument} {...props} />;
export type GetUserIsAppBotProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetUserIsAppBotQuery, GetUserIsAppBotQueryVariables> } & TChildProps;
export function withGetUserIsAppBot<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetUserIsAppBotQuery, GetUserIsAppBotQueryVariables, GetUserIsAppBotProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetUserIsAppBotQuery, GetUserIsAppBotQueryVariables, GetUserIsAppBotProps<TChildProps, TDataName>>(GetUserIsAppBotDocument, {
    alias: 'getUserIsAppBot',
    ...operationOptions
  });
}
export type GetUserIsAppBotQueryResult = ApolloReactCommon.QueryResult<GetUserIsAppBotQuery, GetUserIsAppBotQueryVariables>;
export const GetUserIsAppBot = (({
  operationKind: 'Query',
  document: GetUserIsAppBotDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetUserIsAppBotQueryVariables;
  TResult: GetUserIsAppBotQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetUserIsAppBotQuery['user']['user'];
});
export const SystemEventsDocument = gql`
	subscription SystemEvents {
		systemEvent {
			... on ClientDisconnected {
				user {
					id
				}
				disconnectReason {
					... on ClientDisconnectReasonUnknown {
						unused
					}
					... on ClientDisconnectReasonNicknameChanged {
						newNick
					}
					... on ClientDisconnectReasonUserDeleted {
						unused
					}
					... on ClientDisconnectReasonUserLocked {
						unused
					}
				}
			}
			... on ChannelConnectionDisconnected {
				newReason: reason
			}
			... on OpenUrl {
				target
				url
			}
		}
	}
`;
export type SystemEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<SystemEventsSubscription, SystemEventsSubscriptionVariables>, 'subscription'>;
export const SystemEventsComponent = (props: SystemEventsComponentProps) => <ApolloReactComponents.Subscription<SystemEventsSubscription, SystemEventsSubscriptionVariables> subscription={SystemEventsDocument} {...props} />;
export type SystemEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<SystemEventsSubscription, SystemEventsSubscriptionVariables> } & TChildProps;
export function withSystemEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SystemEventsSubscription, SystemEventsSubscriptionVariables, SystemEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, SystemEventsSubscription, SystemEventsSubscriptionVariables, SystemEventsProps<TChildProps, TDataName>>(SystemEventsDocument, {
    alias: 'systemEvents',
    ...operationOptions
  });
}
export type SystemEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<SystemEventsSubscription>;
export const SystemEvents = (({
  operationKind: 'Subscription',
  document: SystemEventsDocument,
  primaryResultPath: ['systemEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: SystemEventsSubscriptionVariables;
  TResult: SystemEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: SystemEventsSubscription['systemEvent'];
});
export const ExactMatchChannelSearchDocument = gql`
	query ExactMatchChannelSearch($searchText: String!, $pixelDensity: Float!) {
		channel {
			channelGroup(name: $searchText) {
				...SearchChannelGroup
			}
		}
	}
	${SearchChannelGroupFragmentDoc}
`;
export type ExactMatchChannelSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExactMatchChannelSearchQuery, ExactMatchChannelSearchQueryVariables>, 'query'> & ({
  variables: ExactMatchChannelSearchQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const ExactMatchChannelSearchComponent = (props: ExactMatchChannelSearchComponentProps) => <ApolloReactComponents.Query<ExactMatchChannelSearchQuery, ExactMatchChannelSearchQueryVariables> query={ExactMatchChannelSearchDocument} {...props} />;
export type ExactMatchChannelSearchProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ExactMatchChannelSearchQuery, ExactMatchChannelSearchQueryVariables> } & TChildProps;
export function withExactMatchChannelSearch<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ExactMatchChannelSearchQuery, ExactMatchChannelSearchQueryVariables, ExactMatchChannelSearchProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ExactMatchChannelSearchQuery, ExactMatchChannelSearchQueryVariables, ExactMatchChannelSearchProps<TChildProps, TDataName>>(ExactMatchChannelSearchDocument, {
    alias: 'exactMatchChannelSearch',
    ...operationOptions
  });
}
export type ExactMatchChannelSearchQueryResult = ApolloReactCommon.QueryResult<ExactMatchChannelSearchQuery, ExactMatchChannelSearchQueryVariables>;
export const ExactMatchChannelSearch = (({
  operationKind: 'Query',
  document: ExactMatchChannelSearchDocument,
  primaryResultPath: ['channel', 'channelGroup']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ExactMatchChannelSearchQueryVariables;
  TResult: ExactMatchChannelSearchQuery;
  primaryResultPath: string[];
  TPrimaryResult: ExactMatchChannelSearchQuery['channel']['channelGroup'];
});
export const FilteredChannelGroupsByPrefixAndCategoryDocument = gql`
	query FilteredChannelGroupsByPrefixAndCategory(
		$prefix: String!
		$pixelDensity: Float!
	) {
		channel {
			channelGroups(prefix: $prefix, includeCategory: true) {
				...SearchChannelGroup
			}
		}
	}
	${SearchChannelGroupFragmentDoc}
`;
export type FilteredChannelGroupsByPrefixAndCategoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FilteredChannelGroupsByPrefixAndCategoryQuery, FilteredChannelGroupsByPrefixAndCategoryQueryVariables>, 'query'> & ({
  variables: FilteredChannelGroupsByPrefixAndCategoryQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const FilteredChannelGroupsByPrefixAndCategoryComponent = (props: FilteredChannelGroupsByPrefixAndCategoryComponentProps) => <ApolloReactComponents.Query<FilteredChannelGroupsByPrefixAndCategoryQuery, FilteredChannelGroupsByPrefixAndCategoryQueryVariables> query={FilteredChannelGroupsByPrefixAndCategoryDocument} {...props} />;
export type FilteredChannelGroupsByPrefixAndCategoryProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<FilteredChannelGroupsByPrefixAndCategoryQuery, FilteredChannelGroupsByPrefixAndCategoryQueryVariables> } & TChildProps;
export function withFilteredChannelGroupsByPrefixAndCategory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FilteredChannelGroupsByPrefixAndCategoryQuery, FilteredChannelGroupsByPrefixAndCategoryQueryVariables, FilteredChannelGroupsByPrefixAndCategoryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, FilteredChannelGroupsByPrefixAndCategoryQuery, FilteredChannelGroupsByPrefixAndCategoryQueryVariables, FilteredChannelGroupsByPrefixAndCategoryProps<TChildProps, TDataName>>(FilteredChannelGroupsByPrefixAndCategoryDocument, {
    alias: 'filteredChannelGroupsByPrefixAndCategory',
    ...operationOptions
  });
}
export type FilteredChannelGroupsByPrefixAndCategoryQueryResult = ApolloReactCommon.QueryResult<FilteredChannelGroupsByPrefixAndCategoryQuery, FilteredChannelGroupsByPrefixAndCategoryQueryVariables>;
export const FilteredChannelGroupsByPrefixAndCategory = (({
  operationKind: 'Query',
  document: FilteredChannelGroupsByPrefixAndCategoryDocument,
  primaryResultPath: ['channel', 'channelGroups']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: FilteredChannelGroupsByPrefixAndCategoryQueryVariables;
  TResult: FilteredChannelGroupsByPrefixAndCategoryQuery;
  primaryResultPath: string[];
  TPrimaryResult: FilteredChannelGroupsByPrefixAndCategoryQuery['channel']['channelGroups'];
});
export const GetChannelListOverviewDocument = gql`
	query GetChannelListOverview($groupAmount: Int!, $pixelDensity: Float!) {
		channel {
			channelAds(limit: 3) {
				...ChannelAd
			}
			categories {
				...ChannelCategory
			}
		}
	}
	${ChannelAdFragmentDoc}
	${ChannelCategoryFragmentDoc}
`;
export type GetChannelListOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelListOverviewQuery, GetChannelListOverviewQueryVariables>, 'query'> & ({
  variables: GetChannelListOverviewQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelListOverviewComponent = (props: GetChannelListOverviewComponentProps) => <ApolloReactComponents.Query<GetChannelListOverviewQuery, GetChannelListOverviewQueryVariables> query={GetChannelListOverviewDocument} {...props} />;
export type GetChannelListOverviewProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelListOverviewQuery, GetChannelListOverviewQueryVariables> } & TChildProps;
export function withGetChannelListOverview<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelListOverviewQuery, GetChannelListOverviewQueryVariables, GetChannelListOverviewProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelListOverviewQuery, GetChannelListOverviewQueryVariables, GetChannelListOverviewProps<TChildProps, TDataName>>(GetChannelListOverviewDocument, {
    alias: 'getChannelListOverview',
    ...operationOptions
  });
}
export type GetChannelListOverviewQueryResult = ApolloReactCommon.QueryResult<GetChannelListOverviewQuery, GetChannelListOverviewQueryVariables>;
export const GetChannelListOverview = (({
  operationKind: 'Query',
  document: GetChannelListOverviewDocument,
  primaryResultPath: ['channel']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelListOverviewQueryVariables;
  TResult: GetChannelListOverviewQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelListOverviewQuery['channel'];
});
export const GetRecommendedChannelsDocument = gql`
	query GetRecommendedChannels($groupAmount: Int!, $pixelDensity: Float!) {
		channel {
			recommendedCategories {
				lastVisited {
					...ChannelCategory
				}
				popular {
					...ChannelCategory
				}
			}
		}
	}
	${ChannelCategoryFragmentDoc}
`;
export type GetRecommendedChannelsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRecommendedChannelsQuery, GetRecommendedChannelsQueryVariables>, 'query'> & ({
  variables: GetRecommendedChannelsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetRecommendedChannelsComponent = (props: GetRecommendedChannelsComponentProps) => <ApolloReactComponents.Query<GetRecommendedChannelsQuery, GetRecommendedChannelsQueryVariables> query={GetRecommendedChannelsDocument} {...props} />;
export type GetRecommendedChannelsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetRecommendedChannelsQuery, GetRecommendedChannelsQueryVariables> } & TChildProps;
export function withGetRecommendedChannels<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetRecommendedChannelsQuery, GetRecommendedChannelsQueryVariables, GetRecommendedChannelsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetRecommendedChannelsQuery, GetRecommendedChannelsQueryVariables, GetRecommendedChannelsProps<TChildProps, TDataName>>(GetRecommendedChannelsDocument, {
    alias: 'getRecommendedChannels',
    ...operationOptions
  });
}
export type GetRecommendedChannelsQueryResult = ApolloReactCommon.QueryResult<GetRecommendedChannelsQuery, GetRecommendedChannelsQueryVariables>;
export const GetRecommendedChannels = (({
  operationKind: 'Query',
  document: GetRecommendedChannelsDocument,
  primaryResultPath: ['channel', 'recommendedCategories']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetRecommendedChannelsQueryVariables;
  TResult: GetRecommendedChannelsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetRecommendedChannelsQuery['channel']['recommendedCategories'];
});
export const GetChannelCategoriesDocument = gql`
	query GetChannelCategories($groupAmount: Int!, $pixelDensity: Float!) {
		channel {
			categories {
				...ChannelCategory
			}
		}
	}
	${ChannelCategoryFragmentDoc}
`;
export type GetChannelCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelCategoriesQuery, GetChannelCategoriesQueryVariables>, 'query'> & ({
  variables: GetChannelCategoriesQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelCategoriesComponent = (props: GetChannelCategoriesComponentProps) => <ApolloReactComponents.Query<GetChannelCategoriesQuery, GetChannelCategoriesQueryVariables> query={GetChannelCategoriesDocument} {...props} />;
export type GetChannelCategoriesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelCategoriesQuery, GetChannelCategoriesQueryVariables> } & TChildProps;
export function withGetChannelCategories<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelCategoriesQuery, GetChannelCategoriesQueryVariables, GetChannelCategoriesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelCategoriesQuery, GetChannelCategoriesQueryVariables, GetChannelCategoriesProps<TChildProps, TDataName>>(GetChannelCategoriesDocument, {
    alias: 'getChannelCategories',
    ...operationOptions
  });
}
export type GetChannelCategoriesQueryResult = ApolloReactCommon.QueryResult<GetChannelCategoriesQuery, GetChannelCategoriesQueryVariables>;
export const GetChannelCategories = (({
  operationKind: 'Query',
  document: GetChannelCategoriesDocument,
  primaryResultPath: ['channel', 'categories']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelCategoriesQueryVariables;
  TResult: GetChannelCategoriesQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelCategoriesQuery['channel']['categories'];
});
export const GetGameChannelsDocument = gql`
	query GetGameChannels($groupAmount: Int!, $pixelDensity: Float!) {
		channel {
			gameCategories {
				top {
					...TopChannelCategory
				}
				all {
					...ChannelCategory
				}
			}
		}
	}
	${TopChannelCategoryFragmentDoc}
	${ChannelCategoryFragmentDoc}
`;
export type GetGameChannelsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetGameChannelsQuery, GetGameChannelsQueryVariables>, 'query'> & ({
  variables: GetGameChannelsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetGameChannelsComponent = (props: GetGameChannelsComponentProps) => <ApolloReactComponents.Query<GetGameChannelsQuery, GetGameChannelsQueryVariables> query={GetGameChannelsDocument} {...props} />;
export type GetGameChannelsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetGameChannelsQuery, GetGameChannelsQueryVariables> } & TChildProps;
export function withGetGameChannels<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetGameChannelsQuery, GetGameChannelsQueryVariables, GetGameChannelsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetGameChannelsQuery, GetGameChannelsQueryVariables, GetGameChannelsProps<TChildProps, TDataName>>(GetGameChannelsDocument, {
    alias: 'getGameChannels',
    ...operationOptions
  });
}
export type GetGameChannelsQueryResult = ApolloReactCommon.QueryResult<GetGameChannelsQuery, GetGameChannelsQueryVariables>;
export const GetGameChannels = (({
  operationKind: 'Query',
  document: GetGameChannelsDocument,
  primaryResultPath: ['channel', 'gameCategories']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetGameChannelsQueryVariables;
  TResult: GetGameChannelsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetGameChannelsQuery['channel']['gameCategories'];
});
export const GetChannelListCategoryHeaderDocument = gql`
	query GetChannelListCategoryHeader($categoryId: ID!) {
		channel {
			category(id: $categoryId) {
				id
				name
			}
		}
	}
`;
export type GetChannelListCategoryHeaderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelListCategoryHeaderQuery, GetChannelListCategoryHeaderQueryVariables>, 'query'> & ({
  variables: GetChannelListCategoryHeaderQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelListCategoryHeaderComponent = (props: GetChannelListCategoryHeaderComponentProps) => <ApolloReactComponents.Query<GetChannelListCategoryHeaderQuery, GetChannelListCategoryHeaderQueryVariables> query={GetChannelListCategoryHeaderDocument} {...props} />;
export type GetChannelListCategoryHeaderProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelListCategoryHeaderQuery, GetChannelListCategoryHeaderQueryVariables> } & TChildProps;
export function withGetChannelListCategoryHeader<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelListCategoryHeaderQuery, GetChannelListCategoryHeaderQueryVariables, GetChannelListCategoryHeaderProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelListCategoryHeaderQuery, GetChannelListCategoryHeaderQueryVariables, GetChannelListCategoryHeaderProps<TChildProps, TDataName>>(GetChannelListCategoryHeaderDocument, {
    alias: 'getChannelListCategoryHeader',
    ...operationOptions
  });
}
export type GetChannelListCategoryHeaderQueryResult = ApolloReactCommon.QueryResult<GetChannelListCategoryHeaderQuery, GetChannelListCategoryHeaderQueryVariables>;
export const GetChannelListCategoryHeader = (({
  operationKind: 'Query',
  document: GetChannelListCategoryHeaderDocument,
  primaryResultPath: ['channel', 'category']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelListCategoryHeaderQueryVariables;
  TResult: GetChannelListCategoryHeaderQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelListCategoryHeaderQuery['channel']['category'];
});
export const GetChannelListCategoryDocument = gql`
	query GetChannelListCategory(
		$categoryId: ID!
		$groupAmount: Int!
		$pixelDensity: Float!
	) {
		channel {
			category(id: $categoryId) {
				...ChannelCategory
			}
		}
	}
	${ChannelCategoryFragmentDoc}
`;
export type GetChannelListCategoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelListCategoryQuery, GetChannelListCategoryQueryVariables>, 'query'> & ({
  variables: GetChannelListCategoryQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelListCategoryComponent = (props: GetChannelListCategoryComponentProps) => <ApolloReactComponents.Query<GetChannelListCategoryQuery, GetChannelListCategoryQueryVariables> query={GetChannelListCategoryDocument} {...props} />;
export type GetChannelListCategoryProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelListCategoryQuery, GetChannelListCategoryQueryVariables> } & TChildProps;
export function withGetChannelListCategory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelListCategoryQuery, GetChannelListCategoryQueryVariables, GetChannelListCategoryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelListCategoryQuery, GetChannelListCategoryQueryVariables, GetChannelListCategoryProps<TChildProps, TDataName>>(GetChannelListCategoryDocument, {
    alias: 'getChannelListCategory',
    ...operationOptions
  });
}
export type GetChannelListCategoryQueryResult = ApolloReactCommon.QueryResult<GetChannelListCategoryQuery, GetChannelListCategoryQueryVariables>;
export const GetChannelListCategory = (({
  operationKind: 'Query',
  document: GetChannelListCategoryDocument,
  primaryResultPath: ['channel', 'category']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelListCategoryQueryVariables;
  TResult: GetChannelListCategoryQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelListCategoryQuery['channel']['category'];
});
export const GetChannelGroupsDocument = gql`
	query GetChannelGroups($ids: [ID!]!, $pixelDensity: Float!) {
		channel {
			channelGroupsByIds(ids: $ids) {
				...ChannelGroup
			}
		}
	}
	${ChannelGroupFragmentDoc}
`;
export type GetChannelGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChannelGroupsQuery, GetChannelGroupsQueryVariables>, 'query'> & ({
  variables: GetChannelGroupsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetChannelGroupsComponent = (props: GetChannelGroupsComponentProps) => <ApolloReactComponents.Query<GetChannelGroupsQuery, GetChannelGroupsQueryVariables> query={GetChannelGroupsDocument} {...props} />;
export type GetChannelGroupsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetChannelGroupsQuery, GetChannelGroupsQueryVariables> } & TChildProps;
export function withGetChannelGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetChannelGroupsQuery, GetChannelGroupsQueryVariables, GetChannelGroupsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetChannelGroupsQuery, GetChannelGroupsQueryVariables, GetChannelGroupsProps<TChildProps, TDataName>>(GetChannelGroupsDocument, {
    alias: 'getChannelGroups',
    ...operationOptions
  });
}
export type GetChannelGroupsQueryResult = ApolloReactCommon.QueryResult<GetChannelGroupsQuery, GetChannelGroupsQueryVariables>;
export const GetChannelGroups = (({
  operationKind: 'Query',
  document: GetChannelGroupsDocument,
  primaryResultPath: ['channel', 'channelGroupsByIds']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetChannelGroupsQueryVariables;
  TResult: GetChannelGroupsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetChannelGroupsQuery['channel']['channelGroupsByIds'];
});
export const GetDashboardChannelGroupsDocument = gql`
	query GetDashboardChannelGroups($pixelDensity: Float!) {
		channel {
			dashboardChannelGroups {
				...ChannelGroup
			}
		}
	}
	${ChannelGroupFragmentDoc}
`;
export type GetDashboardChannelGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDashboardChannelGroupsQuery, GetDashboardChannelGroupsQueryVariables>, 'query'> & ({
  variables: GetDashboardChannelGroupsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetDashboardChannelGroupsComponent = (props: GetDashboardChannelGroupsComponentProps) => <ApolloReactComponents.Query<GetDashboardChannelGroupsQuery, GetDashboardChannelGroupsQueryVariables> query={GetDashboardChannelGroupsDocument} {...props} />;
export type GetDashboardChannelGroupsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetDashboardChannelGroupsQuery, GetDashboardChannelGroupsQueryVariables> } & TChildProps;
export function withGetDashboardChannelGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetDashboardChannelGroupsQuery, GetDashboardChannelGroupsQueryVariables, GetDashboardChannelGroupsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetDashboardChannelGroupsQuery, GetDashboardChannelGroupsQueryVariables, GetDashboardChannelGroupsProps<TChildProps, TDataName>>(GetDashboardChannelGroupsDocument, {
    alias: 'getDashboardChannelGroups',
    ...operationOptions
  });
}
export type GetDashboardChannelGroupsQueryResult = ApolloReactCommon.QueryResult<GetDashboardChannelGroupsQuery, GetDashboardChannelGroupsQueryVariables>;
export const GetDashboardChannelGroups = (({
  operationKind: 'Query',
  document: GetDashboardChannelGroupsDocument,
  primaryResultPath: ['channel', 'dashboardChannelGroups']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetDashboardChannelGroupsQueryVariables;
  TResult: GetDashboardChannelGroupsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetDashboardChannelGroupsQuery['channel']['dashboardChannelGroups'];
});
export const ContactsUserByNickDocument = gql`
	query ContactsUserByNick(
		$nick: String!
		$pixelDensity: Float!
		$accountForNickSwitch: Boolean
	) {
		user {
			userFromNick(
				nick: $nick
				accountForNickSwitch: $accountForNickSwitch
			) {
				...ContactsUser
			}
		}
	}
	${ContactsUserFragmentDoc}
`;
export type ContactsUserByNickComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ContactsUserByNickQuery, ContactsUserByNickQueryVariables>, 'query'> & ({
  variables: ContactsUserByNickQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const ContactsUserByNickComponent = (props: ContactsUserByNickComponentProps) => <ApolloReactComponents.Query<ContactsUserByNickQuery, ContactsUserByNickQueryVariables> query={ContactsUserByNickDocument} {...props} />;
export type ContactsUserByNickProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ContactsUserByNickQuery, ContactsUserByNickQueryVariables> } & TChildProps;
export function withContactsUserByNick<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ContactsUserByNickQuery, ContactsUserByNickQueryVariables, ContactsUserByNickProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ContactsUserByNickQuery, ContactsUserByNickQueryVariables, ContactsUserByNickProps<TChildProps, TDataName>>(ContactsUserByNickDocument, {
    alias: 'contactsUserByNick',
    ...operationOptions
  });
}
export type ContactsUserByNickQueryResult = ApolloReactCommon.QueryResult<ContactsUserByNickQuery, ContactsUserByNickQueryVariables>;
export const ContactsUserByNick = (({
  operationKind: 'Query',
  document: ContactsUserByNickDocument,
  primaryResultPath: ['user', 'userFromNick']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ContactsUserByNickQueryVariables;
  TResult: ContactsUserByNickQuery;
  primaryResultPath: string[];
  TPrimaryResult: ContactsUserByNickQuery['user']['userFromNick'];
});
export const GetContactsDocument = gql`
	query GetContacts($filter: ContactListFilter, $pixelDensity: Float!) {
		user {
			contactList(filter: $filter) {
				contacts {
					...ContactsUser
				}
			}
		}
	}
	${ContactsUserFragmentDoc}
`;
export type GetContactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContactsQuery, GetContactsQueryVariables>, 'query'> & ({
  variables: GetContactsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetContactsComponent = (props: GetContactsComponentProps) => <ApolloReactComponents.Query<GetContactsQuery, GetContactsQueryVariables> query={GetContactsDocument} {...props} />;
export type GetContactsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetContactsQuery, GetContactsQueryVariables> } & TChildProps;
export function withGetContacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetContactsQuery, GetContactsQueryVariables, GetContactsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetContactsQuery, GetContactsQueryVariables, GetContactsProps<TChildProps, TDataName>>(GetContactsDocument, {
    alias: 'getContacts',
    ...operationOptions
  });
}
export type GetContactsQueryResult = ApolloReactCommon.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetContacts = (({
  operationKind: 'Query',
  document: GetContactsDocument,
  primaryResultPath: ['user', 'contactList', 'contacts']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetContactsQueryVariables;
  TResult: GetContactsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetContactsQuery['user']['contactList']['contacts'];
});
export const FriendRequestsDocument = gql`
	query FriendRequests($pixelDensity: Float!) {
		contacts {
			sentFriendRequests {
				...FriendRequestUser
			}
			receivedFriendRequests {
				...FriendRequestUser
			}
		}
	}
	${FriendRequestUserFragmentDoc}
`;
export type FriendRequestsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FriendRequestsQuery, FriendRequestsQueryVariables>, 'query'> & ({
  variables: FriendRequestsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const FriendRequestsComponent = (props: FriendRequestsComponentProps) => <ApolloReactComponents.Query<FriendRequestsQuery, FriendRequestsQueryVariables> query={FriendRequestsDocument} {...props} />;
export type FriendRequestsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<FriendRequestsQuery, FriendRequestsQueryVariables> } & TChildProps;
export function withFriendRequests<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FriendRequestsQuery, FriendRequestsQueryVariables, FriendRequestsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, FriendRequestsQuery, FriendRequestsQueryVariables, FriendRequestsProps<TChildProps, TDataName>>(FriendRequestsDocument, {
    alias: 'friendRequests',
    ...operationOptions
  });
}
export type FriendRequestsQueryResult = ApolloReactCommon.QueryResult<FriendRequestsQuery, FriendRequestsQueryVariables>;
export const FriendRequests = (({
  operationKind: 'Query',
  document: FriendRequestsDocument,
  primaryResultPath: ['contacts']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: FriendRequestsQueryVariables;
  TResult: FriendRequestsQuery;
  primaryResultPath: string[];
  TPrimaryResult: FriendRequestsQuery['contacts'];
});
export const FriendRequestEventsDocument = gql`
	subscription FriendRequestEvents($pixelDensity: Float!) {
		contactsEvent {
			... on SentFriendRequestAddedEvent {
				user {
					...FriendRequestUser
				}
			}
			... on SentFriendRequestRemovedEvent {
				user {
					...FriendRequestUser
				}
			}
			... on ReceivedFriendRequestAddedEvent {
				user {
					...FriendRequestUser
				}
			}
			... on ReceivedFriendRequestRemovedEvent {
				user {
					...FriendRequestUser
				}
			}
		}
	}
	${FriendRequestUserFragmentDoc}
`;
export type FriendRequestEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<FriendRequestEventsSubscription, FriendRequestEventsSubscriptionVariables>, 'subscription'>;
export const FriendRequestEventsComponent = (props: FriendRequestEventsComponentProps) => <ApolloReactComponents.Subscription<FriendRequestEventsSubscription, FriendRequestEventsSubscriptionVariables> subscription={FriendRequestEventsDocument} {...props} />;
export type FriendRequestEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<FriendRequestEventsSubscription, FriendRequestEventsSubscriptionVariables> } & TChildProps;
export function withFriendRequestEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FriendRequestEventsSubscription, FriendRequestEventsSubscriptionVariables, FriendRequestEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, FriendRequestEventsSubscription, FriendRequestEventsSubscriptionVariables, FriendRequestEventsProps<TChildProps, TDataName>>(FriendRequestEventsDocument, {
    alias: 'friendRequestEvents',
    ...operationOptions
  });
}
export type FriendRequestEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<FriendRequestEventsSubscription>;
export const FriendRequestEvents = (({
  operationKind: 'Subscription',
  document: FriendRequestEventsDocument,
  primaryResultPath: ['contactsEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: FriendRequestEventsSubscriptionVariables;
  TResult: FriendRequestEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: FriendRequestEventsSubscription['contactsEvent'];
});
export const FriendStateChangedEventDocument = gql`
	subscription FriendStateChangedEvent {
		friendStateChanged {
			id
			friendState
		}
	}
`;
export type FriendStateChangedEventComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<FriendStateChangedEventSubscription, FriendStateChangedEventSubscriptionVariables>, 'subscription'>;
export const FriendStateChangedEventComponent = (props: FriendStateChangedEventComponentProps) => <ApolloReactComponents.Subscription<FriendStateChangedEventSubscription, FriendStateChangedEventSubscriptionVariables> subscription={FriendStateChangedEventDocument} {...props} />;
export type FriendStateChangedEventProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<FriendStateChangedEventSubscription, FriendStateChangedEventSubscriptionVariables> } & TChildProps;
export function withFriendStateChangedEvent<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FriendStateChangedEventSubscription, FriendStateChangedEventSubscriptionVariables, FriendStateChangedEventProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, FriendStateChangedEventSubscription, FriendStateChangedEventSubscriptionVariables, FriendStateChangedEventProps<TChildProps, TDataName>>(FriendStateChangedEventDocument, {
    alias: 'friendStateChangedEvent',
    ...operationOptions
  });
}
export type FriendStateChangedEventSubscriptionResult = ApolloReactCommon.SubscriptionResult<FriendStateChangedEventSubscription>;
export const FriendStateChangedEvent = (({
  operationKind: 'Subscription',
  document: FriendStateChangedEventDocument,
  primaryResultPath: ['friendStateChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: FriendStateChangedEventSubscriptionVariables;
  TResult: FriendStateChangedEventSubscription;
  primaryResultPath: string[];
  TPrimaryResult: FriendStateChangedEventSubscription['friendStateChanged'];
});
export const CancelFriendRequestDocument = gql`
	mutation CancelFriendRequest($userId: ID!) {
		contacts {
			cancelFriendRequest(userId: $userId) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type CancelFriendRequestMutationFn = ApolloReactCommon.MutationFunction<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>;
export type CancelFriendRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>, 'mutation'>;
export const CancelFriendRequestComponent = (props: CancelFriendRequestComponentProps) => <ApolloReactComponents.Mutation<CancelFriendRequestMutation, CancelFriendRequestMutationVariables> mutation={CancelFriendRequestDocument} {...props} />;
export type CancelFriendRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<CancelFriendRequestMutation, CancelFriendRequestMutationVariables> } & TChildProps;
export function withCancelFriendRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CancelFriendRequestMutation, CancelFriendRequestMutationVariables, CancelFriendRequestProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, CancelFriendRequestMutation, CancelFriendRequestMutationVariables, CancelFriendRequestProps<TChildProps, TDataName>>(CancelFriendRequestDocument, {
    alias: 'cancelFriendRequest',
    ...operationOptions
  });
}
export type CancelFriendRequestMutationResult = ApolloReactCommon.MutationResult<CancelFriendRequestMutation>;
export type CancelFriendRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>;
export const CancelFriendRequest = (({
  operationKind: 'Mutation',
  document: CancelFriendRequestDocument,
  primaryResultPath: ['contacts', 'cancelFriendRequest']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: CancelFriendRequestMutationVariables;
  TResult: CancelFriendRequestMutation;
  primaryResultPath: string[];
  TPrimaryResult: CancelFriendRequestMutation['contacts']['cancelFriendRequest'];
});
export const CancelFriendshipDocument = gql`
	mutation CancelFriendship($userId: ID!) {
		contacts {
			cancelFriendship(userId: $userId) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type CancelFriendshipMutationFn = ApolloReactCommon.MutationFunction<CancelFriendshipMutation, CancelFriendshipMutationVariables>;
export type CancelFriendshipComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelFriendshipMutation, CancelFriendshipMutationVariables>, 'mutation'>;
export const CancelFriendshipComponent = (props: CancelFriendshipComponentProps) => <ApolloReactComponents.Mutation<CancelFriendshipMutation, CancelFriendshipMutationVariables> mutation={CancelFriendshipDocument} {...props} />;
export type CancelFriendshipProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<CancelFriendshipMutation, CancelFriendshipMutationVariables> } & TChildProps;
export function withCancelFriendship<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CancelFriendshipMutation, CancelFriendshipMutationVariables, CancelFriendshipProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, CancelFriendshipMutation, CancelFriendshipMutationVariables, CancelFriendshipProps<TChildProps, TDataName>>(CancelFriendshipDocument, {
    alias: 'cancelFriendship',
    ...operationOptions
  });
}
export type CancelFriendshipMutationResult = ApolloReactCommon.MutationResult<CancelFriendshipMutation>;
export type CancelFriendshipMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelFriendshipMutation, CancelFriendshipMutationVariables>;
export const CancelFriendship = (({
  operationKind: 'Mutation',
  document: CancelFriendshipDocument,
  primaryResultPath: ['contacts', 'cancelFriendship']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: CancelFriendshipMutationVariables;
  TResult: CancelFriendshipMutation;
  primaryResultPath: string[];
  TPrimaryResult: CancelFriendshipMutation['contacts']['cancelFriendship'];
});
export const AcceptFriendRequestDocument = gql`
	mutation AcceptFriendRequest($userId: ID!) {
		contacts {
			acceptFriendRequest(userId: $userId) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type AcceptFriendRequestMutationFn = ApolloReactCommon.MutationFunction<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>;
export type AcceptFriendRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>, 'mutation'>;
export const AcceptFriendRequestComponent = (props: AcceptFriendRequestComponentProps) => <ApolloReactComponents.Mutation<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables> mutation={AcceptFriendRequestDocument} {...props} />;
export type AcceptFriendRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables> } & TChildProps;
export function withAcceptFriendRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables, AcceptFriendRequestProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables, AcceptFriendRequestProps<TChildProps, TDataName>>(AcceptFriendRequestDocument, {
    alias: 'acceptFriendRequest',
    ...operationOptions
  });
}
export type AcceptFriendRequestMutationResult = ApolloReactCommon.MutationResult<AcceptFriendRequestMutation>;
export type AcceptFriendRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>;
export const AcceptFriendRequest = (({
  operationKind: 'Mutation',
  document: AcceptFriendRequestDocument,
  primaryResultPath: ['contacts', 'acceptFriendRequest']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: AcceptFriendRequestMutationVariables;
  TResult: AcceptFriendRequestMutation;
  primaryResultPath: string[];
  TPrimaryResult: AcceptFriendRequestMutation['contacts']['acceptFriendRequest'];
});
export const DeclineFriendRequestDocument = gql`
	mutation DeclineFriendRequest($userId: ID!) {
		contacts {
			declineFriendRequest(userId: $userId) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DeclineFriendRequestMutationFn = ApolloReactCommon.MutationFunction<DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables>;
export type DeclineFriendRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables>, 'mutation'>;
export const DeclineFriendRequestComponent = (props: DeclineFriendRequestComponentProps) => <ApolloReactComponents.Mutation<DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables> mutation={DeclineFriendRequestDocument} {...props} />;
export type DeclineFriendRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables> } & TChildProps;
export function withDeclineFriendRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables, DeclineFriendRequestProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables, DeclineFriendRequestProps<TChildProps, TDataName>>(DeclineFriendRequestDocument, {
    alias: 'declineFriendRequest',
    ...operationOptions
  });
}
export type DeclineFriendRequestMutationResult = ApolloReactCommon.MutationResult<DeclineFriendRequestMutation>;
export type DeclineFriendRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclineFriendRequestMutation, DeclineFriendRequestMutationVariables>;
export const DeclineFriendRequest = (({
  operationKind: 'Mutation',
  document: DeclineFriendRequestDocument,
  primaryResultPath: ['contacts', 'declineFriendRequest']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DeclineFriendRequestMutationVariables;
  TResult: DeclineFriendRequestMutation;
  primaryResultPath: string[];
  TPrimaryResult: DeclineFriendRequestMutation['contacts']['declineFriendRequest'];
});
export const AddToWatchlistDocument = gql`
	mutation AddToWatchlist($userId: ID!) {
		contacts {
			addToWatchlist(userId: $userId) {
				... on Success {
					unused
				}
				... on WatchlistLimitReachedError {
					limit
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type AddToWatchlistMutationFn = ApolloReactCommon.MutationFunction<AddToWatchlistMutation, AddToWatchlistMutationVariables>;
export type AddToWatchlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddToWatchlistMutation, AddToWatchlistMutationVariables>, 'mutation'>;
export const AddToWatchlistComponent = (props: AddToWatchlistComponentProps) => <ApolloReactComponents.Mutation<AddToWatchlistMutation, AddToWatchlistMutationVariables> mutation={AddToWatchlistDocument} {...props} />;
export type AddToWatchlistProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<AddToWatchlistMutation, AddToWatchlistMutationVariables> } & TChildProps;
export function withAddToWatchlist<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AddToWatchlistMutation, AddToWatchlistMutationVariables, AddToWatchlistProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, AddToWatchlistMutation, AddToWatchlistMutationVariables, AddToWatchlistProps<TChildProps, TDataName>>(AddToWatchlistDocument, {
    alias: 'addToWatchlist',
    ...operationOptions
  });
}
export type AddToWatchlistMutationResult = ApolloReactCommon.MutationResult<AddToWatchlistMutation>;
export type AddToWatchlistMutationOptions = ApolloReactCommon.BaseMutationOptions<AddToWatchlistMutation, AddToWatchlistMutationVariables>;
export const AddToWatchlist = (({
  operationKind: 'Mutation',
  document: AddToWatchlistDocument,
  primaryResultPath: ['contacts', 'addToWatchlist']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: AddToWatchlistMutationVariables;
  TResult: AddToWatchlistMutation;
  primaryResultPath: string[];
  TPrimaryResult: AddToWatchlistMutation['contacts']['addToWatchlist'];
});
export const RemoveFromWatchlistDocument = gql`
	mutation RemoveFromWatchlist($userId: ID!) {
		contacts {
			removeFromWatchlist(userId: $userId) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type RemoveFromWatchlistMutationFn = ApolloReactCommon.MutationFunction<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>;
export type RemoveFromWatchlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>, 'mutation'>;
export const RemoveFromWatchlistComponent = (props: RemoveFromWatchlistComponentProps) => <ApolloReactComponents.Mutation<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables> mutation={RemoveFromWatchlistDocument} {...props} />;
export type RemoveFromWatchlistProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables> } & TChildProps;
export function withRemoveFromWatchlist<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables, RemoveFromWatchlistProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables, RemoveFromWatchlistProps<TChildProps, TDataName>>(RemoveFromWatchlistDocument, {
    alias: 'removeFromWatchlist',
    ...operationOptions
  });
}
export type RemoveFromWatchlistMutationResult = ApolloReactCommon.MutationResult<RemoveFromWatchlistMutation>;
export type RemoveFromWatchlistMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>;
export const RemoveFromWatchlist = (({
  operationKind: 'Mutation',
  document: RemoveFromWatchlistDocument,
  primaryResultPath: ['contacts', 'removeFromWatchlist']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: RemoveFromWatchlistMutationVariables;
  TResult: RemoveFromWatchlistMutation;
  primaryResultPath: string[];
  TPrimaryResult: RemoveFromWatchlistMutation['contacts']['removeFromWatchlist'];
});
export const WatchlistUserDocument = gql`
	query WatchlistUser($userId: ID!) {
		user {
			user(id: $userId) {
				id
				isOnWatchlist
			}
		}
	}
`;
export type WatchlistUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WatchlistUserQuery, WatchlistUserQueryVariables>, 'query'> & ({
  variables: WatchlistUserQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const WatchlistUserComponent = (props: WatchlistUserComponentProps) => <ApolloReactComponents.Query<WatchlistUserQuery, WatchlistUserQueryVariables> query={WatchlistUserDocument} {...props} />;
export type WatchlistUserProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<WatchlistUserQuery, WatchlistUserQueryVariables> } & TChildProps;
export function withWatchlistUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, WatchlistUserQuery, WatchlistUserQueryVariables, WatchlistUserProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, WatchlistUserQuery, WatchlistUserQueryVariables, WatchlistUserProps<TChildProps, TDataName>>(WatchlistUserDocument, {
    alias: 'watchlistUser',
    ...operationOptions
  });
}
export type WatchlistUserQueryResult = ApolloReactCommon.QueryResult<WatchlistUserQuery, WatchlistUserQueryVariables>;
export const WatchlistUser = (({
  operationKind: 'Query',
  document: WatchlistUserDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: WatchlistUserQueryVariables;
  TResult: WatchlistUserQuery;
  primaryResultPath: string[];
  TPrimaryResult: WatchlistUserQuery['user']['user'];
});
export const ContactListChangedDocument = gql`
	subscription ContactListChanged(
		$filter: ContactListFilter!
		$pixelDensity: Float!
	) {
		contactListEvent(filter: $filter) {
			... on ContactAddedEvent {
				user {
					...ContactsUser
				}
			}
			... on ContactRemovedEvent {
				user {
					id
					isOnWatchlist
				}
			}
		}
	}
	${ContactsUserFragmentDoc}
`;
export type ContactListChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ContactListChangedSubscription, ContactListChangedSubscriptionVariables>, 'subscription'>;
export const ContactListChangedComponent = (props: ContactListChangedComponentProps) => <ApolloReactComponents.Subscription<ContactListChangedSubscription, ContactListChangedSubscriptionVariables> subscription={ContactListChangedDocument} {...props} />;
export type ContactListChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ContactListChangedSubscription, ContactListChangedSubscriptionVariables> } & TChildProps;
export function withContactListChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ContactListChangedSubscription, ContactListChangedSubscriptionVariables, ContactListChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, ContactListChangedSubscription, ContactListChangedSubscriptionVariables, ContactListChangedProps<TChildProps, TDataName>>(ContactListChangedDocument, {
    alias: 'contactListChanged',
    ...operationOptions
  });
}
export type ContactListChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<ContactListChangedSubscription>;
export const ContactListChanged = (({
  operationKind: 'Subscription',
  document: ContactListChangedDocument,
  primaryResultPath: ['contactListEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: ContactListChangedSubscriptionVariables;
  TResult: ContactListChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: ContactListChangedSubscription['contactListEvent'];
});
export const GetFeatureFlagsDocument = gql`
	query GetFeatureFlags {
		featureFlags {
			enabledFlags {
				id
			}
		}
	}
`;
export type GetFeatureFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>, 'query'>;
export const GetFeatureFlagsComponent = (props: GetFeatureFlagsComponentProps) => <ApolloReactComponents.Query<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables> query={GetFeatureFlagsDocument} {...props} />;
export type GetFeatureFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables> } & TChildProps;
export function withGetFeatureFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables, GetFeatureFlagsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables, GetFeatureFlagsProps<TChildProps, TDataName>>(GetFeatureFlagsDocument, {
    alias: 'getFeatureFlags',
    ...operationOptions
  });
}
export type GetFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>;
export const GetFeatureFlags = (({
  operationKind: 'Query',
  document: GetFeatureFlagsDocument,
  primaryResultPath: ['featureFlags', 'enabledFlags']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetFeatureFlagsQueryVariables;
  TResult: GetFeatureFlagsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetFeatureFlagsQuery['featureFlags']['enabledFlags'];
});
export const RemoveMatchDocument = gql`
	mutation removeMatch($id: ID!) {
		fotomeet {
			removeMatchedUser(id: $id)
		}
	}
`;
export type RemoveMatchMutationFn = ApolloReactCommon.MutationFunction<RemoveMatchMutation, RemoveMatchMutationVariables>;
export type RemoveMatchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMatchMutation, RemoveMatchMutationVariables>, 'mutation'>;
export const RemoveMatchComponent = (props: RemoveMatchComponentProps) => <ApolloReactComponents.Mutation<RemoveMatchMutation, RemoveMatchMutationVariables> mutation={RemoveMatchDocument} {...props} />;
export type RemoveMatchProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveMatchMutation, RemoveMatchMutationVariables> } & TChildProps;
export function withRemoveMatch<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RemoveMatchMutation, RemoveMatchMutationVariables, RemoveMatchProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, RemoveMatchMutation, RemoveMatchMutationVariables, RemoveMatchProps<TChildProps, TDataName>>(RemoveMatchDocument, {
    alias: 'removeMatch',
    ...operationOptions
  });
}
export type RemoveMatchMutationResult = ApolloReactCommon.MutationResult<RemoveMatchMutation>;
export type RemoveMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMatchMutation, RemoveMatchMutationVariables>;
export const removeMatch = (({
  operationKind: 'Mutation',
  document: RemoveMatchDocument,
  primaryResultPath: ['fotomeet', 'removeMatchedUser']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: RemoveMatchMutationVariables;
  TResult: RemoveMatchMutation;
  primaryResultPath: string[];
  TPrimaryResult: RemoveMatchMutation['fotomeet']['removeMatchedUser'];
});
export const HappyMomentEventsDocument = gql`
	subscription happyMomentEvents {
		happyMomentEvent {
			... on LongConversationOccurred {
				...LongConversationOccurred
			}
			... on DailyLoginUsed {
				...DailyLoginUsed
			}
			... on FriendRequestAccepted {
				...FriendRequestAccepted
			}
		}
	}
	${LongConversationOccurredFragmentDoc}
	${DailyLoginUsedFragmentDoc}
	${FriendRequestAcceptedFragmentDoc}
`;
export type HappyMomentEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<HappyMomentEventsSubscription, HappyMomentEventsSubscriptionVariables>, 'subscription'>;
export const HappyMomentEventsComponent = (props: HappyMomentEventsComponentProps) => <ApolloReactComponents.Subscription<HappyMomentEventsSubscription, HappyMomentEventsSubscriptionVariables> subscription={HappyMomentEventsDocument} {...props} />;
export type HappyMomentEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<HappyMomentEventsSubscription, HappyMomentEventsSubscriptionVariables> } & TChildProps;
export function withHappyMomentEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, HappyMomentEventsSubscription, HappyMomentEventsSubscriptionVariables, HappyMomentEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, HappyMomentEventsSubscription, HappyMomentEventsSubscriptionVariables, HappyMomentEventsProps<TChildProps, TDataName>>(HappyMomentEventsDocument, {
    alias: 'happyMomentEvents',
    ...operationOptions
  });
}
export type HappyMomentEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<HappyMomentEventsSubscription>;
export const happyMomentEvents = (({
  operationKind: 'Subscription',
  document: HappyMomentEventsDocument,
  primaryResultPath: ['happyMomentEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: HappyMomentEventsSubscriptionVariables;
  TResult: HappyMomentEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: HappyMomentEventsSubscription['happyMomentEvent'];
});
export const IpDetectionUrlDocument = gql`
	query IpDetectionUrl {
		user {
			ipDetectionUrl
		}
	}
`;
export type IpDetectionUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IpDetectionUrlQuery, IpDetectionUrlQueryVariables>, 'query'>;
export const IpDetectionUrlComponent = (props: IpDetectionUrlComponentProps) => <ApolloReactComponents.Query<IpDetectionUrlQuery, IpDetectionUrlQueryVariables> query={IpDetectionUrlDocument} {...props} />;
export type IpDetectionUrlProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<IpDetectionUrlQuery, IpDetectionUrlQueryVariables> } & TChildProps;
export function withIpDetectionUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, IpDetectionUrlQuery, IpDetectionUrlQueryVariables, IpDetectionUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, IpDetectionUrlQuery, IpDetectionUrlQueryVariables, IpDetectionUrlProps<TChildProps, TDataName>>(IpDetectionUrlDocument, {
    alias: 'ipDetectionUrl',
    ...operationOptions
  });
}
export type IpDetectionUrlQueryResult = ApolloReactCommon.QueryResult<IpDetectionUrlQuery, IpDetectionUrlQueryVariables>;
export const IpDetectionUrl = (({
  operationKind: 'Query',
  document: IpDetectionUrlDocument,
  primaryResultPath: ['user', 'ipDetectionUrl']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: IpDetectionUrlQueryVariables;
  TResult: IpDetectionUrlQuery;
  primaryResultPath: string[];
  TPrimaryResult: IpDetectionUrlQuery['user']['ipDetectionUrl'];
});
export const MentorEventsDocument = gql`
	subscription MentorEvents {
		mentorEvent {
			... on PotentialMenteeAddedEvent {
				...PotentialMenteeAddedEvent
			}
			... on PotentialMenteeRemovedEvent {
				user {
					id
					menteeStatus
				}
			}
			... on MentorAchievedEvent {
				mentee {
					id
					menteeStatus
					interest
					latestClient
				}
			}
			... on MentorStatusChanged {
				newStatus {
					...MentorStatus
				}
			}
		}
	}
	${PotentialMenteeAddedEventFragmentDoc}
	${MentorStatusFragmentDoc}
`;
export type MentorEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<MentorEventsSubscription, MentorEventsSubscriptionVariables>, 'subscription'>;
export const MentorEventsComponent = (props: MentorEventsComponentProps) => <ApolloReactComponents.Subscription<MentorEventsSubscription, MentorEventsSubscriptionVariables> subscription={MentorEventsDocument} {...props} />;
export type MentorEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<MentorEventsSubscription, MentorEventsSubscriptionVariables> } & TChildProps;
export function withMentorEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MentorEventsSubscription, MentorEventsSubscriptionVariables, MentorEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, MentorEventsSubscription, MentorEventsSubscriptionVariables, MentorEventsProps<TChildProps, TDataName>>(MentorEventsDocument, {
    alias: 'mentorEvents',
    ...operationOptions
  });
}
export type MentorEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<MentorEventsSubscription>;
export const MentorEvents = (({
  operationKind: 'Subscription',
  document: MentorEventsDocument,
  primaryResultPath: ['mentorEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: MentorEventsSubscriptionVariables;
  TResult: MentorEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: MentorEventsSubscription['mentorEvent'];
});
export const GetMentorStatusDocument = gql`
	query GetMentorStatus {
		mentor {
			mentorStatus {
				...MentorStatus
			}
		}
	}
	${MentorStatusFragmentDoc}
`;
export type GetMentorStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMentorStatusQuery, GetMentorStatusQueryVariables>, 'query'>;
export const GetMentorStatusComponent = (props: GetMentorStatusComponentProps) => <ApolloReactComponents.Query<GetMentorStatusQuery, GetMentorStatusQueryVariables> query={GetMentorStatusDocument} {...props} />;
export type GetMentorStatusProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetMentorStatusQuery, GetMentorStatusQueryVariables> } & TChildProps;
export function withGetMentorStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetMentorStatusQuery, GetMentorStatusQueryVariables, GetMentorStatusProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetMentorStatusQuery, GetMentorStatusQueryVariables, GetMentorStatusProps<TChildProps, TDataName>>(GetMentorStatusDocument, {
    alias: 'getMentorStatus',
    ...operationOptions
  });
}
export type GetMentorStatusQueryResult = ApolloReactCommon.QueryResult<GetMentorStatusQuery, GetMentorStatusQueryVariables>;
export const GetMentorStatus = (({
  operationKind: 'Query',
  document: GetMentorStatusDocument,
  primaryResultPath: ['mentor', 'mentorStatus']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetMentorStatusQueryVariables;
  TResult: GetMentorStatusQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetMentorStatusQuery['mentor']['mentorStatus'];
});
export const ActivateMentorSystemDocument = gql`
	mutation ActivateMentorSystem {
		mentor {
			activateMentorSystem {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ActivateMentorSystemMutationFn = ApolloReactCommon.MutationFunction<ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables>;
export type ActivateMentorSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables>, 'mutation'>;
export const ActivateMentorSystemComponent = (props: ActivateMentorSystemComponentProps) => <ApolloReactComponents.Mutation<ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables> mutation={ActivateMentorSystemDocument} {...props} />;
export type ActivateMentorSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables> } & TChildProps;
export function withActivateMentorSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables, ActivateMentorSystemProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables, ActivateMentorSystemProps<TChildProps, TDataName>>(ActivateMentorSystemDocument, {
    alias: 'activateMentorSystem',
    ...operationOptions
  });
}
export type ActivateMentorSystemMutationResult = ApolloReactCommon.MutationResult<ActivateMentorSystemMutation>;
export type ActivateMentorSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateMentorSystemMutation, ActivateMentorSystemMutationVariables>;
export const ActivateMentorSystem = (({
  operationKind: 'Mutation',
  document: ActivateMentorSystemDocument,
  primaryResultPath: ['mentor', 'activateMentorSystem']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ActivateMentorSystemMutationVariables;
  TResult: ActivateMentorSystemMutation;
  primaryResultPath: string[];
  TPrimaryResult: ActivateMentorSystemMutation['mentor']['activateMentorSystem'];
});
export const UpdateMentorPauseStateDocument = gql`
	mutation UpdateMentorPauseState($state: MentorPauseState!) {
		mentor {
			changeMentorPauseState(state: $state) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateMentorPauseStateMutationFn = ApolloReactCommon.MutationFunction<UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables>;
export type UpdateMentorPauseStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables>, 'mutation'>;
export const UpdateMentorPauseStateComponent = (props: UpdateMentorPauseStateComponentProps) => <ApolloReactComponents.Mutation<UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables> mutation={UpdateMentorPauseStateDocument} {...props} />;
export type UpdateMentorPauseStateProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables> } & TChildProps;
export function withUpdateMentorPauseState<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables, UpdateMentorPauseStateProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables, UpdateMentorPauseStateProps<TChildProps, TDataName>>(UpdateMentorPauseStateDocument, {
    alias: 'updateMentorPauseState',
    ...operationOptions
  });
}
export type UpdateMentorPauseStateMutationResult = ApolloReactCommon.MutationResult<UpdateMentorPauseStateMutation>;
export type UpdateMentorPauseStateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMentorPauseStateMutation, UpdateMentorPauseStateMutationVariables>;
export const UpdateMentorPauseState = (({
  operationKind: 'Mutation',
  document: UpdateMentorPauseStateDocument,
  primaryResultPath: ['mentor', 'changeMentorPauseState']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateMentorPauseStateMutationVariables;
  TResult: UpdateMentorPauseStateMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateMentorPauseStateMutation['mentor']['changeMentorPauseState'];
});
export const CommonFriendsDocument = gql`
	query CommonFriends($userId: ID!, $pixelDensity: Float!) {
		contacts {
			commonFriends(userId: $userId) {
				... on InternalError {
					unused
				}
				... on FriendsHiddenByPrivacy {
					unused
				}
				... on FriendList {
					friends {
						...CommonFriend
					}
				}
			}
		}
	}
	${CommonFriendFragmentDoc}
`;
export type CommonFriendsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CommonFriendsQuery, CommonFriendsQueryVariables>, 'query'> & ({
  variables: CommonFriendsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const CommonFriendsComponent = (props: CommonFriendsComponentProps) => <ApolloReactComponents.Query<CommonFriendsQuery, CommonFriendsQueryVariables> query={CommonFriendsDocument} {...props} />;
export type CommonFriendsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<CommonFriendsQuery, CommonFriendsQueryVariables> } & TChildProps;
export function withCommonFriends<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CommonFriendsQuery, CommonFriendsQueryVariables, CommonFriendsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, CommonFriendsQuery, CommonFriendsQueryVariables, CommonFriendsProps<TChildProps, TDataName>>(CommonFriendsDocument, {
    alias: 'commonFriends',
    ...operationOptions
  });
}
export type CommonFriendsQueryResult = ApolloReactCommon.QueryResult<CommonFriendsQuery, CommonFriendsQueryVariables>;
export const CommonFriends = (({
  operationKind: 'Query',
  document: CommonFriendsDocument,
  primaryResultPath: ['contacts', 'commonFriends']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: CommonFriendsQueryVariables;
  TResult: CommonFriendsQuery;
  primaryResultPath: string[];
  TPrimaryResult: CommonFriendsQuery['contacts']['commonFriends'];
});
export const StarredMessagesDocument = gql`
	query StarredMessages(
		$limit: Int!
		$before: UtcTimestamp = null
		$pixelDensity: Float!
	) {
		messenger {
			starredConversationMessages(limit: $limit, before: $before)
				@connection(key: "messages") {
				messages {
					...StarredConversationMessage
				}
				hasMore
			}
		}
	}
	${StarredConversationMessageFragmentDoc}
`;
export type StarredMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StarredMessagesQuery, StarredMessagesQueryVariables>, 'query'> & ({
  variables: StarredMessagesQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const StarredMessagesComponent = (props: StarredMessagesComponentProps) => <ApolloReactComponents.Query<StarredMessagesQuery, StarredMessagesQueryVariables> query={StarredMessagesDocument} {...props} />;
export type StarredMessagesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<StarredMessagesQuery, StarredMessagesQueryVariables> } & TChildProps;
export function withStarredMessages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, StarredMessagesQuery, StarredMessagesQueryVariables, StarredMessagesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, StarredMessagesQuery, StarredMessagesQueryVariables, StarredMessagesProps<TChildProps, TDataName>>(StarredMessagesDocument, {
    alias: 'starredMessages',
    ...operationOptions
  });
}
export type StarredMessagesQueryResult = ApolloReactCommon.QueryResult<StarredMessagesQuery, StarredMessagesQueryVariables>;
export const StarredMessages = (({
  operationKind: 'Query',
  document: StarredMessagesDocument,
  primaryResultPath: ['messenger', 'starredConversationMessages']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: StarredMessagesQueryVariables;
  TResult: StarredMessagesQuery;
  primaryResultPath: string[];
  TPrimaryResult: StarredMessagesQuery['messenger']['starredConversationMessages'];
});
export const MessengerOverviewDocument = gql`
	query MessengerOverview(
		$limit: Int = 20
		$before: UtcTimestamp = null
		$pixelDensity: Float!
		$filterByState: MessengerConversationState = ALL
	) {
		messenger {
			conversations(
				limit: $limit
				before: $before
				filterByState: $filterByState
			) @connection(key: "conversations", filter: ["filterByState"]) {
				conversations {
					...FullConversationWithoutMessages
				}
				hasMore
			}
		}
	}
	${FullConversationWithoutMessagesFragmentDoc}
`;
export type MessengerOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MessengerOverviewQuery, MessengerOverviewQueryVariables>, 'query'> & ({
  variables: MessengerOverviewQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const MessengerOverviewComponent = (props: MessengerOverviewComponentProps) => <ApolloReactComponents.Query<MessengerOverviewQuery, MessengerOverviewQueryVariables> query={MessengerOverviewDocument} {...props} />;
export type MessengerOverviewProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<MessengerOverviewQuery, MessengerOverviewQueryVariables> } & TChildProps;
export function withMessengerOverview<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MessengerOverviewQuery, MessengerOverviewQueryVariables, MessengerOverviewProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, MessengerOverviewQuery, MessengerOverviewQueryVariables, MessengerOverviewProps<TChildProps, TDataName>>(MessengerOverviewDocument, {
    alias: 'messengerOverview',
    ...operationOptions
  });
}
export type MessengerOverviewQueryResult = ApolloReactCommon.QueryResult<MessengerOverviewQuery, MessengerOverviewQueryVariables>;
export const MessengerOverview = (({
  operationKind: 'Query',
  document: MessengerOverviewDocument,
  primaryResultPath: ['messenger', 'conversations']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: MessengerOverviewQueryVariables;
  TResult: MessengerOverviewQuery;
  primaryResultPath: string[];
  TPrimaryResult: MessengerOverviewQuery['messenger']['conversations'];
});
export const GetConversationDocument = gql`
	query GetConversation(
		$id: ID!
		$messageCount: Int = 50
		$beforeMessageId: ID = null
		$pixelDensity: Float!
	) {
		messenger {
			conversation(id: $id) {
				...FullConversation
			}
		}
	}
	${FullConversationFragmentDoc}
`;
export type GetConversationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetConversationQuery, GetConversationQueryVariables>, 'query'> & ({
  variables: GetConversationQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetConversationComponent = (props: GetConversationComponentProps) => <ApolloReactComponents.Query<GetConversationQuery, GetConversationQueryVariables> query={GetConversationDocument} {...props} />;
export type GetConversationProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetConversationQuery, GetConversationQueryVariables> } & TChildProps;
export function withGetConversation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetConversationQuery, GetConversationQueryVariables, GetConversationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetConversationQuery, GetConversationQueryVariables, GetConversationProps<TChildProps, TDataName>>(GetConversationDocument, {
    alias: 'getConversation',
    ...operationOptions
  });
}
export type GetConversationQueryResult = ApolloReactCommon.QueryResult<GetConversationQuery, GetConversationQueryVariables>;
export const GetConversation = (({
  operationKind: 'Query',
  document: GetConversationDocument,
  primaryResultPath: ['messenger', 'conversation']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetConversationQueryVariables;
  TResult: GetConversationQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetConversationQuery['messenger']['conversation'];
});
export const LoadMoreMessagesDocument = gql`
	query LoadMoreMessages(
		$conversationId: ID!
		$limit: Int = 50
		$beforeMessageTimestamp: UtcTimestamp = null
	) {
		messenger {
			conversationMessages(
				conversationId: $conversationId
				limit: $limit
				beforeMessageTimestamp: $beforeMessageTimestamp
			) {
				messages {
					...ConversationMessage
				}
				hasMore
			}
		}
	}
	${ConversationMessageFragmentDoc}
`;
export type LoadMoreMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LoadMoreMessagesQuery, LoadMoreMessagesQueryVariables>, 'query'> & ({
  variables: LoadMoreMessagesQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const LoadMoreMessagesComponent = (props: LoadMoreMessagesComponentProps) => <ApolloReactComponents.Query<LoadMoreMessagesQuery, LoadMoreMessagesQueryVariables> query={LoadMoreMessagesDocument} {...props} />;
export type LoadMoreMessagesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<LoadMoreMessagesQuery, LoadMoreMessagesQueryVariables> } & TChildProps;
export function withLoadMoreMessages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, LoadMoreMessagesQuery, LoadMoreMessagesQueryVariables, LoadMoreMessagesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, LoadMoreMessagesQuery, LoadMoreMessagesQueryVariables, LoadMoreMessagesProps<TChildProps, TDataName>>(LoadMoreMessagesDocument, {
    alias: 'loadMoreMessages',
    ...operationOptions
  });
}
export type LoadMoreMessagesQueryResult = ApolloReactCommon.QueryResult<LoadMoreMessagesQuery, LoadMoreMessagesQueryVariables>;
export const LoadMoreMessages = (({
  operationKind: 'Query',
  document: LoadMoreMessagesDocument,
  primaryResultPath: ['messenger', 'conversationMessages']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: LoadMoreMessagesQueryVariables;
  TResult: LoadMoreMessagesQuery;
  primaryResultPath: string[];
  TPrimaryResult: LoadMoreMessagesQuery['messenger']['conversationMessages'];
});
export const GetConversationWithoutMessagesDocument = gql`
	query GetConversationWithoutMessages($id: ID!, $pixelDensity: Float!) {
		messenger {
			conversation(id: $id) {
				...FullConversationWithoutMessages
			}
		}
	}
	${FullConversationWithoutMessagesFragmentDoc}
`;
export type GetConversationWithoutMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables>, 'query'> & ({
  variables: GetConversationWithoutMessagesQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetConversationWithoutMessagesComponent = (props: GetConversationWithoutMessagesComponentProps) => <ApolloReactComponents.Query<GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables> query={GetConversationWithoutMessagesDocument} {...props} />;
export type GetConversationWithoutMessagesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables> } & TChildProps;
export function withGetConversationWithoutMessages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables, GetConversationWithoutMessagesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables, GetConversationWithoutMessagesProps<TChildProps, TDataName>>(GetConversationWithoutMessagesDocument, {
    alias: 'getConversationWithoutMessages',
    ...operationOptions
  });
}
export type GetConversationWithoutMessagesQueryResult = ApolloReactCommon.QueryResult<GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables>;
export const GetConversationWithoutMessages = (({
  operationKind: 'Query',
  document: GetConversationWithoutMessagesDocument,
  primaryResultPath: ['messenger', 'conversation']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetConversationWithoutMessagesQueryVariables;
  TResult: GetConversationWithoutMessagesQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetConversationWithoutMessagesQuery['messenger']['conversation'];
});
export const ArchiveConversationDocument = gql`
	mutation ArchiveConversation($id: ID!) {
		messenger {
			archiveConversation(id: $id) {
				error
				conversation {
					id
					visibility
				}
			}
		}
	}
`;
export type ArchiveConversationMutationFn = ApolloReactCommon.MutationFunction<ArchiveConversationMutation, ArchiveConversationMutationVariables>;
export type ArchiveConversationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveConversationMutation, ArchiveConversationMutationVariables>, 'mutation'>;
export const ArchiveConversationComponent = (props: ArchiveConversationComponentProps) => <ApolloReactComponents.Mutation<ArchiveConversationMutation, ArchiveConversationMutationVariables> mutation={ArchiveConversationDocument} {...props} />;
export type ArchiveConversationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ArchiveConversationMutation, ArchiveConversationMutationVariables> } & TChildProps;
export function withArchiveConversation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ArchiveConversationMutation, ArchiveConversationMutationVariables, ArchiveConversationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ArchiveConversationMutation, ArchiveConversationMutationVariables, ArchiveConversationProps<TChildProps, TDataName>>(ArchiveConversationDocument, {
    alias: 'archiveConversation',
    ...operationOptions
  });
}
export type ArchiveConversationMutationResult = ApolloReactCommon.MutationResult<ArchiveConversationMutation>;
export type ArchiveConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveConversationMutation, ArchiveConversationMutationVariables>;
export const ArchiveConversation = (({
  operationKind: 'Mutation',
  document: ArchiveConversationDocument,
  primaryResultPath: ['messenger', 'archiveConversation']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ArchiveConversationMutationVariables;
  TResult: ArchiveConversationMutation;
  primaryResultPath: string[];
  TPrimaryResult: ArchiveConversationMutation['messenger']['archiveConversation'];
});
export const DeleteConversationDocument = gql`
	mutation DeleteConversation($id: ID!) {
		messenger {
			deleteConversation(id: $id) {
				error
				conversation {
					id
					visibility
				}
			}
		}
	}
`;
export type DeleteConversationMutationFn = ApolloReactCommon.MutationFunction<DeleteConversationMutation, DeleteConversationMutationVariables>;
export type DeleteConversationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteConversationMutation, DeleteConversationMutationVariables>, 'mutation'>;
export const DeleteConversationComponent = (props: DeleteConversationComponentProps) => <ApolloReactComponents.Mutation<DeleteConversationMutation, DeleteConversationMutationVariables> mutation={DeleteConversationDocument} {...props} />;
export type DeleteConversationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteConversationMutation, DeleteConversationMutationVariables> } & TChildProps;
export function withDeleteConversation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DeleteConversationMutation, DeleteConversationMutationVariables, DeleteConversationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DeleteConversationMutation, DeleteConversationMutationVariables, DeleteConversationProps<TChildProps, TDataName>>(DeleteConversationDocument, {
    alias: 'deleteConversation',
    ...operationOptions
  });
}
export type DeleteConversationMutationResult = ApolloReactCommon.MutationResult<DeleteConversationMutation>;
export type DeleteConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConversationMutation, DeleteConversationMutationVariables>;
export const DeleteConversation = (({
  operationKind: 'Mutation',
  document: DeleteConversationDocument,
  primaryResultPath: ['messenger', 'deleteConversation']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DeleteConversationMutationVariables;
  TResult: DeleteConversationMutation;
  primaryResultPath: string[];
  TPrimaryResult: DeleteConversationMutation['messenger']['deleteConversation'];
});
export const RestoreConversationDocument = gql`
	mutation RestoreConversation($id: ID!) {
		messenger {
			restoreConversation(id: $id) {
				error
				conversation {
					id
					visibility
				}
			}
		}
	}
`;
export type RestoreConversationMutationFn = ApolloReactCommon.MutationFunction<RestoreConversationMutation, RestoreConversationMutationVariables>;
export type RestoreConversationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RestoreConversationMutation, RestoreConversationMutationVariables>, 'mutation'>;
export const RestoreConversationComponent = (props: RestoreConversationComponentProps) => <ApolloReactComponents.Mutation<RestoreConversationMutation, RestoreConversationMutationVariables> mutation={RestoreConversationDocument} {...props} />;
export type RestoreConversationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<RestoreConversationMutation, RestoreConversationMutationVariables> } & TChildProps;
export function withRestoreConversation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RestoreConversationMutation, RestoreConversationMutationVariables, RestoreConversationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, RestoreConversationMutation, RestoreConversationMutationVariables, RestoreConversationProps<TChildProps, TDataName>>(RestoreConversationDocument, {
    alias: 'restoreConversation',
    ...operationOptions
  });
}
export type RestoreConversationMutationResult = ApolloReactCommon.MutationResult<RestoreConversationMutation>;
export type RestoreConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreConversationMutation, RestoreConversationMutationVariables>;
export const RestoreConversation = (({
  operationKind: 'Mutation',
  document: RestoreConversationDocument,
  primaryResultPath: ['messenger', 'restoreConversation']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: RestoreConversationMutationVariables;
  TResult: RestoreConversationMutation;
  primaryResultPath: string[];
  TPrimaryResult: RestoreConversationMutation['messenger']['restoreConversation'];
});
export const MessengerSendMessageDocument = gql`
	mutation MessengerSendMessage(
		$id: ID!
		$text: String!
		$messageCorrelationId: ID!
	) {
		messenger {
			sendMessage(
				conversationId: $id
				text: $text
				messageCorrelationId: $messageCorrelationId
			) {
				error {
					type
					filterReason
				}
			}
		}
	}
`;
export type MessengerSendMessageMutationFn = ApolloReactCommon.MutationFunction<MessengerSendMessageMutation, MessengerSendMessageMutationVariables>;
export type MessengerSendMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MessengerSendMessageMutation, MessengerSendMessageMutationVariables>, 'mutation'>;
export const MessengerSendMessageComponent = (props: MessengerSendMessageComponentProps) => <ApolloReactComponents.Mutation<MessengerSendMessageMutation, MessengerSendMessageMutationVariables> mutation={MessengerSendMessageDocument} {...props} />;
export type MessengerSendMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<MessengerSendMessageMutation, MessengerSendMessageMutationVariables> } & TChildProps;
export function withMessengerSendMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MessengerSendMessageMutation, MessengerSendMessageMutationVariables, MessengerSendMessageProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, MessengerSendMessageMutation, MessengerSendMessageMutationVariables, MessengerSendMessageProps<TChildProps, TDataName>>(MessengerSendMessageDocument, {
    alias: 'messengerSendMessage',
    ...operationOptions
  });
}
export type MessengerSendMessageMutationResult = ApolloReactCommon.MutationResult<MessengerSendMessageMutation>;
export type MessengerSendMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<MessengerSendMessageMutation, MessengerSendMessageMutationVariables>;
export const MessengerSendMessage = (({
  operationKind: 'Mutation',
  document: MessengerSendMessageDocument,
  primaryResultPath: ['messenger', 'sendMessage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: MessengerSendMessageMutationVariables;
  TResult: MessengerSendMessageMutation;
  primaryResultPath: string[];
  TPrimaryResult: MessengerSendMessageMutation['messenger']['sendMessage'];
});
export const ForwardMessageDocument = gql`
	mutation ForwardMessage(
		$messageId: ID!
		$recipients: [ID!]!
		$messageCorrelationId: ID!
	) {
		messenger {
			forwardMessage(
				messageId: $messageId
				recipients: $recipients
				messageCorrelationId: $messageCorrelationId
			) {
				error {
					filterReason
					type
				}
				recipientCount
				results {
					messageError {
						type
						filterReason
					}
				}
			}
		}
	}
`;
export type ForwardMessageMutationFn = ApolloReactCommon.MutationFunction<ForwardMessageMutation, ForwardMessageMutationVariables>;
export type ForwardMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ForwardMessageMutation, ForwardMessageMutationVariables>, 'mutation'>;
export const ForwardMessageComponent = (props: ForwardMessageComponentProps) => <ApolloReactComponents.Mutation<ForwardMessageMutation, ForwardMessageMutationVariables> mutation={ForwardMessageDocument} {...props} />;
export type ForwardMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ForwardMessageMutation, ForwardMessageMutationVariables> } & TChildProps;
export function withForwardMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ForwardMessageMutation, ForwardMessageMutationVariables, ForwardMessageProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ForwardMessageMutation, ForwardMessageMutationVariables, ForwardMessageProps<TChildProps, TDataName>>(ForwardMessageDocument, {
    alias: 'forwardMessage',
    ...operationOptions
  });
}
export type ForwardMessageMutationResult = ApolloReactCommon.MutationResult<ForwardMessageMutation>;
export type ForwardMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<ForwardMessageMutation, ForwardMessageMutationVariables>;
export const ForwardMessage = (({
  operationKind: 'Mutation',
  document: ForwardMessageDocument,
  primaryResultPath: ['messenger', 'forwardMessage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ForwardMessageMutationVariables;
  TResult: ForwardMessageMutation;
  primaryResultPath: string[];
  TPrimaryResult: ForwardMessageMutation['messenger']['forwardMessage'];
});
export const StarMessengerMessageDocument = gql`
	mutation StarMessengerMessage($id: ID!) {
		messenger {
			starMessage(messageId: $id) {
				error
			}
		}
	}
`;
export type StarMessengerMessageMutationFn = ApolloReactCommon.MutationFunction<StarMessengerMessageMutation, StarMessengerMessageMutationVariables>;
export type StarMessengerMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StarMessengerMessageMutation, StarMessengerMessageMutationVariables>, 'mutation'>;
export const StarMessengerMessageComponent = (props: StarMessengerMessageComponentProps) => <ApolloReactComponents.Mutation<StarMessengerMessageMutation, StarMessengerMessageMutationVariables> mutation={StarMessengerMessageDocument} {...props} />;
export type StarMessengerMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<StarMessengerMessageMutation, StarMessengerMessageMutationVariables> } & TChildProps;
export function withStarMessengerMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, StarMessengerMessageMutation, StarMessengerMessageMutationVariables, StarMessengerMessageProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, StarMessengerMessageMutation, StarMessengerMessageMutationVariables, StarMessengerMessageProps<TChildProps, TDataName>>(StarMessengerMessageDocument, {
    alias: 'starMessengerMessage',
    ...operationOptions
  });
}
export type StarMessengerMessageMutationResult = ApolloReactCommon.MutationResult<StarMessengerMessageMutation>;
export type StarMessengerMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<StarMessengerMessageMutation, StarMessengerMessageMutationVariables>;
export const StarMessengerMessage = (({
  operationKind: 'Mutation',
  document: StarMessengerMessageDocument,
  primaryResultPath: ['messenger', 'starMessage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: StarMessengerMessageMutationVariables;
  TResult: StarMessengerMessageMutation;
  primaryResultPath: string[];
  TPrimaryResult: StarMessengerMessageMutation['messenger']['starMessage'];
});
export const UnStarMessengerMessageDocument = gql`
	mutation UnStarMessengerMessage($id: ID!) {
		messenger {
			unstarMessage(messageId: $id) {
				error
			}
		}
	}
`;
export type UnStarMessengerMessageMutationFn = ApolloReactCommon.MutationFunction<UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables>;
export type UnStarMessengerMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables>, 'mutation'>;
export const UnStarMessengerMessageComponent = (props: UnStarMessengerMessageComponentProps) => <ApolloReactComponents.Mutation<UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables> mutation={UnStarMessengerMessageDocument} {...props} />;
export type UnStarMessengerMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables> } & TChildProps;
export function withUnStarMessengerMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables, UnStarMessengerMessageProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables, UnStarMessengerMessageProps<TChildProps, TDataName>>(UnStarMessengerMessageDocument, {
    alias: 'unStarMessengerMessage',
    ...operationOptions
  });
}
export type UnStarMessengerMessageMutationResult = ApolloReactCommon.MutationResult<UnStarMessengerMessageMutation>;
export type UnStarMessengerMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<UnStarMessengerMessageMutation, UnStarMessengerMessageMutationVariables>;
export const UnStarMessengerMessage = (({
  operationKind: 'Mutation',
  document: UnStarMessengerMessageDocument,
  primaryResultPath: ['messenger', 'unstarMessage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UnStarMessengerMessageMutationVariables;
  TResult: UnStarMessengerMessageMutation;
  primaryResultPath: string[];
  TPrimaryResult: UnStarMessengerMessageMutation['messenger']['unstarMessage'];
});
export const SendTypingDocument = gql`
	mutation SendTyping($id: ID!) {
		messenger {
			notifyTyping(conversationId: $id, usesStopEvent: true) {
				error
			}
		}
	}
`;
export type SendTypingMutationFn = ApolloReactCommon.MutationFunction<SendTypingMutation, SendTypingMutationVariables>;
export type SendTypingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendTypingMutation, SendTypingMutationVariables>, 'mutation'>;
export const SendTypingComponent = (props: SendTypingComponentProps) => <ApolloReactComponents.Mutation<SendTypingMutation, SendTypingMutationVariables> mutation={SendTypingDocument} {...props} />;
export type SendTypingProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendTypingMutation, SendTypingMutationVariables> } & TChildProps;
export function withSendTyping<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendTypingMutation, SendTypingMutationVariables, SendTypingProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendTypingMutation, SendTypingMutationVariables, SendTypingProps<TChildProps, TDataName>>(SendTypingDocument, {
    alias: 'sendTyping',
    ...operationOptions
  });
}
export type SendTypingMutationResult = ApolloReactCommon.MutationResult<SendTypingMutation>;
export type SendTypingMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTypingMutation, SendTypingMutationVariables>;
export const SendTyping = (({
  operationKind: 'Mutation',
  document: SendTypingDocument,
  primaryResultPath: ['messenger', 'notifyTyping']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendTypingMutationVariables;
  TResult: SendTypingMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendTypingMutation['messenger']['notifyTyping'];
});
export const SendStopTypingDocument = gql`
	mutation SendStopTyping($id: ID!) {
		messenger {
			notifyStopTyping(conversationId: $id) {
				error
			}
		}
	}
`;
export type SendStopTypingMutationFn = ApolloReactCommon.MutationFunction<SendStopTypingMutation, SendStopTypingMutationVariables>;
export type SendStopTypingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendStopTypingMutation, SendStopTypingMutationVariables>, 'mutation'>;
export const SendStopTypingComponent = (props: SendStopTypingComponentProps) => <ApolloReactComponents.Mutation<SendStopTypingMutation, SendStopTypingMutationVariables> mutation={SendStopTypingDocument} {...props} />;
export type SendStopTypingProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendStopTypingMutation, SendStopTypingMutationVariables> } & TChildProps;
export function withSendStopTyping<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendStopTypingMutation, SendStopTypingMutationVariables, SendStopTypingProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendStopTypingMutation, SendStopTypingMutationVariables, SendStopTypingProps<TChildProps, TDataName>>(SendStopTypingDocument, {
    alias: 'sendStopTyping',
    ...operationOptions
  });
}
export type SendStopTypingMutationResult = ApolloReactCommon.MutationResult<SendStopTypingMutation>;
export type SendStopTypingMutationOptions = ApolloReactCommon.BaseMutationOptions<SendStopTypingMutation, SendStopTypingMutationVariables>;
export const SendStopTyping = (({
  operationKind: 'Mutation',
  document: SendStopTypingDocument,
  primaryResultPath: ['messenger', 'notifyStopTyping']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendStopTypingMutationVariables;
  TResult: SendStopTypingMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendStopTypingMutation['messenger']['notifyStopTyping'];
});
export const ConversationWithParticipantsDocument = gql`
	query ConversationWithParticipants($ids: [ID!]!) {
		messenger {
			conversationWithParticipants(otherParticipantIds: $ids) {
				id
			}
		}
	}
`;
export type ConversationWithParticipantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConversationWithParticipantsQuery, ConversationWithParticipantsQueryVariables>, 'query'> & ({
  variables: ConversationWithParticipantsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const ConversationWithParticipantsComponent = (props: ConversationWithParticipantsComponentProps) => <ApolloReactComponents.Query<ConversationWithParticipantsQuery, ConversationWithParticipantsQueryVariables> query={ConversationWithParticipantsDocument} {...props} />;
export type ConversationWithParticipantsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ConversationWithParticipantsQuery, ConversationWithParticipantsQueryVariables> } & TChildProps;
export function withConversationWithParticipants<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ConversationWithParticipantsQuery, ConversationWithParticipantsQueryVariables, ConversationWithParticipantsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ConversationWithParticipantsQuery, ConversationWithParticipantsQueryVariables, ConversationWithParticipantsProps<TChildProps, TDataName>>(ConversationWithParticipantsDocument, {
    alias: 'conversationWithParticipants',
    ...operationOptions
  });
}
export type ConversationWithParticipantsQueryResult = ApolloReactCommon.QueryResult<ConversationWithParticipantsQuery, ConversationWithParticipantsQueryVariables>;
export const ConversationWithParticipants = (({
  operationKind: 'Query',
  document: ConversationWithParticipantsDocument,
  primaryResultPath: ['messenger', 'conversationWithParticipants']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ConversationWithParticipantsQueryVariables;
  TResult: ConversationWithParticipantsQuery;
  primaryResultPath: string[];
  TPrimaryResult: ConversationWithParticipantsQuery['messenger']['conversationWithParticipants'];
});
export const QuoteMessageDocument = gql`
	mutation QuoteMessage(
		$messageId: ID!
		$text: String!
		$messageCorrelationId: ID!
	) {
		messenger {
			quoteMessage(
				messageId: $messageId
				text: $text
				messageCorrelationId: $messageCorrelationId
			) {
				error {
					filterReason
					type
				}
			}
		}
	}
`;
export type QuoteMessageMutationFn = ApolloReactCommon.MutationFunction<QuoteMessageMutation, QuoteMessageMutationVariables>;
export type QuoteMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<QuoteMessageMutation, QuoteMessageMutationVariables>, 'mutation'>;
export const QuoteMessageComponent = (props: QuoteMessageComponentProps) => <ApolloReactComponents.Mutation<QuoteMessageMutation, QuoteMessageMutationVariables> mutation={QuoteMessageDocument} {...props} />;
export type QuoteMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<QuoteMessageMutation, QuoteMessageMutationVariables> } & TChildProps;
export function withQuoteMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, QuoteMessageMutation, QuoteMessageMutationVariables, QuoteMessageProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, QuoteMessageMutation, QuoteMessageMutationVariables, QuoteMessageProps<TChildProps, TDataName>>(QuoteMessageDocument, {
    alias: 'quoteMessage',
    ...operationOptions
  });
}
export type QuoteMessageMutationResult = ApolloReactCommon.MutationResult<QuoteMessageMutation>;
export type QuoteMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<QuoteMessageMutation, QuoteMessageMutationVariables>;
export const QuoteMessage = (({
  operationKind: 'Mutation',
  document: QuoteMessageDocument,
  primaryResultPath: ['messenger', 'quoteMessage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: QuoteMessageMutationVariables;
  TResult: QuoteMessageMutation;
  primaryResultPath: string[];
  TPrimaryResult: QuoteMessageMutation['messenger']['quoteMessage'];
});
export const CreateImageUploadUrlDocument = gql`
	mutation CreateImageUploadUrl(
		$conversationId: ID!
		$expirationTimeInSeconds: Int
	) {
		messenger {
			createImageUploadUrl(
				conversationId: $conversationId
				expirationTimeInSeconds: $expirationTimeInSeconds
			) {
				url
				error
			}
		}
	}
`;
export type CreateImageUploadUrlMutationFn = ApolloReactCommon.MutationFunction<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>;
export type CreateImageUploadUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>, 'mutation'>;
export const CreateImageUploadUrlComponent = (props: CreateImageUploadUrlComponentProps) => <ApolloReactComponents.Mutation<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables> mutation={CreateImageUploadUrlDocument} {...props} />;
export type CreateImageUploadUrlProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables> } & TChildProps;
export function withCreateImageUploadUrl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables, CreateImageUploadUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables, CreateImageUploadUrlProps<TChildProps, TDataName>>(CreateImageUploadUrlDocument, {
    alias: 'createImageUploadUrl',
    ...operationOptions
  });
}
export type CreateImageUploadUrlMutationResult = ApolloReactCommon.MutationResult<CreateImageUploadUrlMutation>;
export type CreateImageUploadUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>;
export const CreateImageUploadUrl = (({
  operationKind: 'Mutation',
  document: CreateImageUploadUrlDocument,
  primaryResultPath: ['messenger', 'createImageUploadUrl']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: CreateImageUploadUrlMutationVariables;
  TResult: CreateImageUploadUrlMutation;
  primaryResultPath: string[];
  TPrimaryResult: CreateImageUploadUrlMutation['messenger']['createImageUploadUrl'];
});
export const ShowSensitiveContentDocument = gql`
	mutation ShowSensitiveContent($messageId: ID!) {
		messenger {
			viewedSensitiveContent(conversationMessageId: $messageId) {
				unused
			}
		}
	}
`;
export type ShowSensitiveContentMutationFn = ApolloReactCommon.MutationFunction<ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables>;
export type ShowSensitiveContentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables>, 'mutation'>;
export const ShowSensitiveContentComponent = (props: ShowSensitiveContentComponentProps) => <ApolloReactComponents.Mutation<ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables> mutation={ShowSensitiveContentDocument} {...props} />;
export type ShowSensitiveContentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables> } & TChildProps;
export function withShowSensitiveContent<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables, ShowSensitiveContentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables, ShowSensitiveContentProps<TChildProps, TDataName>>(ShowSensitiveContentDocument, {
    alias: 'showSensitiveContent',
    ...operationOptions
  });
}
export type ShowSensitiveContentMutationResult = ApolloReactCommon.MutationResult<ShowSensitiveContentMutation>;
export type ShowSensitiveContentMutationOptions = ApolloReactCommon.BaseMutationOptions<ShowSensitiveContentMutation, ShowSensitiveContentMutationVariables>;
export const ShowSensitiveContent = (({
  operationKind: 'Mutation',
  document: ShowSensitiveContentDocument,
  primaryResultPath: ['messenger', 'viewedSensitiveContent', 'unused']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ShowSensitiveContentMutationVariables;
  TResult: ShowSensitiveContentMutation;
  primaryResultPath: string[];
  TPrimaryResult: ShowSensitiveContentMutation['messenger']['viewedSensitiveContent']['unused'];
});
export const GetPhotoUrlDocument = gql`
	query GetPhotoUrl($photoId: ID!) {
		user {
			photoUrl(photoId: $photoId) {
				__typename
				... on InternalError {
					unused
				}
				... on PhotoUrl {
					url
				}
			}
		}
	}
`;
export type GetPhotoUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPhotoUrlQuery, GetPhotoUrlQueryVariables>, 'query'> & ({
  variables: GetPhotoUrlQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetPhotoUrlComponent = (props: GetPhotoUrlComponentProps) => <ApolloReactComponents.Query<GetPhotoUrlQuery, GetPhotoUrlQueryVariables> query={GetPhotoUrlDocument} {...props} />;
export type GetPhotoUrlProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetPhotoUrlQuery, GetPhotoUrlQueryVariables> } & TChildProps;
export function withGetPhotoUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetPhotoUrlQuery, GetPhotoUrlQueryVariables, GetPhotoUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetPhotoUrlQuery, GetPhotoUrlQueryVariables, GetPhotoUrlProps<TChildProps, TDataName>>(GetPhotoUrlDocument, {
    alias: 'getPhotoUrl',
    ...operationOptions
  });
}
export type GetPhotoUrlQueryResult = ApolloReactCommon.QueryResult<GetPhotoUrlQuery, GetPhotoUrlQueryVariables>;
export const GetPhotoUrl = (({
  operationKind: 'Query',
  document: GetPhotoUrlDocument,
  primaryResultPath: ['user', 'photoUrl']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetPhotoUrlQueryVariables;
  TResult: GetPhotoUrlQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetPhotoUrlQuery['user']['photoUrl'];
});
export const GetMessengerConversationVisibilityDocument = gql`
	query GetMessengerConversationVisibility($id: ID!) {
		messenger {
			conversation(id: $id) {
				id
				visibility
			}
		}
	}
`;
export type GetMessengerConversationVisibilityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMessengerConversationVisibilityQuery, GetMessengerConversationVisibilityQueryVariables>, 'query'> & ({
  variables: GetMessengerConversationVisibilityQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetMessengerConversationVisibilityComponent = (props: GetMessengerConversationVisibilityComponentProps) => <ApolloReactComponents.Query<GetMessengerConversationVisibilityQuery, GetMessengerConversationVisibilityQueryVariables> query={GetMessengerConversationVisibilityDocument} {...props} />;
export type GetMessengerConversationVisibilityProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetMessengerConversationVisibilityQuery, GetMessengerConversationVisibilityQueryVariables> } & TChildProps;
export function withGetMessengerConversationVisibility<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetMessengerConversationVisibilityQuery, GetMessengerConversationVisibilityQueryVariables, GetMessengerConversationVisibilityProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetMessengerConversationVisibilityQuery, GetMessengerConversationVisibilityQueryVariables, GetMessengerConversationVisibilityProps<TChildProps, TDataName>>(GetMessengerConversationVisibilityDocument, {
    alias: 'getMessengerConversationVisibility',
    ...operationOptions
  });
}
export type GetMessengerConversationVisibilityQueryResult = ApolloReactCommon.QueryResult<GetMessengerConversationVisibilityQuery, GetMessengerConversationVisibilityQueryVariables>;
export const GetMessengerConversationVisibility = (({
  operationKind: 'Query',
  document: GetMessengerConversationVisibilityDocument,
  primaryResultPath: ['messenger', 'conversation']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetMessengerConversationVisibilityQueryVariables;
  TResult: GetMessengerConversationVisibilityQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetMessengerConversationVisibilityQuery['messenger']['conversation'];
});
export const InitiateMessageHistoryExportDocument = gql`
	mutation InitiateMessageHistoryExport($id: ID!) {
		messenger {
			initiateMessageHistoryExport(conversationId: $id) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
				... on ExportInitiatedRecentlyError {
					unused
				}
			}
		}
	}
`;
export type InitiateMessageHistoryExportMutationFn = ApolloReactCommon.MutationFunction<InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables>;
export type InitiateMessageHistoryExportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables>, 'mutation'>;
export const InitiateMessageHistoryExportComponent = (props: InitiateMessageHistoryExportComponentProps) => <ApolloReactComponents.Mutation<InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables> mutation={InitiateMessageHistoryExportDocument} {...props} />;
export type InitiateMessageHistoryExportProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables> } & TChildProps;
export function withInitiateMessageHistoryExport<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables, InitiateMessageHistoryExportProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables, InitiateMessageHistoryExportProps<TChildProps, TDataName>>(InitiateMessageHistoryExportDocument, {
    alias: 'initiateMessageHistoryExport',
    ...operationOptions
  });
}
export type InitiateMessageHistoryExportMutationResult = ApolloReactCommon.MutationResult<InitiateMessageHistoryExportMutation>;
export type InitiateMessageHistoryExportMutationOptions = ApolloReactCommon.BaseMutationOptions<InitiateMessageHistoryExportMutation, InitiateMessageHistoryExportMutationVariables>;
export const InitiateMessageHistoryExport = (({
  operationKind: 'Mutation',
  document: InitiateMessageHistoryExportDocument,
  primaryResultPath: ['messenger', 'initiateMessageHistoryExport']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: InitiateMessageHistoryExportMutationVariables;
  TResult: InitiateMessageHistoryExportMutation;
  primaryResultPath: string[];
  TPrimaryResult: InitiateMessageHistoryExportMutation['messenger']['initiateMessageHistoryExport'];
});
export const ConversationStarterSuggestionsDocument = gql`
	query ConversationStarterSuggestions($conversationId: ID!) {
		messenger {
			conversationStarterSuggestions(conversationId: $conversationId) {
				... on ConversationStarterSuggestionText {
					...ConversationStarterSuggestionText
				}
				... on ConversationStarterSuggestionSmiley {
					...ConversationStarterSuggestionSmiley
				}
			}
		}
	}
	${ConversationStarterSuggestionTextFragmentDoc}
	${ConversationStarterSuggestionSmileyFragmentDoc}
`;
export type ConversationStarterSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConversationStarterSuggestionsQuery, ConversationStarterSuggestionsQueryVariables>, 'query'> & ({
  variables: ConversationStarterSuggestionsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const ConversationStarterSuggestionsComponent = (props: ConversationStarterSuggestionsComponentProps) => <ApolloReactComponents.Query<ConversationStarterSuggestionsQuery, ConversationStarterSuggestionsQueryVariables> query={ConversationStarterSuggestionsDocument} {...props} />;
export type ConversationStarterSuggestionsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ConversationStarterSuggestionsQuery, ConversationStarterSuggestionsQueryVariables> } & TChildProps;
export function withConversationStarterSuggestions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ConversationStarterSuggestionsQuery, ConversationStarterSuggestionsQueryVariables, ConversationStarterSuggestionsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ConversationStarterSuggestionsQuery, ConversationStarterSuggestionsQueryVariables, ConversationStarterSuggestionsProps<TChildProps, TDataName>>(ConversationStarterSuggestionsDocument, {
    alias: 'conversationStarterSuggestions',
    ...operationOptions
  });
}
export type ConversationStarterSuggestionsQueryResult = ApolloReactCommon.QueryResult<ConversationStarterSuggestionsQuery, ConversationStarterSuggestionsQueryVariables>;
export const ConversationStarterSuggestions = (({
  operationKind: 'Query',
  document: ConversationStarterSuggestionsDocument,
  primaryResultPath: ['messenger', 'conversationStarterSuggestions']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ConversationStarterSuggestionsQueryVariables;
  TResult: ConversationStarterSuggestionsQuery;
  primaryResultPath: string[];
  TPrimaryResult: ConversationStarterSuggestionsQuery['messenger']['conversationStarterSuggestions'];
});
export const SendConversationStarterSmileyDocument = gql`
	mutation SendConversationStarterSmiley(
		$conversationId: ID!
		$smileyId: ID!
		$messageCorrelationId: ID!
	) {
		messenger {
			sendConversationStarterSmiley(
				conversationId: $conversationId
				smileyId: $smileyId
				messageCorrelationId: $messageCorrelationId
			) {
				error {
					filterReason
					type
				}
			}
		}
	}
`;
export type SendConversationStarterSmileyMutationFn = ApolloReactCommon.MutationFunction<SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables>;
export type SendConversationStarterSmileyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables>, 'mutation'>;
export const SendConversationStarterSmileyComponent = (props: SendConversationStarterSmileyComponentProps) => <ApolloReactComponents.Mutation<SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables> mutation={SendConversationStarterSmileyDocument} {...props} />;
export type SendConversationStarterSmileyProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables> } & TChildProps;
export function withSendConversationStarterSmiley<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables, SendConversationStarterSmileyProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables, SendConversationStarterSmileyProps<TChildProps, TDataName>>(SendConversationStarterSmileyDocument, {
    alias: 'sendConversationStarterSmiley',
    ...operationOptions
  });
}
export type SendConversationStarterSmileyMutationResult = ApolloReactCommon.MutationResult<SendConversationStarterSmileyMutation>;
export type SendConversationStarterSmileyMutationOptions = ApolloReactCommon.BaseMutationOptions<SendConversationStarterSmileyMutation, SendConversationStarterSmileyMutationVariables>;
export const SendConversationStarterSmiley = (({
  operationKind: 'Mutation',
  document: SendConversationStarterSmileyDocument,
  primaryResultPath: ['messenger', 'sendConversationStarterSmiley']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendConversationStarterSmileyMutationVariables;
  TResult: SendConversationStarterSmileyMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendConversationStarterSmileyMutation['messenger']['sendConversationStarterSmiley'];
});
export const FriendRecommendationSubscriptionDocument = gql`
	subscription FriendRecommendationSubscription {
		friendRecommendationInConversationEvent {
			conversationId
		}
	}
`;
export type FriendRecommendationSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<FriendRecommendationSubscriptionSubscription, FriendRecommendationSubscriptionSubscriptionVariables>, 'subscription'>;
export const FriendRecommendationSubscriptionComponent = (props: FriendRecommendationSubscriptionComponentProps) => <ApolloReactComponents.Subscription<FriendRecommendationSubscriptionSubscription, FriendRecommendationSubscriptionSubscriptionVariables> subscription={FriendRecommendationSubscriptionDocument} {...props} />;
export type FriendRecommendationSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<FriendRecommendationSubscriptionSubscription, FriendRecommendationSubscriptionSubscriptionVariables> } & TChildProps;
export function withFriendRecommendationSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FriendRecommendationSubscriptionSubscription, FriendRecommendationSubscriptionSubscriptionVariables, FriendRecommendationSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, FriendRecommendationSubscriptionSubscription, FriendRecommendationSubscriptionSubscriptionVariables, FriendRecommendationSubscriptionProps<TChildProps, TDataName>>(FriendRecommendationSubscriptionDocument, {
    alias: 'friendRecommendationSubscription',
    ...operationOptions
  });
}
export type FriendRecommendationSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<FriendRecommendationSubscriptionSubscription>;
export const FriendRecommendationSubscription = (({
  operationKind: 'Subscription',
  document: FriendRecommendationSubscriptionDocument,
  primaryResultPath: ['friendRecommendationInConversationEvent', 'conversationId']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: FriendRecommendationSubscriptionSubscriptionVariables;
  TResult: FriendRecommendationSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: FriendRecommendationSubscriptionSubscription['friendRecommendationInConversationEvent']['conversationId'];
});
export const NicknameChangeReactionSmileysDocument = gql`
	query NicknameChangeReactionSmileys($conversationId: ID!) {
		messenger {
			nicknameChangeReactions(conversationId: $conversationId) {
				... on NicknameChangeReactionText {
					text
					formattedText
				}
				... on NicknameChangeReactionSmiley {
					smiley {
						id
						image
						textRepresentation
					}
				}
			}
		}
	}
`;
export type NicknameChangeReactionSmileysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NicknameChangeReactionSmileysQuery, NicknameChangeReactionSmileysQueryVariables>, 'query'> & ({
  variables: NicknameChangeReactionSmileysQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const NicknameChangeReactionSmileysComponent = (props: NicknameChangeReactionSmileysComponentProps) => <ApolloReactComponents.Query<NicknameChangeReactionSmileysQuery, NicknameChangeReactionSmileysQueryVariables> query={NicknameChangeReactionSmileysDocument} {...props} />;
export type NicknameChangeReactionSmileysProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<NicknameChangeReactionSmileysQuery, NicknameChangeReactionSmileysQueryVariables> } & TChildProps;
export function withNicknameChangeReactionSmileys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, NicknameChangeReactionSmileysQuery, NicknameChangeReactionSmileysQueryVariables, NicknameChangeReactionSmileysProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, NicknameChangeReactionSmileysQuery, NicknameChangeReactionSmileysQueryVariables, NicknameChangeReactionSmileysProps<TChildProps, TDataName>>(NicknameChangeReactionSmileysDocument, {
    alias: 'nicknameChangeReactionSmileys',
    ...operationOptions
  });
}
export type NicknameChangeReactionSmileysQueryResult = ApolloReactCommon.QueryResult<NicknameChangeReactionSmileysQuery, NicknameChangeReactionSmileysQueryVariables>;
export const NicknameChangeReactionSmileys = (({
  operationKind: 'Query',
  document: NicknameChangeReactionSmileysDocument,
  primaryResultPath: ['messenger', 'nicknameChangeReactions']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: NicknameChangeReactionSmileysQueryVariables;
  TResult: NicknameChangeReactionSmileysQuery;
  primaryResultPath: string[];
  TPrimaryResult: NicknameChangeReactionSmileysQuery['messenger']['nicknameChangeReactions'];
});
export const SendNicknameChangeSmileyReactionDocument = gql`
	mutation SendNicknameChangeSmileyReaction(
		$conversationId: ID!
		$smileyId: ID!
		$messageCorrelationId: ID!
	) {
		messenger {
			sendNicknameChangeReactionSmiley(
				conversationId: $conversationId
				smileyId: $smileyId
				messageCorrelationId: $messageCorrelationId
			) {
				error {
					filterReason
					type
				}
			}
		}
	}
`;
export type SendNicknameChangeSmileyReactionMutationFn = ApolloReactCommon.MutationFunction<SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables>;
export type SendNicknameChangeSmileyReactionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables>, 'mutation'>;
export const SendNicknameChangeSmileyReactionComponent = (props: SendNicknameChangeSmileyReactionComponentProps) => <ApolloReactComponents.Mutation<SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables> mutation={SendNicknameChangeSmileyReactionDocument} {...props} />;
export type SendNicknameChangeSmileyReactionProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables> } & TChildProps;
export function withSendNicknameChangeSmileyReaction<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables, SendNicknameChangeSmileyReactionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables, SendNicknameChangeSmileyReactionProps<TChildProps, TDataName>>(SendNicknameChangeSmileyReactionDocument, {
    alias: 'sendNicknameChangeSmileyReaction',
    ...operationOptions
  });
}
export type SendNicknameChangeSmileyReactionMutationResult = ApolloReactCommon.MutationResult<SendNicknameChangeSmileyReactionMutation>;
export type SendNicknameChangeSmileyReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<SendNicknameChangeSmileyReactionMutation, SendNicknameChangeSmileyReactionMutationVariables>;
export const SendNicknameChangeSmileyReaction = (({
  operationKind: 'Mutation',
  document: SendNicknameChangeSmileyReactionDocument,
  primaryResultPath: ['messenger', 'sendNicknameChangeReactionSmiley']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendNicknameChangeSmileyReactionMutationVariables;
  TResult: SendNicknameChangeSmileyReactionMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendNicknameChangeSmileyReactionMutation['messenger']['sendNicknameChangeReactionSmiley'];
});
export const ReactionSmileysDocument = gql`
	query ReactionSmileys {
		smileybox {
			reactionSmileys {
				id
				image
				textRepresentation
			}
		}
	}
`;
export type ReactionSmileysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReactionSmileysQuery, ReactionSmileysQueryVariables>, 'query'>;
export const ReactionSmileysComponent = (props: ReactionSmileysComponentProps) => <ApolloReactComponents.Query<ReactionSmileysQuery, ReactionSmileysQueryVariables> query={ReactionSmileysDocument} {...props} />;
export type ReactionSmileysProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ReactionSmileysQuery, ReactionSmileysQueryVariables> } & TChildProps;
export function withReactionSmileys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ReactionSmileysQuery, ReactionSmileysQueryVariables, ReactionSmileysProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ReactionSmileysQuery, ReactionSmileysQueryVariables, ReactionSmileysProps<TChildProps, TDataName>>(ReactionSmileysDocument, {
    alias: 'reactionSmileys',
    ...operationOptions
  });
}
export type ReactionSmileysQueryResult = ApolloReactCommon.QueryResult<ReactionSmileysQuery, ReactionSmileysQueryVariables>;
export const ReactionSmileys = (({
  operationKind: 'Query',
  document: ReactionSmileysDocument,
  primaryResultPath: ['smileybox', 'reactionSmileys']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ReactionSmileysQueryVariables;
  TResult: ReactionSmileysQuery;
  primaryResultPath: string[];
  TPrimaryResult: ReactionSmileysQuery['smileybox']['reactionSmileys'];
});
export const SendSmileyReactionDocument = gql`
	mutation SendSmileyReaction(
		$messageId: ID!
		$smileyId: ID!
		$messageCorrelationId: ID!
	) {
		messenger {
			sendSmileyReaction(
				messageId: $messageId
				smileyId: $smileyId
				messageCorrelationId: $messageCorrelationId
			) {
				error {
					filterReason
					type
				}
			}
		}
	}
`;
export type SendSmileyReactionMutationFn = ApolloReactCommon.MutationFunction<SendSmileyReactionMutation, SendSmileyReactionMutationVariables>;
export type SendSmileyReactionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendSmileyReactionMutation, SendSmileyReactionMutationVariables>, 'mutation'>;
export const SendSmileyReactionComponent = (props: SendSmileyReactionComponentProps) => <ApolloReactComponents.Mutation<SendSmileyReactionMutation, SendSmileyReactionMutationVariables> mutation={SendSmileyReactionDocument} {...props} />;
export type SendSmileyReactionProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendSmileyReactionMutation, SendSmileyReactionMutationVariables> } & TChildProps;
export function withSendSmileyReaction<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendSmileyReactionMutation, SendSmileyReactionMutationVariables, SendSmileyReactionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendSmileyReactionMutation, SendSmileyReactionMutationVariables, SendSmileyReactionProps<TChildProps, TDataName>>(SendSmileyReactionDocument, {
    alias: 'sendSmileyReaction',
    ...operationOptions
  });
}
export type SendSmileyReactionMutationResult = ApolloReactCommon.MutationResult<SendSmileyReactionMutation>;
export type SendSmileyReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSmileyReactionMutation, SendSmileyReactionMutationVariables>;
export const SendSmileyReaction = (({
  operationKind: 'Mutation',
  document: SendSmileyReactionDocument,
  primaryResultPath: ['messenger', 'sendSmileyReaction']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendSmileyReactionMutationVariables;
  TResult: SendSmileyReactionMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendSmileyReactionMutation['messenger']['sendSmileyReaction'];
});
export const MessengerSubscriptionDocument = gql`
	subscription MessengerSubscription($pixelDensity: Float!) {
		messengerEvent {
			... on MessengerMessageStarredStateChanged {
				...MessengerMessageStarredStateChanged
			}
			...MessengerMessageReceived
			...MessengerSettingsChanged
			...MessengerUserTypingStarted
			...MessengerUserTypingStopped
			...MessengerConversationReadStateChanged
			...MessengerConversationVisibilityChanged
			...MessengerMessageChanged
			...MessageHistoryExportReady
		}
	}
	${MessengerMessageStarredStateChangedFragmentDoc}
	${MessengerMessageReceivedFragmentDoc}
	${MessengerSettingsChangedFragmentDoc}
	${MessengerUserTypingStartedFragmentDoc}
	${MessengerUserTypingStoppedFragmentDoc}
	${MessengerConversationReadStateChangedFragmentDoc}
	${MessengerConversationVisibilityChangedFragmentDoc}
	${MessengerMessageChangedFragmentDoc}
	${MessageHistoryExportReadyFragmentDoc}
`;
export type MessengerSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<MessengerSubscriptionSubscription, MessengerSubscriptionSubscriptionVariables>, 'subscription'>;
export const MessengerSubscriptionComponent = (props: MessengerSubscriptionComponentProps) => <ApolloReactComponents.Subscription<MessengerSubscriptionSubscription, MessengerSubscriptionSubscriptionVariables> subscription={MessengerSubscriptionDocument} {...props} />;
export type MessengerSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<MessengerSubscriptionSubscription, MessengerSubscriptionSubscriptionVariables> } & TChildProps;
export function withMessengerSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MessengerSubscriptionSubscription, MessengerSubscriptionSubscriptionVariables, MessengerSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, MessengerSubscriptionSubscription, MessengerSubscriptionSubscriptionVariables, MessengerSubscriptionProps<TChildProps, TDataName>>(MessengerSubscriptionDocument, {
    alias: 'messengerSubscription',
    ...operationOptions
  });
}
export type MessengerSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<MessengerSubscriptionSubscription>;
export const MessengerSubscription = (({
  operationKind: 'Subscription',
  document: MessengerSubscriptionDocument,
  primaryResultPath: ['messengerEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: MessengerSubscriptionSubscriptionVariables;
  TResult: MessengerSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: MessengerSubscriptionSubscription['messengerEvent'];
});
export const CanSendImagesChangedDocument = gql`
	subscription CanSendImagesChanged {
		canSendImagesChanged {
			id
			canSendImages
		}
	}
`;
export type CanSendImagesChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CanSendImagesChangedSubscription, CanSendImagesChangedSubscriptionVariables>, 'subscription'>;
export const CanSendImagesChangedComponent = (props: CanSendImagesChangedComponentProps) => <ApolloReactComponents.Subscription<CanSendImagesChangedSubscription, CanSendImagesChangedSubscriptionVariables> subscription={CanSendImagesChangedDocument} {...props} />;
export type CanSendImagesChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<CanSendImagesChangedSubscription, CanSendImagesChangedSubscriptionVariables> } & TChildProps;
export function withCanSendImagesChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CanSendImagesChangedSubscription, CanSendImagesChangedSubscriptionVariables, CanSendImagesChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, CanSendImagesChangedSubscription, CanSendImagesChangedSubscriptionVariables, CanSendImagesChangedProps<TChildProps, TDataName>>(CanSendImagesChangedDocument, {
    alias: 'canSendImagesChanged',
    ...operationOptions
  });
}
export type CanSendImagesChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<CanSendImagesChangedSubscription>;
export const CanSendImagesChanged = (({
  operationKind: 'Subscription',
  document: CanSendImagesChangedDocument,
  primaryResultPath: ['canSendImagesChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: CanSendImagesChangedSubscriptionVariables;
  TResult: CanSendImagesChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: CanSendImagesChangedSubscription['canSendImagesChanged'];
});
export const MessengerMarkConversationsAsReadDocument = gql`
	mutation MessengerMarkConversationsAsRead($ids: [ID!]!) {
		messenger {
			readConversations(ids: $ids) {
				error
				conversation {
					id
					readState {
						lastReadConversationMessage {
							id
						}
						markedAsUnread
						unreadMessageCount
					}
				}
			}
		}
	}
`;
export type MessengerMarkConversationsAsReadMutationFn = ApolloReactCommon.MutationFunction<MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables>;
export type MessengerMarkConversationsAsReadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables>, 'mutation'>;
export const MessengerMarkConversationsAsReadComponent = (props: MessengerMarkConversationsAsReadComponentProps) => <ApolloReactComponents.Mutation<MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables> mutation={MessengerMarkConversationsAsReadDocument} {...props} />;
export type MessengerMarkConversationsAsReadProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables> } & TChildProps;
export function withMessengerMarkConversationsAsRead<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables, MessengerMarkConversationsAsReadProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables, MessengerMarkConversationsAsReadProps<TChildProps, TDataName>>(MessengerMarkConversationsAsReadDocument, {
    alias: 'messengerMarkConversationsAsRead',
    ...operationOptions
  });
}
export type MessengerMarkConversationsAsReadMutationResult = ApolloReactCommon.MutationResult<MessengerMarkConversationsAsReadMutation>;
export type MessengerMarkConversationsAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MessengerMarkConversationsAsReadMutation, MessengerMarkConversationsAsReadMutationVariables>;
export const MessengerMarkConversationsAsRead = (({
  operationKind: 'Mutation',
  document: MessengerMarkConversationsAsReadDocument,
  primaryResultPath: ['messenger', 'readConversations']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: MessengerMarkConversationsAsReadMutationVariables;
  TResult: MessengerMarkConversationsAsReadMutation;
  primaryResultPath: string[];
  TPrimaryResult: MessengerMarkConversationsAsReadMutation['messenger']['readConversations'];
});
export const MessengerMarkConversationAsUnreadDocument = gql`
	mutation MessengerMarkConversationAsUnread($id: ID!) {
		messenger {
			markConversationUnread(id: $id) {
				error
				conversation {
					id
					readState {
						lastReadConversationMessage {
							id
						}
						markedAsUnread
						unreadMessageCount
					}
				}
			}
		}
	}
`;
export type MessengerMarkConversationAsUnreadMutationFn = ApolloReactCommon.MutationFunction<MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables>;
export type MessengerMarkConversationAsUnreadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables>, 'mutation'>;
export const MessengerMarkConversationAsUnreadComponent = (props: MessengerMarkConversationAsUnreadComponentProps) => <ApolloReactComponents.Mutation<MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables> mutation={MessengerMarkConversationAsUnreadDocument} {...props} />;
export type MessengerMarkConversationAsUnreadProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables> } & TChildProps;
export function withMessengerMarkConversationAsUnread<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables, MessengerMarkConversationAsUnreadProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables, MessengerMarkConversationAsUnreadProps<TChildProps, TDataName>>(MessengerMarkConversationAsUnreadDocument, {
    alias: 'messengerMarkConversationAsUnread',
    ...operationOptions
  });
}
export type MessengerMarkConversationAsUnreadMutationResult = ApolloReactCommon.MutationResult<MessengerMarkConversationAsUnreadMutation>;
export type MessengerMarkConversationAsUnreadMutationOptions = ApolloReactCommon.BaseMutationOptions<MessengerMarkConversationAsUnreadMutation, MessengerMarkConversationAsUnreadMutationVariables>;
export const MessengerMarkConversationAsUnread = (({
  operationKind: 'Mutation',
  document: MessengerMarkConversationAsUnreadDocument,
  primaryResultPath: ['messenger', 'markConversationUnread']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: MessengerMarkConversationAsUnreadMutationVariables;
  TResult: MessengerMarkConversationAsUnreadMutation;
  primaryResultPath: string[];
  TPrimaryResult: MessengerMarkConversationAsUnreadMutation['messenger']['markConversationUnread'];
});
export const MessengerConversationReadStateDocument = gql`
	query MessengerConversationReadState($id: ID!) {
		messenger {
			conversation(id: $id) {
				id
				readState {
					markedAsUnread
					unreadMessageCount
					lastReadConversationMessage {
						id
					}
				}
			}
		}
	}
`;
export type MessengerConversationReadStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MessengerConversationReadStateQuery, MessengerConversationReadStateQueryVariables>, 'query'> & ({
  variables: MessengerConversationReadStateQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const MessengerConversationReadStateComponent = (props: MessengerConversationReadStateComponentProps) => <ApolloReactComponents.Query<MessengerConversationReadStateQuery, MessengerConversationReadStateQueryVariables> query={MessengerConversationReadStateDocument} {...props} />;
export type MessengerConversationReadStateProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<MessengerConversationReadStateQuery, MessengerConversationReadStateQueryVariables> } & TChildProps;
export function withMessengerConversationReadState<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MessengerConversationReadStateQuery, MessengerConversationReadStateQueryVariables, MessengerConversationReadStateProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, MessengerConversationReadStateQuery, MessengerConversationReadStateQueryVariables, MessengerConversationReadStateProps<TChildProps, TDataName>>(MessengerConversationReadStateDocument, {
    alias: 'messengerConversationReadState',
    ...operationOptions
  });
}
export type MessengerConversationReadStateQueryResult = ApolloReactCommon.QueryResult<MessengerConversationReadStateQuery, MessengerConversationReadStateQueryVariables>;
export const MessengerConversationReadState = (({
  operationKind: 'Query',
  document: MessengerConversationReadStateDocument,
  primaryResultPath: ['messenger', 'conversation']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: MessengerConversationReadStateQueryVariables;
  TResult: MessengerConversationReadStateQuery;
  primaryResultPath: string[];
  TPrimaryResult: MessengerConversationReadStateQuery['messenger']['conversation'];
});
export const RegisterFirebaseIdDocument = gql`
	mutation RegisterFirebaseId($instanceId: String!) {
		pushnotification {
			registerFirebaseInstanceId(instanceId: $instanceId) {
				__typename
			}
		}
	}
`;
export type RegisterFirebaseIdMutationFn = ApolloReactCommon.MutationFunction<RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables>;
export type RegisterFirebaseIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables>, 'mutation'>;
export const RegisterFirebaseIdComponent = (props: RegisterFirebaseIdComponentProps) => <ApolloReactComponents.Mutation<RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables> mutation={RegisterFirebaseIdDocument} {...props} />;
export type RegisterFirebaseIdProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables> } & TChildProps;
export function withRegisterFirebaseId<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables, RegisterFirebaseIdProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables, RegisterFirebaseIdProps<TChildProps, TDataName>>(RegisterFirebaseIdDocument, {
    alias: 'registerFirebaseId',
    ...operationOptions
  });
}
export type RegisterFirebaseIdMutationResult = ApolloReactCommon.MutationResult<RegisterFirebaseIdMutation>;
export type RegisterFirebaseIdMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterFirebaseIdMutation, RegisterFirebaseIdMutationVariables>;
export const RegisterFirebaseId = (({
  operationKind: 'Mutation',
  document: RegisterFirebaseIdDocument,
  primaryResultPath: ['pushnotification', 'registerFirebaseInstanceId', '__typename']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: RegisterFirebaseIdMutationVariables;
  TResult: RegisterFirebaseIdMutation;
  primaryResultPath: string[];
  TPrimaryResult: RegisterFirebaseIdMutation['pushnotification']['registerFirebaseInstanceId']['__typename'];
});
export const RemoveFirebaseIdDocument = gql`
	mutation RemoveFirebaseId($instanceId: String!) {
		pushnotification {
			removeFirebaseInstanceId(instanceId: $instanceId) {
				__typename
			}
		}
	}
`;
export type RemoveFirebaseIdMutationFn = ApolloReactCommon.MutationFunction<RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables>;
export type RemoveFirebaseIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables>, 'mutation'>;
export const RemoveFirebaseIdComponent = (props: RemoveFirebaseIdComponentProps) => <ApolloReactComponents.Mutation<RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables> mutation={RemoveFirebaseIdDocument} {...props} />;
export type RemoveFirebaseIdProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables> } & TChildProps;
export function withRemoveFirebaseId<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables, RemoveFirebaseIdProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables, RemoveFirebaseIdProps<TChildProps, TDataName>>(RemoveFirebaseIdDocument, {
    alias: 'removeFirebaseId',
    ...operationOptions
  });
}
export type RemoveFirebaseIdMutationResult = ApolloReactCommon.MutationResult<RemoveFirebaseIdMutation>;
export type RemoveFirebaseIdMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFirebaseIdMutation, RemoveFirebaseIdMutationVariables>;
export const RemoveFirebaseId = (({
  operationKind: 'Mutation',
  document: RemoveFirebaseIdDocument,
  primaryResultPath: ['pushnotification', 'removeFirebaseInstanceId', '__typename']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: RemoveFirebaseIdMutationVariables;
  TResult: RemoveFirebaseIdMutation;
  primaryResultPath: string[];
  TPrimaryResult: RemoveFirebaseIdMutation['pushnotification']['removeFirebaseInstanceId']['__typename'];
});
export const NotificationsDocument = gql`
	subscription Notifications($pixelDensity: Float!) {
		notificationReceived {
			...Notification
		}
	}
	${NotificationFragmentDoc}
`;
export type NotificationsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<NotificationsSubscription, NotificationsSubscriptionVariables>, 'subscription'>;
export const NotificationsComponent = (props: NotificationsComponentProps) => <ApolloReactComponents.Subscription<NotificationsSubscription, NotificationsSubscriptionVariables> subscription={NotificationsDocument} {...props} />;
export type NotificationsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<NotificationsSubscription, NotificationsSubscriptionVariables> } & TChildProps;
export function withNotifications<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, NotificationsSubscription, NotificationsSubscriptionVariables, NotificationsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, NotificationsSubscription, NotificationsSubscriptionVariables, NotificationsProps<TChildProps, TDataName>>(NotificationsDocument, {
    alias: 'notifications',
    ...operationOptions
  });
}
export type NotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<NotificationsSubscription>;
export const Notifications = (({
  operationKind: 'Subscription',
  document: NotificationsDocument,
  primaryResultPath: ['notificationReceived']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: NotificationsSubscriptionVariables;
  TResult: NotificationsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: NotificationsSubscription['notificationReceived'];
});
export const StartWebPaymentDocument = gql`
	mutation StartWebPayment($productId: ID!) {
		payment {
			startWebPayment(product: $productId) {
				error
				paymentUrl
			}
		}
	}
`;
export type StartWebPaymentMutationFn = ApolloReactCommon.MutationFunction<StartWebPaymentMutation, StartWebPaymentMutationVariables>;
export type StartWebPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartWebPaymentMutation, StartWebPaymentMutationVariables>, 'mutation'>;
export const StartWebPaymentComponent = (props: StartWebPaymentComponentProps) => <ApolloReactComponents.Mutation<StartWebPaymentMutation, StartWebPaymentMutationVariables> mutation={StartWebPaymentDocument} {...props} />;
export type StartWebPaymentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<StartWebPaymentMutation, StartWebPaymentMutationVariables> } & TChildProps;
export function withStartWebPayment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, StartWebPaymentMutation, StartWebPaymentMutationVariables, StartWebPaymentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, StartWebPaymentMutation, StartWebPaymentMutationVariables, StartWebPaymentProps<TChildProps, TDataName>>(StartWebPaymentDocument, {
    alias: 'startWebPayment',
    ...operationOptions
  });
}
export type StartWebPaymentMutationResult = ApolloReactCommon.MutationResult<StartWebPaymentMutation>;
export type StartWebPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<StartWebPaymentMutation, StartWebPaymentMutationVariables>;
export const StartWebPayment = (({
  operationKind: 'Mutation',
  document: StartWebPaymentDocument,
  primaryResultPath: ['payment', 'startWebPayment']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: StartWebPaymentMutationVariables;
  TResult: StartWebPaymentMutation;
  primaryResultPath: string[];
  TPrimaryResult: StartWebPaymentMutation['payment']['startWebPayment'];
});
export const StartAndroidPaymentDocument = gql`
	mutation StartAndroidPayment($productId: ID!) {
		payment {
			startAndroidPayment(product: $productId) {
				productInfo {
					skuIds
					isSubscription
				}
				error
			}
		}
	}
`;
export type StartAndroidPaymentMutationFn = ApolloReactCommon.MutationFunction<StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables>;
export type StartAndroidPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables>, 'mutation'>;
export const StartAndroidPaymentComponent = (props: StartAndroidPaymentComponentProps) => <ApolloReactComponents.Mutation<StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables> mutation={StartAndroidPaymentDocument} {...props} />;
export type StartAndroidPaymentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables> } & TChildProps;
export function withStartAndroidPayment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables, StartAndroidPaymentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables, StartAndroidPaymentProps<TChildProps, TDataName>>(StartAndroidPaymentDocument, {
    alias: 'startAndroidPayment',
    ...operationOptions
  });
}
export type StartAndroidPaymentMutationResult = ApolloReactCommon.MutationResult<StartAndroidPaymentMutation>;
export type StartAndroidPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<StartAndroidPaymentMutation, StartAndroidPaymentMutationVariables>;
export const StartAndroidPayment = (({
  operationKind: 'Mutation',
  document: StartAndroidPaymentDocument,
  primaryResultPath: ['payment', 'startAndroidPayment']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: StartAndroidPaymentMutationVariables;
  TResult: StartAndroidPaymentMutation;
  primaryResultPath: string[];
  TPrimaryResult: StartAndroidPaymentMutation['payment']['startAndroidPayment'];
});
export const FinishAndroidPaymentDocument = gql`
	mutation FinishAndroidPayment($payload: String!) {
		payment {
			finishAndroidPayment(payload: $payload) {
				error
			}
		}
	}
`;
export type FinishAndroidPaymentMutationFn = ApolloReactCommon.MutationFunction<FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables>;
export type FinishAndroidPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables>, 'mutation'>;
export const FinishAndroidPaymentComponent = (props: FinishAndroidPaymentComponentProps) => <ApolloReactComponents.Mutation<FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables> mutation={FinishAndroidPaymentDocument} {...props} />;
export type FinishAndroidPaymentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables> } & TChildProps;
export function withFinishAndroidPayment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables, FinishAndroidPaymentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables, FinishAndroidPaymentProps<TChildProps, TDataName>>(FinishAndroidPaymentDocument, {
    alias: 'finishAndroidPayment',
    ...operationOptions
  });
}
export type FinishAndroidPaymentMutationResult = ApolloReactCommon.MutationResult<FinishAndroidPaymentMutation>;
export type FinishAndroidPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<FinishAndroidPaymentMutation, FinishAndroidPaymentMutationVariables>;
export const FinishAndroidPayment = (({
  operationKind: 'Mutation',
  document: FinishAndroidPaymentDocument,
  primaryResultPath: ['payment', 'finishAndroidPayment']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: FinishAndroidPaymentMutationVariables;
  TResult: FinishAndroidPaymentMutation;
  primaryResultPath: string[];
  TPrimaryResult: FinishAndroidPaymentMutation['payment']['finishAndroidPayment'];
});
export const StartIosPaymentDocument = gql`
	mutation StartIosPayment($productId: ID!) {
		payment {
			startIosPayment(product: $productId) {
				productInfo {
					skuIds
					isSubscription
				}
				error
			}
		}
	}
`;
export type StartIosPaymentMutationFn = ApolloReactCommon.MutationFunction<StartIosPaymentMutation, StartIosPaymentMutationVariables>;
export type StartIosPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartIosPaymentMutation, StartIosPaymentMutationVariables>, 'mutation'>;
export const StartIosPaymentComponent = (props: StartIosPaymentComponentProps) => <ApolloReactComponents.Mutation<StartIosPaymentMutation, StartIosPaymentMutationVariables> mutation={StartIosPaymentDocument} {...props} />;
export type StartIosPaymentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<StartIosPaymentMutation, StartIosPaymentMutationVariables> } & TChildProps;
export function withStartIosPayment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, StartIosPaymentMutation, StartIosPaymentMutationVariables, StartIosPaymentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, StartIosPaymentMutation, StartIosPaymentMutationVariables, StartIosPaymentProps<TChildProps, TDataName>>(StartIosPaymentDocument, {
    alias: 'startIosPayment',
    ...operationOptions
  });
}
export type StartIosPaymentMutationResult = ApolloReactCommon.MutationResult<StartIosPaymentMutation>;
export type StartIosPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<StartIosPaymentMutation, StartIosPaymentMutationVariables>;
export const StartIosPayment = (({
  operationKind: 'Mutation',
  document: StartIosPaymentDocument,
  primaryResultPath: ['payment', 'startIosPayment']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: StartIosPaymentMutationVariables;
  TResult: StartIosPaymentMutation;
  primaryResultPath: string[];
  TPrimaryResult: StartIosPaymentMutation['payment']['startIosPayment'];
});
export const FinishIosPaymentDocument = gql`
	mutation FinishIosPayment($payload: String!, $transactionId: String) {
		payment {
			finishIosPayment(receipt: $payload, transactionId: $transactionId) {
				error
			}
		}
	}
`;
export type FinishIosPaymentMutationFn = ApolloReactCommon.MutationFunction<FinishIosPaymentMutation, FinishIosPaymentMutationVariables>;
export type FinishIosPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FinishIosPaymentMutation, FinishIosPaymentMutationVariables>, 'mutation'>;
export const FinishIosPaymentComponent = (props: FinishIosPaymentComponentProps) => <ApolloReactComponents.Mutation<FinishIosPaymentMutation, FinishIosPaymentMutationVariables> mutation={FinishIosPaymentDocument} {...props} />;
export type FinishIosPaymentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<FinishIosPaymentMutation, FinishIosPaymentMutationVariables> } & TChildProps;
export function withFinishIosPayment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, FinishIosPaymentMutation, FinishIosPaymentMutationVariables, FinishIosPaymentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, FinishIosPaymentMutation, FinishIosPaymentMutationVariables, FinishIosPaymentProps<TChildProps, TDataName>>(FinishIosPaymentDocument, {
    alias: 'finishIosPayment',
    ...operationOptions
  });
}
export type FinishIosPaymentMutationResult = ApolloReactCommon.MutationResult<FinishIosPaymentMutation>;
export type FinishIosPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<FinishIosPaymentMutation, FinishIosPaymentMutationVariables>;
export const FinishIosPayment = (({
  operationKind: 'Mutation',
  document: FinishIosPaymentDocument,
  primaryResultPath: ['payment', 'finishIosPayment']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: FinishIosPaymentMutationVariables;
  TResult: FinishIosPaymentMutation;
  primaryResultPath: string[];
  TPrimaryResult: FinishIosPaymentMutation['payment']['finishIosPayment'];
});
export const PaymentSubscriptionDocument = gql`
	subscription PaymentSubscription {
		paymentEvent {
			... on PaymentSucceeded {
				productId
			}
			... on PaymentFailed {
				productId
			}
		}
	}
`;
export type PaymentSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PaymentSubscriptionSubscription, PaymentSubscriptionSubscriptionVariables>, 'subscription'>;
export const PaymentSubscriptionComponent = (props: PaymentSubscriptionComponentProps) => <ApolloReactComponents.Subscription<PaymentSubscriptionSubscription, PaymentSubscriptionSubscriptionVariables> subscription={PaymentSubscriptionDocument} {...props} />;
export type PaymentSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<PaymentSubscriptionSubscription, PaymentSubscriptionSubscriptionVariables> } & TChildProps;
export function withPaymentSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PaymentSubscriptionSubscription, PaymentSubscriptionSubscriptionVariables, PaymentSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, PaymentSubscriptionSubscription, PaymentSubscriptionSubscriptionVariables, PaymentSubscriptionProps<TChildProps, TDataName>>(PaymentSubscriptionDocument, {
    alias: 'paymentSubscription',
    ...operationOptions
  });
}
export type PaymentSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<PaymentSubscriptionSubscription>;
export const PaymentSubscription = (({
  operationKind: 'Subscription',
  document: PaymentSubscriptionDocument,
  primaryResultPath: ['paymentEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: PaymentSubscriptionSubscriptionVariables;
  TResult: PaymentSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: PaymentSubscriptionSubscription['paymentEvent'];
});
export const GetUnusedSkuIdDocument = gql`
	query GetUnusedSkuId($skuIds: [String!]!, $receipt: String!) {
		payment {
			unusedSkuIdIos(skuIds: $skuIds, receipt: $receipt)
		}
	}
`;
export type GetUnusedSkuIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnusedSkuIdQuery, GetUnusedSkuIdQueryVariables>, 'query'> & ({
  variables: GetUnusedSkuIdQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetUnusedSkuIdComponent = (props: GetUnusedSkuIdComponentProps) => <ApolloReactComponents.Query<GetUnusedSkuIdQuery, GetUnusedSkuIdQueryVariables> query={GetUnusedSkuIdDocument} {...props} />;
export type GetUnusedSkuIdProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetUnusedSkuIdQuery, GetUnusedSkuIdQueryVariables> } & TChildProps;
export function withGetUnusedSkuId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetUnusedSkuIdQuery, GetUnusedSkuIdQueryVariables, GetUnusedSkuIdProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetUnusedSkuIdQuery, GetUnusedSkuIdQueryVariables, GetUnusedSkuIdProps<TChildProps, TDataName>>(GetUnusedSkuIdDocument, {
    alias: 'getUnusedSkuId',
    ...operationOptions
  });
}
export type GetUnusedSkuIdQueryResult = ApolloReactCommon.QueryResult<GetUnusedSkuIdQuery, GetUnusedSkuIdQueryVariables>;
export const GetUnusedSkuId = (({
  operationKind: 'Query',
  document: GetUnusedSkuIdDocument,
  primaryResultPath: ['payment', 'unusedSkuIdIos']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetUnusedSkuIdQueryVariables;
  TResult: GetUnusedSkuIdQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetUnusedSkuIdQuery['payment']['unusedSkuIdIos'];
});
export const GetNativeProductInfoDocument = gql`
	query GetNativeProductInfo($skuId: String!) {
		payment {
			nativeProductInfo(skuId: $skuId) {
				isSubscription
			}
		}
	}
`;
export type GetNativeProductInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNativeProductInfoQuery, GetNativeProductInfoQueryVariables>, 'query'> & ({
  variables: GetNativeProductInfoQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetNativeProductInfoComponent = (props: GetNativeProductInfoComponentProps) => <ApolloReactComponents.Query<GetNativeProductInfoQuery, GetNativeProductInfoQueryVariables> query={GetNativeProductInfoDocument} {...props} />;
export type GetNativeProductInfoProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetNativeProductInfoQuery, GetNativeProductInfoQueryVariables> } & TChildProps;
export function withGetNativeProductInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetNativeProductInfoQuery, GetNativeProductInfoQueryVariables, GetNativeProductInfoProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetNativeProductInfoQuery, GetNativeProductInfoQueryVariables, GetNativeProductInfoProps<TChildProps, TDataName>>(GetNativeProductInfoDocument, {
    alias: 'getNativeProductInfo',
    ...operationOptions
  });
}
export type GetNativeProductInfoQueryResult = ApolloReactCommon.QueryResult<GetNativeProductInfoQuery, GetNativeProductInfoQueryVariables>;
export const GetNativeProductInfo = (({
  operationKind: 'Query',
  document: GetNativeProductInfoDocument,
  primaryResultPath: ['payment', 'nativeProductInfo', 'isSubscription']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetNativeProductInfoQueryVariables;
  TResult: GetNativeProductInfoQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetNativeProductInfoQuery['payment']['nativeProductInfo']['isSubscription'];
});
export const GetUserForProfileDocument = gql`
	query GetUserForProfile($userId: ID!, $pixelDensity: Float!) {
		user {
			user(id: $userId) {
				...UserForProfile
			}
		}
		contacts {
			friends(userId: $userId) {
				... on FriendsHiddenByPrivacy {
					unused
				}
				... on FriendList {
					friends {
						...ProfileFriendUser
					}
				}
				... on InternalError {
					unused
				}
			}
			commonFriends(userId: $userId) {
				... on FriendsHiddenByPrivacy {
					unused
				}
				... on FriendList {
					friends {
						...ProfileCommonFriendUser
					}
				}
				... on InternalError {
					unused
				}
			}
		}
		messenger {
			conversationWithParticipants(otherParticipantIds: [$userId]) {
				id
			}
		}
	}
	${UserForProfileFragmentDoc}
	${ProfileFriendUserFragmentDoc}
	${ProfileCommonFriendUserFragmentDoc}
`;
export type GetUserForProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserForProfileQuery, GetUserForProfileQueryVariables>, 'query'> & ({
  variables: GetUserForProfileQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetUserForProfileComponent = (props: GetUserForProfileComponentProps) => <ApolloReactComponents.Query<GetUserForProfileQuery, GetUserForProfileQueryVariables> query={GetUserForProfileDocument} {...props} />;
export type GetUserForProfileProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetUserForProfileQuery, GetUserForProfileQueryVariables> } & TChildProps;
export function withGetUserForProfile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetUserForProfileQuery, GetUserForProfileQueryVariables, GetUserForProfileProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetUserForProfileQuery, GetUserForProfileQueryVariables, GetUserForProfileProps<TChildProps, TDataName>>(GetUserForProfileDocument, {
    alias: 'getUserForProfile',
    ...operationOptions
  });
}
export type GetUserForProfileQueryResult = ApolloReactCommon.QueryResult<GetUserForProfileQuery, GetUserForProfileQueryVariables>;
export const GetUserForProfile = (({
  operationKind: 'Query',
  document: GetUserForProfileDocument,
  primaryResultPath: []
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetUserForProfileQueryVariables;
  TResult: GetUserForProfileQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetUserForProfileQuery;
});
export const GetAlbumInfoForProfileDocument = gql`
	query GetAlbumInfoForProfile($userId: ID!) {
		user {
			user(id: $userId) {
				albumPhotos(limit: 12) {
					id
					thumbnailUrl
				}
				albums {
					...Album
				}
				albumProfilePhoto {
					...AlbumDetailPhoto
				}
			}
		}
	}
	${AlbumFragmentDoc}
	${AlbumDetailPhotoFragmentDoc}
`;
export type GetAlbumInfoForProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAlbumInfoForProfileQuery, GetAlbumInfoForProfileQueryVariables>, 'query'> & ({
  variables: GetAlbumInfoForProfileQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetAlbumInfoForProfileComponent = (props: GetAlbumInfoForProfileComponentProps) => <ApolloReactComponents.Query<GetAlbumInfoForProfileQuery, GetAlbumInfoForProfileQueryVariables> query={GetAlbumInfoForProfileDocument} {...props} />;
export type GetAlbumInfoForProfileProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetAlbumInfoForProfileQuery, GetAlbumInfoForProfileQueryVariables> } & TChildProps;
export function withGetAlbumInfoForProfile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetAlbumInfoForProfileQuery, GetAlbumInfoForProfileQueryVariables, GetAlbumInfoForProfileProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetAlbumInfoForProfileQuery, GetAlbumInfoForProfileQueryVariables, GetAlbumInfoForProfileProps<TChildProps, TDataName>>(GetAlbumInfoForProfileDocument, {
    alias: 'getAlbumInfoForProfile',
    ...operationOptions
  });
}
export type GetAlbumInfoForProfileQueryResult = ApolloReactCommon.QueryResult<GetAlbumInfoForProfileQuery, GetAlbumInfoForProfileQueryVariables>;
export const GetAlbumInfoForProfile = (({
  operationKind: 'Query',
  document: GetAlbumInfoForProfileDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetAlbumInfoForProfileQueryVariables;
  TResult: GetAlbumInfoForProfileQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetAlbumInfoForProfileQuery['user']['user'];
});
export const GetUserForQuickWhoisDocument = gql`
	query GetUserForQuickWhois($userId: ID!) {
		user {
			user(id: $userId) {
				...UserForQuickWhois
			}
			currentUser {
				id
			}
		}
		messenger {
			conversationWithParticipants(otherParticipantIds: [$userId]) {
				id
			}
		}
	}
	${UserForQuickWhoisFragmentDoc}
`;
export type GetUserForQuickWhoisComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserForQuickWhoisQuery, GetUserForQuickWhoisQueryVariables>, 'query'> & ({
  variables: GetUserForQuickWhoisQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetUserForQuickWhoisComponent = (props: GetUserForQuickWhoisComponentProps) => <ApolloReactComponents.Query<GetUserForQuickWhoisQuery, GetUserForQuickWhoisQueryVariables> query={GetUserForQuickWhoisDocument} {...props} />;
export type GetUserForQuickWhoisProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetUserForQuickWhoisQuery, GetUserForQuickWhoisQueryVariables> } & TChildProps;
export function withGetUserForQuickWhois<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetUserForQuickWhoisQuery, GetUserForQuickWhoisQueryVariables, GetUserForQuickWhoisProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetUserForQuickWhoisQuery, GetUserForQuickWhoisQueryVariables, GetUserForQuickWhoisProps<TChildProps, TDataName>>(GetUserForQuickWhoisDocument, {
    alias: 'getUserForQuickWhois',
    ...operationOptions
  });
}
export type GetUserForQuickWhoisQueryResult = ApolloReactCommon.QueryResult<GetUserForQuickWhoisQuery, GetUserForQuickWhoisQueryVariables>;
export const GetUserForQuickWhois = (({
  operationKind: 'Query',
  document: GetUserForQuickWhoisDocument,
  primaryResultPath: []
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetUserForQuickWhoisQueryVariables;
  TResult: GetUserForQuickWhoisQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetUserForQuickWhoisQuery;
});
export const NotifyProfileVisitedDocument = gql`
	mutation NotifyProfileVisited($userId: ID!) {
		user {
			notifyProfileVisited(userId: $userId)
		}
	}
`;
export type NotifyProfileVisitedMutationFn = ApolloReactCommon.MutationFunction<NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables>;
export type NotifyProfileVisitedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables>, 'mutation'>;
export const NotifyProfileVisitedComponent = (props: NotifyProfileVisitedComponentProps) => <ApolloReactComponents.Mutation<NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables> mutation={NotifyProfileVisitedDocument} {...props} />;
export type NotifyProfileVisitedProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables> } & TChildProps;
export function withNotifyProfileVisited<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables, NotifyProfileVisitedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables, NotifyProfileVisitedProps<TChildProps, TDataName>>(NotifyProfileVisitedDocument, {
    alias: 'notifyProfileVisited',
    ...operationOptions
  });
}
export type NotifyProfileVisitedMutationResult = ApolloReactCommon.MutationResult<NotifyProfileVisitedMutation>;
export type NotifyProfileVisitedMutationOptions = ApolloReactCommon.BaseMutationOptions<NotifyProfileVisitedMutation, NotifyProfileVisitedMutationVariables>;
export const NotifyProfileVisited = (({
  operationKind: 'Mutation',
  document: NotifyProfileVisitedDocument,
  primaryResultPath: ['user', 'notifyProfileVisited']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: NotifyProfileVisitedMutationVariables;
  TResult: NotifyProfileVisitedMutation;
  primaryResultPath: string[];
  TPrimaryResult: NotifyProfileVisitedMutation['user']['notifyProfileVisited'];
});
export const NotifyProfilePicturedViewedDocument = gql`
	mutation NotifyProfilePicturedViewed($userId: ID!) {
		user {
			notifyProfilePictureViewed(userId: $userId)
		}
	}
`;
export type NotifyProfilePicturedViewedMutationFn = ApolloReactCommon.MutationFunction<NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables>;
export type NotifyProfilePicturedViewedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables>, 'mutation'>;
export const NotifyProfilePicturedViewedComponent = (props: NotifyProfilePicturedViewedComponentProps) => <ApolloReactComponents.Mutation<NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables> mutation={NotifyProfilePicturedViewedDocument} {...props} />;
export type NotifyProfilePicturedViewedProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables> } & TChildProps;
export function withNotifyProfilePicturedViewed<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables, NotifyProfilePicturedViewedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables, NotifyProfilePicturedViewedProps<TChildProps, TDataName>>(NotifyProfilePicturedViewedDocument, {
    alias: 'notifyProfilePicturedViewed',
    ...operationOptions
  });
}
export type NotifyProfilePicturedViewedMutationResult = ApolloReactCommon.MutationResult<NotifyProfilePicturedViewedMutation>;
export type NotifyProfilePicturedViewedMutationOptions = ApolloReactCommon.BaseMutationOptions<NotifyProfilePicturedViewedMutation, NotifyProfilePicturedViewedMutationVariables>;
export const NotifyProfilePicturedViewed = (({
  operationKind: 'Mutation',
  document: NotifyProfilePicturedViewedDocument,
  primaryResultPath: ['user', 'notifyProfilePictureViewed']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: NotifyProfilePicturedViewedMutationVariables;
  TResult: NotifyProfilePicturedViewedMutation;
  primaryResultPath: string[];
  TPrimaryResult: NotifyProfilePicturedViewedMutation['user']['notifyProfilePictureViewed'];
});
export const NotifyAlbumPhotosViewedDocument = gql`
	mutation NotifyAlbumPhotosViewed($userId: ID!) {
		user {
			notifyAlbumPhotoViewed(userId: $userId)
		}
	}
`;
export type NotifyAlbumPhotosViewedMutationFn = ApolloReactCommon.MutationFunction<NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables>;
export type NotifyAlbumPhotosViewedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables>, 'mutation'>;
export const NotifyAlbumPhotosViewedComponent = (props: NotifyAlbumPhotosViewedComponentProps) => <ApolloReactComponents.Mutation<NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables> mutation={NotifyAlbumPhotosViewedDocument} {...props} />;
export type NotifyAlbumPhotosViewedProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables> } & TChildProps;
export function withNotifyAlbumPhotosViewed<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables, NotifyAlbumPhotosViewedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables, NotifyAlbumPhotosViewedProps<TChildProps, TDataName>>(NotifyAlbumPhotosViewedDocument, {
    alias: 'notifyAlbumPhotosViewed',
    ...operationOptions
  });
}
export type NotifyAlbumPhotosViewedMutationResult = ApolloReactCommon.MutationResult<NotifyAlbumPhotosViewedMutation>;
export type NotifyAlbumPhotosViewedMutationOptions = ApolloReactCommon.BaseMutationOptions<NotifyAlbumPhotosViewedMutation, NotifyAlbumPhotosViewedMutationVariables>;
export const NotifyAlbumPhotosViewed = (({
  operationKind: 'Mutation',
  document: NotifyAlbumPhotosViewedDocument,
  primaryResultPath: ['user', 'notifyAlbumPhotoViewed']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: NotifyAlbumPhotosViewedMutationVariables;
  TResult: NotifyAlbumPhotosViewedMutation;
  primaryResultPath: string[];
  TPrimaryResult: NotifyAlbumPhotosViewedMutation['user']['notifyAlbumPhotoViewed'];
});
export const EditProfileEntriesDocument = gql`
	query EditProfileEntries {
		user {
			profileEditEntries {
				...EditProfileEntry
			}
		}
		payment {
			nickSwitchKnuddelCost
		}
	}
	${EditProfileEntryFragmentDoc}
`;
export type EditProfileEntriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EditProfileEntriesQuery, EditProfileEntriesQueryVariables>, 'query'>;
export const EditProfileEntriesComponent = (props: EditProfileEntriesComponentProps) => <ApolloReactComponents.Query<EditProfileEntriesQuery, EditProfileEntriesQueryVariables> query={EditProfileEntriesDocument} {...props} />;
export type EditProfileEntriesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<EditProfileEntriesQuery, EditProfileEntriesQueryVariables> } & TChildProps;
export function withEditProfileEntries<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditProfileEntriesQuery, EditProfileEntriesQueryVariables, EditProfileEntriesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, EditProfileEntriesQuery, EditProfileEntriesQueryVariables, EditProfileEntriesProps<TChildProps, TDataName>>(EditProfileEntriesDocument, {
    alias: 'editProfileEntries',
    ...operationOptions
  });
}
export type EditProfileEntriesQueryResult = ApolloReactCommon.QueryResult<EditProfileEntriesQuery, EditProfileEntriesQueryVariables>;
export const EditProfileEntries = (({
  operationKind: 'Query',
  document: EditProfileEntriesDocument,
  primaryResultPath: []
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: EditProfileEntriesQueryVariables;
  TResult: EditProfileEntriesQuery;
  primaryResultPath: string[];
  TPrimaryResult: EditProfileEntriesQuery;
});
export const EditFreeTextEntryDocument = gql`
	mutation EditFreeTextEntry($field: ProfileEditField!, $value: String!) {
		user {
			editProfileEntryFreeText(field: $field, value: $value) {
				errorResult {
					details
					error
				}
			}
		}
	}
`;
export type EditFreeTextEntryMutationFn = ApolloReactCommon.MutationFunction<EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables>;
export type EditFreeTextEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables>, 'mutation'>;
export const EditFreeTextEntryComponent = (props: EditFreeTextEntryComponentProps) => <ApolloReactComponents.Mutation<EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables> mutation={EditFreeTextEntryDocument} {...props} />;
export type EditFreeTextEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables> } & TChildProps;
export function withEditFreeTextEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables, EditFreeTextEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables, EditFreeTextEntryProps<TChildProps, TDataName>>(EditFreeTextEntryDocument, {
    alias: 'editFreeTextEntry',
    ...operationOptions
  });
}
export type EditFreeTextEntryMutationResult = ApolloReactCommon.MutationResult<EditFreeTextEntryMutation>;
export type EditFreeTextEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFreeTextEntryMutation, EditFreeTextEntryMutationVariables>;
export const EditFreeTextEntry = (({
  operationKind: 'Mutation',
  document: EditFreeTextEntryDocument,
  primaryResultPath: ['user', 'editProfileEntryFreeText', 'errorResult']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditFreeTextEntryMutationVariables;
  TResult: EditFreeTextEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditFreeTextEntryMutation['user']['editProfileEntryFreeText']['errorResult'];
});
export const EditStringListEntryDocument = gql`
	mutation EditStringListEntry(
		$field: ProfileEditField!
		$value: [String!]!
	) {
		user {
			editProfileEntryStringList(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditStringListEntryMutationFn = ApolloReactCommon.MutationFunction<EditStringListEntryMutation, EditStringListEntryMutationVariables>;
export type EditStringListEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditStringListEntryMutation, EditStringListEntryMutationVariables>, 'mutation'>;
export const EditStringListEntryComponent = (props: EditStringListEntryComponentProps) => <ApolloReactComponents.Mutation<EditStringListEntryMutation, EditStringListEntryMutationVariables> mutation={EditStringListEntryDocument} {...props} />;
export type EditStringListEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditStringListEntryMutation, EditStringListEntryMutationVariables> } & TChildProps;
export function withEditStringListEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditStringListEntryMutation, EditStringListEntryMutationVariables, EditStringListEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditStringListEntryMutation, EditStringListEntryMutationVariables, EditStringListEntryProps<TChildProps, TDataName>>(EditStringListEntryDocument, {
    alias: 'editStringListEntry',
    ...operationOptions
  });
}
export type EditStringListEntryMutationResult = ApolloReactCommon.MutationResult<EditStringListEntryMutation>;
export type EditStringListEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditStringListEntryMutation, EditStringListEntryMutationVariables>;
export const EditStringListEntry = (({
  operationKind: 'Mutation',
  document: EditStringListEntryDocument,
  primaryResultPath: ['user', 'editProfileEntryStringList']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditStringListEntryMutationVariables;
  TResult: EditStringListEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditStringListEntryMutation['user']['editProfileEntryStringList'];
});
export const EditDateEntryDocument = gql`
	mutation EditDateEntry($field: ProfileEditField!, $value: UtcTimestamp!) {
		user {
			editProfileEntryDate(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditDateEntryMutationFn = ApolloReactCommon.MutationFunction<EditDateEntryMutation, EditDateEntryMutationVariables>;
export type EditDateEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditDateEntryMutation, EditDateEntryMutationVariables>, 'mutation'>;
export const EditDateEntryComponent = (props: EditDateEntryComponentProps) => <ApolloReactComponents.Mutation<EditDateEntryMutation, EditDateEntryMutationVariables> mutation={EditDateEntryDocument} {...props} />;
export type EditDateEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditDateEntryMutation, EditDateEntryMutationVariables> } & TChildProps;
export function withEditDateEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditDateEntryMutation, EditDateEntryMutationVariables, EditDateEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditDateEntryMutation, EditDateEntryMutationVariables, EditDateEntryProps<TChildProps, TDataName>>(EditDateEntryDocument, {
    alias: 'editDateEntry',
    ...operationOptions
  });
}
export type EditDateEntryMutationResult = ApolloReactCommon.MutationResult<EditDateEntryMutation>;
export type EditDateEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDateEntryMutation, EditDateEntryMutationVariables>;
export const EditDateEntry = (({
  operationKind: 'Mutation',
  document: EditDateEntryDocument,
  primaryResultPath: ['user', 'editProfileEntryDate']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditDateEntryMutationVariables;
  TResult: EditDateEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditDateEntryMutation['user']['editProfileEntryDate'];
});
export const EditChildrenEntryDocument = gql`
	mutation EditChildrenEntry($field: ProfileEditField!, $value: Children) {
		user {
			editProfileEntryChildren(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditChildrenEntryMutationFn = ApolloReactCommon.MutationFunction<EditChildrenEntryMutation, EditChildrenEntryMutationVariables>;
export type EditChildrenEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditChildrenEntryMutation, EditChildrenEntryMutationVariables>, 'mutation'>;
export const EditChildrenEntryComponent = (props: EditChildrenEntryComponentProps) => <ApolloReactComponents.Mutation<EditChildrenEntryMutation, EditChildrenEntryMutationVariables> mutation={EditChildrenEntryDocument} {...props} />;
export type EditChildrenEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditChildrenEntryMutation, EditChildrenEntryMutationVariables> } & TChildProps;
export function withEditChildrenEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditChildrenEntryMutation, EditChildrenEntryMutationVariables, EditChildrenEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditChildrenEntryMutation, EditChildrenEntryMutationVariables, EditChildrenEntryProps<TChildProps, TDataName>>(EditChildrenEntryDocument, {
    alias: 'editChildrenEntry',
    ...operationOptions
  });
}
export type EditChildrenEntryMutationResult = ApolloReactCommon.MutationResult<EditChildrenEntryMutation>;
export type EditChildrenEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditChildrenEntryMutation, EditChildrenEntryMutationVariables>;
export const EditChildrenEntry = (({
  operationKind: 'Mutation',
  document: EditChildrenEntryDocument,
  primaryResultPath: ['user', 'editProfileEntryChildren']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditChildrenEntryMutationVariables;
  TResult: EditChildrenEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditChildrenEntryMutation['user']['editProfileEntryChildren'];
});
export const EditSmokerEntryDocument = gql`
	mutation EditSmokerEntry($field: ProfileEditField!, $value: Smoker) {
		user {
			editProfileEntrySmoker(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditSmokerEntryMutationFn = ApolloReactCommon.MutationFunction<EditSmokerEntryMutation, EditSmokerEntryMutationVariables>;
export type EditSmokerEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSmokerEntryMutation, EditSmokerEntryMutationVariables>, 'mutation'>;
export const EditSmokerEntryComponent = (props: EditSmokerEntryComponentProps) => <ApolloReactComponents.Mutation<EditSmokerEntryMutation, EditSmokerEntryMutationVariables> mutation={EditSmokerEntryDocument} {...props} />;
export type EditSmokerEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditSmokerEntryMutation, EditSmokerEntryMutationVariables> } & TChildProps;
export function withEditSmokerEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditSmokerEntryMutation, EditSmokerEntryMutationVariables, EditSmokerEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditSmokerEntryMutation, EditSmokerEntryMutationVariables, EditSmokerEntryProps<TChildProps, TDataName>>(EditSmokerEntryDocument, {
    alias: 'editSmokerEntry',
    ...operationOptions
  });
}
export type EditSmokerEntryMutationResult = ApolloReactCommon.MutationResult<EditSmokerEntryMutation>;
export type EditSmokerEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSmokerEntryMutation, EditSmokerEntryMutationVariables>;
export const EditSmokerEntry = (({
  operationKind: 'Mutation',
  document: EditSmokerEntryDocument,
  primaryResultPath: ['user', 'editProfileEntrySmoker']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditSmokerEntryMutationVariables;
  TResult: EditSmokerEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditSmokerEntryMutation['user']['editProfileEntrySmoker'];
});
export const EditCountryEntryDocument = gql`
	mutation EditCountryEntry($field: ProfileEditField!, $value: Country!) {
		user {
			editProfileEntryCountry(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditCountryEntryMutationFn = ApolloReactCommon.MutationFunction<EditCountryEntryMutation, EditCountryEntryMutationVariables>;
export type EditCountryEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditCountryEntryMutation, EditCountryEntryMutationVariables>, 'mutation'>;
export const EditCountryEntryComponent = (props: EditCountryEntryComponentProps) => <ApolloReactComponents.Mutation<EditCountryEntryMutation, EditCountryEntryMutationVariables> mutation={EditCountryEntryDocument} {...props} />;
export type EditCountryEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditCountryEntryMutation, EditCountryEntryMutationVariables> } & TChildProps;
export function withEditCountryEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditCountryEntryMutation, EditCountryEntryMutationVariables, EditCountryEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditCountryEntryMutation, EditCountryEntryMutationVariables, EditCountryEntryProps<TChildProps, TDataName>>(EditCountryEntryDocument, {
    alias: 'editCountryEntry',
    ...operationOptions
  });
}
export type EditCountryEntryMutationResult = ApolloReactCommon.MutationResult<EditCountryEntryMutation>;
export type EditCountryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCountryEntryMutation, EditCountryEntryMutationVariables>;
export const EditCountryEntry = (({
  operationKind: 'Mutation',
  document: EditCountryEntryDocument,
  primaryResultPath: ['user', 'editProfileEntryCountry']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditCountryEntryMutationVariables;
  TResult: EditCountryEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditCountryEntryMutation['user']['editProfileEntryCountry'];
});
export const EditRelationshipStatusEntryDocument = gql`
	mutation EditRelationshipStatusEntry(
		$field: ProfileEditField!
		$value: RelationshipStatus
	) {
		user {
			editProfileEntryRelationshipStatus(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditRelationshipStatusEntryMutationFn = ApolloReactCommon.MutationFunction<EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables>;
export type EditRelationshipStatusEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables>, 'mutation'>;
export const EditRelationshipStatusEntryComponent = (props: EditRelationshipStatusEntryComponentProps) => <ApolloReactComponents.Mutation<EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables> mutation={EditRelationshipStatusEntryDocument} {...props} />;
export type EditRelationshipStatusEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables> } & TChildProps;
export function withEditRelationshipStatusEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables, EditRelationshipStatusEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables, EditRelationshipStatusEntryProps<TChildProps, TDataName>>(EditRelationshipStatusEntryDocument, {
    alias: 'editRelationshipStatusEntry',
    ...operationOptions
  });
}
export type EditRelationshipStatusEntryMutationResult = ApolloReactCommon.MutationResult<EditRelationshipStatusEntryMutation>;
export type EditRelationshipStatusEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRelationshipStatusEntryMutation, EditRelationshipStatusEntryMutationVariables>;
export const EditRelationshipStatusEntry = (({
  operationKind: 'Mutation',
  document: EditRelationshipStatusEntryDocument,
  primaryResultPath: ['user', 'editProfileEntryRelationshipStatus']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditRelationshipStatusEntryMutationVariables;
  TResult: EditRelationshipStatusEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditRelationshipStatusEntryMutation['user']['editProfileEntryRelationshipStatus'];
});
export const EditSexualOrientationEntryDocument = gql`
	mutation EditSexualOrientationEntry(
		$field: ProfileEditField!
		$value: SexualOrientation
	) {
		user {
			editProfileEntrySexualOrientation(field: $field, value: $value) {
				error
			}
		}
	}
`;
export type EditSexualOrientationEntryMutationFn = ApolloReactCommon.MutationFunction<EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables>;
export type EditSexualOrientationEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables>, 'mutation'>;
export const EditSexualOrientationEntryComponent = (props: EditSexualOrientationEntryComponentProps) => <ApolloReactComponents.Mutation<EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables> mutation={EditSexualOrientationEntryDocument} {...props} />;
export type EditSexualOrientationEntryProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables> } & TChildProps;
export function withEditSexualOrientationEntry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables, EditSexualOrientationEntryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables, EditSexualOrientationEntryProps<TChildProps, TDataName>>(EditSexualOrientationEntryDocument, {
    alias: 'editSexualOrientationEntry',
    ...operationOptions
  });
}
export type EditSexualOrientationEntryMutationResult = ApolloReactCommon.MutationResult<EditSexualOrientationEntryMutation>;
export type EditSexualOrientationEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSexualOrientationEntryMutation, EditSexualOrientationEntryMutationVariables>;
export const EditSexualOrientationEntry = (({
  operationKind: 'Mutation',
  document: EditSexualOrientationEntryDocument,
  primaryResultPath: ['user', 'editProfileEntrySexualOrientation']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditSexualOrientationEntryMutationVariables;
  TResult: EditSexualOrientationEntryMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditSexualOrientationEntryMutation['user']['editProfileEntrySexualOrientation'];
});
export const EditAlbumTitleDocument = gql`
	mutation EditAlbumTitle($id: ID!, $title: String!) {
		user {
			changeAlbumTitle(albumId: $id, newTitle: $title) {
				... on ChangeAlbumTitleSuccess {
					title
				}
				... on InternalError {
					unused
				}
				... on NotFoundError {
					unused
				}
			}
		}
	}
`;
export type EditAlbumTitleMutationFn = ApolloReactCommon.MutationFunction<EditAlbumTitleMutation, EditAlbumTitleMutationVariables>;
export type EditAlbumTitleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAlbumTitleMutation, EditAlbumTitleMutationVariables>, 'mutation'>;
export const EditAlbumTitleComponent = (props: EditAlbumTitleComponentProps) => <ApolloReactComponents.Mutation<EditAlbumTitleMutation, EditAlbumTitleMutationVariables> mutation={EditAlbumTitleDocument} {...props} />;
export type EditAlbumTitleProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditAlbumTitleMutation, EditAlbumTitleMutationVariables> } & TChildProps;
export function withEditAlbumTitle<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditAlbumTitleMutation, EditAlbumTitleMutationVariables, EditAlbumTitleProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditAlbumTitleMutation, EditAlbumTitleMutationVariables, EditAlbumTitleProps<TChildProps, TDataName>>(EditAlbumTitleDocument, {
    alias: 'editAlbumTitle',
    ...operationOptions
  });
}
export type EditAlbumTitleMutationResult = ApolloReactCommon.MutationResult<EditAlbumTitleMutation>;
export type EditAlbumTitleMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAlbumTitleMutation, EditAlbumTitleMutationVariables>;
export const EditAlbumTitle = (({
  operationKind: 'Mutation',
  document: EditAlbumTitleDocument,
  primaryResultPath: ['user', 'changeAlbumTitle']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditAlbumTitleMutationVariables;
  TResult: EditAlbumTitleMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditAlbumTitleMutation['user']['changeAlbumTitle'];
});
export const CreateNewAlbumDocument = gql`
	mutation CreateNewAlbum($position: Int!, $title: String!) {
		user {
			createAlbum(position: $position, title: $title) {
				... on Album {
					...Album
				}
				... on AlbumLimitReachedError {
					unused
				}
				... on FakeSuspectError {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
	${AlbumFragmentDoc}
`;
export type CreateNewAlbumMutationFn = ApolloReactCommon.MutationFunction<CreateNewAlbumMutation, CreateNewAlbumMutationVariables>;
export type CreateNewAlbumComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewAlbumMutation, CreateNewAlbumMutationVariables>, 'mutation'>;
export const CreateNewAlbumComponent = (props: CreateNewAlbumComponentProps) => <ApolloReactComponents.Mutation<CreateNewAlbumMutation, CreateNewAlbumMutationVariables> mutation={CreateNewAlbumDocument} {...props} />;
export type CreateNewAlbumProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<CreateNewAlbumMutation, CreateNewAlbumMutationVariables> } & TChildProps;
export function withCreateNewAlbum<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CreateNewAlbumMutation, CreateNewAlbumMutationVariables, CreateNewAlbumProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, CreateNewAlbumMutation, CreateNewAlbumMutationVariables, CreateNewAlbumProps<TChildProps, TDataName>>(CreateNewAlbumDocument, {
    alias: 'createNewAlbum',
    ...operationOptions
  });
}
export type CreateNewAlbumMutationResult = ApolloReactCommon.MutationResult<CreateNewAlbumMutation>;
export type CreateNewAlbumMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewAlbumMutation, CreateNewAlbumMutationVariables>;
export const CreateNewAlbum = (({
  operationKind: 'Mutation',
  document: CreateNewAlbumDocument,
  primaryResultPath: ['user', 'createAlbum']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: CreateNewAlbumMutationVariables;
  TResult: CreateNewAlbumMutation;
  primaryResultPath: string[];
  TPrimaryResult: CreateNewAlbumMutation['user']['createAlbum'];
});
export const DeletePhotoDocument = gql`
	mutation DeletePhoto($photoId: ID!) {
		user {
			deleteAlbumPhoto(albumPhotoId: $photoId) {
				... on DeleteAlbumPhotoSuccess {
					albumPhotoId
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DeletePhotoMutationFn = ApolloReactCommon.MutationFunction<DeletePhotoMutation, DeletePhotoMutationVariables>;
export type DeletePhotoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePhotoMutation, DeletePhotoMutationVariables>, 'mutation'>;
export const DeletePhotoComponent = (props: DeletePhotoComponentProps) => <ApolloReactComponents.Mutation<DeletePhotoMutation, DeletePhotoMutationVariables> mutation={DeletePhotoDocument} {...props} />;
export type DeletePhotoProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DeletePhotoMutation, DeletePhotoMutationVariables> } & TChildProps;
export function withDeletePhoto<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DeletePhotoMutation, DeletePhotoMutationVariables, DeletePhotoProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DeletePhotoMutation, DeletePhotoMutationVariables, DeletePhotoProps<TChildProps, TDataName>>(DeletePhotoDocument, {
    alias: 'deletePhoto',
    ...operationOptions
  });
}
export type DeletePhotoMutationResult = ApolloReactCommon.MutationResult<DeletePhotoMutation>;
export type DeletePhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePhotoMutation, DeletePhotoMutationVariables>;
export const DeletePhoto = (({
  operationKind: 'Mutation',
  document: DeletePhotoDocument,
  primaryResultPath: ['user', 'deleteAlbumPhoto']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DeletePhotoMutationVariables;
  TResult: DeletePhotoMutation;
  primaryResultPath: string[];
  TPrimaryResult: DeletePhotoMutation['user']['deleteAlbumPhoto'];
});
export const CreateAlbumPhotoUploadUrlDocument = gql`
	mutation CreateAlbumPhotoUploadUrl($albumId: ID!) {
		user {
			createAlbumPhotoUploadUrl(albumId: $albumId) {
				... on CreateAlbumPhotoUploadUrlSuccess {
					albumPhotoUploadUrl
				}
				... on InternalError {
					unused
				}
				... on NotFoundError {
					unused
				}
				... on FakeSuspectError {
					unused
				}
				... on PhotoUploadBlockedError {
					blockedUntil
				}
			}
		}
	}
`;
export type CreateAlbumPhotoUploadUrlMutationFn = ApolloReactCommon.MutationFunction<CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables>;
export type CreateAlbumPhotoUploadUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables>, 'mutation'>;
export const CreateAlbumPhotoUploadUrlComponent = (props: CreateAlbumPhotoUploadUrlComponentProps) => <ApolloReactComponents.Mutation<CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables> mutation={CreateAlbumPhotoUploadUrlDocument} {...props} />;
export type CreateAlbumPhotoUploadUrlProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables> } & TChildProps;
export function withCreateAlbumPhotoUploadUrl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables, CreateAlbumPhotoUploadUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables, CreateAlbumPhotoUploadUrlProps<TChildProps, TDataName>>(CreateAlbumPhotoUploadUrlDocument, {
    alias: 'createAlbumPhotoUploadUrl',
    ...operationOptions
  });
}
export type CreateAlbumPhotoUploadUrlMutationResult = ApolloReactCommon.MutationResult<CreateAlbumPhotoUploadUrlMutation>;
export type CreateAlbumPhotoUploadUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAlbumPhotoUploadUrlMutation, CreateAlbumPhotoUploadUrlMutationVariables>;
export const CreateAlbumPhotoUploadUrl = (({
  operationKind: 'Mutation',
  document: CreateAlbumPhotoUploadUrlDocument,
  primaryResultPath: ['user', 'createAlbumPhotoUploadUrl']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: CreateAlbumPhotoUploadUrlMutationVariables;
  TResult: CreateAlbumPhotoUploadUrlMutation;
  primaryResultPath: string[];
  TPrimaryResult: CreateAlbumPhotoUploadUrlMutation['user']['createAlbumPhotoUploadUrl'];
});
export const EditAlbumPhotoDescriptionDocument = gql`
	mutation EditAlbumPhotoDescription(
		$photoId: ID!
		$newDescription: String!
	) {
		user {
			changeAlbumPhotoDescription(
				albumPhotoId: $photoId
				description: $newDescription
			) {
				... on ChangeAlbumPhotoDescriptionSuccess {
					description {
						formattedText
						rawText
					}
				}
				... on NotFoundError {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EditAlbumPhotoDescriptionMutationFn = ApolloReactCommon.MutationFunction<EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables>;
export type EditAlbumPhotoDescriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables>, 'mutation'>;
export const EditAlbumPhotoDescriptionComponent = (props: EditAlbumPhotoDescriptionComponentProps) => <ApolloReactComponents.Mutation<EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables> mutation={EditAlbumPhotoDescriptionDocument} {...props} />;
export type EditAlbumPhotoDescriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables> } & TChildProps;
export function withEditAlbumPhotoDescription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables, EditAlbumPhotoDescriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables, EditAlbumPhotoDescriptionProps<TChildProps, TDataName>>(EditAlbumPhotoDescriptionDocument, {
    alias: 'editAlbumPhotoDescription',
    ...operationOptions
  });
}
export type EditAlbumPhotoDescriptionMutationResult = ApolloReactCommon.MutationResult<EditAlbumPhotoDescriptionMutation>;
export type EditAlbumPhotoDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAlbumPhotoDescriptionMutation, EditAlbumPhotoDescriptionMutationVariables>;
export const EditAlbumPhotoDescription = (({
  operationKind: 'Mutation',
  document: EditAlbumPhotoDescriptionDocument,
  primaryResultPath: ['user', 'changeAlbumPhotoDescription']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EditAlbumPhotoDescriptionMutationVariables;
  TResult: EditAlbumPhotoDescriptionMutation;
  primaryResultPath: string[];
  TPrimaryResult: EditAlbumPhotoDescriptionMutation['user']['changeAlbumPhotoDescription'];
});
export const DeletePhotoAlbumDocument = gql`
	mutation DeletePhotoAlbum($albumId: ID!) {
		user {
			deleteAlbum(albumId: $albumId) {
				... on DeleteAlbumSuccess {
					albumId
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DeletePhotoAlbumMutationFn = ApolloReactCommon.MutationFunction<DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables>;
export type DeletePhotoAlbumComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables>, 'mutation'>;
export const DeletePhotoAlbumComponent = (props: DeletePhotoAlbumComponentProps) => <ApolloReactComponents.Mutation<DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables> mutation={DeletePhotoAlbumDocument} {...props} />;
export type DeletePhotoAlbumProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables> } & TChildProps;
export function withDeletePhotoAlbum<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables, DeletePhotoAlbumProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables, DeletePhotoAlbumProps<TChildProps, TDataName>>(DeletePhotoAlbumDocument, {
    alias: 'deletePhotoAlbum',
    ...operationOptions
  });
}
export type DeletePhotoAlbumMutationResult = ApolloReactCommon.MutationResult<DeletePhotoAlbumMutation>;
export type DeletePhotoAlbumMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePhotoAlbumMutation, DeletePhotoAlbumMutationVariables>;
export const DeletePhotoAlbum = (({
  operationKind: 'Mutation',
  document: DeletePhotoAlbumDocument,
  primaryResultPath: ['user', 'deleteAlbum']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DeletePhotoAlbumMutationVariables;
  TResult: DeletePhotoAlbumMutation;
  primaryResultPath: string[];
  TPrimaryResult: DeletePhotoAlbumMutation['user']['deleteAlbum'];
});
export const ChangeAlbumOrderDocument = gql`
	mutation ChangeAlbumOrder($newAlbumOrderIds: [ID!]!) {
		user {
			changeAlbumOrder(newAlbumOrder: $newAlbumOrderIds) {
				... on Success {
					unused
				}
				... on NotFoundError {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ChangeAlbumOrderMutationFn = ApolloReactCommon.MutationFunction<ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables>;
export type ChangeAlbumOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables>, 'mutation'>;
export const ChangeAlbumOrderComponent = (props: ChangeAlbumOrderComponentProps) => <ApolloReactComponents.Mutation<ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables> mutation={ChangeAlbumOrderDocument} {...props} />;
export type ChangeAlbumOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables> } & TChildProps;
export function withChangeAlbumOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables, ChangeAlbumOrderProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables, ChangeAlbumOrderProps<TChildProps, TDataName>>(ChangeAlbumOrderDocument, {
    alias: 'changeAlbumOrder',
    ...operationOptions
  });
}
export type ChangeAlbumOrderMutationResult = ApolloReactCommon.MutationResult<ChangeAlbumOrderMutation>;
export type ChangeAlbumOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAlbumOrderMutation, ChangeAlbumOrderMutationVariables>;
export const ChangeAlbumOrder = (({
  operationKind: 'Mutation',
  document: ChangeAlbumOrderDocument,
  primaryResultPath: ['user', 'changeAlbumOrder']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ChangeAlbumOrderMutationVariables;
  TResult: ChangeAlbumOrderMutation;
  primaryResultPath: string[];
  TPrimaryResult: ChangeAlbumOrderMutation['user']['changeAlbumOrder'];
});
export const UserFriendStateChangedDocument = gql`
	subscription UserFriendStateChanged($userId: ID!) {
		userChanged(config: { userId: $userId, fields: [FriendState] }) {
			id
			friendState
		}
	}
`;
export type UserFriendStateChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserFriendStateChangedSubscription, UserFriendStateChangedSubscriptionVariables>, 'subscription'>;
export const UserFriendStateChangedComponent = (props: UserFriendStateChangedComponentProps) => <ApolloReactComponents.Subscription<UserFriendStateChangedSubscription, UserFriendStateChangedSubscriptionVariables> subscription={UserFriendStateChangedDocument} {...props} />;
export type UserFriendStateChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserFriendStateChangedSubscription, UserFriendStateChangedSubscriptionVariables> } & TChildProps;
export function withUserFriendStateChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserFriendStateChangedSubscription, UserFriendStateChangedSubscriptionVariables, UserFriendStateChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, UserFriendStateChangedSubscription, UserFriendStateChangedSubscriptionVariables, UserFriendStateChangedProps<TChildProps, TDataName>>(UserFriendStateChangedDocument, {
    alias: 'userFriendStateChanged',
    ...operationOptions
  });
}
export type UserFriendStateChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserFriendStateChangedSubscription>;
export const UserFriendStateChanged = (({
  operationKind: 'Subscription',
  document: UserFriendStateChangedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: UserFriendStateChangedSubscriptionVariables;
  TResult: UserFriendStateChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: UserFriendStateChangedSubscription['userChanged'];
});
export const UserEmailChangedDocument = gql`
	subscription UserEmailChanged($userId: ID!) {
		userChanged(config: { userId: $userId, fields: [Email] }) {
			id
			email
		}
	}
`;
export type UserEmailChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserEmailChangedSubscription, UserEmailChangedSubscriptionVariables>, 'subscription'>;
export const UserEmailChangedComponent = (props: UserEmailChangedComponentProps) => <ApolloReactComponents.Subscription<UserEmailChangedSubscription, UserEmailChangedSubscriptionVariables> subscription={UserEmailChangedDocument} {...props} />;
export type UserEmailChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserEmailChangedSubscription, UserEmailChangedSubscriptionVariables> } & TChildProps;
export function withUserEmailChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserEmailChangedSubscription, UserEmailChangedSubscriptionVariables, UserEmailChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, UserEmailChangedSubscription, UserEmailChangedSubscriptionVariables, UserEmailChangedProps<TChildProps, TDataName>>(UserEmailChangedDocument, {
    alias: 'userEmailChanged',
    ...operationOptions
  });
}
export type UserEmailChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserEmailChangedSubscription>;
export const UserEmailChanged = (({
  operationKind: 'Subscription',
  document: UserEmailChangedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: UserEmailChangedSubscriptionVariables;
  TResult: UserEmailChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: UserEmailChangedSubscription['userChanged'];
});
export const MarkProfileSeenAfterFriendshipStartedDocument = gql`
	mutation MarkProfileSeenAfterFriendshipStarted($userId: ID!) {
		user {
			markProfileSeenAfterFriendshipStarted(userId: $userId)
		}
	}
`;
export type MarkProfileSeenAfterFriendshipStartedMutationFn = ApolloReactCommon.MutationFunction<MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables>;
export type MarkProfileSeenAfterFriendshipStartedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables>, 'mutation'>;
export const MarkProfileSeenAfterFriendshipStartedComponent = (props: MarkProfileSeenAfterFriendshipStartedComponentProps) => <ApolloReactComponents.Mutation<MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables> mutation={MarkProfileSeenAfterFriendshipStartedDocument} {...props} />;
export type MarkProfileSeenAfterFriendshipStartedProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables> } & TChildProps;
export function withMarkProfileSeenAfterFriendshipStarted<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables, MarkProfileSeenAfterFriendshipStartedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables, MarkProfileSeenAfterFriendshipStartedProps<TChildProps, TDataName>>(MarkProfileSeenAfterFriendshipStartedDocument, {
    alias: 'markProfileSeenAfterFriendshipStarted',
    ...operationOptions
  });
}
export type MarkProfileSeenAfterFriendshipStartedMutationResult = ApolloReactCommon.MutationResult<MarkProfileSeenAfterFriendshipStartedMutation>;
export type MarkProfileSeenAfterFriendshipStartedMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkProfileSeenAfterFriendshipStartedMutation, MarkProfileSeenAfterFriendshipStartedMutationVariables>;
export const MarkProfileSeenAfterFriendshipStarted = (({
  operationKind: 'Mutation',
  document: MarkProfileSeenAfterFriendshipStartedDocument,
  primaryResultPath: ['user', 'markProfileSeenAfterFriendshipStarted']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: MarkProfileSeenAfterFriendshipStartedMutationVariables;
  TResult: MarkProfileSeenAfterFriendshipStartedMutation;
  primaryResultPath: string[];
  TPrimaryResult: MarkProfileSeenAfterFriendshipStartedMutation['user']['markProfileSeenAfterFriendshipStarted'];
});
export const MarkProfileVisitorSeenDocument = gql`
	mutation MarkProfileVisitorSeen($userId: ID!) {
		user {
			markProfileVisitorSeen(userId: $userId)
		}
	}
`;
export type MarkProfileVisitorSeenMutationFn = ApolloReactCommon.MutationFunction<MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables>;
export type MarkProfileVisitorSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables>, 'mutation'>;
export const MarkProfileVisitorSeenComponent = (props: MarkProfileVisitorSeenComponentProps) => <ApolloReactComponents.Mutation<MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables> mutation={MarkProfileVisitorSeenDocument} {...props} />;
export type MarkProfileVisitorSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables> } & TChildProps;
export function withMarkProfileVisitorSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables, MarkProfileVisitorSeenProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables, MarkProfileVisitorSeenProps<TChildProps, TDataName>>(MarkProfileVisitorSeenDocument, {
    alias: 'markProfileVisitorSeen',
    ...operationOptions
  });
}
export type MarkProfileVisitorSeenMutationResult = ApolloReactCommon.MutationResult<MarkProfileVisitorSeenMutation>;
export type MarkProfileVisitorSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkProfileVisitorSeenMutation, MarkProfileVisitorSeenMutationVariables>;
export const MarkProfileVisitorSeen = (({
  operationKind: 'Mutation',
  document: MarkProfileVisitorSeenDocument,
  primaryResultPath: ['user', 'markProfileVisitorSeen']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: MarkProfileVisitorSeenMutationVariables;
  TResult: MarkProfileVisitorSeenMutation;
  primaryResultPath: string[];
  TPrimaryResult: MarkProfileVisitorSeenMutation['user']['markProfileVisitorSeen'];
});
export const DeleteProfilePictureDocument = gql`
	mutation DeleteProfilePicture {
		user {
			deleteProfileImage {
				user {
					id
					profilePicture {
						urlLargeSquare
						urlVeryLarge
						exists
					}
				}
				error
			}
		}
	}
`;
export type DeleteProfilePictureMutationFn = ApolloReactCommon.MutationFunction<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>;
export type DeleteProfilePictureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>, 'mutation'>;
export const DeleteProfilePictureComponent = (props: DeleteProfilePictureComponentProps) => <ApolloReactComponents.Mutation<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables> mutation={DeleteProfilePictureDocument} {...props} />;
export type DeleteProfilePictureProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables> } & TChildProps;
export function withDeleteProfilePicture<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables, DeleteProfilePictureProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables, DeleteProfilePictureProps<TChildProps, TDataName>>(DeleteProfilePictureDocument, {
    alias: 'deleteProfilePicture',
    ...operationOptions
  });
}
export type DeleteProfilePictureMutationResult = ApolloReactCommon.MutationResult<DeleteProfilePictureMutation>;
export type DeleteProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProfilePictureMutation, DeleteProfilePictureMutationVariables>;
export const DeleteProfilePicture = (({
  operationKind: 'Mutation',
  document: DeleteProfilePictureDocument,
  primaryResultPath: ['user', 'deleteProfileImage']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DeleteProfilePictureMutationVariables;
  TResult: DeleteProfilePictureMutation;
  primaryResultPath: string[];
  TPrimaryResult: DeleteProfilePictureMutation['user']['deleteProfileImage'];
});
export const GetProfilePictureUrlsDocument = gql`
	query GetProfilePictureUrls($userId: ID!) {
		user {
			user(id: $userId) {
				id
				profilePicture {
					urlLargeSquare
					urlVeryLarge
					exists
				}
				profilePictureOverlayUrls {
					...ProfilePictureOverlays
				}
			}
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export type GetProfilePictureUrlsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProfilePictureUrlsQuery, GetProfilePictureUrlsQueryVariables>, 'query'> & ({
  variables: GetProfilePictureUrlsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetProfilePictureUrlsComponent = (props: GetProfilePictureUrlsComponentProps) => <ApolloReactComponents.Query<GetProfilePictureUrlsQuery, GetProfilePictureUrlsQueryVariables> query={GetProfilePictureUrlsDocument} {...props} />;
export type GetProfilePictureUrlsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetProfilePictureUrlsQuery, GetProfilePictureUrlsQueryVariables> } & TChildProps;
export function withGetProfilePictureUrls<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetProfilePictureUrlsQuery, GetProfilePictureUrlsQueryVariables, GetProfilePictureUrlsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetProfilePictureUrlsQuery, GetProfilePictureUrlsQueryVariables, GetProfilePictureUrlsProps<TChildProps, TDataName>>(GetProfilePictureUrlsDocument, {
    alias: 'getProfilePictureUrls',
    ...operationOptions
  });
}
export type GetProfilePictureUrlsQueryResult = ApolloReactCommon.QueryResult<GetProfilePictureUrlsQuery, GetProfilePictureUrlsQueryVariables>;
export const GetProfilePictureUrls = (({
  operationKind: 'Query',
  document: GetProfilePictureUrlsDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetProfilePictureUrlsQueryVariables;
  TResult: GetProfilePictureUrlsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetProfilePictureUrlsQuery['user']['user'];
});
export const GetCurrentUserProfilePictureUrlsDocument = gql`
	query GetCurrentUserProfilePictureUrls {
		user {
			currentUser {
				id
				profilePicture {
					urlLargeSquare
					urlVeryLarge
					exists
				}
			}
		}
	}
`;
export type GetCurrentUserProfilePictureUrlsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserProfilePictureUrlsQuery, GetCurrentUserProfilePictureUrlsQueryVariables>, 'query'>;
export const GetCurrentUserProfilePictureUrlsComponent = (props: GetCurrentUserProfilePictureUrlsComponentProps) => <ApolloReactComponents.Query<GetCurrentUserProfilePictureUrlsQuery, GetCurrentUserProfilePictureUrlsQueryVariables> query={GetCurrentUserProfilePictureUrlsDocument} {...props} />;
export type GetCurrentUserProfilePictureUrlsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetCurrentUserProfilePictureUrlsQuery, GetCurrentUserProfilePictureUrlsQueryVariables> } & TChildProps;
export function withGetCurrentUserProfilePictureUrls<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetCurrentUserProfilePictureUrlsQuery, GetCurrentUserProfilePictureUrlsQueryVariables, GetCurrentUserProfilePictureUrlsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetCurrentUserProfilePictureUrlsQuery, GetCurrentUserProfilePictureUrlsQueryVariables, GetCurrentUserProfilePictureUrlsProps<TChildProps, TDataName>>(GetCurrentUserProfilePictureUrlsDocument, {
    alias: 'getCurrentUserProfilePictureUrls',
    ...operationOptions
  });
}
export type GetCurrentUserProfilePictureUrlsQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserProfilePictureUrlsQuery, GetCurrentUserProfilePictureUrlsQueryVariables>;
export const GetCurrentUserProfilePictureUrls = (({
  operationKind: 'Query',
  document: GetCurrentUserProfilePictureUrlsDocument,
  primaryResultPath: ['user', 'currentUser']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetCurrentUserProfilePictureUrlsQueryVariables;
  TResult: GetCurrentUserProfilePictureUrlsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetCurrentUserProfilePictureUrlsQuery['user']['currentUser'];
});
export const GetCurrentUserProfilePictureCustomSizeUrlDocument = gql`
	query GetCurrentUserProfilePictureCustomSizeUrl(
		$pixelDensity: Float!
		$size: Int!
	) {
		user {
			currentUser {
				id
				profilePicture {
					urlCustomSizeSquare(
						pixelDensity: $pixelDensity
						size: $size
					)
				}
			}
		}
	}
`;
export type GetCurrentUserProfilePictureCustomSizeUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserProfilePictureCustomSizeUrlQuery, GetCurrentUserProfilePictureCustomSizeUrlQueryVariables>, 'query'> & ({
  variables: GetCurrentUserProfilePictureCustomSizeUrlQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetCurrentUserProfilePictureCustomSizeUrlComponent = (props: GetCurrentUserProfilePictureCustomSizeUrlComponentProps) => <ApolloReactComponents.Query<GetCurrentUserProfilePictureCustomSizeUrlQuery, GetCurrentUserProfilePictureCustomSizeUrlQueryVariables> query={GetCurrentUserProfilePictureCustomSizeUrlDocument} {...props} />;
export type GetCurrentUserProfilePictureCustomSizeUrlProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetCurrentUserProfilePictureCustomSizeUrlQuery, GetCurrentUserProfilePictureCustomSizeUrlQueryVariables> } & TChildProps;
export function withGetCurrentUserProfilePictureCustomSizeUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetCurrentUserProfilePictureCustomSizeUrlQuery, GetCurrentUserProfilePictureCustomSizeUrlQueryVariables, GetCurrentUserProfilePictureCustomSizeUrlProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetCurrentUserProfilePictureCustomSizeUrlQuery, GetCurrentUserProfilePictureCustomSizeUrlQueryVariables, GetCurrentUserProfilePictureCustomSizeUrlProps<TChildProps, TDataName>>(GetCurrentUserProfilePictureCustomSizeUrlDocument, {
    alias: 'getCurrentUserProfilePictureCustomSizeUrl',
    ...operationOptions
  });
}
export type GetCurrentUserProfilePictureCustomSizeUrlQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserProfilePictureCustomSizeUrlQuery, GetCurrentUserProfilePictureCustomSizeUrlQueryVariables>;
export const GetCurrentUserProfilePictureCustomSizeUrl = (({
  operationKind: 'Query',
  document: GetCurrentUserProfilePictureCustomSizeUrlDocument,
  primaryResultPath: ['user', 'currentUser']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetCurrentUserProfilePictureCustomSizeUrlQueryVariables;
  TResult: GetCurrentUserProfilePictureCustomSizeUrlQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetCurrentUserProfilePictureCustomSizeUrlQuery['user']['currentUser'];
});
export const AlbumPhotoCommentsDocument = gql`
	query AlbumPhotoComments($albumPhotoId: ID!, $pixelDensity: Float!) {
		user {
			albumPhotoComments(albumPhotoId: $albumPhotoId) {
				...AlbumPhotoComment
			}
		}
	}
	${AlbumPhotoCommentFragmentDoc}
`;
export type AlbumPhotoCommentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AlbumPhotoCommentsQuery, AlbumPhotoCommentsQueryVariables>, 'query'> & ({
  variables: AlbumPhotoCommentsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const AlbumPhotoCommentsComponent = (props: AlbumPhotoCommentsComponentProps) => <ApolloReactComponents.Query<AlbumPhotoCommentsQuery, AlbumPhotoCommentsQueryVariables> query={AlbumPhotoCommentsDocument} {...props} />;
export type AlbumPhotoCommentsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<AlbumPhotoCommentsQuery, AlbumPhotoCommentsQueryVariables> } & TChildProps;
export function withAlbumPhotoComments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AlbumPhotoCommentsQuery, AlbumPhotoCommentsQueryVariables, AlbumPhotoCommentsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, AlbumPhotoCommentsQuery, AlbumPhotoCommentsQueryVariables, AlbumPhotoCommentsProps<TChildProps, TDataName>>(AlbumPhotoCommentsDocument, {
    alias: 'albumPhotoComments',
    ...operationOptions
  });
}
export type AlbumPhotoCommentsQueryResult = ApolloReactCommon.QueryResult<AlbumPhotoCommentsQuery, AlbumPhotoCommentsQueryVariables>;
export const AlbumPhotoComments = (({
  operationKind: 'Query',
  document: AlbumPhotoCommentsDocument,
  primaryResultPath: ['user', 'albumPhotoComments']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: AlbumPhotoCommentsQueryVariables;
  TResult: AlbumPhotoCommentsQuery;
  primaryResultPath: string[];
  TPrimaryResult: AlbumPhotoCommentsQuery['user']['albumPhotoComments'];
});
export const SendAlbumPhotoCommentDocument = gql`
	mutation SendAlbumPhotoComment(
		$albumPhotoId: ID!
		$text: String!
		$pixelDensity: Float!
	) {
		user {
			commentAlbumPhoto(albumPhotoId: $albumPhotoId, text: $text) {
				... on AlbumPhotoComment {
					...AlbumPhotoComment
				}
				... on NotFoundError {
					unused
				}
				... on InternalError {
					unused
				}
				... on CantCommentError {
					reason
				}
			}
		}
	}
	${AlbumPhotoCommentFragmentDoc}
`;
export type SendAlbumPhotoCommentMutationFn = ApolloReactCommon.MutationFunction<SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables>;
export type SendAlbumPhotoCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables>, 'mutation'>;
export const SendAlbumPhotoCommentComponent = (props: SendAlbumPhotoCommentComponentProps) => <ApolloReactComponents.Mutation<SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables> mutation={SendAlbumPhotoCommentDocument} {...props} />;
export type SendAlbumPhotoCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables> } & TChildProps;
export function withSendAlbumPhotoComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables, SendAlbumPhotoCommentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables, SendAlbumPhotoCommentProps<TChildProps, TDataName>>(SendAlbumPhotoCommentDocument, {
    alias: 'sendAlbumPhotoComment',
    ...operationOptions
  });
}
export type SendAlbumPhotoCommentMutationResult = ApolloReactCommon.MutationResult<SendAlbumPhotoCommentMutation>;
export type SendAlbumPhotoCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<SendAlbumPhotoCommentMutation, SendAlbumPhotoCommentMutationVariables>;
export const SendAlbumPhotoComment = (({
  operationKind: 'Mutation',
  document: SendAlbumPhotoCommentDocument,
  primaryResultPath: ['user', 'commentAlbumPhoto']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendAlbumPhotoCommentMutationVariables;
  TResult: SendAlbumPhotoCommentMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendAlbumPhotoCommentMutation['user']['commentAlbumPhoto'];
});
export const DeleteAlbumPhotoCommentDocument = gql`
	mutation DeleteAlbumPhotoComment(
		$albumPhotoId: ID!
		$albumPhotoCommentId: ID!
	) {
		user {
			deleteAlbumPhotoComment(
				albumPhotoId: $albumPhotoId
				albumPhotoCommentId: $albumPhotoCommentId
			) {
				... on DeleteAlbumPhotoCommentSuccess {
					albumPhotoCommentId
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DeleteAlbumPhotoCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables>;
export type DeleteAlbumPhotoCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables>, 'mutation'>;
export const DeleteAlbumPhotoCommentComponent = (props: DeleteAlbumPhotoCommentComponentProps) => <ApolloReactComponents.Mutation<DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables> mutation={DeleteAlbumPhotoCommentDocument} {...props} />;
export type DeleteAlbumPhotoCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables> } & TChildProps;
export function withDeleteAlbumPhotoComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables, DeleteAlbumPhotoCommentProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables, DeleteAlbumPhotoCommentProps<TChildProps, TDataName>>(DeleteAlbumPhotoCommentDocument, {
    alias: 'deleteAlbumPhotoComment',
    ...operationOptions
  });
}
export type DeleteAlbumPhotoCommentMutationResult = ApolloReactCommon.MutationResult<DeleteAlbumPhotoCommentMutation>;
export type DeleteAlbumPhotoCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlbumPhotoCommentMutation, DeleteAlbumPhotoCommentMutationVariables>;
export const DeleteAlbumPhotoComment = (({
  operationKind: 'Mutation',
  document: DeleteAlbumPhotoCommentDocument,
  primaryResultPath: ['user', 'deleteAlbumPhotoComment']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DeleteAlbumPhotoCommentMutationVariables;
  TResult: DeleteAlbumPhotoCommentMutation;
  primaryResultPath: string[];
  TPrimaryResult: DeleteAlbumPhotoCommentMutation['user']['deleteAlbumPhotoComment'];
});
export const SendFriendRequestDocument = gql`
	mutation SendFriendRequest($userId: ID!) {
		contacts {
			sendFriendRequest(userId: $userId) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
				... on NotAllowedByReceiverError {
					unused
				}
				... on ReceiverFriendLimitReachedError {
					unused
				}
				... on SenderFriendLimitReachedError {
					unused
				}
				... on TooManyRequestsError {
					unused
				}
				... on ConversationDepthNotReachedError {
					unused
				}
			}
		}
	}
`;
export type SendFriendRequestMutationFn = ApolloReactCommon.MutationFunction<SendFriendRequestMutation, SendFriendRequestMutationVariables>;
export type SendFriendRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendFriendRequestMutation, SendFriendRequestMutationVariables>, 'mutation'>;
export const SendFriendRequestComponent = (props: SendFriendRequestComponentProps) => <ApolloReactComponents.Mutation<SendFriendRequestMutation, SendFriendRequestMutationVariables> mutation={SendFriendRequestDocument} {...props} />;
export type SendFriendRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SendFriendRequestMutation, SendFriendRequestMutationVariables> } & TChildProps;
export function withSendFriendRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SendFriendRequestMutation, SendFriendRequestMutationVariables, SendFriendRequestProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SendFriendRequestMutation, SendFriendRequestMutationVariables, SendFriendRequestProps<TChildProps, TDataName>>(SendFriendRequestDocument, {
    alias: 'sendFriendRequest',
    ...operationOptions
  });
}
export type SendFriendRequestMutationResult = ApolloReactCommon.MutationResult<SendFriendRequestMutation>;
export type SendFriendRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendFriendRequestMutation, SendFriendRequestMutationVariables>;
export const SendFriendRequest = (({
  operationKind: 'Mutation',
  document: SendFriendRequestDocument,
  primaryResultPath: ['contacts', 'sendFriendRequest']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SendFriendRequestMutationVariables;
  TResult: SendFriendRequestMutation;
  primaryResultPath: string[];
  TPrimaryResult: SendFriendRequestMutation['contacts']['sendFriendRequest'];
});
export const ProfileVisitEventsDocument = gql`
	subscription profileVisitEvents($pixelDensity: Float!) {
		profileVisitEvent {
			... on NewProfileVisitEvent {
				profileVisit {
					visitor {
						...ProfileVisitorsUser
					}
					isUnlocked
				}
			}
			... on ProfileVisitChangedEvent {
				profileVisit {
					visitor {
						...ProfileVisitorsUser
					}
					isUnlocked
				}
			}
			... on ProfileVisitorSystemEnabledChanged {
				enabled
			}
		}
	}
	${ProfileVisitorsUserFragmentDoc}
`;
export type ProfileVisitEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ProfileVisitEventsSubscription, ProfileVisitEventsSubscriptionVariables>, 'subscription'>;
export const ProfileVisitEventsComponent = (props: ProfileVisitEventsComponentProps) => <ApolloReactComponents.Subscription<ProfileVisitEventsSubscription, ProfileVisitEventsSubscriptionVariables> subscription={ProfileVisitEventsDocument} {...props} />;
export type ProfileVisitEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ProfileVisitEventsSubscription, ProfileVisitEventsSubscriptionVariables> } & TChildProps;
export function withProfileVisitEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ProfileVisitEventsSubscription, ProfileVisitEventsSubscriptionVariables, ProfileVisitEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, ProfileVisitEventsSubscription, ProfileVisitEventsSubscriptionVariables, ProfileVisitEventsProps<TChildProps, TDataName>>(ProfileVisitEventsDocument, {
    alias: 'profileVisitEvents',
    ...operationOptions
  });
}
export type ProfileVisitEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<ProfileVisitEventsSubscription>;
export const profileVisitEvents = (({
  operationKind: 'Subscription',
  document: ProfileVisitEventsDocument,
  primaryResultPath: ['profileVisitEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: ProfileVisitEventsSubscriptionVariables;
  TResult: ProfileVisitEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: ProfileVisitEventsSubscription['profileVisitEvent'];
});
export const ProfileVisitsDocument = gql`
	query profileVisits($pixelDensity: Float!) {
		user {
			profileVisits {
				visitor {
					...ProfileVisitorsUser
				}
				isUnlocked
			}
			profileVisitorsEnabled
		}
	}
	${ProfileVisitorsUserFragmentDoc}
`;
export type ProfileVisitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileVisitsQuery, ProfileVisitsQueryVariables>, 'query'> & ({
  variables: ProfileVisitsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const ProfileVisitsComponent = (props: ProfileVisitsComponentProps) => <ApolloReactComponents.Query<ProfileVisitsQuery, ProfileVisitsQueryVariables> query={ProfileVisitsDocument} {...props} />;
export type ProfileVisitsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ProfileVisitsQuery, ProfileVisitsQueryVariables> } & TChildProps;
export function withProfileVisits<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ProfileVisitsQuery, ProfileVisitsQueryVariables, ProfileVisitsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ProfileVisitsQuery, ProfileVisitsQueryVariables, ProfileVisitsProps<TChildProps, TDataName>>(ProfileVisitsDocument, {
    alias: 'profileVisits',
    ...operationOptions
  });
}
export type ProfileVisitsQueryResult = ApolloReactCommon.QueryResult<ProfileVisitsQuery, ProfileVisitsQueryVariables>;
export const profileVisits = (({
  operationKind: 'Query',
  document: ProfileVisitsDocument,
  primaryResultPath: ['user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ProfileVisitsQueryVariables;
  TResult: ProfileVisitsQuery;
  primaryResultPath: string[];
  TPrimaryResult: ProfileVisitsQuery['user'];
});
export const ActivateProfileVisitorsFeatureDocument = gql`
	mutation activateProfileVisitorsFeature {
		user {
			activateProfileVisitorsFeature {
				error
			}
		}
	}
`;
export type ActivateProfileVisitorsFeatureMutationFn = ApolloReactCommon.MutationFunction<ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables>;
export type ActivateProfileVisitorsFeatureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables>, 'mutation'>;
export const ActivateProfileVisitorsFeatureComponent = (props: ActivateProfileVisitorsFeatureComponentProps) => <ApolloReactComponents.Mutation<ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables> mutation={ActivateProfileVisitorsFeatureDocument} {...props} />;
export type ActivateProfileVisitorsFeatureProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables> } & TChildProps;
export function withActivateProfileVisitorsFeature<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables, ActivateProfileVisitorsFeatureProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables, ActivateProfileVisitorsFeatureProps<TChildProps, TDataName>>(ActivateProfileVisitorsFeatureDocument, {
    alias: 'activateProfileVisitorsFeature',
    ...operationOptions
  });
}
export type ActivateProfileVisitorsFeatureMutationResult = ApolloReactCommon.MutationResult<ActivateProfileVisitorsFeatureMutation>;
export type ActivateProfileVisitorsFeatureMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateProfileVisitorsFeatureMutation, ActivateProfileVisitorsFeatureMutationVariables>;
export const activateProfileVisitorsFeature = (({
  operationKind: 'Mutation',
  document: ActivateProfileVisitorsFeatureDocument,
  primaryResultPath: ['user', 'activateProfileVisitorsFeature']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ActivateProfileVisitorsFeatureMutationVariables;
  TResult: ActivateProfileVisitorsFeatureMutation;
  primaryResultPath: string[];
  TPrimaryResult: ActivateProfileVisitorsFeatureMutation['user']['activateProfileVisitorsFeature'];
});
export const PromotionEventsDocument = gql`
	subscription PromotionEvents {
		promotionEvent {
			... on PromotionStartedEvent {
				newPromotion {
					... on HappyHourPromotion {
						...HappyHour
					}
				}
			}
			... on PromotionEndedEvent {
				promotion {
					... on HappyHourPromotion {
						...HappyHour
					}
				}
			}
		}
	}
	${HappyHourFragmentDoc}
`;
export type PromotionEventsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PromotionEventsSubscription, PromotionEventsSubscriptionVariables>, 'subscription'>;
export const PromotionEventsComponent = (props: PromotionEventsComponentProps) => <ApolloReactComponents.Subscription<PromotionEventsSubscription, PromotionEventsSubscriptionVariables> subscription={PromotionEventsDocument} {...props} />;
export type PromotionEventsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<PromotionEventsSubscription, PromotionEventsSubscriptionVariables> } & TChildProps;
export function withPromotionEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PromotionEventsSubscription, PromotionEventsSubscriptionVariables, PromotionEventsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, PromotionEventsSubscription, PromotionEventsSubscriptionVariables, PromotionEventsProps<TChildProps, TDataName>>(PromotionEventsDocument, {
    alias: 'promotionEvents',
    ...operationOptions
  });
}
export type PromotionEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<PromotionEventsSubscription>;
export const PromotionEvents = (({
  operationKind: 'Subscription',
  document: PromotionEventsDocument,
  primaryResultPath: ['promotionEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: PromotionEventsSubscriptionVariables;
  TResult: PromotionEventsSubscription;
  primaryResultPath: string[];
  TPrimaryResult: PromotionEventsSubscription['promotionEvent'];
});
export const ActivePromotionsDocument = gql`
	query ActivePromotions {
		promotions {
			activePromotions {
				... on HappyHourPromotion {
					...HappyHour
				}
			}
		}
	}
	${HappyHourFragmentDoc}
`;
export type ActivePromotionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivePromotionsQuery, ActivePromotionsQueryVariables>, 'query'>;
export const ActivePromotionsComponent = (props: ActivePromotionsComponentProps) => <ApolloReactComponents.Query<ActivePromotionsQuery, ActivePromotionsQueryVariables> query={ActivePromotionsDocument} {...props} />;
export type ActivePromotionsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ActivePromotionsQuery, ActivePromotionsQueryVariables> } & TChildProps;
export function withActivePromotions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ActivePromotionsQuery, ActivePromotionsQueryVariables, ActivePromotionsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, ActivePromotionsQuery, ActivePromotionsQueryVariables, ActivePromotionsProps<TChildProps, TDataName>>(ActivePromotionsDocument, {
    alias: 'activePromotions',
    ...operationOptions
  });
}
export type ActivePromotionsQueryResult = ApolloReactCommon.QueryResult<ActivePromotionsQuery, ActivePromotionsQueryVariables>;
export const ActivePromotions = (({
  operationKind: 'Query',
  document: ActivePromotionsDocument,
  primaryResultPath: ['promotions', 'activePromotions']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: ActivePromotionsQueryVariables;
  TResult: ActivePromotionsQuery;
  primaryResultPath: string[];
  TPrimaryResult: ActivePromotionsQuery['promotions']['activePromotions'];
});
export const OpenAccountDeletionAppDocument = gql`
	mutation OpenAccountDeletionApp {
		user {
			openAccountDeletionApp {
				... on Success {
					unused
				}
				... on DeletionAlreadyRunningError {
					unused
				}
				... on SpecialStatusOrRightsError {
					unused
				}
				... on ActivePremiumSubscriptionError {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type OpenAccountDeletionAppMutationFn = ApolloReactCommon.MutationFunction<OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables>;
export type OpenAccountDeletionAppComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables>, 'mutation'>;
export const OpenAccountDeletionAppComponent = (props: OpenAccountDeletionAppComponentProps) => <ApolloReactComponents.Mutation<OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables> mutation={OpenAccountDeletionAppDocument} {...props} />;
export type OpenAccountDeletionAppProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables> } & TChildProps;
export function withOpenAccountDeletionApp<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables, OpenAccountDeletionAppProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables, OpenAccountDeletionAppProps<TChildProps, TDataName>>(OpenAccountDeletionAppDocument, {
    alias: 'openAccountDeletionApp',
    ...operationOptions
  });
}
export type OpenAccountDeletionAppMutationResult = ApolloReactCommon.MutationResult<OpenAccountDeletionAppMutation>;
export type OpenAccountDeletionAppMutationOptions = ApolloReactCommon.BaseMutationOptions<OpenAccountDeletionAppMutation, OpenAccountDeletionAppMutationVariables>;
export const OpenAccountDeletionApp = (({
  operationKind: 'Mutation',
  document: OpenAccountDeletionAppDocument,
  primaryResultPath: ['user', 'openAccountDeletionApp']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: OpenAccountDeletionAppMutationVariables;
  TResult: OpenAccountDeletionAppMutation;
  primaryResultPath: string[];
  TPrimaryResult: OpenAccountDeletionAppMutation['user']['openAccountDeletionApp'];
});
export const GetFotomeetEnabledDocument = gql`
	query GetFotomeetEnabled {
		fotomeet {
			enabled
		}
	}
`;
export type GetFotomeetEnabledComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFotomeetEnabledQuery, GetFotomeetEnabledQueryVariables>, 'query'>;
export const GetFotomeetEnabledComponent = (props: GetFotomeetEnabledComponentProps) => <ApolloReactComponents.Query<GetFotomeetEnabledQuery, GetFotomeetEnabledQueryVariables> query={GetFotomeetEnabledDocument} {...props} />;
export type GetFotomeetEnabledProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetFotomeetEnabledQuery, GetFotomeetEnabledQueryVariables> } & TChildProps;
export function withGetFotomeetEnabled<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetFotomeetEnabledQuery, GetFotomeetEnabledQueryVariables, GetFotomeetEnabledProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetFotomeetEnabledQuery, GetFotomeetEnabledQueryVariables, GetFotomeetEnabledProps<TChildProps, TDataName>>(GetFotomeetEnabledDocument, {
    alias: 'getFotomeetEnabled',
    ...operationOptions
  });
}
export type GetFotomeetEnabledQueryResult = ApolloReactCommon.QueryResult<GetFotomeetEnabledQuery, GetFotomeetEnabledQueryVariables>;
export const GetFotomeetEnabled = (({
  operationKind: 'Query',
  document: GetFotomeetEnabledDocument,
  primaryResultPath: ['fotomeet', 'enabled']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetFotomeetEnabledQueryVariables;
  TResult: GetFotomeetEnabledQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetFotomeetEnabledQuery['fotomeet']['enabled'];
});
export const SetFotomeetEnabledDocument = gql`
	mutation SetFotomeetEnabled($enabled: Boolean!) {
		fotomeet {
			setFotomeetEnabled(enabled: $enabled) {
				... on InternalError {
					unused
				}
				... on Success {
					unused
				}
			}
		}
	}
`;
export type SetFotomeetEnabledMutationFn = ApolloReactCommon.MutationFunction<SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables>;
export type SetFotomeetEnabledComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables>, 'mutation'>;
export const SetFotomeetEnabledComponent = (props: SetFotomeetEnabledComponentProps) => <ApolloReactComponents.Mutation<SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables> mutation={SetFotomeetEnabledDocument} {...props} />;
export type SetFotomeetEnabledProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables> } & TChildProps;
export function withSetFotomeetEnabled<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables, SetFotomeetEnabledProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables, SetFotomeetEnabledProps<TChildProps, TDataName>>(SetFotomeetEnabledDocument, {
    alias: 'setFotomeetEnabled',
    ...operationOptions
  });
}
export type SetFotomeetEnabledMutationResult = ApolloReactCommon.MutationResult<SetFotomeetEnabledMutation>;
export type SetFotomeetEnabledMutationOptions = ApolloReactCommon.BaseMutationOptions<SetFotomeetEnabledMutation, SetFotomeetEnabledMutationVariables>;
export const SetFotomeetEnabled = (({
  operationKind: 'Mutation',
  document: SetFotomeetEnabledDocument,
  primaryResultPath: ['fotomeet', 'setFotomeetEnabled']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SetFotomeetEnabledMutationVariables;
  TResult: SetFotomeetEnabledMutation;
  primaryResultPath: string[];
  TPrimaryResult: SetFotomeetEnabledMutation['fotomeet']['setFotomeetEnabled'];
});
export const PrivacySettingsQueryDocument = gql`
	query PrivacySettingsQuery {
		user {
			privacySettings {
				friendsPrivacyType
				knuddelPrivacyType
				nicknameChangeOptions {
					...NicknameChangeOptions
				}
				nicknameHistory {
					...NicknameHistorySettings
				}
				adultChannelsVisibleInHardcoreChannels
			}
		}
	}
	${NicknameChangeOptionsFragmentDoc}
	${NicknameHistorySettingsFragmentDoc}
`;
export type PrivacySettingsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PrivacySettingsQueryQuery, PrivacySettingsQueryQueryVariables>, 'query'>;
export const PrivacySettingsQueryComponent = (props: PrivacySettingsQueryComponentProps) => <ApolloReactComponents.Query<PrivacySettingsQueryQuery, PrivacySettingsQueryQueryVariables> query={PrivacySettingsQueryDocument} {...props} />;
export type PrivacySettingsQueryProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<PrivacySettingsQueryQuery, PrivacySettingsQueryQueryVariables> } & TChildProps;
export function withPrivacySettingsQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PrivacySettingsQueryQuery, PrivacySettingsQueryQueryVariables, PrivacySettingsQueryProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, PrivacySettingsQueryQuery, PrivacySettingsQueryQueryVariables, PrivacySettingsQueryProps<TChildProps, TDataName>>(PrivacySettingsQueryDocument, {
    alias: 'privacySettingsQuery',
    ...operationOptions
  });
}
export type PrivacySettingsQueryQueryResult = ApolloReactCommon.QueryResult<PrivacySettingsQueryQuery, PrivacySettingsQueryQueryVariables>;
export const PrivacySettingsQuery = (({
  operationKind: 'Query',
  document: PrivacySettingsQueryDocument,
  primaryResultPath: ['user', 'privacySettings']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: PrivacySettingsQueryQueryVariables;
  TResult: PrivacySettingsQueryQuery;
  primaryResultPath: string[];
  TPrimaryResult: PrivacySettingsQueryQuery['user']['privacySettings'];
});
export const PrivacySettingsSubscriptionDocument = gql`
	subscription PrivacySettingsSubscription {
		privacySettingsChanged {
			friendsPrivacyType
			knuddelPrivacyType
			nicknameChangeOptions {
				...NicknameChangeOptions
			}
			nicknameHistory {
				...NicknameHistorySettings
			}
			adultChannelsVisibleInHardcoreChannels
		}
	}
	${NicknameChangeOptionsFragmentDoc}
	${NicknameHistorySettingsFragmentDoc}
`;
export type PrivacySettingsSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PrivacySettingsSubscriptionSubscription, PrivacySettingsSubscriptionSubscriptionVariables>, 'subscription'>;
export const PrivacySettingsSubscriptionComponent = (props: PrivacySettingsSubscriptionComponentProps) => <ApolloReactComponents.Subscription<PrivacySettingsSubscriptionSubscription, PrivacySettingsSubscriptionSubscriptionVariables> subscription={PrivacySettingsSubscriptionDocument} {...props} />;
export type PrivacySettingsSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<PrivacySettingsSubscriptionSubscription, PrivacySettingsSubscriptionSubscriptionVariables> } & TChildProps;
export function withPrivacySettingsSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PrivacySettingsSubscriptionSubscription, PrivacySettingsSubscriptionSubscriptionVariables, PrivacySettingsSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, PrivacySettingsSubscriptionSubscription, PrivacySettingsSubscriptionSubscriptionVariables, PrivacySettingsSubscriptionProps<TChildProps, TDataName>>(PrivacySettingsSubscriptionDocument, {
    alias: 'privacySettingsSubscription',
    ...operationOptions
  });
}
export type PrivacySettingsSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<PrivacySettingsSubscriptionSubscription>;
export const PrivacySettingsSubscription = (({
  operationKind: 'Subscription',
  document: PrivacySettingsSubscriptionDocument,
  primaryResultPath: ['privacySettingsChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: PrivacySettingsSubscriptionSubscriptionVariables;
  TResult: PrivacySettingsSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: PrivacySettingsSubscriptionSubscription['privacySettingsChanged'];
});
export const ChangeFriendsPrivacySettingsDocument = gql`
	mutation ChangeFriendsPrivacySettings($privacyType: PrivacyType!) {
		user {
			changeFriendsPrivacyType(privacyType: $privacyType) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ChangeFriendsPrivacySettingsMutationFn = ApolloReactCommon.MutationFunction<ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables>;
export type ChangeFriendsPrivacySettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables>, 'mutation'>;
export const ChangeFriendsPrivacySettingsComponent = (props: ChangeFriendsPrivacySettingsComponentProps) => <ApolloReactComponents.Mutation<ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables> mutation={ChangeFriendsPrivacySettingsDocument} {...props} />;
export type ChangeFriendsPrivacySettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables> } & TChildProps;
export function withChangeFriendsPrivacySettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables, ChangeFriendsPrivacySettingsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables, ChangeFriendsPrivacySettingsProps<TChildProps, TDataName>>(ChangeFriendsPrivacySettingsDocument, {
    alias: 'changeFriendsPrivacySettings',
    ...operationOptions
  });
}
export type ChangeFriendsPrivacySettingsMutationResult = ApolloReactCommon.MutationResult<ChangeFriendsPrivacySettingsMutation>;
export type ChangeFriendsPrivacySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeFriendsPrivacySettingsMutation, ChangeFriendsPrivacySettingsMutationVariables>;
export const ChangeFriendsPrivacySettings = (({
  operationKind: 'Mutation',
  document: ChangeFriendsPrivacySettingsDocument,
  primaryResultPath: ['user', 'changeFriendsPrivacyType']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ChangeFriendsPrivacySettingsMutationVariables;
  TResult: ChangeFriendsPrivacySettingsMutation;
  primaryResultPath: string[];
  TPrimaryResult: ChangeFriendsPrivacySettingsMutation['user']['changeFriendsPrivacyType'];
});
export const ChangeKnuddelPrivacySettingsDocument = gql`
	mutation ChangeKnuddelPrivacySettings($privacyType: PrivacyType!) {
		user {
			changeKnuddelPrivacyType(privacyType: $privacyType) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ChangeKnuddelPrivacySettingsMutationFn = ApolloReactCommon.MutationFunction<ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables>;
export type ChangeKnuddelPrivacySettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables>, 'mutation'>;
export const ChangeKnuddelPrivacySettingsComponent = (props: ChangeKnuddelPrivacySettingsComponentProps) => <ApolloReactComponents.Mutation<ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables> mutation={ChangeKnuddelPrivacySettingsDocument} {...props} />;
export type ChangeKnuddelPrivacySettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables> } & TChildProps;
export function withChangeKnuddelPrivacySettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables, ChangeKnuddelPrivacySettingsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables, ChangeKnuddelPrivacySettingsProps<TChildProps, TDataName>>(ChangeKnuddelPrivacySettingsDocument, {
    alias: 'changeKnuddelPrivacySettings',
    ...operationOptions
  });
}
export type ChangeKnuddelPrivacySettingsMutationResult = ApolloReactCommon.MutationResult<ChangeKnuddelPrivacySettingsMutation>;
export type ChangeKnuddelPrivacySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeKnuddelPrivacySettingsMutation, ChangeKnuddelPrivacySettingsMutationVariables>;
export const ChangeKnuddelPrivacySettings = (({
  operationKind: 'Mutation',
  document: ChangeKnuddelPrivacySettingsDocument,
  primaryResultPath: ['user', 'changeKnuddelPrivacyType']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ChangeKnuddelPrivacySettingsMutationVariables;
  TResult: ChangeKnuddelPrivacySettingsMutation;
  primaryResultPath: string[];
  TPrimaryResult: ChangeKnuddelPrivacySettingsMutation['user']['changeKnuddelPrivacyType'];
});
export const ChangeAdultChannelsVisibleInHardcoreChannelsDocument = gql`
	mutation ChangeAdultChannelsVisibleInHardcoreChannels($visible: Boolean!) {
		user {
			changeAdultChannelsVisibleInHardcoreChannels(value: $visible) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ChangeAdultChannelsVisibleInHardcoreChannelsMutationFn = ApolloReactCommon.MutationFunction<ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables>;
export type ChangeAdultChannelsVisibleInHardcoreChannelsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables>, 'mutation'>;
export const ChangeAdultChannelsVisibleInHardcoreChannelsComponent = (props: ChangeAdultChannelsVisibleInHardcoreChannelsComponentProps) => <ApolloReactComponents.Mutation<ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables> mutation={ChangeAdultChannelsVisibleInHardcoreChannelsDocument} {...props} />;
export type ChangeAdultChannelsVisibleInHardcoreChannelsProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables> } & TChildProps;
export function withChangeAdultChannelsVisibleInHardcoreChannels<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables, ChangeAdultChannelsVisibleInHardcoreChannelsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables, ChangeAdultChannelsVisibleInHardcoreChannelsProps<TChildProps, TDataName>>(ChangeAdultChannelsVisibleInHardcoreChannelsDocument, {
    alias: 'changeAdultChannelsVisibleInHardcoreChannels',
    ...operationOptions
  });
}
export type ChangeAdultChannelsVisibleInHardcoreChannelsMutationResult = ApolloReactCommon.MutationResult<ChangeAdultChannelsVisibleInHardcoreChannelsMutation>;
export type ChangeAdultChannelsVisibleInHardcoreChannelsMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAdultChannelsVisibleInHardcoreChannelsMutation, ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables>;
export const ChangeAdultChannelsVisibleInHardcoreChannels = (({
  operationKind: 'Mutation',
  document: ChangeAdultChannelsVisibleInHardcoreChannelsDocument,
  primaryResultPath: ['user', 'changeAdultChannelsVisibleInHardcoreChannels']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ChangeAdultChannelsVisibleInHardcoreChannelsMutationVariables;
  TResult: ChangeAdultChannelsVisibleInHardcoreChannelsMutation;
  primaryResultPath: string[];
  TPrimaryResult: ChangeAdultChannelsVisibleInHardcoreChannelsMutation['user']['changeAdultChannelsVisibleInHardcoreChannels'];
});
export const ChangeNicknameHistoryCheckedDocument = gql`
	mutation ChangeNicknameHistoryChecked($checked: Boolean!) {
		user {
			changeNicknameHistoryChecked(checked: $checked) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ChangeNicknameHistoryCheckedMutationFn = ApolloReactCommon.MutationFunction<ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables>;
export type ChangeNicknameHistoryCheckedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables>, 'mutation'>;
export const ChangeNicknameHistoryCheckedComponent = (props: ChangeNicknameHistoryCheckedComponentProps) => <ApolloReactComponents.Mutation<ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables> mutation={ChangeNicknameHistoryCheckedDocument} {...props} />;
export type ChangeNicknameHistoryCheckedProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables> } & TChildProps;
export function withChangeNicknameHistoryChecked<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables, ChangeNicknameHistoryCheckedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables, ChangeNicknameHistoryCheckedProps<TChildProps, TDataName>>(ChangeNicknameHistoryCheckedDocument, {
    alias: 'changeNicknameHistoryChecked',
    ...operationOptions
  });
}
export type ChangeNicknameHistoryCheckedMutationResult = ApolloReactCommon.MutationResult<ChangeNicknameHistoryCheckedMutation>;
export type ChangeNicknameHistoryCheckedMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeNicknameHistoryCheckedMutation, ChangeNicknameHistoryCheckedMutationVariables>;
export const ChangeNicknameHistoryChecked = (({
  operationKind: 'Mutation',
  document: ChangeNicknameHistoryCheckedDocument,
  primaryResultPath: ['user', 'changeNicknameHistoryChecked']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ChangeNicknameHistoryCheckedMutationVariables;
  TResult: ChangeNicknameHistoryCheckedMutation;
  primaryResultPath: string[];
  TPrimaryResult: ChangeNicknameHistoryCheckedMutation['user']['changeNicknameHistoryChecked'];
});
export const ChangeNicknameChangeOptionsDocument = gql`
	mutation ChangeNicknameChangeOptions($change: NicknameChangeOptionsInput!) {
		user {
			changeNicknameChangeOptions(change: $change) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type ChangeNicknameChangeOptionsMutationFn = ApolloReactCommon.MutationFunction<ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables>;
export type ChangeNicknameChangeOptionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables>, 'mutation'>;
export const ChangeNicknameChangeOptionsComponent = (props: ChangeNicknameChangeOptionsComponentProps) => <ApolloReactComponents.Mutation<ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables> mutation={ChangeNicknameChangeOptionsDocument} {...props} />;
export type ChangeNicknameChangeOptionsProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables> } & TChildProps;
export function withChangeNicknameChangeOptions<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables, ChangeNicknameChangeOptionsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables, ChangeNicknameChangeOptionsProps<TChildProps, TDataName>>(ChangeNicknameChangeOptionsDocument, {
    alias: 'changeNicknameChangeOptions',
    ...operationOptions
  });
}
export type ChangeNicknameChangeOptionsMutationResult = ApolloReactCommon.MutationResult<ChangeNicknameChangeOptionsMutation>;
export type ChangeNicknameChangeOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeNicknameChangeOptionsMutation, ChangeNicknameChangeOptionsMutationVariables>;
export const ChangeNicknameChangeOptions = (({
  operationKind: 'Mutation',
  document: ChangeNicknameChangeOptionsDocument,
  primaryResultPath: ['user', 'changeNicknameChangeOptions']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: ChangeNicknameChangeOptionsMutationVariables;
  TResult: ChangeNicknameChangeOptionsMutation;
  primaryResultPath: string[];
  TPrimaryResult: ChangeNicknameChangeOptionsMutation['user']['changeNicknameChangeOptions'];
});
export const GetAdultChannelsVisibleDocument = gql`
	query GetAdultChannelsVisible {
		user {
			adultChannelsVisible
		}
	}
`;
export type GetAdultChannelsVisibleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdultChannelsVisibleQuery, GetAdultChannelsVisibleQueryVariables>, 'query'>;
export const GetAdultChannelsVisibleComponent = (props: GetAdultChannelsVisibleComponentProps) => <ApolloReactComponents.Query<GetAdultChannelsVisibleQuery, GetAdultChannelsVisibleQueryVariables> query={GetAdultChannelsVisibleDocument} {...props} />;
export type GetAdultChannelsVisibleProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetAdultChannelsVisibleQuery, GetAdultChannelsVisibleQueryVariables> } & TChildProps;
export function withGetAdultChannelsVisible<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetAdultChannelsVisibleQuery, GetAdultChannelsVisibleQueryVariables, GetAdultChannelsVisibleProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetAdultChannelsVisibleQuery, GetAdultChannelsVisibleQueryVariables, GetAdultChannelsVisibleProps<TChildProps, TDataName>>(GetAdultChannelsVisibleDocument, {
    alias: 'getAdultChannelsVisible',
    ...operationOptions
  });
}
export type GetAdultChannelsVisibleQueryResult = ApolloReactCommon.QueryResult<GetAdultChannelsVisibleQuery, GetAdultChannelsVisibleQueryVariables>;
export const GetAdultChannelsVisible = (({
  operationKind: 'Query',
  document: GetAdultChannelsVisibleDocument,
  primaryResultPath: ['user', 'adultChannelsVisible']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetAdultChannelsVisibleQueryVariables;
  TResult: GetAdultChannelsVisibleQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetAdultChannelsVisibleQuery['user']['adultChannelsVisible'];
});
export const GetNsfwSearchEntriesVisibleDocument = gql`
	query GetNSFWSearchEntriesVisible {
		user {
			nsfwSearchEntriesVisible
		}
	}
`;
export type GetNsfwSearchEntriesVisibleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNsfwSearchEntriesVisibleQuery, GetNsfwSearchEntriesVisibleQueryVariables>, 'query'>;
export const GetNsfwSearchEntriesVisibleComponent = (props: GetNsfwSearchEntriesVisibleComponentProps) => <ApolloReactComponents.Query<GetNsfwSearchEntriesVisibleQuery, GetNsfwSearchEntriesVisibleQueryVariables> query={GetNsfwSearchEntriesVisibleDocument} {...props} />;
export type GetNsfwSearchEntriesVisibleProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetNsfwSearchEntriesVisibleQuery, GetNsfwSearchEntriesVisibleQueryVariables> } & TChildProps;
export function withGetNsfwSearchEntriesVisible<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetNsfwSearchEntriesVisibleQuery, GetNsfwSearchEntriesVisibleQueryVariables, GetNsfwSearchEntriesVisibleProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetNsfwSearchEntriesVisibleQuery, GetNsfwSearchEntriesVisibleQueryVariables, GetNsfwSearchEntriesVisibleProps<TChildProps, TDataName>>(GetNsfwSearchEntriesVisibleDocument, {
    alias: 'getNsfwSearchEntriesVisible',
    ...operationOptions
  });
}
export type GetNsfwSearchEntriesVisibleQueryResult = ApolloReactCommon.QueryResult<GetNsfwSearchEntriesVisibleQuery, GetNsfwSearchEntriesVisibleQueryVariables>;
export const GetNSFWSearchEntriesVisible = (({
  operationKind: 'Query',
  document: GetNsfwSearchEntriesVisibleDocument,
  primaryResultPath: ['user', 'nsfwSearchEntriesVisible']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetNsfwSearchEntriesVisibleQueryVariables;
  TResult: GetNsfwSearchEntriesVisibleQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetNsfwSearchEntriesVisibleQuery['user']['nsfwSearchEntriesVisible'];
});
export const GetAdultChannelsShownInLastVisitedDocument = gql`
	query GetAdultChannelsShownInLastVisited {
		user {
			adultChannelsShownInLastVisited
		}
	}
`;
export type GetAdultChannelsShownInLastVisitedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdultChannelsShownInLastVisitedQuery, GetAdultChannelsShownInLastVisitedQueryVariables>, 'query'>;
export const GetAdultChannelsShownInLastVisitedComponent = (props: GetAdultChannelsShownInLastVisitedComponentProps) => <ApolloReactComponents.Query<GetAdultChannelsShownInLastVisitedQuery, GetAdultChannelsShownInLastVisitedQueryVariables> query={GetAdultChannelsShownInLastVisitedDocument} {...props} />;
export type GetAdultChannelsShownInLastVisitedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetAdultChannelsShownInLastVisitedQuery, GetAdultChannelsShownInLastVisitedQueryVariables> } & TChildProps;
export function withGetAdultChannelsShownInLastVisited<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetAdultChannelsShownInLastVisitedQuery, GetAdultChannelsShownInLastVisitedQueryVariables, GetAdultChannelsShownInLastVisitedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetAdultChannelsShownInLastVisitedQuery, GetAdultChannelsShownInLastVisitedQueryVariables, GetAdultChannelsShownInLastVisitedProps<TChildProps, TDataName>>(GetAdultChannelsShownInLastVisitedDocument, {
    alias: 'getAdultChannelsShownInLastVisited',
    ...operationOptions
  });
}
export type GetAdultChannelsShownInLastVisitedQueryResult = ApolloReactCommon.QueryResult<GetAdultChannelsShownInLastVisitedQuery, GetAdultChannelsShownInLastVisitedQueryVariables>;
export const GetAdultChannelsShownInLastVisited = (({
  operationKind: 'Query',
  document: GetAdultChannelsShownInLastVisitedDocument,
  primaryResultPath: ['user', 'adultChannelsShownInLastVisited']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetAdultChannelsShownInLastVisitedQueryVariables;
  TResult: GetAdultChannelsShownInLastVisitedQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetAdultChannelsShownInLastVisitedQuery['user']['adultChannelsShownInLastVisited'];
});
export const UpdateAdultChannelsVisibleDocument = gql`
	mutation UpdateAdultChannelsVisible($visible: Boolean!) {
		user {
			updateAdultChannelsVisible(adultChannelsVisible: $visible) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateAdultChannelsVisibleMutationFn = ApolloReactCommon.MutationFunction<UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables>;
export type UpdateAdultChannelsVisibleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables>, 'mutation'>;
export const UpdateAdultChannelsVisibleComponent = (props: UpdateAdultChannelsVisibleComponentProps) => <ApolloReactComponents.Mutation<UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables> mutation={UpdateAdultChannelsVisibleDocument} {...props} />;
export type UpdateAdultChannelsVisibleProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables> } & TChildProps;
export function withUpdateAdultChannelsVisible<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables, UpdateAdultChannelsVisibleProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables, UpdateAdultChannelsVisibleProps<TChildProps, TDataName>>(UpdateAdultChannelsVisibleDocument, {
    alias: 'updateAdultChannelsVisible',
    ...operationOptions
  });
}
export type UpdateAdultChannelsVisibleMutationResult = ApolloReactCommon.MutationResult<UpdateAdultChannelsVisibleMutation>;
export type UpdateAdultChannelsVisibleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdultChannelsVisibleMutation, UpdateAdultChannelsVisibleMutationVariables>;
export const UpdateAdultChannelsVisible = (({
  operationKind: 'Mutation',
  document: UpdateAdultChannelsVisibleDocument,
  primaryResultPath: ['user', 'updateAdultChannelsVisible']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateAdultChannelsVisibleMutationVariables;
  TResult: UpdateAdultChannelsVisibleMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateAdultChannelsVisibleMutation['user']['updateAdultChannelsVisible'];
});
export const UpdateNsfwSearchEntriesVisibleDocument = gql`
	mutation UpdateNsfwSearchEntriesVisible($visible: Boolean!) {
		user {
			updateNsfwSearchEntriesVisible(nsfwSearchEntriesVisible: $visible) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateNsfwSearchEntriesVisibleMutationFn = ApolloReactCommon.MutationFunction<UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables>;
export type UpdateNsfwSearchEntriesVisibleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables>, 'mutation'>;
export const UpdateNsfwSearchEntriesVisibleComponent = (props: UpdateNsfwSearchEntriesVisibleComponentProps) => <ApolloReactComponents.Mutation<UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables> mutation={UpdateNsfwSearchEntriesVisibleDocument} {...props} />;
export type UpdateNsfwSearchEntriesVisibleProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables> } & TChildProps;
export function withUpdateNsfwSearchEntriesVisible<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables, UpdateNsfwSearchEntriesVisibleProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables, UpdateNsfwSearchEntriesVisibleProps<TChildProps, TDataName>>(UpdateNsfwSearchEntriesVisibleDocument, {
    alias: 'updateNsfwSearchEntriesVisible',
    ...operationOptions
  });
}
export type UpdateNsfwSearchEntriesVisibleMutationResult = ApolloReactCommon.MutationResult<UpdateNsfwSearchEntriesVisibleMutation>;
export type UpdateNsfwSearchEntriesVisibleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNsfwSearchEntriesVisibleMutation, UpdateNsfwSearchEntriesVisibleMutationVariables>;
export const UpdateNsfwSearchEntriesVisible = (({
  operationKind: 'Mutation',
  document: UpdateNsfwSearchEntriesVisibleDocument,
  primaryResultPath: ['user', 'updateNsfwSearchEntriesVisible']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateNsfwSearchEntriesVisibleMutationVariables;
  TResult: UpdateNsfwSearchEntriesVisibleMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateNsfwSearchEntriesVisibleMutation['user']['updateNsfwSearchEntriesVisible'];
});
export const UpdateAdultChannelsShownInLastVisitedDocument = gql`
	mutation UpdateAdultChannelsShownInLastVisited($shown: Boolean!) {
		user {
			updateAdultChannelsShownInLastVisited(
				adultChannelsShownInLastVisited: $shown
			) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateAdultChannelsShownInLastVisitedMutationFn = ApolloReactCommon.MutationFunction<UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables>;
export type UpdateAdultChannelsShownInLastVisitedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables>, 'mutation'>;
export const UpdateAdultChannelsShownInLastVisitedComponent = (props: UpdateAdultChannelsShownInLastVisitedComponentProps) => <ApolloReactComponents.Mutation<UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables> mutation={UpdateAdultChannelsShownInLastVisitedDocument} {...props} />;
export type UpdateAdultChannelsShownInLastVisitedProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables> } & TChildProps;
export function withUpdateAdultChannelsShownInLastVisited<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables, UpdateAdultChannelsShownInLastVisitedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables, UpdateAdultChannelsShownInLastVisitedProps<TChildProps, TDataName>>(UpdateAdultChannelsShownInLastVisitedDocument, {
    alias: 'updateAdultChannelsShownInLastVisited',
    ...operationOptions
  });
}
export type UpdateAdultChannelsShownInLastVisitedMutationResult = ApolloReactCommon.MutationResult<UpdateAdultChannelsShownInLastVisitedMutation>;
export type UpdateAdultChannelsShownInLastVisitedMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdultChannelsShownInLastVisitedMutation, UpdateAdultChannelsShownInLastVisitedMutationVariables>;
export const UpdateAdultChannelsShownInLastVisited = (({
  operationKind: 'Mutation',
  document: UpdateAdultChannelsShownInLastVisitedDocument,
  primaryResultPath: ['user', 'updateAdultChannelsShownInLastVisited']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateAdultChannelsShownInLastVisitedMutationVariables;
  TResult: UpdateAdultChannelsShownInLastVisitedMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateAdultChannelsShownInLastVisitedMutation['user']['updateAdultChannelsShownInLastVisited'];
});
export const AdultChannelsVisibleSubscriptionDocument = gql`
	subscription AdultChannelsVisibleSubscription {
		adultChannelsVisibleChanged {
			adultChannelsVisible
		}
	}
`;
export type AdultChannelsVisibleSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<AdultChannelsVisibleSubscriptionSubscription, AdultChannelsVisibleSubscriptionSubscriptionVariables>, 'subscription'>;
export const AdultChannelsVisibleSubscriptionComponent = (props: AdultChannelsVisibleSubscriptionComponentProps) => <ApolloReactComponents.Subscription<AdultChannelsVisibleSubscriptionSubscription, AdultChannelsVisibleSubscriptionSubscriptionVariables> subscription={AdultChannelsVisibleSubscriptionDocument} {...props} />;
export type AdultChannelsVisibleSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<AdultChannelsVisibleSubscriptionSubscription, AdultChannelsVisibleSubscriptionSubscriptionVariables> } & TChildProps;
export function withAdultChannelsVisibleSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AdultChannelsVisibleSubscriptionSubscription, AdultChannelsVisibleSubscriptionSubscriptionVariables, AdultChannelsVisibleSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, AdultChannelsVisibleSubscriptionSubscription, AdultChannelsVisibleSubscriptionSubscriptionVariables, AdultChannelsVisibleSubscriptionProps<TChildProps, TDataName>>(AdultChannelsVisibleSubscriptionDocument, {
    alias: 'adultChannelsVisibleSubscription',
    ...operationOptions
  });
}
export type AdultChannelsVisibleSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<AdultChannelsVisibleSubscriptionSubscription>;
export const AdultChannelsVisibleSubscription = (({
  operationKind: 'Subscription',
  document: AdultChannelsVisibleSubscriptionDocument,
  primaryResultPath: ['adultChannelsVisibleChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: AdultChannelsVisibleSubscriptionSubscriptionVariables;
  TResult: AdultChannelsVisibleSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: AdultChannelsVisibleSubscriptionSubscription['adultChannelsVisibleChanged'];
});
export const GetClientSettingsDocument = gql`
	query GetClientSettings {
		clientSettings {
			settings {
				...AllClientSettings
			}
		}
	}
	${AllClientSettingsFragmentDoc}
`;
export type GetClientSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetClientSettingsQuery, GetClientSettingsQueryVariables>, 'query'>;
export const GetClientSettingsComponent = (props: GetClientSettingsComponentProps) => <ApolloReactComponents.Query<GetClientSettingsQuery, GetClientSettingsQueryVariables> query={GetClientSettingsDocument} {...props} />;
export type GetClientSettingsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetClientSettingsQuery, GetClientSettingsQueryVariables> } & TChildProps;
export function withGetClientSettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetClientSettingsQuery, GetClientSettingsQueryVariables, GetClientSettingsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetClientSettingsQuery, GetClientSettingsQueryVariables, GetClientSettingsProps<TChildProps, TDataName>>(GetClientSettingsDocument, {
    alias: 'getClientSettings',
    ...operationOptions
  });
}
export type GetClientSettingsQueryResult = ApolloReactCommon.QueryResult<GetClientSettingsQuery, GetClientSettingsQueryVariables>;
export const GetClientSettings = (({
  operationKind: 'Query',
  document: GetClientSettingsDocument,
  primaryResultPath: ['clientSettings', 'settings']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetClientSettingsQueryVariables;
  TResult: GetClientSettingsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetClientSettingsQuery['clientSettings']['settings'];
});
export const UpdateClientSettingsDocument = gql`
	mutation UpdateClientSettings($settings: ClientUpdateSettings!) {
		clientSettings {
			updateSettings(settings: $settings) {
				error
				settings {
					...AllClientSettings
				}
			}
		}
	}
	${AllClientSettingsFragmentDoc}
`;
export type UpdateClientSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables>;
export type UpdateClientSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables>, 'mutation'>;
export const UpdateClientSettingsComponent = (props: UpdateClientSettingsComponentProps) => <ApolloReactComponents.Mutation<UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables> mutation={UpdateClientSettingsDocument} {...props} />;
export type UpdateClientSettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables> } & TChildProps;
export function withUpdateClientSettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables, UpdateClientSettingsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables, UpdateClientSettingsProps<TChildProps, TDataName>>(UpdateClientSettingsDocument, {
    alias: 'updateClientSettings',
    ...operationOptions
  });
}
export type UpdateClientSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateClientSettingsMutation>;
export type UpdateClientSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientSettingsMutation, UpdateClientSettingsMutationVariables>;
export const UpdateClientSettings = (({
  operationKind: 'Mutation',
  document: UpdateClientSettingsDocument,
  primaryResultPath: ['clientSettings', 'updateSettings']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateClientSettingsMutationVariables;
  TResult: UpdateClientSettingsMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateClientSettingsMutation['clientSettings']['updateSettings'];
});
export const ClientSettingsSubscriptionDocument = gql`
	subscription ClientSettingsSubscription {
		clientSettingsEvent {
			...ClientSettingsChanged
		}
	}
	${ClientSettingsChangedFragmentDoc}
`;
export type ClientSettingsSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ClientSettingsSubscriptionSubscription, ClientSettingsSubscriptionSubscriptionVariables>, 'subscription'>;
export const ClientSettingsSubscriptionComponent = (props: ClientSettingsSubscriptionComponentProps) => <ApolloReactComponents.Subscription<ClientSettingsSubscriptionSubscription, ClientSettingsSubscriptionSubscriptionVariables> subscription={ClientSettingsSubscriptionDocument} {...props} />;
export type ClientSettingsSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<ClientSettingsSubscriptionSubscription, ClientSettingsSubscriptionSubscriptionVariables> } & TChildProps;
export function withClientSettingsSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, ClientSettingsSubscriptionSubscription, ClientSettingsSubscriptionSubscriptionVariables, ClientSettingsSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, ClientSettingsSubscriptionSubscription, ClientSettingsSubscriptionSubscriptionVariables, ClientSettingsSubscriptionProps<TChildProps, TDataName>>(ClientSettingsSubscriptionDocument, {
    alias: 'clientSettingsSubscription',
    ...operationOptions
  });
}
export type ClientSettingsSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<ClientSettingsSubscriptionSubscription>;
export const ClientSettingsSubscription = (({
  operationKind: 'Subscription',
  document: ClientSettingsSubscriptionDocument,
  primaryResultPath: ['clientSettingsEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: ClientSettingsSubscriptionSubscriptionVariables;
  TResult: ClientSettingsSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: ClientSettingsSubscriptionSubscription['clientSettingsEvent'];
});
export const GetNotificationSettingsDocument = gql`
	query GetNotificationSettings {
		notificationSettings {
			friendStatus {
				...NotificationStatus
			}
			watchlistStatus {
				...NotificationStatus
			}
			sensitiveContentSettings {
				warnOnSensitiveContent
			}
		}
	}
	${NotificationStatusFragmentDoc}
`;
export type GetNotificationSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>, 'query'>;
export const GetNotificationSettingsComponent = (props: GetNotificationSettingsComponentProps) => <ApolloReactComponents.Query<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables> query={GetNotificationSettingsDocument} {...props} />;
export type GetNotificationSettingsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables> } & TChildProps;
export function withGetNotificationSettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables, GetNotificationSettingsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables, GetNotificationSettingsProps<TChildProps, TDataName>>(GetNotificationSettingsDocument, {
    alias: 'getNotificationSettings',
    ...operationOptions
  });
}
export type GetNotificationSettingsQueryResult = ApolloReactCommon.QueryResult<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>;
export const GetNotificationSettings = (({
  operationKind: 'Query',
  document: GetNotificationSettingsDocument,
  primaryResultPath: ['notificationSettings']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetNotificationSettingsQueryVariables;
  TResult: GetNotificationSettingsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetNotificationSettingsQuery['notificationSettings'];
});
export const GetVisitorNotificationSettingsDocument = gql`
	query GetVisitorNotificationSettings {
		notificationSettings {
			profileVisitorSettings {
				newVisitorNotificationEnabled
				freeUnlockAvailableNotificationEnabled
			}
		}
	}
`;
export type GetVisitorNotificationSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetVisitorNotificationSettingsQuery, GetVisitorNotificationSettingsQueryVariables>, 'query'>;
export const GetVisitorNotificationSettingsComponent = (props: GetVisitorNotificationSettingsComponentProps) => <ApolloReactComponents.Query<GetVisitorNotificationSettingsQuery, GetVisitorNotificationSettingsQueryVariables> query={GetVisitorNotificationSettingsDocument} {...props} />;
export type GetVisitorNotificationSettingsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetVisitorNotificationSettingsQuery, GetVisitorNotificationSettingsQueryVariables> } & TChildProps;
export function withGetVisitorNotificationSettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetVisitorNotificationSettingsQuery, GetVisitorNotificationSettingsQueryVariables, GetVisitorNotificationSettingsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetVisitorNotificationSettingsQuery, GetVisitorNotificationSettingsQueryVariables, GetVisitorNotificationSettingsProps<TChildProps, TDataName>>(GetVisitorNotificationSettingsDocument, {
    alias: 'getVisitorNotificationSettings',
    ...operationOptions
  });
}
export type GetVisitorNotificationSettingsQueryResult = ApolloReactCommon.QueryResult<GetVisitorNotificationSettingsQuery, GetVisitorNotificationSettingsQueryVariables>;
export const GetVisitorNotificationSettings = (({
  operationKind: 'Query',
  document: GetVisitorNotificationSettingsDocument,
  primaryResultPath: ['notificationSettings', 'profileVisitorSettings']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetVisitorNotificationSettingsQueryVariables;
  TResult: GetVisitorNotificationSettingsQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetVisitorNotificationSettingsQuery['notificationSettings']['profileVisitorSettings'];
});
export const NotificationSettingsSubscriptionDocument = gql`
	subscription NotificationSettingsSubscription {
		notificationSettingsEvent {
			... on NotificationSettingsChanged {
				...NotificationSettingsChanged
			}
		}
	}
	${NotificationSettingsChangedFragmentDoc}
`;
export type NotificationSettingsSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<NotificationSettingsSubscriptionSubscription, NotificationSettingsSubscriptionSubscriptionVariables>, 'subscription'>;
export const NotificationSettingsSubscriptionComponent = (props: NotificationSettingsSubscriptionComponentProps) => <ApolloReactComponents.Subscription<NotificationSettingsSubscriptionSubscription, NotificationSettingsSubscriptionSubscriptionVariables> subscription={NotificationSettingsSubscriptionDocument} {...props} />;
export type NotificationSettingsSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<NotificationSettingsSubscriptionSubscription, NotificationSettingsSubscriptionSubscriptionVariables> } & TChildProps;
export function withNotificationSettingsSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, NotificationSettingsSubscriptionSubscription, NotificationSettingsSubscriptionSubscriptionVariables, NotificationSettingsSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, NotificationSettingsSubscriptionSubscription, NotificationSettingsSubscriptionSubscriptionVariables, NotificationSettingsSubscriptionProps<TChildProps, TDataName>>(NotificationSettingsSubscriptionDocument, {
    alias: 'notificationSettingsSubscription',
    ...operationOptions
  });
}
export type NotificationSettingsSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<NotificationSettingsSubscriptionSubscription>;
export const NotificationSettingsSubscription = (({
  operationKind: 'Subscription',
  document: NotificationSettingsSubscriptionDocument,
  primaryResultPath: ['notificationSettingsEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: NotificationSettingsSubscriptionSubscriptionVariables;
  TResult: NotificationSettingsSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: NotificationSettingsSubscriptionSubscription['notificationSettingsEvent'];
});
export const VisitorNotificationSettingsSubscriptionDocument = gql`
	subscription VisitorNotificationSettingsSubscription {
		notificationSettingsEvent {
			... on NotificationSettingsChanged {
				...VisitorNotificationSettingsChanged
			}
		}
	}
	${VisitorNotificationSettingsChangedFragmentDoc}
`;
export type VisitorNotificationSettingsSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<VisitorNotificationSettingsSubscriptionSubscription, VisitorNotificationSettingsSubscriptionSubscriptionVariables>, 'subscription'>;
export const VisitorNotificationSettingsSubscriptionComponent = (props: VisitorNotificationSettingsSubscriptionComponentProps) => <ApolloReactComponents.Subscription<VisitorNotificationSettingsSubscriptionSubscription, VisitorNotificationSettingsSubscriptionSubscriptionVariables> subscription={VisitorNotificationSettingsSubscriptionDocument} {...props} />;
export type VisitorNotificationSettingsSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<VisitorNotificationSettingsSubscriptionSubscription, VisitorNotificationSettingsSubscriptionSubscriptionVariables> } & TChildProps;
export function withVisitorNotificationSettingsSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, VisitorNotificationSettingsSubscriptionSubscription, VisitorNotificationSettingsSubscriptionSubscriptionVariables, VisitorNotificationSettingsSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, VisitorNotificationSettingsSubscriptionSubscription, VisitorNotificationSettingsSubscriptionSubscriptionVariables, VisitorNotificationSettingsSubscriptionProps<TChildProps, TDataName>>(VisitorNotificationSettingsSubscriptionDocument, {
    alias: 'visitorNotificationSettingsSubscription',
    ...operationOptions
  });
}
export type VisitorNotificationSettingsSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<VisitorNotificationSettingsSubscriptionSubscription>;
export const VisitorNotificationSettingsSubscription = (({
  operationKind: 'Subscription',
  document: VisitorNotificationSettingsSubscriptionDocument,
  primaryResultPath: ['notificationSettingsEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: VisitorNotificationSettingsSubscriptionSubscriptionVariables;
  TResult: VisitorNotificationSettingsSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: VisitorNotificationSettingsSubscriptionSubscription['notificationSettingsEvent'];
});
export const EnableFriendNotificationDocument = gql`
	mutation EnableFriendNotification {
		notificationSettings {
			enableFriendNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EnableFriendNotificationMutationFn = ApolloReactCommon.MutationFunction<EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables>;
export type EnableFriendNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables>, 'mutation'>;
export const EnableFriendNotificationComponent = (props: EnableFriendNotificationComponentProps) => <ApolloReactComponents.Mutation<EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables> mutation={EnableFriendNotificationDocument} {...props} />;
export type EnableFriendNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables> } & TChildProps;
export function withEnableFriendNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables, EnableFriendNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables, EnableFriendNotificationProps<TChildProps, TDataName>>(EnableFriendNotificationDocument, {
    alias: 'enableFriendNotification',
    ...operationOptions
  });
}
export type EnableFriendNotificationMutationResult = ApolloReactCommon.MutationResult<EnableFriendNotificationMutation>;
export type EnableFriendNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableFriendNotificationMutation, EnableFriendNotificationMutationVariables>;
export const EnableFriendNotification = (({
  operationKind: 'Mutation',
  document: EnableFriendNotificationDocument,
  primaryResultPath: ['notificationSettings', 'enableFriendNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EnableFriendNotificationMutationVariables;
  TResult: EnableFriendNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: EnableFriendNotificationMutation['notificationSettings']['enableFriendNotification'];
});
export const DisableFriendNotificationDocument = gql`
	mutation DisableFriendNotification {
		notificationSettings {
			disableFriendNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DisableFriendNotificationMutationFn = ApolloReactCommon.MutationFunction<DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables>;
export type DisableFriendNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables>, 'mutation'>;
export const DisableFriendNotificationComponent = (props: DisableFriendNotificationComponentProps) => <ApolloReactComponents.Mutation<DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables> mutation={DisableFriendNotificationDocument} {...props} />;
export type DisableFriendNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables> } & TChildProps;
export function withDisableFriendNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables, DisableFriendNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables, DisableFriendNotificationProps<TChildProps, TDataName>>(DisableFriendNotificationDocument, {
    alias: 'disableFriendNotification',
    ...operationOptions
  });
}
export type DisableFriendNotificationMutationResult = ApolloReactCommon.MutationResult<DisableFriendNotificationMutation>;
export type DisableFriendNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableFriendNotificationMutation, DisableFriendNotificationMutationVariables>;
export const DisableFriendNotification = (({
  operationKind: 'Mutation',
  document: DisableFriendNotificationDocument,
  primaryResultPath: ['notificationSettings', 'disableFriendNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DisableFriendNotificationMutationVariables;
  TResult: DisableFriendNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: DisableFriendNotificationMutation['notificationSettings']['disableFriendNotification'];
});
export const UpdateFriendNotificationEnabledStatusDocument = gql`
	mutation UpdateFriendNotificationEnabledStatus(
		$status: NotificationEnabledInput!
	) {
		notificationSettings {
			updateFriendNotificationEnabledStatus(status: $status) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateFriendNotificationEnabledStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables>;
export type UpdateFriendNotificationEnabledStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables>, 'mutation'>;
export const UpdateFriendNotificationEnabledStatusComponent = (props: UpdateFriendNotificationEnabledStatusComponentProps) => <ApolloReactComponents.Mutation<UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables> mutation={UpdateFriendNotificationEnabledStatusDocument} {...props} />;
export type UpdateFriendNotificationEnabledStatusProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables> } & TChildProps;
export function withUpdateFriendNotificationEnabledStatus<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables, UpdateFriendNotificationEnabledStatusProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables, UpdateFriendNotificationEnabledStatusProps<TChildProps, TDataName>>(UpdateFriendNotificationEnabledStatusDocument, {
    alias: 'updateFriendNotificationEnabledStatus',
    ...operationOptions
  });
}
export type UpdateFriendNotificationEnabledStatusMutationResult = ApolloReactCommon.MutationResult<UpdateFriendNotificationEnabledStatusMutation>;
export type UpdateFriendNotificationEnabledStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFriendNotificationEnabledStatusMutation, UpdateFriendNotificationEnabledStatusMutationVariables>;
export const UpdateFriendNotificationEnabledStatus = (({
  operationKind: 'Mutation',
  document: UpdateFriendNotificationEnabledStatusDocument,
  primaryResultPath: ['notificationSettings', 'updateFriendNotificationEnabledStatus']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateFriendNotificationEnabledStatusMutationVariables;
  TResult: UpdateFriendNotificationEnabledStatusMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateFriendNotificationEnabledStatusMutation['notificationSettings']['updateFriendNotificationEnabledStatus'];
});
export const EnableWatchlistNotificationDocument = gql`
	mutation EnableWatchlistNotification {
		notificationSettings {
			enableWatchlistNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EnableWatchlistNotificationMutationFn = ApolloReactCommon.MutationFunction<EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables>;
export type EnableWatchlistNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables>, 'mutation'>;
export const EnableWatchlistNotificationComponent = (props: EnableWatchlistNotificationComponentProps) => <ApolloReactComponents.Mutation<EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables> mutation={EnableWatchlistNotificationDocument} {...props} />;
export type EnableWatchlistNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables> } & TChildProps;
export function withEnableWatchlistNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables, EnableWatchlistNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables, EnableWatchlistNotificationProps<TChildProps, TDataName>>(EnableWatchlistNotificationDocument, {
    alias: 'enableWatchlistNotification',
    ...operationOptions
  });
}
export type EnableWatchlistNotificationMutationResult = ApolloReactCommon.MutationResult<EnableWatchlistNotificationMutation>;
export type EnableWatchlistNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableWatchlistNotificationMutation, EnableWatchlistNotificationMutationVariables>;
export const EnableWatchlistNotification = (({
  operationKind: 'Mutation',
  document: EnableWatchlistNotificationDocument,
  primaryResultPath: ['notificationSettings', 'enableWatchlistNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EnableWatchlistNotificationMutationVariables;
  TResult: EnableWatchlistNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: EnableWatchlistNotificationMutation['notificationSettings']['enableWatchlistNotification'];
});
export const DisableWatchlistNotificationDocument = gql`
	mutation DisableWatchlistNotification {
		notificationSettings {
			disableWatchlistNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DisableWatchlistNotificationMutationFn = ApolloReactCommon.MutationFunction<DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables>;
export type DisableWatchlistNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables>, 'mutation'>;
export const DisableWatchlistNotificationComponent = (props: DisableWatchlistNotificationComponentProps) => <ApolloReactComponents.Mutation<DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables> mutation={DisableWatchlistNotificationDocument} {...props} />;
export type DisableWatchlistNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables> } & TChildProps;
export function withDisableWatchlistNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables, DisableWatchlistNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables, DisableWatchlistNotificationProps<TChildProps, TDataName>>(DisableWatchlistNotificationDocument, {
    alias: 'disableWatchlistNotification',
    ...operationOptions
  });
}
export type DisableWatchlistNotificationMutationResult = ApolloReactCommon.MutationResult<DisableWatchlistNotificationMutation>;
export type DisableWatchlistNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableWatchlistNotificationMutation, DisableWatchlistNotificationMutationVariables>;
export const DisableWatchlistNotification = (({
  operationKind: 'Mutation',
  document: DisableWatchlistNotificationDocument,
  primaryResultPath: ['notificationSettings', 'disableWatchlistNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DisableWatchlistNotificationMutationVariables;
  TResult: DisableWatchlistNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: DisableWatchlistNotificationMutation['notificationSettings']['disableWatchlistNotification'];
});
export const UpdateWatchlistNotificationEnabledStatusDocument = gql`
	mutation UpdateWatchlistNotificationEnabledStatus(
		$status: NotificationEnabledInput!
	) {
		notificationSettings {
			updateWatchlistNotificationEnabledStatus(status: $status) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateWatchlistNotificationEnabledStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables>;
export type UpdateWatchlistNotificationEnabledStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables>, 'mutation'>;
export const UpdateWatchlistNotificationEnabledStatusComponent = (props: UpdateWatchlistNotificationEnabledStatusComponentProps) => <ApolloReactComponents.Mutation<UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables> mutation={UpdateWatchlistNotificationEnabledStatusDocument} {...props} />;
export type UpdateWatchlistNotificationEnabledStatusProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables> } & TChildProps;
export function withUpdateWatchlistNotificationEnabledStatus<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables, UpdateWatchlistNotificationEnabledStatusProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables, UpdateWatchlistNotificationEnabledStatusProps<TChildProps, TDataName>>(UpdateWatchlistNotificationEnabledStatusDocument, {
    alias: 'updateWatchlistNotificationEnabledStatus',
    ...operationOptions
  });
}
export type UpdateWatchlistNotificationEnabledStatusMutationResult = ApolloReactCommon.MutationResult<UpdateWatchlistNotificationEnabledStatusMutation>;
export type UpdateWatchlistNotificationEnabledStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWatchlistNotificationEnabledStatusMutation, UpdateWatchlistNotificationEnabledStatusMutationVariables>;
export const UpdateWatchlistNotificationEnabledStatus = (({
  operationKind: 'Mutation',
  document: UpdateWatchlistNotificationEnabledStatusDocument,
  primaryResultPath: ['notificationSettings', 'updateWatchlistNotificationEnabledStatus']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateWatchlistNotificationEnabledStatusMutationVariables;
  TResult: UpdateWatchlistNotificationEnabledStatusMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateWatchlistNotificationEnabledStatusMutation['notificationSettings']['updateWatchlistNotificationEnabledStatus'];
});
export const EnableSensitiveContentWarningDocument = gql`
	mutation EnableSensitiveContentWarning {
		notificationSettings {
			enableSensitiveContentWarning {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EnableSensitiveContentWarningMutationFn = ApolloReactCommon.MutationFunction<EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables>;
export type EnableSensitiveContentWarningComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables>, 'mutation'>;
export const EnableSensitiveContentWarningComponent = (props: EnableSensitiveContentWarningComponentProps) => <ApolloReactComponents.Mutation<EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables> mutation={EnableSensitiveContentWarningDocument} {...props} />;
export type EnableSensitiveContentWarningProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables> } & TChildProps;
export function withEnableSensitiveContentWarning<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables, EnableSensitiveContentWarningProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables, EnableSensitiveContentWarningProps<TChildProps, TDataName>>(EnableSensitiveContentWarningDocument, {
    alias: 'enableSensitiveContentWarning',
    ...operationOptions
  });
}
export type EnableSensitiveContentWarningMutationResult = ApolloReactCommon.MutationResult<EnableSensitiveContentWarningMutation>;
export type EnableSensitiveContentWarningMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableSensitiveContentWarningMutation, EnableSensitiveContentWarningMutationVariables>;
export const EnableSensitiveContentWarning = (({
  operationKind: 'Mutation',
  document: EnableSensitiveContentWarningDocument,
  primaryResultPath: ['notificationSettings', 'enableSensitiveContentWarning']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EnableSensitiveContentWarningMutationVariables;
  TResult: EnableSensitiveContentWarningMutation;
  primaryResultPath: string[];
  TPrimaryResult: EnableSensitiveContentWarningMutation['notificationSettings']['enableSensitiveContentWarning'];
});
export const DisableSensitiveContentWarningDocument = gql`
	mutation DisableSensitiveContentWarning {
		notificationSettings {
			disableSensitiveContentWarning {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DisableSensitiveContentWarningMutationFn = ApolloReactCommon.MutationFunction<DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables>;
export type DisableSensitiveContentWarningComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables>, 'mutation'>;
export const DisableSensitiveContentWarningComponent = (props: DisableSensitiveContentWarningComponentProps) => <ApolloReactComponents.Mutation<DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables> mutation={DisableSensitiveContentWarningDocument} {...props} />;
export type DisableSensitiveContentWarningProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables> } & TChildProps;
export function withDisableSensitiveContentWarning<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables, DisableSensitiveContentWarningProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables, DisableSensitiveContentWarningProps<TChildProps, TDataName>>(DisableSensitiveContentWarningDocument, {
    alias: 'disableSensitiveContentWarning',
    ...operationOptions
  });
}
export type DisableSensitiveContentWarningMutationResult = ApolloReactCommon.MutationResult<DisableSensitiveContentWarningMutation>;
export type DisableSensitiveContentWarningMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableSensitiveContentWarningMutation, DisableSensitiveContentWarningMutationVariables>;
export const DisableSensitiveContentWarning = (({
  operationKind: 'Mutation',
  document: DisableSensitiveContentWarningDocument,
  primaryResultPath: ['notificationSettings', 'disableSensitiveContentWarning']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DisableSensitiveContentWarningMutationVariables;
  TResult: DisableSensitiveContentWarningMutation;
  primaryResultPath: string[];
  TPrimaryResult: DisableSensitiveContentWarningMutation['notificationSettings']['disableSensitiveContentWarning'];
});
export const EnableNewProfileVisitorNotificationDocument = gql`
	mutation EnableNewProfileVisitorNotification {
		notificationSettings {
			enableNewProfileVisitorNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EnableNewProfileVisitorNotificationMutationFn = ApolloReactCommon.MutationFunction<EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables>;
export type EnableNewProfileVisitorNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables>, 'mutation'>;
export const EnableNewProfileVisitorNotificationComponent = (props: EnableNewProfileVisitorNotificationComponentProps) => <ApolloReactComponents.Mutation<EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables> mutation={EnableNewProfileVisitorNotificationDocument} {...props} />;
export type EnableNewProfileVisitorNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables> } & TChildProps;
export function withEnableNewProfileVisitorNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables, EnableNewProfileVisitorNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables, EnableNewProfileVisitorNotificationProps<TChildProps, TDataName>>(EnableNewProfileVisitorNotificationDocument, {
    alias: 'enableNewProfileVisitorNotification',
    ...operationOptions
  });
}
export type EnableNewProfileVisitorNotificationMutationResult = ApolloReactCommon.MutationResult<EnableNewProfileVisitorNotificationMutation>;
export type EnableNewProfileVisitorNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableNewProfileVisitorNotificationMutation, EnableNewProfileVisitorNotificationMutationVariables>;
export const EnableNewProfileVisitorNotification = (({
  operationKind: 'Mutation',
  document: EnableNewProfileVisitorNotificationDocument,
  primaryResultPath: ['notificationSettings', 'enableNewProfileVisitorNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EnableNewProfileVisitorNotificationMutationVariables;
  TResult: EnableNewProfileVisitorNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: EnableNewProfileVisitorNotificationMutation['notificationSettings']['enableNewProfileVisitorNotification'];
});
export const DisableNewProfileVisitorNotificationDocument = gql`
	mutation DisableNewProfileVisitorNotification {
		notificationSettings {
			disableNewProfileVisitorNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DisableNewProfileVisitorNotificationMutationFn = ApolloReactCommon.MutationFunction<DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables>;
export type DisableNewProfileVisitorNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables>, 'mutation'>;
export const DisableNewProfileVisitorNotificationComponent = (props: DisableNewProfileVisitorNotificationComponentProps) => <ApolloReactComponents.Mutation<DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables> mutation={DisableNewProfileVisitorNotificationDocument} {...props} />;
export type DisableNewProfileVisitorNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables> } & TChildProps;
export function withDisableNewProfileVisitorNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables, DisableNewProfileVisitorNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables, DisableNewProfileVisitorNotificationProps<TChildProps, TDataName>>(DisableNewProfileVisitorNotificationDocument, {
    alias: 'disableNewProfileVisitorNotification',
    ...operationOptions
  });
}
export type DisableNewProfileVisitorNotificationMutationResult = ApolloReactCommon.MutationResult<DisableNewProfileVisitorNotificationMutation>;
export type DisableNewProfileVisitorNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableNewProfileVisitorNotificationMutation, DisableNewProfileVisitorNotificationMutationVariables>;
export const DisableNewProfileVisitorNotification = (({
  operationKind: 'Mutation',
  document: DisableNewProfileVisitorNotificationDocument,
  primaryResultPath: ['notificationSettings', 'disableNewProfileVisitorNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DisableNewProfileVisitorNotificationMutationVariables;
  TResult: DisableNewProfileVisitorNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: DisableNewProfileVisitorNotificationMutation['notificationSettings']['disableNewProfileVisitorNotification'];
});
export const EnableFreeProfileVisitorUnlockAvailableNotificationDocument = gql`
	mutation EnableFreeProfileVisitorUnlockAvailableNotification {
		notificationSettings {
			enableFreeProfileVisitorUnlockAvailableNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EnableFreeProfileVisitorUnlockAvailableNotificationMutationFn = ApolloReactCommon.MutationFunction<EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables>;
export type EnableFreeProfileVisitorUnlockAvailableNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables>, 'mutation'>;
export const EnableFreeProfileVisitorUnlockAvailableNotificationComponent = (props: EnableFreeProfileVisitorUnlockAvailableNotificationComponentProps) => <ApolloReactComponents.Mutation<EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables> mutation={EnableFreeProfileVisitorUnlockAvailableNotificationDocument} {...props} />;
export type EnableFreeProfileVisitorUnlockAvailableNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables> } & TChildProps;
export function withEnableFreeProfileVisitorUnlockAvailableNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables, EnableFreeProfileVisitorUnlockAvailableNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables, EnableFreeProfileVisitorUnlockAvailableNotificationProps<TChildProps, TDataName>>(EnableFreeProfileVisitorUnlockAvailableNotificationDocument, {
    alias: 'enableFreeProfileVisitorUnlockAvailableNotification',
    ...operationOptions
  });
}
export type EnableFreeProfileVisitorUnlockAvailableNotificationMutationResult = ApolloReactCommon.MutationResult<EnableFreeProfileVisitorUnlockAvailableNotificationMutation>;
export type EnableFreeProfileVisitorUnlockAvailableNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableFreeProfileVisitorUnlockAvailableNotificationMutation, EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables>;
export const EnableFreeProfileVisitorUnlockAvailableNotification = (({
  operationKind: 'Mutation',
  document: EnableFreeProfileVisitorUnlockAvailableNotificationDocument,
  primaryResultPath: ['notificationSettings', 'enableFreeProfileVisitorUnlockAvailableNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EnableFreeProfileVisitorUnlockAvailableNotificationMutationVariables;
  TResult: EnableFreeProfileVisitorUnlockAvailableNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: EnableFreeProfileVisitorUnlockAvailableNotificationMutation['notificationSettings']['enableFreeProfileVisitorUnlockAvailableNotification'];
});
export const DisableFreeProfileVisitorUnlockAvailableNotificationDocument = gql`
	mutation DisableFreeProfileVisitorUnlockAvailableNotification {
		notificationSettings {
			disableFreeProfileVisitorUnlockAvailableNotification {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DisableFreeProfileVisitorUnlockAvailableNotificationMutationFn = ApolloReactCommon.MutationFunction<DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables>;
export type DisableFreeProfileVisitorUnlockAvailableNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables>, 'mutation'>;
export const DisableFreeProfileVisitorUnlockAvailableNotificationComponent = (props: DisableFreeProfileVisitorUnlockAvailableNotificationComponentProps) => <ApolloReactComponents.Mutation<DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables> mutation={DisableFreeProfileVisitorUnlockAvailableNotificationDocument} {...props} />;
export type DisableFreeProfileVisitorUnlockAvailableNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables> } & TChildProps;
export function withDisableFreeProfileVisitorUnlockAvailableNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables, DisableFreeProfileVisitorUnlockAvailableNotificationProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables, DisableFreeProfileVisitorUnlockAvailableNotificationProps<TChildProps, TDataName>>(DisableFreeProfileVisitorUnlockAvailableNotificationDocument, {
    alias: 'disableFreeProfileVisitorUnlockAvailableNotification',
    ...operationOptions
  });
}
export type DisableFreeProfileVisitorUnlockAvailableNotificationMutationResult = ApolloReactCommon.MutationResult<DisableFreeProfileVisitorUnlockAvailableNotificationMutation>;
export type DisableFreeProfileVisitorUnlockAvailableNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableFreeProfileVisitorUnlockAvailableNotificationMutation, DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables>;
export const DisableFreeProfileVisitorUnlockAvailableNotification = (({
  operationKind: 'Mutation',
  document: DisableFreeProfileVisitorUnlockAvailableNotificationDocument,
  primaryResultPath: ['notificationSettings', 'disableFreeProfileVisitorUnlockAvailableNotification']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DisableFreeProfileVisitorUnlockAvailableNotificationMutationVariables;
  TResult: DisableFreeProfileVisitorUnlockAvailableNotificationMutation;
  primaryResultPath: string[];
  TPrimaryResult: DisableFreeProfileVisitorUnlockAvailableNotificationMutation['notificationSettings']['disableFreeProfileVisitorUnlockAvailableNotification'];
});
export const GetTanSystemStatusDocument = gql`
	query GetTanSystemStatus {
		tan {
			status {
				...TanSystemStatus
			}
		}
	}
	${TanSystemStatusFragmentDoc}
`;
export type GetTanSystemStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTanSystemStatusQuery, GetTanSystemStatusQueryVariables>, 'query'>;
export const GetTanSystemStatusComponent = (props: GetTanSystemStatusComponentProps) => <ApolloReactComponents.Query<GetTanSystemStatusQuery, GetTanSystemStatusQueryVariables> query={GetTanSystemStatusDocument} {...props} />;
export type GetTanSystemStatusProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetTanSystemStatusQuery, GetTanSystemStatusQueryVariables> } & TChildProps;
export function withGetTanSystemStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetTanSystemStatusQuery, GetTanSystemStatusQueryVariables, GetTanSystemStatusProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetTanSystemStatusQuery, GetTanSystemStatusQueryVariables, GetTanSystemStatusProps<TChildProps, TDataName>>(GetTanSystemStatusDocument, {
    alias: 'getTanSystemStatus',
    ...operationOptions
  });
}
export type GetTanSystemStatusQueryResult = ApolloReactCommon.QueryResult<GetTanSystemStatusQuery, GetTanSystemStatusQueryVariables>;
export const GetTanSystemStatus = (({
  operationKind: 'Query',
  document: GetTanSystemStatusDocument,
  primaryResultPath: ['tan', 'status']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetTanSystemStatusQueryVariables;
  TResult: GetTanSystemStatusQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetTanSystemStatusQuery['tan']['status'];
});
export const TanSystemSubscriptionDocument = gql`
	subscription TanSystemSubscription {
		tanStatusChanged {
			...TanSystemStatus
		}
	}
	${TanSystemStatusFragmentDoc}
`;
export type TanSystemSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TanSystemSubscriptionSubscription, TanSystemSubscriptionSubscriptionVariables>, 'subscription'>;
export const TanSystemSubscriptionComponent = (props: TanSystemSubscriptionComponentProps) => <ApolloReactComponents.Subscription<TanSystemSubscriptionSubscription, TanSystemSubscriptionSubscriptionVariables> subscription={TanSystemSubscriptionDocument} {...props} />;
export type TanSystemSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<TanSystemSubscriptionSubscription, TanSystemSubscriptionSubscriptionVariables> } & TChildProps;
export function withTanSystemSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, TanSystemSubscriptionSubscription, TanSystemSubscriptionSubscriptionVariables, TanSystemSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, TanSystemSubscriptionSubscription, TanSystemSubscriptionSubscriptionVariables, TanSystemSubscriptionProps<TChildProps, TDataName>>(TanSystemSubscriptionDocument, {
    alias: 'tanSystemSubscription',
    ...operationOptions
  });
}
export type TanSystemSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<TanSystemSubscriptionSubscription>;
export const TanSystemSubscription = (({
  operationKind: 'Subscription',
  document: TanSystemSubscriptionDocument,
  primaryResultPath: ['tanStatusChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: TanSystemSubscriptionSubscriptionVariables;
  TResult: TanSystemSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: TanSystemSubscriptionSubscription['tanStatusChanged'];
});
export const VerifiedDataSubscriptionDocument = gql`
	subscription VerifiedDataSubscription($id: ID!) {
		userChanged(
			config: { userId: $id, fields: [Email, VerifiedPhoneNumber] }
		) {
			id
			email
			verifiedPhoneNumber
		}
	}
`;
export type VerifiedDataSubscriptionComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<VerifiedDataSubscriptionSubscription, VerifiedDataSubscriptionSubscriptionVariables>, 'subscription'>;
export const VerifiedDataSubscriptionComponent = (props: VerifiedDataSubscriptionComponentProps) => <ApolloReactComponents.Subscription<VerifiedDataSubscriptionSubscription, VerifiedDataSubscriptionSubscriptionVariables> subscription={VerifiedDataSubscriptionDocument} {...props} />;
export type VerifiedDataSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<VerifiedDataSubscriptionSubscription, VerifiedDataSubscriptionSubscriptionVariables> } & TChildProps;
export function withVerifiedDataSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, VerifiedDataSubscriptionSubscription, VerifiedDataSubscriptionSubscriptionVariables, VerifiedDataSubscriptionProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, VerifiedDataSubscriptionSubscription, VerifiedDataSubscriptionSubscriptionVariables, VerifiedDataSubscriptionProps<TChildProps, TDataName>>(VerifiedDataSubscriptionDocument, {
    alias: 'verifiedDataSubscription',
    ...operationOptions
  });
}
export type VerifiedDataSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<VerifiedDataSubscriptionSubscription>;
export const VerifiedDataSubscription = (({
  operationKind: 'Subscription',
  document: VerifiedDataSubscriptionDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: VerifiedDataSubscriptionSubscriptionVariables;
  TResult: VerifiedDataSubscriptionSubscription;
  primaryResultPath: string[];
  TPrimaryResult: VerifiedDataSubscriptionSubscription['userChanged'];
});
export const EnableTanSystemDocument = gql`
	mutation EnableTanSystem {
		tan {
			enableTan {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type EnableTanSystemMutationFn = ApolloReactCommon.MutationFunction<EnableTanSystemMutation, EnableTanSystemMutationVariables>;
export type EnableTanSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableTanSystemMutation, EnableTanSystemMutationVariables>, 'mutation'>;
export const EnableTanSystemComponent = (props: EnableTanSystemComponentProps) => <ApolloReactComponents.Mutation<EnableTanSystemMutation, EnableTanSystemMutationVariables> mutation={EnableTanSystemDocument} {...props} />;
export type EnableTanSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<EnableTanSystemMutation, EnableTanSystemMutationVariables> } & TChildProps;
export function withEnableTanSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, EnableTanSystemMutation, EnableTanSystemMutationVariables, EnableTanSystemProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, EnableTanSystemMutation, EnableTanSystemMutationVariables, EnableTanSystemProps<TChildProps, TDataName>>(EnableTanSystemDocument, {
    alias: 'enableTanSystem',
    ...operationOptions
  });
}
export type EnableTanSystemMutationResult = ApolloReactCommon.MutationResult<EnableTanSystemMutation>;
export type EnableTanSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableTanSystemMutation, EnableTanSystemMutationVariables>;
export const EnableTanSystem = (({
  operationKind: 'Mutation',
  document: EnableTanSystemDocument,
  primaryResultPath: ['tan', 'enableTan']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: EnableTanSystemMutationVariables;
  TResult: EnableTanSystemMutation;
  primaryResultPath: string[];
  TPrimaryResult: EnableTanSystemMutation['tan']['enableTan'];
});
export const DisableTanSystemDocument = gql`
	mutation DisableTanSystem {
		tan {
			disableTan {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type DisableTanSystemMutationFn = ApolloReactCommon.MutationFunction<DisableTanSystemMutation, DisableTanSystemMutationVariables>;
export type DisableTanSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableTanSystemMutation, DisableTanSystemMutationVariables>, 'mutation'>;
export const DisableTanSystemComponent = (props: DisableTanSystemComponentProps) => <ApolloReactComponents.Mutation<DisableTanSystemMutation, DisableTanSystemMutationVariables> mutation={DisableTanSystemDocument} {...props} />;
export type DisableTanSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<DisableTanSystemMutation, DisableTanSystemMutationVariables> } & TChildProps;
export function withDisableTanSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, DisableTanSystemMutation, DisableTanSystemMutationVariables, DisableTanSystemProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, DisableTanSystemMutation, DisableTanSystemMutationVariables, DisableTanSystemProps<TChildProps, TDataName>>(DisableTanSystemDocument, {
    alias: 'disableTanSystem',
    ...operationOptions
  });
}
export type DisableTanSystemMutationResult = ApolloReactCommon.MutationResult<DisableTanSystemMutation>;
export type DisableTanSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableTanSystemMutation, DisableTanSystemMutationVariables>;
export const DisableTanSystem = (({
  operationKind: 'Mutation',
  document: DisableTanSystemDocument,
  primaryResultPath: ['tan', 'disableTan']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: DisableTanSystemMutationVariables;
  TResult: DisableTanSystemMutation;
  primaryResultPath: string[];
  TPrimaryResult: DisableTanSystemMutation['tan']['disableTan'];
});
export const UpdateTanSystemStatusDocument = gql`
	mutation UpdateTanSystemStatus($status: TanEnabledInput!) {
		tan {
			updateTanEnabledStatus(status: $status) {
				... on Success {
					unused
				}
				... on InternalError {
					unused
				}
			}
		}
	}
`;
export type UpdateTanSystemStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables>;
export type UpdateTanSystemStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables>, 'mutation'>;
export const UpdateTanSystemStatusComponent = (props: UpdateTanSystemStatusComponentProps) => <ApolloReactComponents.Mutation<UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables> mutation={UpdateTanSystemStatusDocument} {...props} />;
export type UpdateTanSystemStatusProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables> } & TChildProps;
export function withUpdateTanSystemStatus<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables, UpdateTanSystemStatusProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables, UpdateTanSystemStatusProps<TChildProps, TDataName>>(UpdateTanSystemStatusDocument, {
    alias: 'updateTanSystemStatus',
    ...operationOptions
  });
}
export type UpdateTanSystemStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTanSystemStatusMutation>;
export type UpdateTanSystemStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTanSystemStatusMutation, UpdateTanSystemStatusMutationVariables>;
export const UpdateTanSystemStatus = (({
  operationKind: 'Mutation',
  document: UpdateTanSystemStatusDocument,
  primaryResultPath: ['tan', 'updateTanEnabledStatus']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UpdateTanSystemStatusMutationVariables;
  TResult: UpdateTanSystemStatusMutation;
  primaryResultPath: string[];
  TPrimaryResult: UpdateTanSystemStatusMutation['tan']['updateTanEnabledStatus'];
});
export const SmileyboxChangedDocument = gql`
	subscription SmileyboxChanged {
		smileyBoxEvent {
			... on SmileyBoxListChanged {
				listType
				smileys {
					id
				}
			}
		}
	}
`;
export type SmileyboxChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<SmileyboxChangedSubscription, SmileyboxChangedSubscriptionVariables>, 'subscription'>;
export const SmileyboxChangedComponent = (props: SmileyboxChangedComponentProps) => <ApolloReactComponents.Subscription<SmileyboxChangedSubscription, SmileyboxChangedSubscriptionVariables> subscription={SmileyboxChangedDocument} {...props} />;
export type SmileyboxChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<SmileyboxChangedSubscription, SmileyboxChangedSubscriptionVariables> } & TChildProps;
export function withSmileyboxChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SmileyboxChangedSubscription, SmileyboxChangedSubscriptionVariables, SmileyboxChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, SmileyboxChangedSubscription, SmileyboxChangedSubscriptionVariables, SmileyboxChangedProps<TChildProps, TDataName>>(SmileyboxChangedDocument, {
    alias: 'smileyboxChanged',
    ...operationOptions
  });
}
export type SmileyboxChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<SmileyboxChangedSubscription>;
export const SmileyboxChanged = (({
  operationKind: 'Subscription',
  document: SmileyboxChangedDocument,
  primaryResultPath: ['smileyBoxEvent']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: SmileyboxChangedSubscriptionVariables;
  TResult: SmileyboxChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: SmileyboxChangedSubscription['smileyBoxEvent'];
});
export const RecentSmileysDocument = gql`
	query RecentSmileys($limit: Int) {
		smileybox {
			smileyList(type: RecentlyUsed, limit: $limit) {
				...Smiley
			}
		}
	}
	${SmileyFragmentDoc}
`;
export type RecentSmileysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecentSmileysQuery, RecentSmileysQueryVariables>, 'query'>;
export const RecentSmileysComponent = (props: RecentSmileysComponentProps) => <ApolloReactComponents.Query<RecentSmileysQuery, RecentSmileysQueryVariables> query={RecentSmileysDocument} {...props} />;
export type RecentSmileysProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<RecentSmileysQuery, RecentSmileysQueryVariables> } & TChildProps;
export function withRecentSmileys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, RecentSmileysQuery, RecentSmileysQueryVariables, RecentSmileysProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, RecentSmileysQuery, RecentSmileysQueryVariables, RecentSmileysProps<TChildProps, TDataName>>(RecentSmileysDocument, {
    alias: 'recentSmileys',
    ...operationOptions
  });
}
export type RecentSmileysQueryResult = ApolloReactCommon.QueryResult<RecentSmileysQuery, RecentSmileysQueryVariables>;
export const RecentSmileys = (({
  operationKind: 'Query',
  document: RecentSmileysDocument,
  primaryResultPath: ['smileybox', 'smileyList']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: RecentSmileysQueryVariables;
  TResult: RecentSmileysQuery;
  primaryResultPath: string[];
  TPrimaryResult: RecentSmileysQuery['smileybox']['smileyList'];
});
export const AllSmileyIdsDocument = gql`
	query AllSmileyIds($limit: Int) {
		smileybox {
			smileyList(type: AllUsable, limit: $limit) {
				id
			}
		}
	}
`;
export type AllSmileyIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllSmileyIdsQuery, AllSmileyIdsQueryVariables>, 'query'>;
export const AllSmileyIdsComponent = (props: AllSmileyIdsComponentProps) => <ApolloReactComponents.Query<AllSmileyIdsQuery, AllSmileyIdsQueryVariables> query={AllSmileyIdsDocument} {...props} />;
export type AllSmileyIdsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<AllSmileyIdsQuery, AllSmileyIdsQueryVariables> } & TChildProps;
export function withAllSmileyIds<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AllSmileyIdsQuery, AllSmileyIdsQueryVariables, AllSmileyIdsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, AllSmileyIdsQuery, AllSmileyIdsQueryVariables, AllSmileyIdsProps<TChildProps, TDataName>>(AllSmileyIdsDocument, {
    alias: 'allSmileyIds',
    ...operationOptions
  });
}
export type AllSmileyIdsQueryResult = ApolloReactCommon.QueryResult<AllSmileyIdsQuery, AllSmileyIdsQueryVariables>;
export const AllSmileyIds = (({
  operationKind: 'Query',
  document: AllSmileyIdsDocument,
  primaryResultPath: ['smileybox', 'smileyList']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: AllSmileyIdsQueryVariables;
  TResult: AllSmileyIdsQuery;
  primaryResultPath: string[];
  TPrimaryResult: AllSmileyIdsQuery['smileybox']['smileyList'];
});
export const PreloadAllSmileysDocument = gql`
	query PreloadAllSmileys($limit: Int) {
		smileybox {
			smileyList(type: AllUsable, limit: $limit) {
				...Smiley
			}
		}
	}
	${SmileyFragmentDoc}
`;
export type PreloadAllSmileysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PreloadAllSmileysQuery, PreloadAllSmileysQueryVariables>, 'query'>;
export const PreloadAllSmileysComponent = (props: PreloadAllSmileysComponentProps) => <ApolloReactComponents.Query<PreloadAllSmileysQuery, PreloadAllSmileysQueryVariables> query={PreloadAllSmileysDocument} {...props} />;
export type PreloadAllSmileysProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<PreloadAllSmileysQuery, PreloadAllSmileysQueryVariables> } & TChildProps;
export function withPreloadAllSmileys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PreloadAllSmileysQuery, PreloadAllSmileysQueryVariables, PreloadAllSmileysProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, PreloadAllSmileysQuery, PreloadAllSmileysQueryVariables, PreloadAllSmileysProps<TChildProps, TDataName>>(PreloadAllSmileysDocument, {
    alias: 'preloadAllSmileys',
    ...operationOptions
  });
}
export type PreloadAllSmileysQueryResult = ApolloReactCommon.QueryResult<PreloadAllSmileysQuery, PreloadAllSmileysQueryVariables>;
export const PreloadAllSmileys = (({
  operationKind: 'Query',
  document: PreloadAllSmileysDocument,
  primaryResultPath: ['smileybox', 'smileyList']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: PreloadAllSmileysQueryVariables;
  TResult: PreloadAllSmileysQuery;
  primaryResultPath: string[];
  TPrimaryResult: PreloadAllSmileysQuery['smileybox']['smileyList'];
});
export const SmileyDetailsDocument = gql`
	query SmileyDetails($id: ID!) {
		smileybox {
			smileyDetails(id: $id) {
				...Smiley
			}
		}
	}
	${SmileyFragmentDoc}
`;
export type SmileyDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SmileyDetailsQuery, SmileyDetailsQueryVariables>, 'query'> & ({
  variables: SmileyDetailsQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const SmileyDetailsComponent = (props: SmileyDetailsComponentProps) => <ApolloReactComponents.Query<SmileyDetailsQuery, SmileyDetailsQueryVariables> query={SmileyDetailsDocument} {...props} />;
export type SmileyDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<SmileyDetailsQuery, SmileyDetailsQueryVariables> } & TChildProps;
export function withSmileyDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SmileyDetailsQuery, SmileyDetailsQueryVariables, SmileyDetailsProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, SmileyDetailsQuery, SmileyDetailsQueryVariables, SmileyDetailsProps<TChildProps, TDataName>>(SmileyDetailsDocument, {
    alias: 'smileyDetails',
    ...operationOptions
  });
}
export type SmileyDetailsQueryResult = ApolloReactCommon.QueryResult<SmileyDetailsQuery, SmileyDetailsQueryVariables>;
export const SmileyDetails = (({
  operationKind: 'Query',
  document: SmileyDetailsDocument,
  primaryResultPath: ['smileybox', 'smileyDetails']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: SmileyDetailsQueryVariables;
  TResult: SmileyDetailsQuery;
  primaryResultPath: string[];
  TPrimaryResult: SmileyDetailsQuery['smileybox']['smileyDetails'];
});
export const GetCurrentUserNickDocument = gql`
	query GetCurrentUserNick {
		user {
			currentUser {
				id
				nick
				gender
				evergreenData
				email
				engagementSystemStage
				verifiedPhoneNumber
				isKnuddelsTeamMember
				hasKnuddelsPlus
				knuddelsPlusActiveUntil
				knuddelsPlusBonusDaysActiveUntil
				profilePicture {
					exists
					urlLargeSquare
					urlVeryLarge
				}
				profilePictureOverlayUrls {
					...ProfilePictureOverlays
				}
				knuddelAmount
				age
				dateOfRegistration
				status
				aliasNicks
				registrationInterest
				onlineMinutes
			}
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export type GetCurrentUserNickComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserNickQuery, GetCurrentUserNickQueryVariables>, 'query'>;
export const GetCurrentUserNickComponent = (props: GetCurrentUserNickComponentProps) => <ApolloReactComponents.Query<GetCurrentUserNickQuery, GetCurrentUserNickQueryVariables> query={GetCurrentUserNickDocument} {...props} />;
export type GetCurrentUserNickProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetCurrentUserNickQuery, GetCurrentUserNickQueryVariables> } & TChildProps;
export function withGetCurrentUserNick<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetCurrentUserNickQuery, GetCurrentUserNickQueryVariables, GetCurrentUserNickProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetCurrentUserNickQuery, GetCurrentUserNickQueryVariables, GetCurrentUserNickProps<TChildProps, TDataName>>(GetCurrentUserNickDocument, {
    alias: 'getCurrentUserNick',
    ...operationOptions
  });
}
export type GetCurrentUserNickQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserNickQuery, GetCurrentUserNickQueryVariables>;
export const GetCurrentUserNick = (({
  operationKind: 'Query',
  document: GetCurrentUserNickDocument,
  primaryResultPath: ['user', 'currentUser']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetCurrentUserNickQueryVariables;
  TResult: GetCurrentUserNickQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetCurrentUserNickQuery['user']['currentUser'];
});
export const GetCurrentUserGenderDocument = gql`
	query GetCurrentUserGender {
		user {
			currentUser {
				id
				gender
			}
		}
	}
`;
export type GetCurrentUserGenderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserGenderQuery, GetCurrentUserGenderQueryVariables>, 'query'>;
export const GetCurrentUserGenderComponent = (props: GetCurrentUserGenderComponentProps) => <ApolloReactComponents.Query<GetCurrentUserGenderQuery, GetCurrentUserGenderQueryVariables> query={GetCurrentUserGenderDocument} {...props} />;
export type GetCurrentUserGenderProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetCurrentUserGenderQuery, GetCurrentUserGenderQueryVariables> } & TChildProps;
export function withGetCurrentUserGender<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetCurrentUserGenderQuery, GetCurrentUserGenderQueryVariables, GetCurrentUserGenderProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetCurrentUserGenderQuery, GetCurrentUserGenderQueryVariables, GetCurrentUserGenderProps<TChildProps, TDataName>>(GetCurrentUserGenderDocument, {
    alias: 'getCurrentUserGender',
    ...operationOptions
  });
}
export type GetCurrentUserGenderQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserGenderQuery, GetCurrentUserGenderQueryVariables>;
export const GetCurrentUserGender = (({
  operationKind: 'Query',
  document: GetCurrentUserGenderDocument,
  primaryResultPath: ['user', 'currentUser']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetCurrentUserGenderQueryVariables;
  TResult: GetCurrentUserGenderQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetCurrentUserGenderQuery['user']['currentUser'];
});
export const GetProfilePictureLargeSquareDocument = gql`
	query GetProfilePictureLargeSquare($userId: ID!) {
		user {
			user(id: $userId) {
				id
				profilePicture {
					urlLargeSquare
				}
			}
		}
	}
`;
export type GetProfilePictureLargeSquareComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProfilePictureLargeSquareQuery, GetProfilePictureLargeSquareQueryVariables>, 'query'> & ({
  variables: GetProfilePictureLargeSquareQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetProfilePictureLargeSquareComponent = (props: GetProfilePictureLargeSquareComponentProps) => <ApolloReactComponents.Query<GetProfilePictureLargeSquareQuery, GetProfilePictureLargeSquareQueryVariables> query={GetProfilePictureLargeSquareDocument} {...props} />;
export type GetProfilePictureLargeSquareProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetProfilePictureLargeSquareQuery, GetProfilePictureLargeSquareQueryVariables> } & TChildProps;
export function withGetProfilePictureLargeSquare<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetProfilePictureLargeSquareQuery, GetProfilePictureLargeSquareQueryVariables, GetProfilePictureLargeSquareProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetProfilePictureLargeSquareQuery, GetProfilePictureLargeSquareQueryVariables, GetProfilePictureLargeSquareProps<TChildProps, TDataName>>(GetProfilePictureLargeSquareDocument, {
    alias: 'getProfilePictureLargeSquare',
    ...operationOptions
  });
}
export type GetProfilePictureLargeSquareQueryResult = ApolloReactCommon.QueryResult<GetProfilePictureLargeSquareQuery, GetProfilePictureLargeSquareQueryVariables>;
export const GetProfilePictureLargeSquare = (({
  operationKind: 'Query',
  document: GetProfilePictureLargeSquareDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetProfilePictureLargeSquareQueryVariables;
  TResult: GetProfilePictureLargeSquareQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetProfilePictureLargeSquareQuery['user']['user'];
});
export const GetUserIdFromNickDocument = gql`
	query GetUserIdFromNick($nick: String!, $accountForNickSwitch: Boolean) {
		user {
			userFromNick(
				nick: $nick
				accountForNickSwitch: $accountForNickSwitch
			) {
				id
				nick
			}
		}
	}
`;
export type GetUserIdFromNickComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserIdFromNickQuery, GetUserIdFromNickQueryVariables>, 'query'> & ({
  variables: GetUserIdFromNickQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetUserIdFromNickComponent = (props: GetUserIdFromNickComponentProps) => <ApolloReactComponents.Query<GetUserIdFromNickQuery, GetUserIdFromNickQueryVariables> query={GetUserIdFromNickDocument} {...props} />;
export type GetUserIdFromNickProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetUserIdFromNickQuery, GetUserIdFromNickQueryVariables> } & TChildProps;
export function withGetUserIdFromNick<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetUserIdFromNickQuery, GetUserIdFromNickQueryVariables, GetUserIdFromNickProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetUserIdFromNickQuery, GetUserIdFromNickQueryVariables, GetUserIdFromNickProps<TChildProps, TDataName>>(GetUserIdFromNickDocument, {
    alias: 'getUserIdFromNick',
    ...operationOptions
  });
}
export type GetUserIdFromNickQueryResult = ApolloReactCommon.QueryResult<GetUserIdFromNickQuery, GetUserIdFromNickQueryVariables>;
export const GetUserIdFromNick = (({
  operationKind: 'Query',
  document: GetUserIdFromNickDocument,
  primaryResultPath: ['user', 'userFromNick']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetUserIdFromNickQueryVariables;
  TResult: GetUserIdFromNickQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetUserIdFromNickQuery['user']['userFromNick'];
});
export const AllowImagesDocument = gql`
	mutation AllowImages($userId: ID!) {
		user {
			allowImages(userId: $userId) {
				error
			}
		}
	}
`;
export type AllowImagesMutationFn = ApolloReactCommon.MutationFunction<AllowImagesMutation, AllowImagesMutationVariables>;
export type AllowImagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AllowImagesMutation, AllowImagesMutationVariables>, 'mutation'>;
export const AllowImagesComponent = (props: AllowImagesComponentProps) => <ApolloReactComponents.Mutation<AllowImagesMutation, AllowImagesMutationVariables> mutation={AllowImagesDocument} {...props} />;
export type AllowImagesProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<AllowImagesMutation, AllowImagesMutationVariables> } & TChildProps;
export function withAllowImages<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, AllowImagesMutation, AllowImagesMutationVariables, AllowImagesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, AllowImagesMutation, AllowImagesMutationVariables, AllowImagesProps<TChildProps, TDataName>>(AllowImagesDocument, {
    alias: 'allowImages',
    ...operationOptions
  });
}
export type AllowImagesMutationResult = ApolloReactCommon.MutationResult<AllowImagesMutation>;
export type AllowImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<AllowImagesMutation, AllowImagesMutationVariables>;
export const AllowImages = (({
  operationKind: 'Mutation',
  document: AllowImagesDocument,
  primaryResultPath: ['user', 'allowImages']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: AllowImagesMutationVariables;
  TResult: AllowImagesMutation;
  primaryResultPath: string[];
  TPrimaryResult: AllowImagesMutation['user']['allowImages'];
});
export const BlockImagesDocument = gql`
	mutation BlockImages($userId: ID!) {
		user {
			blockImages(userId: $userId) {
				error
			}
		}
	}
`;
export type BlockImagesMutationFn = ApolloReactCommon.MutationFunction<BlockImagesMutation, BlockImagesMutationVariables>;
export type BlockImagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BlockImagesMutation, BlockImagesMutationVariables>, 'mutation'>;
export const BlockImagesComponent = (props: BlockImagesComponentProps) => <ApolloReactComponents.Mutation<BlockImagesMutation, BlockImagesMutationVariables> mutation={BlockImagesDocument} {...props} />;
export type BlockImagesProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<BlockImagesMutation, BlockImagesMutationVariables> } & TChildProps;
export function withBlockImages<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, BlockImagesMutation, BlockImagesMutationVariables, BlockImagesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, BlockImagesMutation, BlockImagesMutationVariables, BlockImagesProps<TChildProps, TDataName>>(BlockImagesDocument, {
    alias: 'blockImages',
    ...operationOptions
  });
}
export type BlockImagesMutationResult = ApolloReactCommon.MutationResult<BlockImagesMutation>;
export type BlockImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockImagesMutation, BlockImagesMutationVariables>;
export const BlockImages = (({
  operationKind: 'Mutation',
  document: BlockImagesDocument,
  primaryResultPath: ['user', 'blockImages']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: BlockImagesMutationVariables;
  TResult: BlockImagesMutation;
  primaryResultPath: string[];
  TPrimaryResult: BlockImagesMutation['user']['blockImages'];
});
export const CanSendImagesDocument = gql`
	query CanSendImages($userId: ID!) {
		user {
			user(id: $userId) {
				id
				canSendImages
			}
		}
	}
`;
export type CanSendImagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CanSendImagesQuery, CanSendImagesQueryVariables>, 'query'> & ({
  variables: CanSendImagesQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const CanSendImagesComponent = (props: CanSendImagesComponentProps) => <ApolloReactComponents.Query<CanSendImagesQuery, CanSendImagesQueryVariables> query={CanSendImagesDocument} {...props} />;
export type CanSendImagesProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<CanSendImagesQuery, CanSendImagesQueryVariables> } & TChildProps;
export function withCanSendImages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, CanSendImagesQuery, CanSendImagesQueryVariables, CanSendImagesProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, CanSendImagesQuery, CanSendImagesQueryVariables, CanSendImagesProps<TChildProps, TDataName>>(CanSendImagesDocument, {
    alias: 'canSendImages',
    ...operationOptions
  });
}
export type CanSendImagesQueryResult = ApolloReactCommon.QueryResult<CanSendImagesQuery, CanSendImagesQueryVariables>;
export const CanSendImages = (({
  operationKind: 'Query',
  document: CanSendImagesDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: CanSendImagesQueryVariables;
  TResult: CanSendImagesQuery;
  primaryResultPath: string[];
  TPrimaryResult: CanSendImagesQuery['user']['user'];
});
export const PrivateIgnoreDocument = gql`
	mutation PrivateIgnore($id: ID!) {
		user {
			privateIgnore(userId: $id) {
				error
			}
		}
	}
`;
export type PrivateIgnoreMutationFn = ApolloReactCommon.MutationFunction<PrivateIgnoreMutation, PrivateIgnoreMutationVariables>;
export type PrivateIgnoreComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PrivateIgnoreMutation, PrivateIgnoreMutationVariables>, 'mutation'>;
export const PrivateIgnoreComponent = (props: PrivateIgnoreComponentProps) => <ApolloReactComponents.Mutation<PrivateIgnoreMutation, PrivateIgnoreMutationVariables> mutation={PrivateIgnoreDocument} {...props} />;
export type PrivateIgnoreProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<PrivateIgnoreMutation, PrivateIgnoreMutationVariables> } & TChildProps;
export function withPrivateIgnore<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, PrivateIgnoreMutation, PrivateIgnoreMutationVariables, PrivateIgnoreProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, PrivateIgnoreMutation, PrivateIgnoreMutationVariables, PrivateIgnoreProps<TChildProps, TDataName>>(PrivateIgnoreDocument, {
    alias: 'privateIgnore',
    ...operationOptions
  });
}
export type PrivateIgnoreMutationResult = ApolloReactCommon.MutationResult<PrivateIgnoreMutation>;
export type PrivateIgnoreMutationOptions = ApolloReactCommon.BaseMutationOptions<PrivateIgnoreMutation, PrivateIgnoreMutationVariables>;
export const PrivateIgnore = (({
  operationKind: 'Mutation',
  document: PrivateIgnoreDocument,
  primaryResultPath: ['user', 'privateIgnore']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: PrivateIgnoreMutationVariables;
  TResult: PrivateIgnoreMutation;
  primaryResultPath: string[];
  TPrimaryResult: PrivateIgnoreMutation['user']['privateIgnore'];
});
export const IgnoreDocument = gql`
	mutation Ignore($id: ID!) {
		user {
			ignore(userId: $id) {
				error
			}
		}
	}
`;
export type IgnoreMutationFn = ApolloReactCommon.MutationFunction<IgnoreMutation, IgnoreMutationVariables>;
export type IgnoreComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IgnoreMutation, IgnoreMutationVariables>, 'mutation'>;
export const IgnoreComponent = (props: IgnoreComponentProps) => <ApolloReactComponents.Mutation<IgnoreMutation, IgnoreMutationVariables> mutation={IgnoreDocument} {...props} />;
export type IgnoreProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<IgnoreMutation, IgnoreMutationVariables> } & TChildProps;
export function withIgnore<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, IgnoreMutation, IgnoreMutationVariables, IgnoreProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, IgnoreMutation, IgnoreMutationVariables, IgnoreProps<TChildProps, TDataName>>(IgnoreDocument, {
    alias: 'ignore',
    ...operationOptions
  });
}
export type IgnoreMutationResult = ApolloReactCommon.MutationResult<IgnoreMutation>;
export type IgnoreMutationOptions = ApolloReactCommon.BaseMutationOptions<IgnoreMutation, IgnoreMutationVariables>;
export const Ignore = (({
  operationKind: 'Mutation',
  document: IgnoreDocument,
  primaryResultPath: ['user', 'ignore']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: IgnoreMutationVariables;
  TResult: IgnoreMutation;
  primaryResultPath: string[];
  TPrimaryResult: IgnoreMutation['user']['ignore'];
});
export const BlockDocument = gql`
	mutation Block($id: ID!) {
		user {
			block(userId: $id) {
				error
			}
		}
	}
`;
export type BlockMutationFn = ApolloReactCommon.MutationFunction<BlockMutation, BlockMutationVariables>;
export type BlockComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BlockMutation, BlockMutationVariables>, 'mutation'>;
export const BlockComponent = (props: BlockComponentProps) => <ApolloReactComponents.Mutation<BlockMutation, BlockMutationVariables> mutation={BlockDocument} {...props} />;
export type BlockProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<BlockMutation, BlockMutationVariables> } & TChildProps;
export function withBlock<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, BlockMutation, BlockMutationVariables, BlockProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, BlockMutation, BlockMutationVariables, BlockProps<TChildProps, TDataName>>(BlockDocument, {
    alias: 'block',
    ...operationOptions
  });
}
export type BlockMutationResult = ApolloReactCommon.MutationResult<BlockMutation>;
export type BlockMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockMutation, BlockMutationVariables>;
export const Block = (({
  operationKind: 'Mutation',
  document: BlockDocument,
  primaryResultPath: ['user', 'block']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: BlockMutationVariables;
  TResult: BlockMutation;
  primaryResultPath: string[];
  TPrimaryResult: BlockMutation['user']['block'];
});
export const UnPrivateIgnoreDocument = gql`
	mutation UnPrivateIgnore($id: ID!) {
		user {
			unPrivateIgnore(userId: $id) {
				error
			}
		}
	}
`;
export type UnPrivateIgnoreMutationFn = ApolloReactCommon.MutationFunction<UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables>;
export type UnPrivateIgnoreComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables>, 'mutation'>;
export const UnPrivateIgnoreComponent = (props: UnPrivateIgnoreComponentProps) => <ApolloReactComponents.Mutation<UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables> mutation={UnPrivateIgnoreDocument} {...props} />;
export type UnPrivateIgnoreProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables> } & TChildProps;
export function withUnPrivateIgnore<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables, UnPrivateIgnoreProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables, UnPrivateIgnoreProps<TChildProps, TDataName>>(UnPrivateIgnoreDocument, {
    alias: 'unPrivateIgnore',
    ...operationOptions
  });
}
export type UnPrivateIgnoreMutationResult = ApolloReactCommon.MutationResult<UnPrivateIgnoreMutation>;
export type UnPrivateIgnoreMutationOptions = ApolloReactCommon.BaseMutationOptions<UnPrivateIgnoreMutation, UnPrivateIgnoreMutationVariables>;
export const UnPrivateIgnore = (({
  operationKind: 'Mutation',
  document: UnPrivateIgnoreDocument,
  primaryResultPath: ['user', 'unPrivateIgnore']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UnPrivateIgnoreMutationVariables;
  TResult: UnPrivateIgnoreMutation;
  primaryResultPath: string[];
  TPrimaryResult: UnPrivateIgnoreMutation['user']['unPrivateIgnore'];
});
export const UnIgnoreDocument = gql`
	mutation UnIgnore($id: ID!) {
		user {
			unignore(userId: $id) {
				error
			}
		}
	}
`;
export type UnIgnoreMutationFn = ApolloReactCommon.MutationFunction<UnIgnoreMutation, UnIgnoreMutationVariables>;
export type UnIgnoreComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnIgnoreMutation, UnIgnoreMutationVariables>, 'mutation'>;
export const UnIgnoreComponent = (props: UnIgnoreComponentProps) => <ApolloReactComponents.Mutation<UnIgnoreMutation, UnIgnoreMutationVariables> mutation={UnIgnoreDocument} {...props} />;
export type UnIgnoreProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UnIgnoreMutation, UnIgnoreMutationVariables> } & TChildProps;
export function withUnIgnore<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UnIgnoreMutation, UnIgnoreMutationVariables, UnIgnoreProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UnIgnoreMutation, UnIgnoreMutationVariables, UnIgnoreProps<TChildProps, TDataName>>(UnIgnoreDocument, {
    alias: 'unIgnore',
    ...operationOptions
  });
}
export type UnIgnoreMutationResult = ApolloReactCommon.MutationResult<UnIgnoreMutation>;
export type UnIgnoreMutationOptions = ApolloReactCommon.BaseMutationOptions<UnIgnoreMutation, UnIgnoreMutationVariables>;
export const UnIgnore = (({
  operationKind: 'Mutation',
  document: UnIgnoreDocument,
  primaryResultPath: ['user', 'unignore']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UnIgnoreMutationVariables;
  TResult: UnIgnoreMutation;
  primaryResultPath: string[];
  TPrimaryResult: UnIgnoreMutation['user']['unignore'];
});
export const UnBlockDocument = gql`
	mutation UnBlock($id: ID!) {
		user {
			unblock(userId: $id) {
				error
			}
		}
	}
`;
export type UnBlockMutationFn = ApolloReactCommon.MutationFunction<UnBlockMutation, UnBlockMutationVariables>;
export type UnBlockComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnBlockMutation, UnBlockMutationVariables>, 'mutation'>;
export const UnBlockComponent = (props: UnBlockComponentProps) => <ApolloReactComponents.Mutation<UnBlockMutation, UnBlockMutationVariables> mutation={UnBlockDocument} {...props} />;
export type UnBlockProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<UnBlockMutation, UnBlockMutationVariables> } & TChildProps;
export function withUnBlock<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UnBlockMutation, UnBlockMutationVariables, UnBlockProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, UnBlockMutation, UnBlockMutationVariables, UnBlockProps<TChildProps, TDataName>>(UnBlockDocument, {
    alias: 'unBlock',
    ...operationOptions
  });
}
export type UnBlockMutationResult = ApolloReactCommon.MutationResult<UnBlockMutation>;
export type UnBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<UnBlockMutation, UnBlockMutationVariables>;
export const UnBlock = (({
  operationKind: 'Mutation',
  document: UnBlockDocument,
  primaryResultPath: ['user', 'unblock']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: UnBlockMutationVariables;
  TResult: UnBlockMutation;
  primaryResultPath: string[];
  TPrimaryResult: UnBlockMutation['user']['unblock'];
});
export const IgnoreStateChangedDocument = gql`
	subscription IgnoreStateChanged {
		ignoreStateChanged {
			...IgnoreStateChanged
		}
	}
	${IgnoreStateChangedFragmentDoc}
`;
export type IgnoreStateChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<IgnoreStateChangedSubscription, IgnoreStateChangedSubscriptionVariables>, 'subscription'>;
export const IgnoreStateChangedComponent = (props: IgnoreStateChangedComponentProps) => <ApolloReactComponents.Subscription<IgnoreStateChangedSubscription, IgnoreStateChangedSubscriptionVariables> subscription={IgnoreStateChangedDocument} {...props} />;
export type IgnoreStateChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<IgnoreStateChangedSubscription, IgnoreStateChangedSubscriptionVariables> } & TChildProps;
export function withIgnoreStateChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, IgnoreStateChangedSubscription, IgnoreStateChangedSubscriptionVariables, IgnoreStateChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, IgnoreStateChangedSubscription, IgnoreStateChangedSubscriptionVariables, IgnoreStateChangedProps<TChildProps, TDataName>>(IgnoreStateChangedDocument, {
    alias: 'ignoreStateChanged',
    ...operationOptions
  });
}
export type IgnoreStateChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<IgnoreStateChangedSubscription>;
export const IgnoreStateChanged = (({
  operationKind: 'Subscription',
  document: IgnoreStateChangedDocument,
  primaryResultPath: ['ignoreStateChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: IgnoreStateChangedSubscriptionVariables;
  TResult: IgnoreStateChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: IgnoreStateChangedSubscription['ignoreStateChanged'];
});
export const IsIgnoringChangedDocument = gql`
	subscription IsIgnoringChanged {
		isIgnoringChanged {
			id
			isIgnoring
			canReceiveMessages
			canReceivePhotoComments
		}
	}
`;
export type IsIgnoringChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<IsIgnoringChangedSubscription, IsIgnoringChangedSubscriptionVariables>, 'subscription'>;
export const IsIgnoringChangedComponent = (props: IsIgnoringChangedComponentProps) => <ApolloReactComponents.Subscription<IsIgnoringChangedSubscription, IsIgnoringChangedSubscriptionVariables> subscription={IsIgnoringChangedDocument} {...props} />;
export type IsIgnoringChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<IsIgnoringChangedSubscription, IsIgnoringChangedSubscriptionVariables> } & TChildProps;
export function withIsIgnoringChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, IsIgnoringChangedSubscription, IsIgnoringChangedSubscriptionVariables, IsIgnoringChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, IsIgnoringChangedSubscription, IsIgnoringChangedSubscriptionVariables, IsIgnoringChangedProps<TChildProps, TDataName>>(IsIgnoringChangedDocument, {
    alias: 'isIgnoringChanged',
    ...operationOptions
  });
}
export type IsIgnoringChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<IsIgnoringChangedSubscription>;
export const IsIgnoringChanged = (({
  operationKind: 'Subscription',
  document: IsIgnoringChangedDocument,
  primaryResultPath: ['isIgnoringChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: IsIgnoringChangedSubscriptionVariables;
  TResult: IsIgnoringChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: IsIgnoringChangedSubscription['isIgnoringChanged'];
});
export const HasKnuddelsPlusChangedDocument = gql`
	subscription HasKnuddelsPlusChanged {
		hasKnuddelsPlusChanged {
			id
			hasKnuddelsPlus
			knuddelsPlusActiveUntil
			knuddelsPlusBonusDaysActiveUntil
		}
	}
`;
export type HasKnuddelsPlusChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<HasKnuddelsPlusChangedSubscription, HasKnuddelsPlusChangedSubscriptionVariables>, 'subscription'>;
export const HasKnuddelsPlusChangedComponent = (props: HasKnuddelsPlusChangedComponentProps) => <ApolloReactComponents.Subscription<HasKnuddelsPlusChangedSubscription, HasKnuddelsPlusChangedSubscriptionVariables> subscription={HasKnuddelsPlusChangedDocument} {...props} />;
export type HasKnuddelsPlusChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<HasKnuddelsPlusChangedSubscription, HasKnuddelsPlusChangedSubscriptionVariables> } & TChildProps;
export function withHasKnuddelsPlusChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, HasKnuddelsPlusChangedSubscription, HasKnuddelsPlusChangedSubscriptionVariables, HasKnuddelsPlusChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, HasKnuddelsPlusChangedSubscription, HasKnuddelsPlusChangedSubscriptionVariables, HasKnuddelsPlusChangedProps<TChildProps, TDataName>>(HasKnuddelsPlusChangedDocument, {
    alias: 'hasKnuddelsPlusChanged',
    ...operationOptions
  });
}
export type HasKnuddelsPlusChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<HasKnuddelsPlusChangedSubscription>;
export const HasKnuddelsPlusChanged = (({
  operationKind: 'Subscription',
  document: HasKnuddelsPlusChangedDocument,
  primaryResultPath: ['hasKnuddelsPlusChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: HasKnuddelsPlusChangedSubscriptionVariables;
  TResult: HasKnuddelsPlusChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: HasKnuddelsPlusChangedSubscription['hasKnuddelsPlusChanged'];
});
export const OwnProfilePictureChangedDocument = gql`
	subscription OwnProfilePictureChanged($id: ID!, $pixelDensity: Float!) {
		userChanged(config: { userId: $id, fields: [ProfilePicture] }) {
			...ProfilePictureUser
			... on User {
				profilePicture {
					urlVeryLarge
					urlLargeSquare
				}
			}
		}
	}
	${ProfilePictureUserFragmentDoc}
`;
export type OwnProfilePictureChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OwnProfilePictureChangedSubscription, OwnProfilePictureChangedSubscriptionVariables>, 'subscription'>;
export const OwnProfilePictureChangedComponent = (props: OwnProfilePictureChangedComponentProps) => <ApolloReactComponents.Subscription<OwnProfilePictureChangedSubscription, OwnProfilePictureChangedSubscriptionVariables> subscription={OwnProfilePictureChangedDocument} {...props} />;
export type OwnProfilePictureChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<OwnProfilePictureChangedSubscription, OwnProfilePictureChangedSubscriptionVariables> } & TChildProps;
export function withOwnProfilePictureChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, OwnProfilePictureChangedSubscription, OwnProfilePictureChangedSubscriptionVariables, OwnProfilePictureChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, OwnProfilePictureChangedSubscription, OwnProfilePictureChangedSubscriptionVariables, OwnProfilePictureChangedProps<TChildProps, TDataName>>(OwnProfilePictureChangedDocument, {
    alias: 'ownProfilePictureChanged',
    ...operationOptions
  });
}
export type OwnProfilePictureChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OwnProfilePictureChangedSubscription>;
export const OwnProfilePictureChanged = (({
  operationKind: 'Subscription',
  document: OwnProfilePictureChangedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: OwnProfilePictureChangedSubscriptionVariables;
  TResult: OwnProfilePictureChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: OwnProfilePictureChangedSubscription['userChanged'];
});
export const OwnProfilePictureOverlaysChangedDocument = gql`
	subscription OwnProfilePictureOverlaysChanged($id: ID!) {
		userChanged(config: { userId: $id, fields: [ProfilePictureOverlays] }) {
			id
			profilePictureOverlayUrls {
				...ProfilePictureOverlays
			}
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export type OwnProfilePictureOverlaysChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OwnProfilePictureOverlaysChangedSubscription, OwnProfilePictureOverlaysChangedSubscriptionVariables>, 'subscription'>;
export const OwnProfilePictureOverlaysChangedComponent = (props: OwnProfilePictureOverlaysChangedComponentProps) => <ApolloReactComponents.Subscription<OwnProfilePictureOverlaysChangedSubscription, OwnProfilePictureOverlaysChangedSubscriptionVariables> subscription={OwnProfilePictureOverlaysChangedDocument} {...props} />;
export type OwnProfilePictureOverlaysChangedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<OwnProfilePictureOverlaysChangedSubscription, OwnProfilePictureOverlaysChangedSubscriptionVariables> } & TChildProps;
export function withOwnProfilePictureOverlaysChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, OwnProfilePictureOverlaysChangedSubscription, OwnProfilePictureOverlaysChangedSubscriptionVariables, OwnProfilePictureOverlaysChangedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, OwnProfilePictureOverlaysChangedSubscription, OwnProfilePictureOverlaysChangedSubscriptionVariables, OwnProfilePictureOverlaysChangedProps<TChildProps, TDataName>>(OwnProfilePictureOverlaysChangedDocument, {
    alias: 'ownProfilePictureOverlaysChanged',
    ...operationOptions
  });
}
export type OwnProfilePictureOverlaysChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OwnProfilePictureOverlaysChangedSubscription>;
export const OwnProfilePictureOverlaysChanged = (({
  operationKind: 'Subscription',
  document: OwnProfilePictureOverlaysChangedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: OwnProfilePictureOverlaysChangedSubscriptionVariables;
  TResult: OwnProfilePictureOverlaysChangedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: OwnProfilePictureOverlaysChangedSubscription['userChanged'];
});
export const UserIsOnlineInChannelDocument = gql`
	query UserIsOnlineInChannel($id: ID!) {
		user {
			user(id: $id) {
				...UserWithOnlineAndChannel
			}
		}
	}
	${UserWithOnlineAndChannelFragmentDoc}
`;
export type UserIsOnlineInChannelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserIsOnlineInChannelQuery, UserIsOnlineInChannelQueryVariables>, 'query'> & ({
  variables: UserIsOnlineInChannelQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const UserIsOnlineInChannelComponent = (props: UserIsOnlineInChannelComponentProps) => <ApolloReactComponents.Query<UserIsOnlineInChannelQuery, UserIsOnlineInChannelQueryVariables> query={UserIsOnlineInChannelDocument} {...props} />;
export type UserIsOnlineInChannelProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserIsOnlineInChannelQuery, UserIsOnlineInChannelQueryVariables> } & TChildProps;
export function withUserIsOnlineInChannel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserIsOnlineInChannelQuery, UserIsOnlineInChannelQueryVariables, UserIsOnlineInChannelProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, UserIsOnlineInChannelQuery, UserIsOnlineInChannelQueryVariables, UserIsOnlineInChannelProps<TChildProps, TDataName>>(UserIsOnlineInChannelDocument, {
    alias: 'userIsOnlineInChannel',
    ...operationOptions
  });
}
export type UserIsOnlineInChannelQueryResult = ApolloReactCommon.QueryResult<UserIsOnlineInChannelQuery, UserIsOnlineInChannelQueryVariables>;
export const UserIsOnlineInChannel = (({
  operationKind: 'Query',
  document: UserIsOnlineInChannelDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: UserIsOnlineInChannelQueryVariables;
  TResult: UserIsOnlineInChannelQuery;
  primaryResultPath: string[];
  TPrimaryResult: UserIsOnlineInChannelQuery['user']['user'];
});
export const UserOnlineOrChannelUpdatedDocument = gql`
	subscription UserOnlineOrChannelUpdated($id: ID!) {
		userChanged(
			config: {
				userId: $id
				fields: [isOnline, CurrentOnlineChannelName]
			}
		) {
			...UserWithOnlineAndChannel
			latestOnlineChannelName
			lastOnlineTime
		}
	}
	${UserWithOnlineAndChannelFragmentDoc}
`;
export type UserOnlineOrChannelUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserOnlineOrChannelUpdatedSubscription, UserOnlineOrChannelUpdatedSubscriptionVariables>, 'subscription'>;
export const UserOnlineOrChannelUpdatedComponent = (props: UserOnlineOrChannelUpdatedComponentProps) => <ApolloReactComponents.Subscription<UserOnlineOrChannelUpdatedSubscription, UserOnlineOrChannelUpdatedSubscriptionVariables> subscription={UserOnlineOrChannelUpdatedDocument} {...props} />;
export type UserOnlineOrChannelUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserOnlineOrChannelUpdatedSubscription, UserOnlineOrChannelUpdatedSubscriptionVariables> } & TChildProps;
export function withUserOnlineOrChannelUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserOnlineOrChannelUpdatedSubscription, UserOnlineOrChannelUpdatedSubscriptionVariables, UserOnlineOrChannelUpdatedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, UserOnlineOrChannelUpdatedSubscription, UserOnlineOrChannelUpdatedSubscriptionVariables, UserOnlineOrChannelUpdatedProps<TChildProps, TDataName>>(UserOnlineOrChannelUpdatedDocument, {
    alias: 'userOnlineOrChannelUpdated',
    ...operationOptions
  });
}
export type UserOnlineOrChannelUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserOnlineOrChannelUpdatedSubscription>;
export const UserOnlineOrChannelUpdated = (({
  operationKind: 'Subscription',
  document: UserOnlineOrChannelUpdatedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: UserOnlineOrChannelUpdatedSubscriptionVariables;
  TResult: UserOnlineOrChannelUpdatedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: UserOnlineOrChannelUpdatedSubscription['userChanged'];
});
export const MultipleUserOnlineOrChannelUpdatedDocument = gql`
	subscription MultipleUserOnlineOrChannelUpdated($ids: [ID!]!) {
		multipleUserChanged(
			config: {
				userIds: $ids
				fields: [isOnline, CurrentOnlineChannelName]
			}
		) {
			...UserWithOnlineAndChannel
			latestOnlineChannelName
			lastOnlineTime
		}
	}
	${UserWithOnlineAndChannelFragmentDoc}
`;
export type MultipleUserOnlineOrChannelUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<MultipleUserOnlineOrChannelUpdatedSubscription, MultipleUserOnlineOrChannelUpdatedSubscriptionVariables>, 'subscription'>;
export const MultipleUserOnlineOrChannelUpdatedComponent = (props: MultipleUserOnlineOrChannelUpdatedComponentProps) => <ApolloReactComponents.Subscription<MultipleUserOnlineOrChannelUpdatedSubscription, MultipleUserOnlineOrChannelUpdatedSubscriptionVariables> subscription={MultipleUserOnlineOrChannelUpdatedDocument} {...props} />;
export type MultipleUserOnlineOrChannelUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<MultipleUserOnlineOrChannelUpdatedSubscription, MultipleUserOnlineOrChannelUpdatedSubscriptionVariables> } & TChildProps;
export function withMultipleUserOnlineOrChannelUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MultipleUserOnlineOrChannelUpdatedSubscription, MultipleUserOnlineOrChannelUpdatedSubscriptionVariables, MultipleUserOnlineOrChannelUpdatedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, MultipleUserOnlineOrChannelUpdatedSubscription, MultipleUserOnlineOrChannelUpdatedSubscriptionVariables, MultipleUserOnlineOrChannelUpdatedProps<TChildProps, TDataName>>(MultipleUserOnlineOrChannelUpdatedDocument, {
    alias: 'multipleUserOnlineOrChannelUpdated',
    ...operationOptions
  });
}
export type MultipleUserOnlineOrChannelUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<MultipleUserOnlineOrChannelUpdatedSubscription>;
export const MultipleUserOnlineOrChannelUpdated = (({
  operationKind: 'Subscription',
  document: MultipleUserOnlineOrChannelUpdatedDocument,
  primaryResultPath: ['multipleUserChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: MultipleUserOnlineOrChannelUpdatedSubscriptionVariables;
  TResult: MultipleUserOnlineOrChannelUpdatedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: MultipleUserOnlineOrChannelUpdatedSubscription['multipleUserChanged'];
});
export const KeepOnlineDocument = gql`
	mutation KeepOnline($isPresent: Boolean!) {
		user {
			keepOnline(isPresent: $isPresent) {
				__typename
				... on KeepOnlineError {
					reason
				}
			}
		}
	}
`;
export type KeepOnlineMutationFn = ApolloReactCommon.MutationFunction<KeepOnlineMutation, KeepOnlineMutationVariables>;
export type KeepOnlineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<KeepOnlineMutation, KeepOnlineMutationVariables>, 'mutation'>;
export const KeepOnlineComponent = (props: KeepOnlineComponentProps) => <ApolloReactComponents.Mutation<KeepOnlineMutation, KeepOnlineMutationVariables> mutation={KeepOnlineDocument} {...props} />;
export type KeepOnlineProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<KeepOnlineMutation, KeepOnlineMutationVariables> } & TChildProps;
export function withKeepOnline<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, KeepOnlineMutation, KeepOnlineMutationVariables, KeepOnlineProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, KeepOnlineMutation, KeepOnlineMutationVariables, KeepOnlineProps<TChildProps, TDataName>>(KeepOnlineDocument, {
    alias: 'keepOnline',
    ...operationOptions
  });
}
export type KeepOnlineMutationResult = ApolloReactCommon.MutationResult<KeepOnlineMutation>;
export type KeepOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<KeepOnlineMutation, KeepOnlineMutationVariables>;
export const KeepOnline = (({
  operationKind: 'Mutation',
  document: KeepOnlineDocument,
  primaryResultPath: ['user', 'keepOnline']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: KeepOnlineMutationVariables;
  TResult: KeepOnlineMutation;
  primaryResultPath: string[];
  TPrimaryResult: KeepOnlineMutation['user']['keepOnline'];
});
export const KeepOnlineV2Document = gql`
	mutation KeepOnlineV2($clientState: ClientState!) {
		user {
			keepOnlineV2(clientState: $clientState) {
				__typename
				... on KeepOnlineError {
					reason
				}
			}
		}
	}
`;
export type KeepOnlineV2MutationFn = ApolloReactCommon.MutationFunction<KeepOnlineV2Mutation, KeepOnlineV2MutationVariables>;
export type KeepOnlineV2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<KeepOnlineV2Mutation, KeepOnlineV2MutationVariables>, 'mutation'>;
export const KeepOnlineV2Component = (props: KeepOnlineV2ComponentProps) => <ApolloReactComponents.Mutation<KeepOnlineV2Mutation, KeepOnlineV2MutationVariables> mutation={KeepOnlineV2Document} {...props} />;
export type KeepOnlineV2Props<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<KeepOnlineV2Mutation, KeepOnlineV2MutationVariables> } & TChildProps;
export function withKeepOnlineV2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, KeepOnlineV2Mutation, KeepOnlineV2MutationVariables, KeepOnlineV2Props<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, KeepOnlineV2Mutation, KeepOnlineV2MutationVariables, KeepOnlineV2Props<TChildProps, TDataName>>(KeepOnlineV2Document, {
    alias: 'keepOnlineV2',
    ...operationOptions
  });
}
export type KeepOnlineV2MutationResult = ApolloReactCommon.MutationResult<KeepOnlineV2Mutation>;
export type KeepOnlineV2MutationOptions = ApolloReactCommon.BaseMutationOptions<KeepOnlineV2Mutation, KeepOnlineV2MutationVariables>;
export const KeepOnlineV2 = (({
  operationKind: 'Mutation',
  document: KeepOnlineV2Document,
  primaryResultPath: ['user', 'keepOnlineV2']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: KeepOnlineV2MutationVariables;
  TResult: KeepOnlineV2Mutation;
  primaryResultPath: string[];
  TPrimaryResult: KeepOnlineV2Mutation['user']['keepOnlineV2'];
});
export const UserIsOnlineDocument = gql`
	query UserIsOnline($id: ID!) {
		user {
			user(id: $id) {
				...UserWithOnline
			}
		}
	}
	${UserWithOnlineFragmentDoc}
`;
export type UserIsOnlineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserIsOnlineQuery, UserIsOnlineQueryVariables>, 'query'> & ({
  variables: UserIsOnlineQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const UserIsOnlineComponent = (props: UserIsOnlineComponentProps) => <ApolloReactComponents.Query<UserIsOnlineQuery, UserIsOnlineQueryVariables> query={UserIsOnlineDocument} {...props} />;
export type UserIsOnlineProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserIsOnlineQuery, UserIsOnlineQueryVariables> } & TChildProps;
export function withUserIsOnline<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserIsOnlineQuery, UserIsOnlineQueryVariables, UserIsOnlineProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, UserIsOnlineQuery, UserIsOnlineQueryVariables, UserIsOnlineProps<TChildProps, TDataName>>(UserIsOnlineDocument, {
    alias: 'userIsOnline',
    ...operationOptions
  });
}
export type UserIsOnlineQueryResult = ApolloReactCommon.QueryResult<UserIsOnlineQuery, UserIsOnlineQueryVariables>;
export const UserIsOnline = (({
  operationKind: 'Query',
  document: UserIsOnlineDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: UserIsOnlineQueryVariables;
  TResult: UserIsOnlineQuery;
  primaryResultPath: string[];
  TPrimaryResult: UserIsOnlineQuery['user']['user'];
});
export const UserOnlineUpdatedDocument = gql`
	subscription UserOnlineUpdated($id: ID!) {
		userChanged(config: { userId: $id, fields: [isOnline] }) {
			...UserWithOnline
			latestOnlineChannelName
			lastOnlineTime
		}
	}
	${UserWithOnlineFragmentDoc}
`;
export type UserOnlineUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserOnlineUpdatedSubscription, UserOnlineUpdatedSubscriptionVariables>, 'subscription'>;
export const UserOnlineUpdatedComponent = (props: UserOnlineUpdatedComponentProps) => <ApolloReactComponents.Subscription<UserOnlineUpdatedSubscription, UserOnlineUpdatedSubscriptionVariables> subscription={UserOnlineUpdatedDocument} {...props} />;
export type UserOnlineUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserOnlineUpdatedSubscription, UserOnlineUpdatedSubscriptionVariables> } & TChildProps;
export function withUserOnlineUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserOnlineUpdatedSubscription, UserOnlineUpdatedSubscriptionVariables, UserOnlineUpdatedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, UserOnlineUpdatedSubscription, UserOnlineUpdatedSubscriptionVariables, UserOnlineUpdatedProps<TChildProps, TDataName>>(UserOnlineUpdatedDocument, {
    alias: 'userOnlineUpdated',
    ...operationOptions
  });
}
export type UserOnlineUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserOnlineUpdatedSubscription>;
export const UserOnlineUpdated = (({
  operationKind: 'Subscription',
  document: UserOnlineUpdatedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: UserOnlineUpdatedSubscriptionVariables;
  TResult: UserOnlineUpdatedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: UserOnlineUpdatedSubscription['userChanged'];
});
export const SetOfflineDocument = gql`
	mutation SetOffline {
		user {
			setOffline
		}
	}
`;
export type SetOfflineMutationFn = ApolloReactCommon.MutationFunction<SetOfflineMutation, SetOfflineMutationVariables>;
export type SetOfflineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetOfflineMutation, SetOfflineMutationVariables>, 'mutation'>;
export const SetOfflineComponent = (props: SetOfflineComponentProps) => <ApolloReactComponents.Mutation<SetOfflineMutation, SetOfflineMutationVariables> mutation={SetOfflineDocument} {...props} />;
export type SetOfflineProps<TChildProps = {}, TDataName extends string = 'mutate'> = { [key in TDataName]: ApolloReactCommon.MutationFunction<SetOfflineMutation, SetOfflineMutationVariables> } & TChildProps;
export function withSetOffline<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, SetOfflineMutation, SetOfflineMutationVariables, SetOfflineProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withMutation<TProps, SetOfflineMutation, SetOfflineMutationVariables, SetOfflineProps<TChildProps, TDataName>>(SetOfflineDocument, {
    alias: 'setOffline',
    ...operationOptions
  });
}
export type SetOfflineMutationResult = ApolloReactCommon.MutationResult<SetOfflineMutation>;
export type SetOfflineMutationOptions = ApolloReactCommon.BaseMutationOptions<SetOfflineMutation, SetOfflineMutationVariables>;
export const SetOffline = (({
  operationKind: 'Mutation',
  document: SetOfflineDocument,
  primaryResultPath: ['user', 'setOffline']
} as unknown) as {
  operationKind: 'Mutation';
  document: any;
  TVariables: SetOfflineMutationVariables;
  TResult: SetOfflineMutation;
  primaryResultPath: string[];
  TPrimaryResult: SetOfflineMutation['user']['setOffline'];
});
export const MultipleUserOnlineUpdatedDocument = gql`
	subscription MultipleUserOnlineUpdated($ids: [ID!]!) {
		multipleUserChanged(config: { userIds: $ids, fields: [isOnline] }) {
			...UserWithOnline
			latestOnlineChannelName
			lastOnlineTime
		}
	}
	${UserWithOnlineFragmentDoc}
`;
export type MultipleUserOnlineUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<MultipleUserOnlineUpdatedSubscription, MultipleUserOnlineUpdatedSubscriptionVariables>, 'subscription'>;
export const MultipleUserOnlineUpdatedComponent = (props: MultipleUserOnlineUpdatedComponentProps) => <ApolloReactComponents.Subscription<MultipleUserOnlineUpdatedSubscription, MultipleUserOnlineUpdatedSubscriptionVariables> subscription={MultipleUserOnlineUpdatedDocument} {...props} />;
export type MultipleUserOnlineUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<MultipleUserOnlineUpdatedSubscription, MultipleUserOnlineUpdatedSubscriptionVariables> } & TChildProps;
export function withMultipleUserOnlineUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, MultipleUserOnlineUpdatedSubscription, MultipleUserOnlineUpdatedSubscriptionVariables, MultipleUserOnlineUpdatedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, MultipleUserOnlineUpdatedSubscription, MultipleUserOnlineUpdatedSubscriptionVariables, MultipleUserOnlineUpdatedProps<TChildProps, TDataName>>(MultipleUserOnlineUpdatedDocument, {
    alias: 'multipleUserOnlineUpdated',
    ...operationOptions
  });
}
export type MultipleUserOnlineUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<MultipleUserOnlineUpdatedSubscription>;
export const MultipleUserOnlineUpdated = (({
  operationKind: 'Subscription',
  document: MultipleUserOnlineUpdatedDocument,
  primaryResultPath: ['multipleUserChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: MultipleUserOnlineUpdatedSubscriptionVariables;
  TResult: MultipleUserOnlineUpdatedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: MultipleUserOnlineUpdatedSubscription['multipleUserChanged'];
});
export const UserKnuddelDocument = gql`
	query UserKnuddel($id: ID!) {
		user {
			user(id: $id) {
				...UserKnuddel
			}
		}
	}
	${UserKnuddelFragmentDoc}
`;
export type UserKnuddelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserKnuddelQuery, UserKnuddelQueryVariables>, 'query'> & ({
  variables: UserKnuddelQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const UserKnuddelComponent = (props: UserKnuddelComponentProps) => <ApolloReactComponents.Query<UserKnuddelQuery, UserKnuddelQueryVariables> query={UserKnuddelDocument} {...props} />;
export type UserKnuddelProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserKnuddelQuery, UserKnuddelQueryVariables> } & TChildProps;
export function withUserKnuddel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserKnuddelQuery, UserKnuddelQueryVariables, UserKnuddelProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, UserKnuddelQuery, UserKnuddelQueryVariables, UserKnuddelProps<TChildProps, TDataName>>(UserKnuddelDocument, {
    alias: 'userKnuddel',
    ...operationOptions
  });
}
export type UserKnuddelQueryResult = ApolloReactCommon.QueryResult<UserKnuddelQuery, UserKnuddelQueryVariables>;
export const UserKnuddel = (({
  operationKind: 'Query',
  document: UserKnuddelDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: UserKnuddelQueryVariables;
  TResult: UserKnuddelQuery;
  primaryResultPath: string[];
  TPrimaryResult: UserKnuddelQuery['user']['user'];
});
export const UserKnuddelUpdatedDocument = gql`
	subscription UserKnuddelUpdated($id: ID!) {
		userChanged(config: { userId: $id, fields: [KnuddelAmount] }) {
			...UserKnuddel
		}
	}
	${UserKnuddelFragmentDoc}
`;
export type UserKnuddelUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserKnuddelUpdatedSubscription, UserKnuddelUpdatedSubscriptionVariables>, 'subscription'>;
export const UserKnuddelUpdatedComponent = (props: UserKnuddelUpdatedComponentProps) => <ApolloReactComponents.Subscription<UserKnuddelUpdatedSubscription, UserKnuddelUpdatedSubscriptionVariables> subscription={UserKnuddelUpdatedDocument} {...props} />;
export type UserKnuddelUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserKnuddelUpdatedSubscription, UserKnuddelUpdatedSubscriptionVariables> } & TChildProps;
export function withUserKnuddelUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserKnuddelUpdatedSubscription, UserKnuddelUpdatedSubscriptionVariables, UserKnuddelUpdatedProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withSubscription<TProps, UserKnuddelUpdatedSubscription, UserKnuddelUpdatedSubscriptionVariables, UserKnuddelUpdatedProps<TChildProps, TDataName>>(UserKnuddelUpdatedDocument, {
    alias: 'userKnuddelUpdated',
    ...operationOptions
  });
}
export type UserKnuddelUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserKnuddelUpdatedSubscription>;
export const UserKnuddelUpdated = (({
  operationKind: 'Subscription',
  document: UserKnuddelUpdatedDocument,
  primaryResultPath: ['userChanged']
} as unknown) as {
  operationKind: 'Subscription';
  document: any;
  TVariables: UserKnuddelUpdatedSubscriptionVariables;
  TResult: UserKnuddelUpdatedSubscription;
  primaryResultPath: string[];
  TPrimaryResult: UserKnuddelUpdatedSubscription['userChanged'];
});
export const UserFriendStateDocument = gql`
	query UserFriendState($userId: ID!) {
		user {
			user(id: $userId) {
				id
				friendState
			}
		}
	}
`;
export type UserFriendStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserFriendStateQuery, UserFriendStateQueryVariables>, 'query'> & ({
  variables: UserFriendStateQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const UserFriendStateComponent = (props: UserFriendStateComponentProps) => <ApolloReactComponents.Query<UserFriendStateQuery, UserFriendStateQueryVariables> query={UserFriendStateDocument} {...props} />;
export type UserFriendStateProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<UserFriendStateQuery, UserFriendStateQueryVariables> } & TChildProps;
export function withUserFriendState<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, UserFriendStateQuery, UserFriendStateQueryVariables, UserFriendStateProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, UserFriendStateQuery, UserFriendStateQueryVariables, UserFriendStateProps<TChildProps, TDataName>>(UserFriendStateDocument, {
    alias: 'userFriendState',
    ...operationOptions
  });
}
export type UserFriendStateQueryResult = ApolloReactCommon.QueryResult<UserFriendStateQuery, UserFriendStateQueryVariables>;
export const UserFriendState = (({
  operationKind: 'Query',
  document: UserFriendStateDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: UserFriendStateQueryVariables;
  TResult: UserFriendStateQuery;
  primaryResultPath: string[];
  TPrimaryResult: UserFriendStateQuery['user']['user'];
});
export const GetProfilePictureCustomSizeDocument = gql`
	query GetProfilePictureCustomSize(
		$userId: ID!
		$pixelDensity: Float!
		$size: Int!
	) {
		user {
			user(id: $userId) {
				id
				profilePicture {
					urlCustomSizeSquare(
						pixelDensity: $pixelDensity
						size: $size
					)
				}
				profilePictureOverlayUrls {
					...ProfilePictureOverlays
				}
			}
		}
	}
	${ProfilePictureOverlaysFragmentDoc}
`;
export type GetProfilePictureCustomSizeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProfilePictureCustomSizeQuery, GetProfilePictureCustomSizeQueryVariables>, 'query'> & ({
  variables: GetProfilePictureCustomSizeQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
});
export const GetProfilePictureCustomSizeComponent = (props: GetProfilePictureCustomSizeComponentProps) => <ApolloReactComponents.Query<GetProfilePictureCustomSizeQuery, GetProfilePictureCustomSizeQueryVariables> query={GetProfilePictureCustomSizeDocument} {...props} />;
export type GetProfilePictureCustomSizeProps<TChildProps = {}, TDataName extends string = 'data'> = { [key in TDataName]: ApolloReactHoc.DataValue<GetProfilePictureCustomSizeQuery, GetProfilePictureCustomSizeQueryVariables> } & TChildProps;
export function withGetProfilePictureCustomSize<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<TProps, GetProfilePictureCustomSizeQuery, GetProfilePictureCustomSizeQueryVariables, GetProfilePictureCustomSizeProps<TChildProps, TDataName>>) {
  return ApolloReactHoc.withQuery<TProps, GetProfilePictureCustomSizeQuery, GetProfilePictureCustomSizeQueryVariables, GetProfilePictureCustomSizeProps<TChildProps, TDataName>>(GetProfilePictureCustomSizeDocument, {
    alias: 'getProfilePictureCustomSize',
    ...operationOptions
  });
}
export type GetProfilePictureCustomSizeQueryResult = ApolloReactCommon.QueryResult<GetProfilePictureCustomSizeQuery, GetProfilePictureCustomSizeQueryVariables>;
export const GetProfilePictureCustomSize = (({
  operationKind: 'Query',
  document: GetProfilePictureCustomSizeDocument,
  primaryResultPath: ['user', 'user']
} as unknown) as {
  operationKind: 'Query';
  document: any;
  TVariables: GetProfilePictureCustomSizeQueryVariables;
  TResult: GetProfilePictureCustomSizeQuery;
  primaryResultPath: string[];
  TPrimaryResult: GetProfilePictureCustomSizeQuery['user']['user'];
});
export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [{
      kind: 'UNION',
      name: 'AcceptFriendRequestResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'ActivateMentorSystemResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'AddPreviousSearchMutationResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'AddToWatchlistResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'WatchlistLimitReachedError'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'AnalyticsEventResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'AppDisplay',
      possibleTypes: [{
        name: 'AppDisplayHeaderbar'
      }, {
        name: 'AppDisplayScaled'
      }, {
        name: 'AppDisplayPopup'
      }, {
        name: 'AppDisplayChannelBackground'
      }, {
        name: 'AppDisplaySidebar'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'AppEvent',
      possibleTypes: [{
        name: 'AppClosedEvent'
      }, {
        name: 'AppControlPlaneEvent'
      }, {
        name: 'AppDataEvent'
      }, {
        name: 'AppOpenEvent'
      }]
    }, {
      kind: 'UNION',
      name: 'CancelFriendRequestResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'CancelFriendshipResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeAdultChannelsVisibleInHardcoreChannelsResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeAlbumOrderResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }, {
        name: 'NotFoundError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeAlbumPhotoDescriptionResponse',
      possibleTypes: [{
        name: 'ChangeAlbumPhotoDescriptionSuccess'
      }, {
        name: 'InternalError'
      }, {
        name: 'NotFoundError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeAlbumTitleResponse',
      possibleTypes: [{
        name: 'ChangeAlbumTitleSuccess'
      }, {
        name: 'InternalError'
      }, {
        name: 'NotFoundError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeMentorPauseStateResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeNicknameChangeOptionsResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangeNicknameHistoryCheckedResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'ChangePrivacyTypeResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ChannelEvent',
      possibleTypes: [{
        name: 'ChannelMessageReceived'
      }, {
        name: 'ChannelPasswordRequired'
      }, {
        name: 'ChannelUserJoined'
      }, {
        name: 'ChannelUserLeft'
      }, {
        name: 'NicklistIconRemoved'
      }, {
        name: 'NicklistIconsAdded'
      }, {
        name: 'NicklistPriorityChanged'
      }, {
        name: 'ProfilePictureChanged'
      }, {
        name: 'ProfilePictureOverlaysChanged'
      }]
    }, {
      kind: 'UNION',
      name: 'ChannelMsg',
      possibleTypes: [{
        name: 'ChannelMsgAction'
      }, {
        name: 'ChannelMsgPublic'
      }, {
        name: 'ChannelMsgSystem'
      }, {
        name: 'ChannelMsgPrivateGroup'
      }]
    }, {
      kind: 'UNION',
      name: 'ClientDisconnectReason',
      possibleTypes: [{
        name: 'ClientDisconnectReasonUnknown'
      }, {
        name: 'ClientDisconnectReasonUserLocked'
      }, {
        name: 'ClientDisconnectReasonUserDeleted'
      }, {
        name: 'ClientDisconnectReasonNicknameChanged'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ClientSettingsEvent',
      possibleTypes: [{
        name: 'ClientSettingsChanged'
      }]
    }, {
      kind: 'UNION',
      name: 'CommentAlbumPhotoResponse',
      possibleTypes: [{
        name: 'AlbumPhotoComment'
      }, {
        name: 'InternalError'
      }, {
        name: 'NotFoundError'
      }, {
        name: 'CantCommentError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ComplaintsEvent',
      possibleTypes: [{
        name: 'AutomaticComplaintCommandExpired'
      }, {
        name: 'AutomaticComplaintTriggered'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ContactFilterSettingsEvent',
      possibleTypes: [{
        name: 'ContactFilterSettingsChanged'
      }]
    }, {
      kind: 'UNION',
      name: 'ContactListEvent',
      possibleTypes: [{
        name: 'ContactAddedEvent'
      }, {
        name: 'ContactRemovedEvent'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ContactsEvent',
      possibleTypes: [{
        name: 'ReceivedFriendRequestAddedEvent'
      }, {
        name: 'ReceivedFriendRequestRemovedEvent'
      }, {
        name: 'SentFriendRequestAddedEvent'
      }, {
        name: 'SentFriendRequestRemovedEvent'
      }]
    }, {
      kind: 'UNION',
      name: 'ConversationMessageContent',
      possibleTypes: [{
        name: 'ConversationTextMessageContent'
      }, {
        name: 'ConversationForwardedMessageContent'
      }, {
        name: 'ConversationQuotedMessageContent'
      }, {
        name: 'ConversationSnapMessageContent'
      }, {
        name: 'ConversationImageMessageContent'
      }, {
        name: 'ConversationVisiblePhotoCommentMessageContent'
      }, {
        name: 'ConversationHiddenPhotoCommentMessageContent'
      }, {
        name: 'ConversationDeletedPhotoCommentMessageContent'
      }, {
        name: 'ConversationKnuddelTransferMessageContent'
      }, {
        name: 'ConversationMentorAchievedMessageContent'
      }, {
        name: 'ConversationPrivateSystemMessageContent'
      }, {
        name: 'ConversationBirthdayMessageContent'
      }, {
        name: 'ConversationNicknameChangeMessageContent'
      }]
    }, {
      kind: 'UNION',
      name: 'ConversationNestedMessageContent',
      possibleTypes: [{
        name: 'ConversationTextMessageContent'
      }, {
        name: 'ConversationImageMessageContent'
      }]
    }, {
      kind: 'UNION',
      name: 'ConversationStarterSuggestion',
      possibleTypes: [{
        name: 'ConversationStarterSuggestionText'
      }, {
        name: 'ConversationStarterSuggestionSmiley'
      }]
    }, {
      kind: 'UNION',
      name: 'CreateAlbumPhotoUploadUrlResponse',
      possibleTypes: [{
        name: 'CreateAlbumPhotoUploadUrlSuccess'
      }, {
        name: 'InternalError'
      }, {
        name: 'NotFoundError'
      }, {
        name: 'FakeSuspectError'
      }, {
        name: 'PhotoUploadBlockedError'
      }]
    }, {
      kind: 'UNION',
      name: 'CreateAlbumResponse',
      possibleTypes: [{
        name: 'Album'
      }, {
        name: 'InternalError'
      }, {
        name: 'FakeSuspectError'
      }, {
        name: 'AlbumLimitReachedError'
      }]
    }, {
      kind: 'UNION',
      name: 'DeclineFriendRequestResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'DeleteAlbumPhotoCommentResponse',
      possibleTypes: [{
        name: 'DeleteAlbumPhotoCommentSuccess'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'DeleteAlbumPhotoResponse',
      possibleTypes: [{
        name: 'DeleteAlbumPhotoSuccess'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'DeleteAlbumResponse',
      possibleTypes: [{
        name: 'DeleteAlbumSuccess'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'DisableNotificationResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'DisableSensitiveContentSettingsResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'DisableTanResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'EnableNotificationResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'EnableSensitiveContentSettingsResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'EnableTanResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'FotomeetEvent',
      possibleTypes: [{
        name: 'FotomeetEnabledChanged'
      }, {
        name: 'FotomeetMatchAdded'
      }, {
        name: 'FotomeetMatchRemoved'
      }, {
        name: 'FotomeetPotentialMatchCountChanged'
      }, {
        name: 'FotomeetPremiumAcquired'
      }]
    }, {
      kind: 'UNION',
      name: 'FriendsResponse',
      possibleTypes: [{
        name: 'FriendList'
      }, {
        name: 'FriendsHiddenByPrivacy'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'HappyMomentEvent',
      possibleTypes: [{
        name: 'DailyLoginUsed'
      }, {
        name: 'FriendRequestAccepted'
      }, {
        name: 'LongConversationOccurred'
      }]
    }, {
      kind: 'UNION',
      name: 'InitiateMessageHistoryExportResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'ExportInitiatedRecentlyError'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'KeepOnlineResponse',
      possibleTypes: [{
        name: 'KeepOnlineSuccess'
      }, {
        name: 'KeepOnlineError'
      }]
    }, {
      kind: 'UNION',
      name: 'LockInfo',
      possibleTypes: [{
        name: 'TemporaryLockInfo'
      }, {
        name: 'PermanentLockInfo'
      }, {
        name: 'UnlockedLockInfo'
      }]
    }, {
      kind: 'UNION',
      name: 'MarkAllFotomeetContactsAsSeenResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'MentorEvent',
      possibleTypes: [{
        name: 'PotentialMenteeAddedEvent'
      }, {
        name: 'PotentialMenteeRemovedEvent'
      }, {
        name: 'MentorStatusChanged'
      }, {
        name: 'MentorAchievedEvent'
      }]
    }, {
      kind: 'UNION',
      name: 'MentorStatus',
      possibleTypes: [{
        name: 'MentorStatusDisabled'
      }, {
        name: 'MentorStatusAvailable'
      }, {
        name: 'MentorStatusActive'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'MessengerEvent',
      possibleTypes: [{
        name: 'ImageAcceptStateChanged'
      }, {
        name: 'MessageHistoryExportReadyEvent'
      }, {
        name: 'MessengerConversationArchiveStateChanged'
      }, {
        name: 'MessengerConversationReadStateChanged'
      }, {
        name: 'MessengerConversationVisibilityChanged'
      }, {
        name: 'MessengerMessageChanged'
      }, {
        name: 'MessengerMessageReceived'
      }, {
        name: 'MessengerMessageStarredStateChanged'
      }, {
        name: 'MessengerSettingsChanged'
      }, {
        name: 'MessengerUserTypingStarted'
      }, {
        name: 'MessengerUserTypingStopped'
      }]
    }, {
      kind: 'UNION',
      name: 'MessengerPhotoComment',
      possibleTypes: [{
        name: 'MessengerVisiblePhotoComment'
      }, {
        name: 'MessengerHiddenPhotoComment'
      }, {
        name: 'MessengerDeletedPhotoComment'
      }]
    }, {
      kind: 'UNION',
      name: 'NicknameChangeReaction',
      possibleTypes: [{
        name: 'NicknameChangeReactionText'
      }, {
        name: 'NicknameChangeReactionSmiley'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'Node',
      possibleTypes: [{
        name: 'ChannelMessage'
      }, {
        name: 'User'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'NotificationImage',
      possibleTypes: [{
        name: 'IconNotificationImage'
      }, {
        name: 'ProfilePictureNotificationImage'
      }]
    }, {
      kind: 'UNION',
      name: 'NotificationSettingsEvent',
      possibleTypes: [{
        name: 'NotificationSettingsChanged'
      }]
    }, {
      kind: 'UNION',
      name: 'NotificationStatus',
      possibleTypes: [{
        name: 'NotificationDisabled'
      }, {
        name: 'NotificationEnabled'
      }]
    }, {
      kind: 'UNION',
      name: 'OpenAccountDeletionAppResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'DeletionAlreadyRunningError'
      }, {
        name: 'SpecialStatusOrRightsError'
      }, {
        name: 'ActivePremiumSubscriptionError'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'PaymentEvent',
      possibleTypes: [{
        name: 'PaymentFailed'
      }, {
        name: 'PaymentSucceeded'
      }]
    }, {
      kind: 'UNION',
      name: 'PhotoAuthenticatedAsJamesUrlResponse',
      possibleTypes: [{
        name: 'PhotoAuthenticatedAsJamesUrl'
      }, {
        name: 'AccessDeniedError'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'PhotoUrlResponse',
      possibleTypes: [{
        name: 'PhotoUrl'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'PhotoVerifyUrlResponse',
      possibleTypes: [{
        name: 'PhotoVerifyUrl'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ProfileEditEntry',
      possibleTypes: [{
        name: 'ProfileEditEntryChildren'
      }, {
        name: 'ProfileEditEntryCountry'
      }, {
        name: 'ProfileEditEntryDate'
      }, {
        name: 'ProfileEditEntryFreeText'
      }, {
        name: 'ProfileEditEntryRelationshipStatus'
      }, {
        name: 'ProfileEditEntrySexualOrientation'
      }, {
        name: 'ProfileEditEntrySmoker'
      }, {
        name: 'ProfileEditEntryStringList'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ProfileVisitEvent',
      possibleTypes: [{
        name: 'NewProfileVisitEvent'
      }, {
        name: 'ProfileVisitChangedEvent'
      }, {
        name: 'ProfileVisitorSystemEnabledChanged'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'ProfileVisitorsEvent',
      possibleTypes: [{
        name: 'ProfileVisitorAdded'
      }, {
        name: 'ProfileVisitorsVisibilityStatusChanged'
      }]
    }, {
      kind: 'UNION',
      name: 'Promotion',
      possibleTypes: [{
        name: 'HappyHourPromotion'
      }]
    }, {
      kind: 'UNION',
      name: 'PromotionEvent',
      possibleTypes: [{
        name: 'PromotionStartedEvent'
      }, {
        name: 'PromotionEndedEvent'
      }]
    }, {
      kind: 'UNION',
      name: 'RefreshSessionResponse',
      possibleTypes: [{
        name: 'RefreshSessionSuccess'
      }, {
        name: 'RefreshSessionError'
      }]
    }, {
      kind: 'UNION',
      name: 'RemoveFromWatchlistResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'RevalidateSessionResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'SendFriendRequestResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }, {
        name: 'SenderFriendLimitReachedError'
      }, {
        name: 'ReceiverFriendLimitReachedError'
      }, {
        name: 'NotAllowedByReceiverError'
      }, {
        name: 'TooManyRequestsError'
      }, {
        name: 'ConversationDepthNotReachedError'
      }]
    }, {
      kind: 'UNION',
      name: 'SendSlashCommandResponse',
      possibleTypes: [{
        name: 'SendSlashCommandSuccess'
      }, {
        name: 'SendSlashCommandInternalError'
      }, {
        name: 'SendSlashCommandNotFoundError'
      }, {
        name: 'SendSlashCommandNotSupportedError'
      }]
    }, {
      kind: 'UNION',
      name: 'SetFotomeetEnabledResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'SilenceCommandResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'SilenceCommandChannelNotFoundError'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'SmileyBoxEvent',
      possibleTypes: [{
        name: 'SmileyBoxListChanged'
      }]
    }, {
      kind: 'INTERFACE',
      name: 'SystemEvent',
      possibleTypes: [{
        name: 'ChannelConnectionDisconnected'
      }, {
        name: 'ClientDisconnected'
      }, {
        name: 'OpenUrl'
      }]
    }, {
      kind: 'UNION',
      name: 'TanStatus',
      possibleTypes: [{
        name: 'TanDisabled'
      }, {
        name: 'TanEnabled'
      }]
    }, {
      kind: 'UNION',
      name: 'UpdateAdultChannelsShownInLastVisitedResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'UpdateAdultChannelsVisibleResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'UpdateNotificationEnabledStatusResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'UpdateNsfwSearchEntriesVisibleResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }, {
      kind: 'UNION',
      name: 'UpdateTanEnabledStatusResponse',
      possibleTypes: [{
        name: 'Success'
      }, {
        name: 'InternalError'
      }]
    }]
  }
};
export default result;